import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BackButton from '@src/components/BackButton';
import { useNavigate, useOutletContext } from 'react-router';
import { BASE_URL } from '@src/utils/constants';
import { json } from 'stream/consumers';
import { toast } from 'react-toastify';









const YouTubeTranscript: React.FC = () => {
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [transcript, setTranscript] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const {
            setShowMobileHistory,
            setShowTop,
            setShowSidebar,
            setRightComponentName,
            setRightComponentPlaceholder,
            setRightComponentData,
            setHistory,
            setMiddleClass,
            setMobileNavStyle,
            selectedHistory,
            setShowPagePath,
            setTopExtraData,
            setCloseIdValue,
            setShowHistoryOption,
            setGmindTyping,
            setCanNavigateHome,
            setShowExpiredModal,
            setShowCompleteProfileModal,
            setIsAffiliate,
            setMenu,
            setShowInput,
            setShowHistoryOptions,
            setRightComponentClick,
            setOnDetailsFetched,
            setOnDeleteAllHistory,
            setOnDeleteHistory,
            setOnClickPage,
            setSelectedHistory,
            setValue,
            setShowRightComponent,
            setSchoolAdminPageName,
            setShowSchoolHeader,
            setShowHistory,
            setPromptSent,
            setResponse,
            setShowingHistory,
            setHistoryType,
            setPageHistory,
            showingHistory,
            response,
            promptSent,
            getHistory,
            historyType,
            showSidebar,
        } = useOutletContext<any>();

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setVideoUrl(event.target.value);
    };
    const fetchTranscript = async () => {
        const apiUrl = `${BASE_URL}/gmind/video_transcript`;
        const token = localStorage.getItem('token');
        const payload = { video_url: videoUrl }; // Correct payload structure
        setLoading(true);
        setError(null);
    
        try {
            const response = await axios.post(apiUrl, JSON.stringify(payload), {
                headers: {
                    Authorization: `Bearer ${token}`, // Replace with your token
                    'Content-Type': 'application/json', // Ensures the correct content type is sent
                },
            });
    
            setTranscript(response.data); // Set the transcript on successful response
            toast.success('Transcript fetched successfully');
        } catch (err) {
            console.error(err);
            toast.error(err.response.data.message)
    
           
        } finally {
            setLoading(false); // Always reset loading state
        }
    };

    const navigate = useNavigate();
    useEffect(()=>{
      setShowSidebar(true);
      setShowTop(true);
    },[])

    return (
        <div className="h-screen flex w-full px-10 flex-col items-start gap-3 pt-6">
            <BackButton
                onclick={() => {
                  navigate(-1);
                  
                }}
            />
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mt-2">YouTube Transcript</h2>
            <p className="text-gray-600 dark:text-gray-100 mb-4">Enter a YouTube video URL to get its transcript:</p>
            <textarea
                value={videoUrl}
                onChange={handleInputChange}
                placeholder="Paste YouTube link here..."
                rows={4}
                cols={50}
                className="border border-gray-500 dark:border-gray-200 rounded-md p-2 mb-2 w-[50%] dark:text-gray-100"
            />
            <br />
            <button
                onClick={fetchTranscript}
                disabled={!videoUrl || loading}
                className="bg-customOrange text-white px-4 py-2 rounded-md"
            >
                {loading ? 'Fetching...' : 'Get Transcript'}
            </button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {transcript && (
                <div>
                    <h3>Transcript:</h3>
                    <p>{transcript}</p>
                </div>
            )}
        </div>
    );
};

export default YouTubeTranscript;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { Steps, Button, Spin } from 'antd';
import { ReferenceStyle } from './components/reference_style';
import { Review } from './components/review';
import { ArticleTitle } from './components/title';
import { ArticleTone } from './components/tone';
import { ArticleLength } from './length';
import BackButton from '@src/components/BackButton';
import './index.scss';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import {
    Chats,
    ChatType,
    getHistory,
    handleAddHistory,
    handleDeleteAllWithTypeHistory,
    handleDeleteHistory,
} from '../../../../dashboard/endpoints';
import { BASE_URL } from '@src/utils/constants';

import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import FinishPage from './components/finishPage';
import { reference } from '@popperjs/core';
import { CircularProgress } from '@mui/material';
import { ArticleStyle } from './components/article_style';
import { ThemeContext } from '@src/theme_provider/theme';

const { Step } = Steps;

interface Section {
    title: string;
    content: string;
    reference: string[];
}
interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const ArticleWriter = ({ currentPage }: { currentPage?: Dispatch<SetStateAction<number>> }) => {
    const [current, setCurrent] = useState<number>(0);
    const [topic, setTopic] = useState('');
    const [length, setLength] = useState<number>(500);
    const [selectedTone, setSelectedTone] = useState<string>('');
    const [selectedStyle, setSelectedStyle] = useState<string>('');
    const [selectedArticleStyle, setArticleSelectedStyle] = useState<string>('');
    const baseurl = BASE_URL;
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });
    const editorInstanceRef = useRef<any>();
    const [histories, setHistories] = useState<any[]>([]);
    const [page, setPage] = useState<string>('');
    const [generating, setGenerating] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [sideBarVisible, setSidebarVisible] = useState<boolean | null>(null);
    const [basePage, setBasePage] = useState<number>(0);
    const [showEditorButton, setShowEditorButton] = useState(false);
    const UrlRef = useRef<string>('');
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });

    const [newPromptSent, setNewPromptSent] = useState('');

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const location = useLocation();
    const { error, loading, fetchDocument, document: textDocument, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [message, setMessage] = useState('');
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [canNavigate, setCanNavigate] = useState(false);
    const [basePrompt, setBasePrompt] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [content, setContent] = useState<string>();

    const [conversation, setConversation] = useState<ChatType[]>([]);
    const [sections, setSections] = useState<Section[]>([]);
    const {
        setShowMobileHistory,
        setShowTop,
        setShowSidebar,
        setRightComponentName,
        setRightComponentPlaceholder,
        setRightComponentData,
        setHistory,
        setMiddleClass,
        setMobileNavStyle,
        selectedHistory,
        setShowPagePath,
        setTopExtraData,
        setCloseIdValue,
        setShowHistoryOption,
        setGmindTyping,
        setCanNavigateHome,
        setShowExpiredModal,
        setShowCompleteProfileModal,
        setIsAffiliate,
        setMenu,
        setShowInput,
        setShowHistoryOptions,
        setRightComponentClick,
        setOnDetailsFetched,
        setOnDeleteAllHistory,
        setOnDeleteHistory,
        setOnClickPage,
        setSelectedHistory,
        setValue,
        setShowRightComponent,
        setSchoolAdminPageName,
        setShowSchoolHeader,
        setShowHistory,
        setPromptSent,
        setResponse,
        setShowingHistory,
        setHistoryType,
        setPageHistory,
        showingHistory,
        response,
        promptSent,
        getHistory,
        historyType,
        showSidebar,
        details,
    } = useOutletContext<any>();
    const [loadings, setLoadings] = useState(false);
    const [streaming, setStreaming] = useState(false);

    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [contentSockets, setContentSockets] = useState<{ [key: number]: Socket | null }>({});

    const [socketKey, setSocketKey] = useState<string[]>(['1', '2', '3', '4', '5', '6']);
    const [streamStatus, setStreamStatus] = useState<Record<string, boolean>>({});
    const [allStreamsComplete, setAllStreamsComplete] = useState<boolean>(false);
    const [htmlContent, setHtmlContent] = useState<any>();
    const essayRef = useRef<HTMLDivElement | null>(null);
    const [connectionStatus, setConnectionStatus] = useState<{ [key: string]: boolean }>({});
    const [allSocketsConnected, setAllSocketsConnected] = useState(false);
    const navigate = useNavigate();
    const {isMobile} = useContext(ThemeContext)

    const onGenerateContent = async (index: number, socketKey: string) => {
        const token = localStorage.getItem('token');

        const currentSlide = sections[index];
        const contentNumber = sections.length;
        const no = length / contentNumber;
        console.log('Number:', no);
        const newPrompt = `
        Generate content for this article subtitle "${currentSlide.title} based on ${topic} using this tone ${selectedTone}"
        ** Note the article style must match this ${selectedArticleStyle}
        **Note: The content must not be less than ${no} words.
        The content must be in the following format:
        -No title
        -No headding
        it must be detailed and informative.
         - add reference as citation between the content .
         **Note: The content must not be less than ${no} words.
         ** Response must come with html tags
         ** do not include html tag <i></i> you can add the rest of the html tags.

          ** Note all references must be in the following format:
          

            Bullet Format: Each reference starts with a bullet.
            Line-by-Line Layout: No indentation, making it easy to read.
            Standard Styles: Follows common citation styles (APA, MLA, Chicago, etc.).
            Elements of a Reference:
            Author(s)
            Year of publication
            Title (italicized for books, reports, or journals)
            Journal or book details
            URL or DOI for online sources.

            Example:

            References must be in an <ul></ul>

            References:

           <ul>
  <li>Smith, J. (2021). <em>The Effects of Climate Change on Global Agriculture</em>. Journal of Environmental Science, 34(5), 112-125. doi:10.1234/jes.2021.0045</li>
  <li>Brown, A., & Taylor, P. (2019). <em>Advances in Artificial Intelligence</em>. Cambridge University Press.</li>
  <li>World Health Organization. (2020). <em>Global Health Statistics 2020</em>. Retrieved from https://www.who.int/publications/global-health-statistics-2020</li>
</ul>

              ** Note: Do not use Reference List or sources. Use 'References' instead.
              ** Note: Content should only be taken from 1 source and avoid citing multiple references
                   ** do not include html tag <i></i> you can add the rest of the html tags.
              
              
              '

                            `;

        try {
            const selectedSocket = sockets[socketKey];
            console.log('Selected socket:', selectedSocket);
            if (selectedSocket) {
                selectedSocket.emit('data', {
                    data: {
                        messages: [
                            {
                                role: 'system',
                                content: newPrompt,
                                type: 'gpt-4',
                            },
                        ],
                        userId: details?.userId,
                    },
                });
            }
        } catch (error) {
            console.error('Error generating content:', error);
        }
    };

    const onGenerateContentReference = async (index: number, socketKey: number) => {
        const token = localStorage.getItem('token');

        const currentSlide = sections[index];
        const contentNumber = sections.length;
        const no = length / contentNumber;
        console.log('Number:', no);
        const newPrompt = `
        Using this Reference style ${selectedStyle} generate reference for the citations in this ${currentSlide.content}

        `;

        try {
            const selectedSocket = sockets[socketKey];
            console.log('Selected socket:', selectedSocket);
            if (selectedSocket) {
                selectedSocket.emit('data', {
                    data: {
                        messages: [
                            {
                                role: 'system',
                                content: newPrompt,
                                type: 'gpt-4',
                            },
                        ],
                        userId: details?.userId,
                    },
                });
            }
        } catch (error) {
            console.error('Error generating content:', error);
        }
    };

    useEffect(() => {
        sections.forEach((item, index) => {
            const socket = sockets[item.title];
            if (socket) {
                socket.on('data', (data: string) => {
                    const referencePattern = /\(([^)]+)\)|\[[^\]]+\]|https?:\/\/[^\s]+/g;

                    const newReferences = data.match(referencePattern) || [];

                    const cleanedContent = data.replace(referencePattern, '').trim();
                    setSections((prevSections) =>
                        prevSections.map((section, i) =>
                            i === index
                                ? {
                                      ...section,
                                      content: (section.content || '') + data,
                                      references: [...(section.reference || []), ...newReferences],
                                  }
                                : section,
                        ),
                    );
                });
            }


            if (socket) {
                socket.on('stream_end', (data: { streamId: string; assistant: ChatType }) => {
                    const { streamId, assistant } = data;
                    console.log('Socket ended');
                    setStreamStatus((prevStatus) => {
                        const updatedStatus = { ...prevStatus, [item.title]: true };
                        const totalStreams = sections.length;
                        const completedStreams = Object.values(updatedStatus).filter(Boolean).length;

                        console.log(`Stream progress: ${completedStreams}/${totalStreams}`);

                        if (completedStreams === totalStreams && !allStreamsComplete) {
                            setAllStreamsComplete(true);
                            console.log('✅ All streams are complete');
                        }

                        return updatedStatus;
                    });
                });
            }
        });
        return () => {
            sections.forEach((item) => {
                const socket = sockets[item.title];
                if (socket) {
                    socket.off('data');
                    socket.off('stream_end');
                }
            });
        };
    }, [sections, sockets, allStreamsComplete, streamStatus]);
    const updateSlideContent = (index: number, newContent: string) => {
        setContent(newContent);
        console.log('Content updated:', newContent);
        sections[index].content = newContent; // Update content for the specific slide
    };

    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setContent((prev) => prev + data);
            console.log(content);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;
            setStreamEndMessage(assistant);
            setShowEditorButton(true);
            setIsTyping(false);
            setCanNavigate(true);
            setConversation([assistant]);
            // if (streamId === StreamId.current) {
            StreamId.current = '';
            // setStreaming(false);
            const uuid = uuidv4();
            setDocId(uuid);
            const id = uuid;
            DocumentId.current = uuid;
            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: assistant.content,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: assistant.content,
                });
            }
            socket.emit('get-documents', user?.id);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const onGenerateTable = async () => {
        const token = localStorage.getItem('token');
        // if (!message) {
        //     toast.error("Input can't be empty");
        //     return;
        // }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: topic };
        // console.log(msgs);
        // return;
        setChats(msgs);
        setNewPromptSent('');
        setResponse('');

        const base = prompt(topic, selectedTone);
        setBasePrompt(base);
        console.log(base);

        try {
            let msgs = chats;
            msgs = { role: 'user', content: prompt(topic, selectedTone) };
            const response = await axios.post(
                `${BASE_URL}/knowledge-base`,
                {
                    model: 'gpt-4',
                    messages: [
                        {
                            role: 'system',
                            content: prompt(topic, selectedTone),
                        },
                        msgs,
                    ],
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            msgs = response.data.data.choices[0].message;
            const AIRESULT = response?.data?.data?.choices[0]?.message;
            const generatedContent = AIRESULT?.content;
            // const formattedData = formatJSON(generatedContent);
            setContent(generatedContent);
            console.log(generatedContent);
            return generatedContent;
        } catch (error: any) {
            return [];
        } finally {
        }
    };
    

    useEffect(() => {
        if (content) {
            const contentLines = content.split(/(?=\d+\.)/).filter(Boolean);

            const initialSections = contentLines.map((line) => {
                const title = line.match(/^\d+(\.\d+)*\..*/)?.[0] || '';
                return { title, content: '', reference: [] };
            });

            setSections(initialSections);

            // Clear old sockets before initializing new ones
            Object.values(sockets).forEach((socket) => {
                if (socket) socket.close();
            });

            const newSockets: { [key: string]: Socket } = {};
            const newConnectionStatus: { [key: string]: boolean } = {};

            contentLines.forEach((line) => {
                const title = line.match(/^\d+(\.\d+)*\..*/)?.[0] || '';
                if (!title) return; // Skip if no valid title is found

                const socket = io(baseurl.slice(0, -2), { transports: ['websocket'] });

                // Listen for socket connection success
                socket.on('connect', () => {
                    console.log(`✅ Socket for ${title} connected successfully!`);
                    setConnectionStatus((prev) => {
                        const updatedStatus = { ...prev, [title]: true };

                        // Check if all sockets are connected
                        const allConnected = Object.values(updatedStatus).every((status) => status === true);
                        if (allConnected) {
                            console.log('🎉 All sockets are connected!');
                            setAllSocketsConnected(true);
                        }

                        return updatedStatus;
                    });
                });

                // Handle connection errors
                socket.on('connect_error', (error) => {
                    console.error(`❌ Socket for ${title} connection error:`, error);
                });

                newSockets[title] = socket;
                newConnectionStatus[title] = false; // Initially, the socket is not connected
            });

            setSockets(newSockets);
            setConnectionStatus(newConnectionStatus);
            setAllSocketsConnected(false); // Reset the connection status for a new batch of sockets

            return () => {
                // Cleanup: Close all sockets
                Object.values(newSockets).forEach((socket) => {
                    if (socket) socket.close();
                });
            };
        } else {
            // If no content, clear sections and close sockets
            setSections([]);
            Object.values(sockets).forEach((socket) => {
                if (socket) socket.close();
            });
        }
    }, [content]);

    // useEffect(() => {
    //     if (allStreamsComplete) {
    //         console.log('🚀 Initializing sockets for all sections...');

    //         const newSockets: Socket[] = [];

    //         sections.forEach((section, index) => {
    //             const socket = io(BASE_URL, {
    //                 transports: ['websocket'],
    //             });

    //             socket.on('connect', () => {
    //                 console.log(`✅ Socket connected for section: ${index}`);
    //             });

    //             socket.on('disconnect', () => {
    //                 console.log(`❌ Socket disconnected for section: ${index}`);
    //             });

    //             // Store the socket reference
    //             newSockets[index] = socket;
    //         });

    //         setContentSockets(newSockets);

    //         // Cleanup function to close all socket connections
    //         return () => {
    //             console.log('🔌 Cleaning up socket connections...');
    //             newSockets.forEach((socket) => socket.disconnect());
    //         };
    //     }
    // }, [allStreamsComplete, sections]);

    useEffect(() => {
        sections.forEach((section, index) => {
            contentSockets[index]?.on('data', (data) => {
                console.log('Received data:', data);
                setSections((prevSections) => {
                    const updatedSections = [...prevSections];
                    updatedSections[index] = {
                        ...updatedSections[index],
                        reference: (updatedSections[index].reference || '') + data,
                    };
                    return updatedSections;
                });
            });
        });
    }, [sections, contentSockets]);

    const next = () => setCurrent(current + 1);
    const prev = () => setCurrent(current - 1);

    useEffect(() => {
        if (generate) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generate]);

    const pages = [
        <ArticleTitle topic={topic} setTopic={setTopic} />,
        <ArticleLength length={length} setLength={setLength} />,
        <ArticleStyle selectedStyle={selectedArticleStyle} setSelectedStyle={setArticleSelectedStyle} />,
        <ArticleTone selectedTone={selectedTone} setSelectedTone={setSelectedTone} />,
        <ReferenceStyle selectedStyle={selectedStyle} setSelectedStyle={setSelectedStyle} />,
        <Review contents={content ?? ''} />,
        <FinishPage
            streaming={streaming}
            setStreaming={setStreaming}
            slide={sections}
            setSlide={setSections}
            generate={generate}
            setGenerate={setGenerate}
            isStreamEnd={allStreamsComplete}
            setHtmlContent={setHtmlContent}
            handleReference={() => {
                sections.forEach((section, index) => {
                    onGenerateContentReference(index, index);
                });
            }}
        />,
    ];

    const stepsData = [
        { title: 'Topic' },
        { title: 'Length' },
        { title: 'Tone' },
        { title: 'Reference' },
        { title: 'Review' },
        { title: 'Finish' },
    ];

    return (
        <div
            className={`   ${generate ? '' : 'pt-7 pb-3  px-[2rem]'} w-full`}
            style={{ overflow: 'scroll', height: generate ? '100%' : '87%' }}
        >
            {!generate && (
                <BackButton
                    onclick={() => {
                        navigate(-1);
                    }}
                />
            )}

            {!generate && !isMobile && (
                <Steps current={current} direction="horizontal" className="steps-container mb-3 mt-8">
                    <Step title="Topic" />
                    <Step title="Length" />
                    <Step title="style" />
                    <Step title="Tone" />
                    <Step title="Reference" />
                    <Step title="Review" />
                    <Step title="Finish" />
                </Steps>
            )}

            {current === 6 && !generate && (
                <div className="flex flex-row justify-center items-center mb-4 ">
                    <div className="flex flex-row justify-center items-center  text-customOrange border border-customOrange rounded-lg p-4  ">
                        {!allStreamsComplete && <CircularProgress size={20} style={{ color: 'orange' }} />}{' '}
                        <p className="ml-2">{allStreamsComplete ? 'Content is ready' : 'Gmind is typing'}</p>
                    </div>
                </div>
            )}

            <div className="p-6  rounded-lg ">{pages[current]}</div>

            <div className="mt-6 flex justify-center space-x-4">
                {current > 0 && !generate && (
                    <button
                        onClick={() => {
                            setCurrent(current - 1);

                            if (current === 5) {
                                setAllStreamsComplete(false);
                            }
                        }}
                        className="px-4 py-2 bg-gray-300 dark:bg-gray-700 dark:text-white rounded"
                    >
                        Back
                    </button>
                )}
                {current < pages.length - 1 ? (
                    <button
                        onClick={async () => {
                            console.log(current);

                            if( current === 0 ){
                                if(!topic){
                                    toast.info('Please enter topic for your article')
                                    return;
                                }
                                setCurrent(current + 1);


                            }
                            else if(current === 1) {
                                if(!length){
                                    toast.info('Please select length  for your article')
                                    return;
                                }
                                setCurrent(current + 1);


                            }

                           else if(current === 2) {
                            if(!selectedArticleStyle){
                                toast.info('Please select article type for your article')
                                return;
                            }
                            setCurrent(current + 1);

                           }
                           else if(current === 3) {
                            if(!selectedTone){
                                toast.info('Please select tone for your article')
                                return;
                            }
                            setCurrent(current + 1);
                            
                           }if (current === 4) {
                            if (!selectedStyle){
                                toast.info('Please select reference style for your article')
                                return;
                            }
                                setLoadings(true);
                                setContent('');
                                await onGenerateTable();
                                setLoadings(false);
                                setCurrent(current + 1);
                            } else if (current === 5) {
                                if (!allSocketsConnected) {
                                    toast.info('Please wait while Gmind prepares your essay.');
                                    return;
                                }

                                setLoadings(true);
                                sections.forEach((section, index) => {
                                    onGenerateContent(index, section.title);
                                });

                                setCurrent(current + 1);
                                setStreaming(true);
                                setLoadings(false);
                            } else {
                                setCurrent(current + 1);
                            }
                        }}
                        className={`px-4 py-2 bg-customOrange text-white rounded`}
                        disabled={loadings}
                    >
                        {loadings ? (
                            'Generating'
                        ) : !allSocketsConnected && current === 5 ? (
                            <div className="flex flex-row items-center justify-center">
                                <span className="p-2">
                                    <EllipsisLoader />
                                </span>{' '}
                                <span>Preparing essay...</span>
                            </div>
                        ) : (
                            'Next'
                        )}{' '}
                    </button>
                ) : (
                    <div>
                        {!generate && (
                            <button
                                className="px-4 py-2 bg-green-500 text-white rounded"
                                onClick={async () => {
                                    // const content =  essayRef.current?.innerHTML;
                                    // console.log(content);
                                    // if (content) {
                                    //     setResponse(content);
                                    // }
                                    setGenerate(true);
                                    console.log('testing');
                                }}
                            >
                                Generate Essay
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ArticleWriter;

const prompt = (topic: string, tone: string): string => {
    return `Generate a concise table of contents with 6-8 sections based on the following topic: 
  - ${topic}
  
  **Instructions:**
  - The table of contents must have a maximum of 6 sections.
  - Use a hierarchical numbering format like this:
    1. Introduction
    2. Background
    3. Main Section 1
    4. Main Section 2
    5. Conclusion
  - Generate only titles for the sections; no content or sub-sections.
  - Do not add references or any explanatory text.
  - Provide a clean, professional structure suitable for an academic or professional document.
  
  Ensure the table of contents is concise, clean, and focused on the topic: "${topic}".`;
};

const EllipsisLoader = () => (
    <div className="flex space-x-1">
        <div className="w-1 h-1 bg-gray-500 rounded-full animate-bounce"></div>
        <div className="w-1 h-1 bg-gray-500 rounded-full animate-bounce delay-200"></div>
        <div className="w-1 h-1 bg-gray-500 rounded-full animate-bounce delay-400"></div>
    </div>
);

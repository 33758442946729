import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import logo from '@assets/gmind.png';
import whitelogo from '@assets/essay/gmind_icon_logo white 1.png';
import logoWhite from '@assets/essay/Gmind Logo White.png';
import normallogo from '@assets/gmind_white.png';
import hamburger from '@icons/menu-2.svg';
import { getDeviceId, getUrlPath } from '@src/utils/app_functions';
import { GetStartedButton } from '@src/components/GetStartedButton';
import PromoHeader from '../../pricing/components/promo_price';
import { FaHamburger } from 'react-icons/fa';
import { Menu } from 'lucide-react';
import { UserDetailsData } from '@src/core/interfaces/user';
import { GetUser } from '@src/core/endpoints/user';
import { toast } from 'react-toastify';
import useEndpoint from '../../auth/auth_endpoints';
import { acquisitionRoutePages, acquisitionRoutes, chatRoutes } from '@src/utils/app_routes';
import chat from '@assets/icons/landing-brand-hipchat.svg';

type HeaderProps = {
    onClickFaQ: () => void;
    onClickCompany?: () => void;
    onClickFeature: () => void;
    onClickPricing: () => void;
    isLoggedIn: boolean;
    isPromo?: boolean;
    showHeader?: boolean;
    showWhiteHeader?: boolean;
};

const Header = ({ onClickFaQ, onClickFeature, onClickPricing, onClickCompany, isLoggedIn = false, isPromo, showHeader = true, showWhiteHeader = false, }: HeaderProps) => {
    const navigatePage = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showSections, setShowSections] = useState<boolean>(true);
    const [isMobile, setIsMobile] = useState(true);
    const [isClose, setIsClose] = useState(true);
    const [isGuest, setIsGuest] = useState(true);
    const [showDropdown, setShowDropdown] = useState(false);
    const path = getUrlPath().pagename


    const getDetails = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            const details = await GetUser(token);
            if (details.statusCode == 1) {
                const { id, firstName, lastName, email, isVerified, plan, freeTrialAvailable, daysRemaining } =
                    details.data;
                const detailsFetched: UserDetailsData = details.data;
                setIsGuest(detailsFetched?.isGuest ?? false);
            }
        }
    };


    useEffect(() => {
        getDetails();
        const pagePathDetails = getUrlPath();
        let pagename = pagePathDetails.pagename;

        const pages = [acquisitionRoutePages.cookies, acquisitionRoutePages.privacyPolicy, acquisitionRoutePages.termsOfService];

        if (pagename != acquisitionRoutePages.home && !pages.includes(pagename) && !isMobile) {
            setShowSections(false);
        } else {
            setShowSections(true);
        }
    }, [window.location.pathname, isMobile]);

    const handleGuestLogin = async () => {
        function handleCallback() {
            const userData = localStorage.getItem('user');
            if (userData) {
                const userDetails = JSON.parse(userData);

                // if ( userDetails?.plan?.details !== undefined ){
                //     window.location.href = '/dashboard/chat';
                // }else {
                //     window.location.href = '/dashboard/pricing';
                // }
                window.location.href = chatRoutes.chat;
            }
        }

        const deviceId = await getDeviceId();

        useEndpoint()
            .guestLogin({ deviceId })
            .then((res: any) => {
                if (res?.success) {
                    localStorage.setItem('token', res?.data?.token);
                    const { id, firstName, lastName, email, isVerified, plan } = res.data;
                    localStorage.setItem('user', JSON.stringify({ id, firstName, lastName, email, isVerified, plan }));
                    handleCallback();
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            });
    }

    const currentPage = getUrlPath().pagename;
    const getLogoSrc = () => {
        const useWhiteLogo = [
            acquisitionRoutePages.productivity, 
            acquisitionRoutePages.assistant, 
            acquisitionRoutePages.researchers, 
            acquisitionRoutePages.creation, 
            acquisitionRoutePages.company, 
            acquisitionRoutePages.pricing, 
            acquisitionRoutePages.login, 
            acquisitionRoutePages.getStarted
        ].includes(currentPage);
        const isMobile = window.innerWidth < 1024; // Matches lg breakpoint
        // console.log(useWhiteLogo);
        return isMobile || useWhiteLogo ? logoWhite : logo;
    };


    return (
        <div className="w-full flex flex-col gap-1 3xl:max-w-[1800px] 3xl:mx-auto">
            {!isPromo && (
                <div
                    className={`w-full flex justify-center px-2 md:p-4 md:px-24 py-4 3xl:px-8`}
                >
                    <div className="w-full flex flex-row justify-between items-center">
                        <Link to={acquisitionRoutes.home}>
                            <img
                                src={getLogoSrc()}
                                alt="G-mind logo"
                                className="w-[102px]"
                            />
                        </Link>
                        {showHeader && (
                            <>
                                {showSections && (
                                    <div className="hidden lg:flex items-center justify-center ml-6 gap-10">
                                        {/* Features Dropdown */}
                                        <div
                                            onMouseEnter={() => setShowDropdown(true)}
                                            onMouseLeave={() => setShowDropdown(false)}
                                            className="relative"
                                        >
                                            <div
                                                className={`${getUrlPath().pagename === acquisitionRoutePages.home ? 'text-black' : 'text-white'
                                                    } text-[14px] font-normal cursor-pointer`}
                                            >
                                                Features
                                                {showDropdown && (
                                                    <div
                                                        className={`absolute z-40 top-full mt-0 shadow-lg rounded-md w-48 text-left 
                          ${getUrlPath().pagename === ''
                                                                ? 'bg-[#F1F1EA] text-black'
                                                                : 'bg-[#000A26] text-white'
                                                            }`}
                                                    >
                                                        <ul className="flex flex-col">
                                                            <li
                                                                onClick={onClickFeature}
                                                                className={`px-4 py-2 cursor-pointer hover:bg-[#1E3A8A] ${getUrlPath().pagename === acquisitionRoutePages.home
                                                                        ? 'hover:text-white'
                                                                        : ''
                                                                    }`}
                                                            >
                                                                Productivity
                                                            </li>
                                                            <li
                                                                onClick={() => navigatePage(acquisitionRoutes.creation)}
                                                                className={`px-4 py-2 cursor-pointer hover:bg-[#1E3A8A] ${getUrlPath().pagename === ''
                                                                        ? 'hover:text-white'
                                                                        : ''
                                                                    }`}
                                                            >
                                                                Content Creation
                                                            </li>
                                                            <li
                                                                onClick={() => navigatePage(acquisitionRoutes.assistant)}
                                                                className={`px-4 py-2 cursor-pointer hover:bg-[#1E3A8A] ${getUrlPath().pagename === acquisitionRoutePages.home
                                                                        ? 'hover:text-white'
                                                                        : ''
                                                                    }`}
                                                            >
                                                                AI Assistant
                                                            </li>
                                                            <li
                                                                onClick={() => navigatePage(acquisitionRoutes.researchers)}
                                                                className={`px-4 py-2 cursor-pointer hover:bg-[#1E3A8A] ${getUrlPath().pagename === ''
                                                                        ? 'hover:text-white'
                                                                        : ''
                                                                    }`}
                                                            >
                                                                Researchers
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* Company */}
                                        <div
                                            onClick={onClickCompany}
                                            className={`${getUrlPath().pagename === acquisitionRoutePages.home ? 'text-black' : 'text-white'
                                                } text-[14px] font-normal cursor-pointer`}
                                        >
                                            Company
                                        </div>

                                        {/* Pricing */}
                                        <div
                                            onClick={onClickPricing}
                                            className={`${getUrlPath().pagename === acquisitionRoutePages.home ? 'text-black' : 'text-white'
                                                } text-[14px] font-normal cursor-pointer`}
                                        >
                                            Pricing
                                        </div>

                                        {/* Blog */}
                                        <a
                                            href="https://thegmindai.com/blog/"
                                            className={`${getUrlPath().pagename === acquisitionRoutePages.home ? 'text-black' : 'text-white'
                                                } text-[14px] font-normal cursor-pointer`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Blog
                                        </a>
                                    </div>
                                )}

                                <Menu
                                    className="flex lg:hidden cursor-pointer text-white"
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                />

                                {showSections && (
                                    <div className="hidden lg:flex gap-x-3 justify-end">
                                        {!isLoggedIn && getUrlPath().pagename !== acquisitionRoutePages.pricing && (
                                            <GetStartedButton
                                                className="w-auto bg-black"
                                                onclick={() => {
                                                    handleGuestLogin();
                                                }}
                                                showforFree={true}
                                            />
                                        )}
                                        <button
                                            onClick={() => {
                                                if (isLoggedIn) {
                                                    window.localStorage.removeItem('token');
                                                    window.location.reload();
                                                    return;
                                                }
                                                navigatePage(acquisitionRoutes.login);
                                            }}
                                            className={`hidden md:flex border justify-center items-center py-1 px-6 bg-transparent rounded-lg 
                    ${getUrlPath().pagename === acquisitionRoutePages.home
                                                    ? 'text-black border-black'
                                                    : 'text-white border-white'
                                                }`}
                                        >
                                            {isLoggedIn ? 'Logout' : 'Login'}
                                        </button>
                                    </div>
                                )}

                                {isMenuOpen && showSections && (
                                    <div className="absolute w-60 top-16 right-4 bg-white shadow-md rounded-lg p-4 flex flex-col items-start gap-4 lg:hidden z-50">
                                        <div
                                            onClick={() => {
                                                onClickFaQ();
                                                setIsMenuOpen(!isMenuOpen);
                                            }}
                                            className="text-[16px] text-[var(--gmind-black)] font-medium cursor-pointer"
                                        >
                                            FAQ
                                        </div>
                                        <div
                                            onClick={() => {
                                                onClickFeature();
                                                setIsMenuOpen(!isMenuOpen);
                                            }}
                                            className="text-[16px] text-[var(--gmind-black)] font-medium cursor-pointer"
                                        >
                                            Features
                                        </div>
                                        <div
                                            onClick={() => {
                                                setIsMenuOpen(!isMenuOpen);
                                                navigatePage(acquisitionRoutes.pricing);
                                            }}
                                            className="text-[16px] text-[var(--gmind-black)] font-medium cursor-pointer"
                                        >
                                            Pricing
                                        </div>
                                        <div
                                            onClick={() => {
                                                setIsMenuOpen(!isMenuOpen);
                                                window.open(
                                                    'https://thegmindai.com/blog/',
                                                    '_blank',
                                                    'noopener,noreferrer'
                                                );
                                            }}
                                            className="text-[16px] text-[var(--gmind-black)] font-medium cursor-pointer"
                                        >
                                            Blog
                                        </div>
                                        <button
                                            onClick={() => {
                                                setIsMenuOpen(!isMenuOpen);
                                                if (isLoggedIn) {
                                                    window.localStorage.removeItem('token');
                                                    window.location.reload();
                                                    return;
                                                }
                                                navigatePage(acquisitionRoutes.login)
                                            }}
                                            className="w-full px-5 py-2 bg-white text-[var(--gmind-orange)] rounded-[10px] border border-[var(--gmind-orange)] flex justify-center items-center"
                                        >
                                            {isLoggedIn ? 'Logout' : 'Login'}
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
            {isPromo && (
                <div className="w-full">
                    <PromoHeader isLayout={true} closeClick={() => setIsClose(false)} />
                </div>
            )}
        </div>
    );
};

export default Header;
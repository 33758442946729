import React, { useEffect, useRef, useState } from 'react';

import BackButton from '@src/components/BackButton';
import { useNavigate, useOutletContext } from 'react-router';

import { toast } from 'react-toastify';
import { Table, Tooltip, Input } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import './style.scss';
import { handleKeywordResearch } from '../../business_resources/helper';
import { isHtmlString } from '@src/utils/app_functions';
import { marked } from 'marked';
import { convertHTMLToPDF, convertHTMLToWord } from '../../essay/functions';
import { Download } from '@src/pages/dashboard/components/Share/Download';

interface KeywordData {
    keyword: string;
    searchVolume: number;
    competition: string;
    cpc: string;
}

const KeywordResearcher: React.FC = () => {
    const [seedKeyword, setSeedKeyword] = useState<string>('');
    const [keywordData, setKeywordData] = useState<KeywordData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [pdfDownloading, setPdfDownloading] = useState(false);
    const [wordDownloading, setWordDownloading] = useState(false);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const UrlRef = useRef<string>('');
    const { setShowTop, setShowSidebar } = useOutletContext<any>();

    const columns = [
        {
            title: 'Keyword',
            dataIndex: 'keyword',
            key: 'keyword',
            render: (text: string) => (
                <div className="table-cell-with-copy">
                    <span>{text}</span>
                    <Tooltip title="Copy">
                        <CopyOutlined
                            className="copy-icon"
                            onClick={() => {
                                navigator.clipboard.writeText(text);
                                toast.success('Keyword copied to clipboard!');
                            }}
                        />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Search Volume',
            dataIndex: 'searchVolume',
            key: 'searchVolume',
        },
        {
            title: 'Competition',
            dataIndex: 'competition',
            key: 'competition',
        },
        {
            title: 'CPC',
            dataIndex: 'cpc',
            key: 'cpc',
        },
    ];

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSeedKeyword(event.target.value);
    };

    const fetchKeywordData = async () => {
        if (!seedKeyword) {
            toast.error('Please enter a seed keyword!');
            return;
        }

        setLoading(true);
        setError(null);
        setKeywordData([]);

        try {
            // Call the handleKeywordResearch function and extract the keywords array
            const fetchedData = await handleKeywordResearch(seedKeyword);
            console.log('Fetched Data:', fetchedData);

            // Check if fetchedData.keywords exists and is an array
            const keywords = Array.isArray(fetchedData?.keywords) ? fetchedData.keywords : [];
            console.log('Extracted Keywords:', keywords);

            // Update state with the new keywords
            setKeywordData((prevKeywordData) => [...prevKeywordData, ...keywords]);
            toast.success('Keyword research completed successfully!');
        } catch (err: any) {
            console.error('Error fetching keyword data:', err);
            const errorMessage = err.response?.data?.message || 'An error occurred.';
            setError(errorMessage);
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    async function handlePDF() {
        let data = '';
        const metaTable = document.getElementById('meta-table');
        const editorContent = metaTable?.innerHTML;
        console.log(editorContent);

        if (!editorContent) {
            toast.error('No content to download');
            return;
        }

        // Temporarily hide copy icons
        const copyIcons = metaTable?.querySelectorAll('.copy-icon');
        copyIcons?.forEach((icon) => {
            if (icon instanceof HTMLElement) {
                icon.style.display = 'none'; // Hide copy icons
            }
        });

        if (isHtmlString(editorContent)) {
            data = editorContent;
        } else {
            data = await marked(editorContent);
        }

        try {
            setPdfDownloading(true);
            await convertHTMLToPDF(data);
        } catch (error) {
            toast.error('Error downloading documents');
        } finally {
            // Restore copy icons after download
            copyIcons?.forEach((icon) => {
                if (icon instanceof HTMLElement) {
                    icon.style.display = '';
                }
            });
            setPdfDownloading(false);
        }
    }

    async function handleWord() {
        let to_html = '';
        const metaTable = document.getElementById('meta-table');
        const editorContent = metaTable?.innerHTML;

        if (!editorContent) {
            toast.error('No content to download');
            return;
        }

        // Temporarily remove buttons or specific elements
        const buttons = metaTable?.querySelectorAll('button');
        buttons?.forEach((button) => (button.style.display = 'none')); // Hide buttons

        if (isHtmlString(editorContent)) {
            to_html = editorContent;
        } else {
            to_html = await marked(editorContent);
        }

        try {
            setWordDownloading(true);
            await convertHTMLToWord(to_html);
        } catch (error) {
            toast.error('Error downloading documents');
        } finally {
            // Restore buttons after download
            buttons?.forEach((button) => (button.style.display = ''));
            setWordDownloading(false);
        }
    }

    useEffect(() => {
        setShowSidebar(true);
        setShowTop(true);
    }, []);

    return (
        <div className="h-screen flex w-full px-10 flex-col items-start gap-3 pt-6 overflow-y-auto mb-20">
            <BackButton onclick={() => navigate(-1)} />
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mt-5 md:mt-2">Keyword Researcher</h2>
            <p className="text-gray-600 dark:text-gray-100 mb-4">Enter a seed keyword to find related keywords.</p>
            <Input
                value={seedKeyword}
                onChange={handleInputChange}
                placeholder="Enter your seed keyword here"
                className="border border-gray-500 dark:border-gray-200 rounded-md p-2 md:w-[50%] w-full dark:text-gray-100"
            />
            <br />
            <button
                onClick={fetchKeywordData}
                disabled={!seedKeyword || loading}
                className={`px-4 py-2 rounded-md ${
                    !seedKeyword || loading ? 'bg-gray-300 text-black' : 'bg-customOrange text-white'
                }`}
            >
                {loading ? 'Fetching...' : 'Find Keywords'}
            </button>

            {keywordData.length > 0 && (
                <div
                    className="meta-tag-table-container ml-0 w-full mb-4 overflow-x-auto sm:ml-2 sm:overflow-hidden"
                    id="meta-table"
                >
                    <h2 className="table-header text-lg sm:text-xl font-semibold mb-4">Keyword Research Results</h2>
                    <Table
                        dataSource={keywordData}
                        columns={columns}
                        rowKey={(record) => record.keyword}
                        bordered
                        pagination={{ pageSize: 10 }}
                        className="custom-meta-table"
                    />
                </div>
            )}

            {keywordData.length > 0 && (
                <button
                    className=" text-white bg-customOrange rounded py-2 px-3 mb-20 ml-3"
                    onClick={() => setShowDownload(true)}
                >
                    Download
                </button>
            )}

            {showDownload && (
                <Download
                    handlePDF={handlePDF}
                    handleWord={handleWord}
                    url={UrlRef.current}
                    onClose={() => setShowDownload(false)}
                    isPdfDownloading={pdfDownloading}
                    isWordDownloading={wordDownloading}
                />
            )}
        </div>
    );
};

export default KeywordResearcher;

/* eslint-disable @typescript-eslint/no-unused-vars */
import Login from "@src/pages/acquisition/login/components/Login";
import Header from "../components/header";
import { useNavigate } from "react-router";
import ExampleCard from "@src/components/example_card";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { loginStructuredData } from "../strutured_data/structured_data";
import { acquisitionRoutes } from "@src/utils/app_routes";

export default function LoginPage() {
  const navigatePage = useNavigate();

  return (
    <div className="w-full flex flex-col h-screen bg-white gap-y-5 py-5 px-5 md:px-20">
      {/* <Helmet>
              <script type="application/ld+json">{JSON.stringify(loginStructuredData)}</script>
              <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
              <meta 
                name="description" 
                content="Log in to GMind AI and access a full suite of productivity tools. Manage your projects, streamline workflows, and boost efficiency with AI-driven solutions for every professional." 
              />
              <meta
                name="title"
                content="GMind AI Login | Access Your AI Productivity Tools"     
              />
              <title>GMind AI Login | Access Your AI Productivity Tools</title>
              <link rel="canonical" href="https://gmind.ai/login" />
              <meta name="keywords" content="gmind, ai, chat, gmind, gmindai" />z
            </Helmet> */}
      <Helmet>
        <title>GMind AI Login | Access Your AI Productivity Tools</title>
        <meta
          name="description" 
          content="Log in to GMind AI and access a full suite of productivity tools. Manage your projects, streamline workflows, and boost efficiency with AI-driven solutions for every professional."
        />
        <meta
          name="keywords" 
          content="GMind AI login, AI productivity tools login, GMind AI access, productivity tools, AI solutions, streamline workflows, project management"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://gmind.ai${acquisitionRoutes.login}`} />
        <meta
          property="og:title"
          content="GMind AI Login | Access Your AI Productivity Tools"
        />
        <meta
          property="og:description"
          content="Log in to GMind AI and access a full suite of productivity tools. Manage your projects, streamline workflows, and boost efficiency with AI-driven solutions for every professional."
        />
        <meta property="og:image" content="https://gmind.ai/images/login-og-image.jpg" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://gmind.ai${acquisitionRoutes.login}`} />
        <meta
          property="twitter:title"
          content="GMind AI Login | Access Your AI Productivity Tools"
        />
        <meta
          property="twitter:description"
          content="Log in to GMind AI and access a full suite of productivity tools. Manage your projects, streamline workflows, and boost efficiency with AI-driven solutions for every professional." 
        />
        <meta
          property="twitter:image"
          content="https://gmind.ai/images/login-twitter-image.jpg"
        />
        <link rel="canonical" href={`https://gmind.ai${acquisitionRoutes.login}`} />
        <meta name="robots" content="noindex, follow" />
        <script type="application/ld+json">
          {JSON.stringify(loginStructuredData)}
        </script>
      </Helmet>

      <div className="flex flex-col md:flex-row items-center justify-between">
        <Login
          handleForgetPasssword={() => navigatePage(acquisitionRoutes.forgotPassword)}
          handleCreateAccount={() => navigatePage(acquisitionRoutes.getStarted)}
        />
        <ExampleCard />
      </div>
      <ToastContainer />
    </div>
  )
}

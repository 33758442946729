import React from "react";
import gmind from "../../../assets/gmind-frame.png"
import bottom from "../../../assets/bottom-left.png"
import top from "../../../assets/top-right.png"
import people from "../../../assets/people-frame.png"
import Footer from '../components/footer';
import Faq from "../components/faq";
import { landingPagePrps } from "@src/core/interfaces/general";
import { GetStartedButton } from "@src/components/GetStartedButton";
import { useNavigate } from "react-router";
import { getDeviceId } from "@src/utils/app_functions";
import useEndpoint from "../auth/auth_endpoints";
import { toast } from "react-toastify";
import { acquisitionRoutes, chatRoutes } from "@src/utils/app_routes";




const Company = ({ faqRef, isLoggedIn }: landingPagePrps) => {
    const navigatePage = useNavigate();
    const handleGuestLogin = async () => {
        function handleCallback() {
            const userData = localStorage.getItem('user');
            if (userData) {
                const userDetails = JSON.parse(userData);

                // if ( userDetails?.plan?.details !== undefined ){
                //     window.location.href = '/dashboard/chat';
                // }else {
                //     window.location.href = '/dashboard/pricing';
                // }
                window.location.href = chatRoutes.chat;
            }
        }

        const deviceId = await getDeviceId();

        useEndpoint()
            .guestLogin({ deviceId })
            .then((res: any) => {
                if (res?.success) {
                    localStorage.setItem('token', res?.data?.token);
                    const { id, firstName, lastName, email, isVerified, plan } = res.data;
                    localStorage.setItem('user', JSON.stringify({ id, firstName, lastName, email, isVerified, plan }));
                    handleCallback();
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            });
    }

    return (
        <main>
            <div className="w-full bg-[#000A26] text-white relative z-20">
                <div className="w-full h-screen flex items-center justify-center 3xl:max-w-[1800px] 3xl:mx-auto">
                    <div className="text-center px-6 md:px-12">
                        <h2 className="text-4xl md:text-7xl font-bold ">
                            <span className="text-white font-archivo">Who</span>{" "}
                            <span className="text-[#E65109] font-archivo">We Are</span>
                        </h2>
                        <p className="mt-6 text-sm md:text-base max-w-3xl mx-auto font-poppins">
                            At GMind AI, we believe in empowering individuals and organizations to
                            achieve more with less effort. Our mission is to bring intelligent
                            automation and efficiency to everyday tasks, enabling professionals
                            across industries to maximize productivity, creativity, and impact.
                        </p>
                        <div className="mt-8 flex flex-col sm:flex-row sm:justify-center gap-4">
                            <GetStartedButton
                                isLoggedIn={isLoggedIn}
                                className="w-full sm:w-auto px-4 py-3 text-center border border-white text-white rounded-2xl hover:bg-white hover:border-[#24252A] hover:text-black transition duration-300"
                                onclick={() => {
                                    handleGuestLogin();
                                }}
                            />
                            <a
                                onClick={() => navigatePage(isLoggedIn ? chatRoutes.pricing : acquisitionRoutes.pricing)}
                                className="cursor-pointer w-full sm:w-auto flex justify-center md:items-center px-4 py-3 text-center border border-white text-white rounded-2xl hover:bg-white hover:text-black transition duration-300"
                            >
                                Explore Pricing
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <section className="w-full bg-white relative">
                <div className="w-full py-16 px-6 md:px-24 3xl:max-w-[1800px] 3xl:mx-auto 3xl:px-8">
                    {/* Top-right Image */}
                    <img
                        src={top}
                        alt="Top decorative"
                        className="absolute -top-40 right-0 w-24 sm:w-32 md:w-48 lg:w-64"
                    />

                    {/* Our Story Section */}
                    <div className="flex flex-col md:flex-row items-center md:items-center md:justify-between gap-8 relative z-10">
                        {/* Text Content */}
                        <div className="w-full md:w-1/2 text-center md:text-left">
                            <h4 className="text-[#E55109] text-xl sm:text-2xl font-normal font-workSans">Our Story</h4>
                            <p className="text-[#111111] text-2xl sm:text-3xl md:text-5xl font-medium mt-4 md:leading-[70px] tracking-wide font-jakarta ">
                                Founded on Innovation and Driven by Purpose
                            </p>
                            <p className="text-[#7E8492] text-sm sm:text-base leading-relaxed mt-4 text-justify font-jakarta ">
                                GMind AI was born out of a desire to simplify the way we work.
                                Seeing the growing demands on professionals—teachers, creators,
                                business managers, researchers, and more—we set out to create a
                                platform that uses advanced AI to handle the repetitive,
                                time-consuming tasks that often hinder productivity.
                            </p>
                        </div>

                        {/* Main Image */}
                        <div className="w-full md:w-2/5 flex justify-center">
                            <img
                                src={gmind}
                                alt="Story image"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>
                    </div>

                    {/* Our Vision Section */}
                    <div className="flex flex-col md:flex-row items-center md:justify-end gap-8 mt-16">
                        <div className="w-full md:w-[50%] text-center md:text-left">
                            <h4 className="text-[#E55109] text-xl sm:text-2xl font-normal font-workSans">Our Vision</h4>
                            <p className="text-[#7E8492] text-sm sm:text-base leading-relaxed mt-4 font-jakarta ">
                                We envision a world where technology supports human potential, where
                                AI-driven tools empower people to focus on what truly matters:
                                creativity, innovation, and meaningful work.
                            </p>
                        </div>
                    </div>

                    {/* Bottom-left Image */}
                    <img
                        src={bottom}
                        alt="Bottom decorative"
                        className="hidden md:block absolute -bottom-64 left-0 w-32 md:w-48 lg:w-64"
                    />
                </div>
            </section>
            <section className="w-full bg-white border-y border-[#E8E8E8]">
                <div className="w-full py-16 px-6 md:px-24 3xl:max-w-[1800px] 3xl:mx-auto 3xl:px-8">
                    {/* Our Mission Section */}
                    <div className="flex flex-col-reverse md:flex-row items-center md:items-center justify-between gap-8 mb-12">
                        {/* Image */}
                        <div className="w-full md:w-2/5 flex justify-center">
                            <img
                                src={gmind}
                                alt="Gmind AI"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>

                        {/* Text Content */}
                        <div className="w-full md:w-1/2 text-center md:text-left">
                            <h4 className="text-[#E55109] text-2xl font-normal font-workSans">Our Mission</h4>
                            <p className="text-[#111111] text-2xl md:text-6xl font-medium mt-4 font-jakarta md:leading-[70px]">
                                Empowering Productivity Across Professions
                            </p>
                            <p className="text-[#7E8492] text-base leading-relaxed mt-4 font-jakarta">
                                GMind AI was born out of a desire to simplify the way we work.
                                Seeing the growing demands on professionals—teachers, creators,
                                business managers, researchers, and more—we set out to create a
                                platform that uses advanced AI to handle the repetitive,
                                time-consuming tasks that often hinder productivity.
                            </p>
                        </div>
                    </div>
                    {/* Meet the Team Section */}
                    {/* <div className="bg-white w-full md:w-1/2 p-6 md:px-0 text-center md:text-left font-workSans">
                    <h4 className="text-[#E55109] text-2xl font-medium">Meet the Team</h4>
                    <p className="text-[#7E8492] text-base leading-relaxed mt-4 font-jakarta">
                        Our team is a blend of AI experts, software developers, and industry
                        professionals with a shared commitment to enhancing productivity. We
                        bring diverse skills and perspectives to our work, united by a single
                        goal: to make GMind AI the leading productivity tool for professionals
                        everywhere.
                    </p>
                    <h6 className="text-[#111111] text-lg font-bold mt-6 font-archivo">
                        Dr. Success Ojo - CEO, Cofounder
                    </h6>
                    </div> */}
                </div>
            </section>
            <section className="w-full bg-white border-b border-[#E8E8E8]">
                <div className="w-full py-16 px-6 md:px-24 flex flex-col md:flex-row items-center md:items-start md:justify-between 3xl:max-w-[1800px] 3xl:mx-auto 3xl:px-8">
                    {/* Text Content */}
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <h4 className="text-2xl md:text-6xl font-medium font-archivo mb-10">
                            Ready to Experience GMind AI?
                        </h4>
                        <p className="text-[#7E8492] text-base mt-4 font-workSans mb-10">
                            Whether you’re a teacher, creator, or business professional, we’re here to support your journey toward a more efficient, impactful workday.
                        </p>

                        {/* Buttons */}
                        <div className="flex flex-wrap justify-center md:justify-start gap-4 mt-8">
                            <GetStartedButton
                                isLoggedIn={isLoggedIn}
                                className="px-6 py-2 bg-[#24252A] text-white rounded-2xl border border-[#24252A] hover:bg-white hover:text-black transition duration-300"
                                onclick={() => {
                                    handleGuestLogin()
                                }}
                            />

                            <a
                                onClick={() => navigatePage(isLoggedIn ? chatRoutes.pricing : acquisitionRoutes.pricing)}
                                className="flex items-center gap-2 px-6 py-2 border border-[#E9EAE9] text-black rounded-2xl hover:bg-white hover:text-black transition duration-300 cursor-pointer"
                            >
                                <span>Explore Pricing</span>
                                <span>
                                    <svg
                                        width="12"
                                        height="10"
                                        viewBox="0 0 12 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.4879 5.33166L7.26914 9.55041C7.18118 9.63836 7.06189 9.68778 6.9375 9.68778C6.81311 9.68778 6.69381 9.63836 6.60586 9.55041C6.5179 9.46245 6.46849 9.34316 6.46849 9.21877C6.46849 9.09438 6.5179 8.97508 6.60586 8.88713L10.0248 5.46877H0.84375C0.71943 5.46877 0.600201 5.41938 0.512294 5.33147C0.424386 5.24357 0.375 5.12434 0.375 5.00002C0.375 4.8757 0.424386 4.75647 0.512294 4.66856C0.600201 4.58065 0.71943 4.53127 0.84375 4.53127H10.0248L6.60586 1.11291C6.5179 1.02495 6.46849 0.905656 6.46849 0.781267C6.46849 0.656877 6.5179 0.537582 6.60586 0.449626C6.69381 0.361669 6.81311 0.312256 6.9375 0.312256C7.06189 0.312256 7.18118 0.361669 7.26914 0.449626L11.4879 4.66838C11.5315 4.71191 11.566 4.76361 11.5896 4.82051C11.6132 4.87742 11.6254 4.93842 11.6254 5.00002C11.6254 5.06162 11.6132 5.12261 11.5896 5.17952C11.566 5.23643 11.5315 5.28812 11.4879 5.33166Z"
                                            fill="#24252A"
                                        />
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>

                    {/* Image */}
                    <div className="w-full md:w-2/5 flex justify-center">
                        <img
                            src={people}
                            alt="Team"
                            className="w-full object-cover"
                        />
                    </div>
                </div>
            </section>
            <section ref={faqRef} className="w-full bg-white">
                <div className="w-full py-16 px-6 md:px-24 3xl:max-w-[1800px] 3xl:mx-auto 3xl:px-8">
                    <Faq />
                </div>
            </section>
            <Footer />
        </main>
    );
};

export default Company;

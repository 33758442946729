/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import home from '@assets/home.svg';
import logoWhite from '@assets/essay/Gmind Logo White.png';
import logo from '@assets/gmind.png';
import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import right from '@assets/right_gray.svg';
import search from '@assets/search.svg';
import question_circle from '@assets/question_circle.svg';
import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { IoMdLogOut } from 'react-icons/io';
import { capitalizeFirstLetter, getUrlPath, logOutUser } from '@src/utils/app_functions';
import ContentTypingComponent from '@src/pages/dashboard/components/content_typing';
import { ArrowRight, ArrowRightIcon, Home  } from 'lucide-react';
import { ThemeContext } from '@src/theme_provider/theme';
import { Chats } from '@src/pages/dashboard/components/interface/interface';
import { acquisitionRoutes, chatRoutePages, chatRoutes } from '@src/utils/app_routes';

interface ChatList {
    setChatList: (chats: Chats[]) => void
}

const Top = ({
    onShowHistoryOption,
    showHistoryOption,
    sidebarCollapse,
    topChild,
    otherRightActions,
    onClickPage,
    breadCrumbPage,
    showPagePath,
    gmindTyping,
    canNavigateHome = true,
    packageName,
    subPage,
    setChatList,
    isGuest,
    isHistory,
    isPricing
}: {
    header?: string;
    onClick?: (v: string) => void;
    isMobile?: boolean;
    sidebarCollapse: boolean;
    showHistoryOption: boolean;
    onShowHistoryOption?: () => void;
    topChild?: ReactNode;
    otherRightActions?: string[];
    onClickPage?: () => void;
    breadCrumbPage?: string;
    showPagePath?: boolean;
    gmindTyping?: boolean;
    canNavigateHome: boolean;
    packageName?: string;
    subPage?: string;
    setChatList: (chats: Chats[]) => void;
    isGuest?: boolean
    isHistory?:boolean;
    isPricing?: boolean;
}) => {
    const location = useLocation();
    const isEditorPage = location.pathname === '/article/editor';
    const navigate = useNavigate();
    const [paths, setPaths] = useState<any[]>([]);
    const dictionary: any = {
        chat: 'Chat',
        course_content: 'Course Content',
        article: 'Article Writer',
        curriculum: 'Curriculum Planner',
        assessment: 'Create Assessment',
        transcriber: 'Transcriber',
        research: 'Make Research',
        generate_content: 'Generate Content',
        slide: 'Slider Maker',
    };
    const reverseDictionary: any = {
        'Chat': 'chat',
        'Course Content': 'course_content',
        'Article Writer': 'article',
        'Curriculum Planner': 'curriculum',
        'Create Assessment': 'assessment',
        'Make Research': 'research',
        'Generate Content': 'generate_content',
        'Slide Maker': 'slide',
        'Transcriber': 'transcriber',
    };

    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const { savedTheme } = useContext(ThemeContext);
    const outletContext = useOutletContext<ChatList | null>();
    // const setChatList = outletContext?.setChatList;
    

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAuthenticated(true);
        } else {
            setAuthenticated(false);
        }
    }, [window.location.pathname]);

    useEffect(() => {
        const { pathname } = window.location;
        const split = pathname.split('/');
        console.log(pathname);
        const formatPaths = split.map((path: string) => dictionary[path]);
        setPaths(formatPaths);
    }, [window.location.pathname]);

    function handleRoute(path: string) {
        // navigate(path);
        window.location.href = path;
    }

    function handleLogout() {
        if ( isGuest ){
            navigate(acquisitionRoutes.getStarted);
            return; 
        }

        if (!authenticated) {
            navigate(acquisitionRoutes.login);
            return;
        }
        logOutUser();
        localStorage.setItem('isLoggedOut', 'true');
        window.location.reload();
    }

    return (
        <section className={`${isHistory ? 'w-[70%]':'w-[100%]'} h-16 ${ getUrlPath().pagename != 'pricing' ?  'bg-custom-opacity-2 dark:bg-[#212121]' : 'bg-[#031A57]' }  py-5 flex sticky top-0 z-[300] items-center px-4 justify-between gap-8`}>
            <div className="flex items-center gap-3">
                {/* <img src={home} alt="" className="w-[1rem]" /> */}
                <Home onClick={() => {
                   if (!canNavigateHome) return;
                   navigate(chatRoutes.chat); 
                }} className={`h-3 w-3 ${isPricing ? 'text-white' : 'text-black dark:text-white'}` }/>

                <div className={`flex items-center ${isPricing ? 'text-white': 'text-black dark:text-white'} gap-1 mt-[0.2rem]`}>
                    <p
                        role="button"
                        onClick={() => (canNavigateHome ? handleRoute(chatRoutes.chat) : handleRoute(acquisitionRoutes.home))}
                        className="m-0 text-md"
                    >
                        Home
                    </p>
                    {
                        ( 
                            paths.length > 0 && (
                                breadCrumbPage   
                            )
                        ) 
                        && 
                        <ArrowRightIcon className="h-3 w-3 text-black dark:text-white" />
                    }
                    {breadCrumbPage && (
                        <div className="flex gap-1 items-ceneter">
                            <p
                                role="button"
                                className=" m-0 text-md font-light text-nowrap"
                                onClick={() => {
                                  const path = getUrlPath().pagename;
                                  localStorage.removeItem('activeChat');
                                  const lastChat = localStorage.getItem('lastChat');
                                  const lastChatWithInternet = localStorage.getItem('lastChatWithInternet');
                                  
                                  if (lastChat && path === chatRoutePages.chat ) {
                                      try {
                                          // Parse JSON and ensure it matches the expected format
                                          const parsedChatList = JSON.parse(lastChat) as Chats[];
                                          console.log(parsedChatList)
                                  
                                          // Check if parsedChatList is indeed an array
                                          if (Array.isArray(parsedChatList)) {
                                            console.log("I am here");
                                            
                                            if ( setChatList != undefined ){
                                                setChatList(parsedChatList);
                                                console.log("I am setting");
                                            }
                                          } else {
                                              console.error("Parsed lastChat is not an array of Chats.");
                                          }
                                      } catch (error) {
                                          console.error("Failed to parse lastChat from localStorage", error);
                                      }
                                      return;
                                      
                                  }else if (lastChatWithInternet && path === chatRoutePages.smartChat ) {
                                    try {
                                        // Parse JSON and ensure it matches the expected format
                                        const parsedChatList = JSON.parse(lastChatWithInternet) as Chats[];
                                        console.log(parsedChatList)
                                
                                        // Check if parsedChatList is indeed an array
                                        if (Array.isArray(parsedChatList)) {
                                          console.log("I am here");
                                          
                                          if ( setChatList != undefined ){
                                              setChatList(parsedChatList);
                                              console.log("I am setting");
                                          }
                                        } else {
                                            console.error("Parsed lastChat is not an array of Chats.");
                                        }
                                    } catch (error) {
                                        console.error("Failed to parse lastChat from localStorage", error);
                                    }
                                    return;
                                    
                                }
                                  
                                  
                                  path ==='editor'? navigate(-1): handleRoute(`/${path}`);
                                }}
                            >
                                {capitalizeFirstLetter(breadCrumbPage)}
                            </p>
                        </div>
                    )}
                    {showPagePath &&
                        !breadCrumbPage &&
                        paths.map((p, i) => (
                            <div key={i} className="flex gap-1 items-center">
                                <p
                                    style={paths.length === i + 1 ? { fontWeight: '600' } : { fontWeight: '300' }}
                                    role="button"
                                    className=" m-0 text-md font-light text-nowrap"
                                    onClick={() => {
                                        if (onClickPage) {
                                            onClickPage();
                                            return;
                                        }
                                        const new_path = paths
                                            .slice(0, i + 1)
                                            .map((c) => reverseDictionary[c])
                                            .join('/');
                                        localStorage.removeItem('activeChat');
                                        handleRoute(`/${new_path}`);
                                    }}
                                >
                                    {p}
                                </p>
                                {/* {paths.length !== i + 1 && <img src={right} className="w-3" />} */}
                            </div>
                        ))
                    }
                    {showPagePath && topChild}
                    {subPage && <ArrowRightIcon className="h-3 w-3 text-black dark:text-white" />}
                    {subPage && <div className="text-md font-light">{subPage}</div>}
                </div>
            </div>
{/* 
            <img
                onClick={() => {
                    if (!canNavigateHome) return;
                    navigate('/');
                }}
                role="button"
                src={ savedTheme === 'dark' ? logoWhite : logo}
                className="w-[8rem]"
                alt=""
            /> */}
            <div className="flex items-center gap-2 pe-10">
                {packageName && (
                    <button onClick={() => {}} type="button" className="text-brandOrange w-fit p-1 px-2 text-xs">
                        {packageName}
                    </button>
                )}
                {showHistoryOption && !isGuest && !isPricing && (
                    <button
                        onClick={() => onShowHistoryOption && onShowHistoryOption()}
                        type="button"
                        className="text-black dark:text-white w-fit p-1 px-3 text-md"
                    >
                        {!isHistory ?'History':null}
                    </button>
                )}
                <button
                    onClick={handleLogout}
                    className={`p-2 transition-all flex items-center hover:bg-brandOrange hover:text-white gap-2 text-md justify-center rounded-md w-full ${isPricing ? 'text-white' : 'text-black dark:text-white'} `}
                    type="button"
                >
                    <IoMdLogOut className={`text-lg ${isPricing ? 'text-white' : 'text-black dark:text-white' } rotate-180`} />
                    <span> { isGuest ? 'Signup' : authenticated ? 'Logout' : 'Login'}</span>
                </button>
                {otherRightActions?.map((action) => {
                    return (
                        <button className="text-brandOrange p-1 px-3 text-xs" key={action} type="button">
                            {action}
                        </button>
                    );
                })}
            </div>
        </section>
    );
};

export default Top;

import LoadingComponent from '@src/components/LoadingComponent';
import { ChatType } from '@src/core/interfaces/chat';
import { handleAddHistory } from '@src/pages/dashboard/endpoints';
import { prompts } from '@src/utils/prompts';
import { Button, Form, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import MarkdownDesign from '@src/components/Markdown';
import { useNavigate } from 'react-router';
import { Chats } from '@src/pages/dashboard/components/interface/interface';
import TextArea from 'antd/es/input/TextArea';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import { LessonMaterialProps } from '../interface';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import './style.scss';
import { extractTextFromPDF } from '@src/pages/dashboard/function/pdf_doc_helper';

const ClassLetter = ({ onGenerate, setTopic }: LessonMaterialProps) => {
    const [response, setResponse] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [description, setDescription] = useState<string>('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const bottomRef = useRef<HTMLDivElement>(null);
    const [celebrations, setCelebrations] = useState('');
    const [announcements, setAnnouncements] = useState('');
    const [additionalContent, setAdditionalContent] = useState('');
    const recognitionRef = useRef<any>(null);
    const [recording, setRecording] = useState<{[key:string]:boolean}>({
        celebrations:false,
        announcements:false,
        additionalContent:false,

    });
    const [form] = Form.useForm();
    const [finalTranscript, setFinalTranscript] = useState('');
    const [recordingField, setRecordingField] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null); // Ref for the file input
    const fileInputRef2 = useRef<HTMLInputElement>(null); // Ref for the file input
    const fileInputRef3 = useRef<HTMLInputElement>(null); // Ref for the file input
    const [docData1, setDocData1] = useState<string | ArrayBuffer | null>(null);
    const [docData2, setDocData2] = useState<string | ArrayBuffer | null>(null);
    const [docData3, setDocData3] = useState<string | ArrayBuffer | null>(null);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const content = e.target?.result;

                if (file.type === 'application/pdf') {
                    const pdfText = await extractTextFromPDF(file);

                    setDocData1(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData1(result.value);
                    form.setFieldsValue({ [field]: result.value });
                } else if (content) {
                    setDocData1(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };
            reader.onerror = () => console.error('Error reading file');

            reader.readAsArrayBuffer(file);
        }
    };

    const handleFileChange1 = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const content = e.target?.result;

                if (file.type === 'application/pdf') {
                    const pdfText = await extractTextFromPDF(file);

                    setDocData2(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData2(result.value);
                    form.setFieldsValue({ [field]: result.value });
                } else if (content) {
                    setDocData2(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };
            reader.onerror = () => console.error('Error reading file');

            reader.readAsArrayBuffer(file);
        }
    };

    const handleFileChange2 = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const content = e.target?.result;

                if (file.type === 'application/pdf') {
                    const pdfText = await extractTextFromPDF(file);

                    setDocData3(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData3(result.value);
                    form.setFieldsValue({ [field]: result.value });
                } else if (content) {
                    setDocData3(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };
            reader.onerror = () => console.error('Error reading file');

            reader.readAsArrayBuffer(file);
        }
    };

    const onPaperClipClick = () => {
        // Programmatically trigger the file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onPaperClipClick2 = () => {
        // Programmatically trigger the file input click
        if (fileInputRef2.current) {
            fileInputRef2.current.click();
        }
    };

    const onPaperClipClick3 = () => {
        // Programmatically trigger the file input click
        if (fileInputRef3.current) {
            fileInputRef3.current.click();
        }
    };

    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
        }
    }, [fileInputRef]);
    useEffect(() => {
        if (fileInputRef2.current) {
            fileInputRef2.current.value = ''; // Reset the file input value
        }
    }, [fileInputRef2]);

    useEffect(() => {
        if (fileInputRef3.current) {
            fileInputRef3.current.value = ''; // Reset the file input value
        }
    }, [fileInputRef3]);

    const handleMicrophoneClicked = (field: string) => {
        if (recording[field] && recordingField !== field) {
            toast.warning('Please stop the current recording before starting a new one.');
            return; // Prevent starting a new recording
        }

        setRecordingField(field); // Set the current field being recorded
        handleMicrophone(field);
        console.log(recordingField);
    };

    const handleMicrophoneClicked1 = (field: string) => {
        if (recording[field] && recordingField !== field) {
            toast.warning('Please stop the current recording before starting a new one.');
            return; // Prevent starting a new recording
        }

        setRecordingField(field); // Set the current field being recorded
        handleMicrophone(field);
        console.log(recordingField);
    };

    
    const handleMicrophoneClicked2 = (field: string) => {
        console.log(field);
        if (recording[field] && recordingField !== field) {
            toast.warning('Please stop the current recording before starting a new one.');
            return; // Prevent starting a new recording
        }

        setRecordingField(field); // Set the current field being recorded
        handleMicrophone(field);
        console.log(recordingField);
    };

    async function handleMicrophone(field: string) {
        if (recording[field]) {
            stopRecording(field);
        } else {
            startRecording(field);
        }
    }


    const startRecording = (field: string) => {
        try {
            toast.success('Recording in progress...');
            setRecording((prevRecording) => ({ ...prevRecording, [field]: true }));
            recognitionRef.current = new (window as any).webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setFinalTranscript(texts.join(' '));
                console.log('text', texts);
                console.log('final:', finalTranscript);
                // Use getFieldsValue and setFieldsValue for Antd form
                // Use getFieldsValue and setFieldsValue for Antd form
                const fields = form.getFieldsValue();
                console.log('Current fields:', fields);

                // Set the updated value in the correct field
                form.setFieldsValue({
                    [field]: texts.join(''), // Update the specific field
                });
                console.log('Current fields:', fields);

                console.log(`Updated ${field}:`, texts);
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const stopRecording = (field: string) => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                recognitionRef.current.stop();
                setRecording((prevRecording) => ({ ...prevRecording, [field]: false })); // Reset after stopping
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const onFinish = (values: any) => {
        let promptMessage = '';

        const celebrations = values.celebrations;
        const announcements = values.announcements;
        const additionalContent = values.additionalContent;

        // check if all data is passed
        if (!celebrations || !announcements) {
            toast.error('Please enter all prompts field');
            return;
        }

        let informationData = `
            Celebrations: ${celebrations}
            Announcements: ${announcements}
            ${additionalContent ? `Additional Information: ${additionalContent}` : ''}
        `;

         promptMessage = `
        You are tasked with creating a professional, engaging, and visually appealing class newsletter. The newsletter should include the following information:
        
        ${informationData}
        
        Requirements:
        - Start with a welcoming headline and an engaging introduction.
        - Use clear sections with headings for each category of information.
        - Include a highlights section for notable achievements, upcoming events, or important announcements.
        - Maintain a friendly yet professional tone suitable for students, parents, and educators.
        - Use bulleted lists or numbered points for clarity when appropriate.
        - Provide a closing note with contact information or a motivational quote to leave a lasting impression.
        
        Ensure the newsletter is creative, well-organized, and easy to read.
        `;
        

        onGenerate(promptMessage, false);
    };

    // Other useEffects and functions...

    return (
        <Form form={form} onFinish={onFinish} className="flex flex-col py-4 px-10 space-y-4" layout="vertical">
            <h1 className="text-xl font-bold dark:text-white">Class Newsletter</h1>
            <p className='dark:text-gray-300'>Generate a newsletter to send to families weekly.</p>

            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 w-full">
                <Form.Item label="Celebrations from the week:" name="celebrations" className="w-full md:w-[47%]">
                    <div className="flex items-start border dark:border-white border-gray-700 rounded">
                        <div className="flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                            <button
                                type="button"
                                onClick={() => handleMicrophoneClicked('celebrations')}
                                className={`hover:text-green-500 transition-colors duration-200 ${recording['celebrations'] ? 'text-customOrange dark:text-customOrange' : 'dark:text-white text-gray-500'}`}
                            >
                                <AudioOutlined className='' />
                            </button>
                            <PaperClipOutlined className='dark:text-white' onClick={onPaperClipClick} />

                            <input
                                id="file-input"
                                ref={fileInputRef}
                                type="file"
                                accept=".doc,.docx,.pdf"
                                style={{ display: 'none' }} // Hide the input element
                                onChange={(e) => handleFileChange(e, 'celebrations')}
                            />
                        </div>
                        <TextArea
                            value={form.getFieldValue('celebrations'|| docData1 )} // This makes it controlled
                            onChange={(e) => {
                                setDocData1(e.target.value)
                                form.setFieldsValue({ celebrations: e.target.value })
                            }}
                            rows={4}
                            className="p-2 rounded-none border-none w-full focus:outline-none"
                            placeholder="Enter a topic"
                            // value={celebrations}
                            style={{ outline: 'none', boxShadow: 'none' }}
                        />
                    </div>
                </Form.Item>

                <Form.Item label="Important Announcements:" name="announcements" className="w-full md:w-[47%]">
                    <div className="flex items-start border dark:border-white border-gray-700 rounded">
                        <div className="flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                            <button
                                type="button"
                                onClick={() => handleMicrophoneClicked1('announcements')}
                                className={`hover:text-green-500 transition-colors duration-200 ${recording['announcements'] ? 'text-customOrange dark:text-customOrange' : 'dark:text-white text-gray-500'}`}
                            >
                                <AudioOutlined className='' />
                            </button>
                            <PaperClipOutlined className='dark:text-white' onClick={onPaperClipClick2} />

                            <input
                                id="file-input"
                                ref={fileInputRef2}
                                type="file"
                                accept=".doc,.docx,.pdf"
                                style={{ display: 'none' }} // Hide the input element
                                onChange={(e) => handleFileChange1(e, 'announcements')}
                            />
                        </div>
                        <TextArea
                            value={form.getFieldValue('announcements' || docData2)} // This makes it controlled
                            onChange={(e) => {
                                setDocData2(e.target.value)
                                form.setFieldsValue({ announcements: e.target.value })}}
                            rows={4}
                            className="p-2 rounded-none border-none w-full focus:outline-none"
                            placeholder="Enter a topic"
                            style={{ outline: 'none', boxShadow: 'none' }}
                        />
                    </div>
                </Form.Item>
            </div>

            <Form.Item label="Additional Content (Optional):" name="additionalContent" className="w-full md:w-[47%]">
                <div className="flex items-start border dark:border-white  border-gray-700 rounded">
                    <div className="flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                        <button
                            type="button"
                            onClick={() => handleMicrophoneClicked2('additionalContent')}
                            className={`hover:text-green-500 transition-colors duration-200 ${recording[additionalContent] ? 'text-customOrange dark:text-customOrange' : 'dark:text-white text-gray-500'}`}
                        >
                            <AudioOutlined/>
                        </button>
                        <PaperClipOutlined className='dark:text-white' onClick={onPaperClipClick3} />

                        <input
                            id="file-input"
                            ref={fileInputRef3}
                            type="file"
                            accept=".doc,.docx,.pdf"
                            style={{ display: 'none' }} // Hide the input element
                            onChange={(e) => handleFileChange2(e, 'additionalContent')}
                        />
                    </div>
                    <TextArea
                        // value={additionalContent}
                        value={form.getFieldValue('additionalContent' || docData3)} // This makes it controlled
                        onChange={(e) => {
                            setDocData3(e.target.value)
                            form.setFieldsValue({ additionalContent: e.target.value })
                        }}
                        rows={4}
                        className="p-2 rounded-none border-none w-full focus:outline-none"
                        placeholder="Enter a topic"
                        style={{ outline: 'none', boxShadow: 'none' }}
                    />
                </div>
            </Form.Item>

            <Form.Item className='bg-transparent'>
                <button 
                    type="submit"
                    className=" no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-customOrange text-white rounded-lg hover:none"
                >
                    Generate
                </button>
            </Form.Item>
        </Form>
    );
};

export default ClassLetter;

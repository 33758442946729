/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useRef, useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { BASE_URL } from '@src/utils/constants';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import { Chats, ChatType } from '@src/pages/dashboard/endpoints';
import { useLocation, useOutletContext } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import Markdown from '@src/components/Markdown';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import Profile from '@assets/Avatar.svg';
import NewEditorPage from '@src/pages/dashboard/pages/essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '@src/pages/dashboard/pages/essay/sun_editor copy';

interface Section {
    title: string;
    content: string;
    reference?: string[];
}

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const FinishPage = ({
    slide,
    isStreamEnd,
    generate,
    setGenerate,
    setHtmlContent,
    essayRef,
    setStreaming,
}: {
    slide: Section[];
    setSlide: React.Dispatch<React.SetStateAction<Section[]>>;
    isStreamEnd?: boolean;
    generate?: boolean;
    setGenerate?: React.Dispatch<React.SetStateAction<boolean>>;
    setHtmlContent?: any;
    essayRef?: any;
    streaming?: boolean;
    setStreaming?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [content, setContent] = useState<string>('');
    const [socket, setSocket] = useState<Socket | null>(null);

    const [socketKey, setSocketKey] = useState<string[]>(['1', '2', '3', '4', '5', '6']);
    const [references, setReferences] = useState<Section[]>([]);
    const editorInstanceRef = useRef<any>();

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const { documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const [isTyping, setIsTyping] = useState(false);
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const {
        setResponse,
        setShowingHistory,
        showingHistory,
        response,
        promptSent,
    } = useOutletContext<any>();

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const extractReferences = (content: Section) => {
        if (!content?.content) {
            console.warn('Content is undefined or does not have a "content" property', content);
            return content; // Return the unmodified content to avoid breaking the loop
        }

        // Updated pattern to capture everything after "References:" until the end of the content
        const referenceSectionPattern = /References:(.*)$/s;
        const referencesListPattern = /References List:(.*)$/s;
        const referenceSectionMatch =
            content.content.match(referenceSectionPattern) || content.content.match(referencesListPattern);

        let fullReferences: string[] = [];
        let cleanedContent = content.content;

        if (referenceSectionMatch) {
            // Extract only the text after "References:" or "References List:"
            const referenceText = referenceSectionMatch[1].trim();

            // Split by year in parentheses, keeping the year as part of the reference
            fullReferences = referenceText
                .split(/(?=\(\d{4}\))/) // Lookahead for a year like (2010), (1996), etc.
                .map((ref) => ref.trim())
                .filter(Boolean);

            // Remove the reference section from the main content
            cleanedContent = cleanedContent.replace(referenceSectionMatch[0], '').trim();
            console.log('Full References:', fullReferences);
        }

        return {
            ...content,
            content: cleanedContent,
            reference: fullReferences,
        };
    };

    useEffect(() => {
        setStreaming?.(true);
    }, []);

    useEffect(() => {
        if (isStreamEnd) {
            toast.success('Content generated successfully');
            const updatedSections = slide.map((section) => extractReferences(section));
            setReferences(updatedSections);
            console.log(updatedSections);

            const essayContent = document.getElementById('essay')?.innerHTML;

            setHtmlContent(essayContent);
        }
    }, [isStreamEnd]);

    useEffect(() => {
        if (references.length > 0) {
            const essayContent = references
            .map((section) => `<h1>${section.title}</h1><p>${section.content}</p>`)
            .join('');

        // Collect all references
        const allReferences = references
            .flatMap((section) => section.reference || []) // Flatten and filter out undefined references
            .map((ref, index) => `<p> ${ref}</p>`)
            .join('');

        // Combine essay content and references
        const finalEssayContent = essayContent + (allReferences ? `<h2>References</h2>${allReferences}` : '');
        setHtmlContent(finalEssayContent);
        console.log('Essay Content:', finalEssayContent);
        setResponse(finalEssayContent);
        }
    }, [references]);

    useEffect(() => {
        setStreaming?.(false);
    }, [isStreamEnd]);



    return (
        <div id="essay" ref={essayRef} >
            {!generate ? (
                <>
                    <div>
                        {slide.map((s, index) => (
                            <div key={index}>
                                <h1 className="text-lg font-bold text-gray-800 dark:text-white mt-2">
                                    {' '}
                                    {`${s.title.replace(/[\d.]+/g, '')}`}
                                </h1>
                                <Markdown>{s.content}</Markdown>
                            </div>
                        ))}
                        <p className="text-lg font-bold text-gray-800">References</p>

                        {slide.map((s, index) => (
                            <p key={index} className="text-sm text-gray-600 mt-2">
                                {s.reference}
                            </p>
                        ))}
                    </div>
                    <div style={{ display: 'none' }} id="extracted-content">
                        {references.map((s, index) => (
                            <div key={index}>
                                <h1 className="text-lg font-bold text-gray-800 dark:text-white mt-2">
                                    {' '}
                                    {`${s.title.replace(/[\d.]+/g, '')}`}
                                </h1>
                                <Markdown>{s.content}</Markdown>
                            </div>
                        ))}

                        <h1 className="text-lg font-bold text-gray-800">References:</h1>
                        {references.map((s, index) => (
                            <Markdown key={index}>{String(s.reference)}</Markdown>
                        ))}
                    </div>
                </>
            ) : (
                <div className="h-screen mb-20">
                <NewEditorPage
                    editorInstanceRef={editorInstanceRef}
                    regenerate={() => {}}
                    latestRespone={latestRespone}
                    setLatestRespone={setLatestRespone}
                    highlightedTexts={highlightedTexts}
                    setHighlightedTexts={setHighlightedTexts}
                    content={
                        <NewSunEditorComponent
                            latestRespone={latestRespone}
                            setLatestRespone={setLatestRespone}
                            highlightedTexts={highlightedTexts}
                            setHighlightedTexts={setHighlightedTexts}
                            editorInstanceRef={editorInstanceRef}
                            hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                            initial_content={response}
                            onChangeEditorContent={(e: string) => {
                                setEditorContent(e);
                                const newtext = e;
                                console.log('newtext', newtext);

                                console.log('docId', docId);
                                console.log('userDetails?.userId', userDetails?.userId);

                                sendChanges(docId, userDetails?.userId, e);
                            }}
                        />
                    }
                    showTopNavBar={false}
                    showInvite={true}
                    isTyping={isTyping}
                    Profile={Profile}
                    promptSent={promptSent}
                    showingHistory={showingHistory}
                    setGenerate={setGenerate}
                    setShowingHistory={setShowingHistory}
                    generate={generate}
                    setEditorContent={setEditorContent}
                    editorContent={editorContent}
                    translatedContent={translatedContent}
                    setTranslatedContent={setTranslatedContent}
                    humanizedContent={humanizedContent}
                    setHumanizedContent={setHumanizedContent}
                    setUserOnDoc={setUserOnDoc}
                    userOnDoc={userOnDoc}
                />
                </div>
            )}
        </div>
    );
};
export default FinishPage;

import { useState } from "react";

const ImageItem = ({ src, alt, key, className }: {src: string; alt: string; key: number; className: string; }) => {
    const [isLoaded, setIsLoaded] = useState(true);
  
    return (
      <>
        {isLoaded ? (
            <img
                className={className}
                key={key}
                src={src}
                alt={alt}
                onLoad={() => setIsLoaded(true)}
                onError={() => setIsLoaded(false)}
                style={{ display: isLoaded ? 'block' : 'none' }}
                loading="lazy"
            />
        ): (
            <div className="mt-2"></div>
        )
    }
      </>
    );
};

export default ImageItem;
import { cloud_name, upload_preset } from './component/Forms/second-form';

const base64ToBlob = (base64: any, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};

export const uploadToCloudinary = async (imageUrl: string | File) => {
    try {
        const imageBlob = ( typeof imageUrl === 'string' ) ? base64ToBlob(imageUrl, 'image/png'): imageUrl;

        const formData = new FormData();
        formData.append('file', imageBlob);
        formData.append('upload_preset', upload_preset);

        const uploadResponse = await fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, {
            method: 'POST',
            body: formData,
        });
        const responseData = await uploadResponse.json();
        // console.log(responseData);
        return responseData.secure_url;
    } catch (error) {
        console.error('Error uploading image to Cloudinary:', error);
        throw new Error('Failed to upload image to Cloudinary');
    }
};

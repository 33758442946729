import React from "react";
import { Card } from "antd";

const styles = [ 'High school', 'College','Post graduate'];

export const AGrade = ({selectedGrade, setSelectedGrade}: {selectedGrade: string, setSelectedGrade: React.Dispatch<React.SetStateAction<string>>}) => {
 

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="text-2xl font-semibold mb-4 dark:text-gray-300">Grade level</h2>
      <p className="text-gray-600 dark:text-gray-300 mb-6">Select grade level</p>
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }} className="w-full items-center justify-center">
        {styles.map((style) => (
          <Card
            key={style}
            hoverable
            className='w-full md:w-auto flex flex-row items-center justify-center'
            style={{
              border: style === selectedGrade ? "2px solid #1890ff" : "1px solid #ddd",
            }}
            onClick={() => setSelectedGrade(style)}
          >
            {style}
          </Card>
        ))}
      </div>
    </div>
  );
};

import { GetStartedButton } from '@src/components/GetStartedButton';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router';
import Playground from '../components/playground';
import Faq from '../components/faq';
import { landingPagePrps } from '@src/core/interfaces/general';
import Footer from '../components/footer';
import { useEffect } from 'react';
import bottom from "../../../assets/bottom-left.png"
import top from "../../../assets/top-right.png"
import content from "../../../assets/features/content.png"
import writing from "../../../assets/features/writing.png"
import editor from "../../../assets/features/editor.png"
import research from "../../../assets/features/Research.png"
import analytics from "../../../assets/features/Analytics.png"
import phone from "../../../assets/features/phone.png"
import useEndpoint from '../auth/auth_endpoints';
import { getDeviceId } from '@src/utils/app_functions';
import { toast } from 'react-toastify';
import Testimonials from '../components/about/testimonial';
import { featuresData } from '../strutured_data/structured_data';
import { acquisitionRoutes, chatRoutes } from '@src/utils/app_routes';






interface Feature {
    id: number;
    title: string;
    text: string;
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const FeaturesScreen = ({ faqRef, featuresRef, isLoggedIn }: landingPagePrps) => {
    const query = useQuery();
    const navigatePage = useNavigate();

    const scrollToSection = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const scrollToFeature = query.get('showSection') || '';

        if (scrollToFeature === '1') {
            if (featuresRef) {
                scrollToSection(featuresRef);
            }
        }

        navigatePage(
            {
                pathname: window.location.pathname,
                search: '',
            },
            { replace: true },
        );
    }, []);

    const features: Feature[] = [
        {
            id: 1,
            title: "Structured Template",
            text: "Generate consistent, high-quality content with minimal prompts using inbuilt structured templates."
        },
        {
            id: 2,
            title: "Workflows That Work",
            text: "GMind keeps things moving so you don’t have to.",
        },
        {
            id: 3,
            title: "Instant Insights",
            text: "Make better calls with data-backed, real-time insights.",
        }
    ];
    const handleGuestLogin = async () => {
        function handleCallback() {
            const userData = localStorage.getItem('user');
            if (userData) {
                const userDetails = JSON.parse(userData);

                // if ( userDetails?.plan?.details !== undefined ){
                //     window.location.href = '/dashboard/chat';
                // }else {
                //     window.location.href = '/dashboard/pricing';
                // }
                window.location.href = chatRoutes.chat;
            }
        }

        const deviceId = await getDeviceId();

        useEndpoint()
            .guestLogin({ deviceId })
            .then((res: any) => {
                if (res?.success) {
                    localStorage.setItem('token', res?.data?.token);
                    const { id, firstName, lastName, email, isVerified, plan } = res.data;
                    localStorage.setItem('user', JSON.stringify({ id, firstName, lastName, email, isVerified, plan }));
                    handleCallback();
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            });
    }


    return (
        <div className="w-full bg-white">
            <Helmet>
                <title>AI-Powered Productivity | GMind AI</title>
                <meta
                    name="description"
                    content="Supercharge your productivity with the best AI tool. Streamline workflows, and enhance efficiency with powerful AI features tailored to your needs."
                />
                <meta
                    name="keywords"
                    content="AI tools, AI features, GMind AI tools, productivity tools, powerful AI features, workflow efficiency, AI-driven productivity"
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://gmind.ai/${acquisitionRoutes.productivity}`} />
                <meta property="og:title" content="AI-Powered Productivity | GMind AI" />
                <meta property="og:description" content="Supercharge your productivity with the
                best AI tool. Streamline workflows, and enhance efficiency with powerful AI features
                tailored to your needs." />

                
                <meta property="og:image" content="https://gmind.ai/images/productivity-og-image.jpg" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={`https://gmind.ai/${acquisitionRoutes.productivity}`} />
                <meta property="twitter:title" content="AI-Powered Productivity | GMind AI" />
                <meta property="twitter:description" content="Supercharge your productivity with the
                best AI tool. Streamline workflows, and enhance efficiency with powerful AI features
                tailored to your needs." />
                <meta property="twitter:image" content="https://gmind.ai/images/productivity-twitter-image.jpg" />

                <link rel="canonical" href={`https://gmind.ai/${acquisitionRoutes.productivity}`} />
                <meta name="robots" content="index, follow" />

                <link rel="canonical" href={`https://gmind.ai/${acquisitionRoutes.productivity}`} />
                <meta name="robots" content="index, follow" />
                <script type="application/ld+json">
                    {JSON.stringify(featuresData)}
                </script>
            </Helmet>
            <div className="w-full bg-[#031A57] text-white relative z-20">
                <div className="w-full h-screen flex items-center justify-center 3xl:max-w-[1800px] 3xl:mx-auto">
                    <div className="text-center px-6 md:px-12 3xl:px-8">
                        <h2 className="text-4xl md:text-7xl font-medium">
                            <span className="text-white font-archivo">
                                Smash Through Your To-Do List With
                                <span className="text-[#E65109] font-archivo">Gmind AI</span>
                                Productivity Tools
                            </span>
                        </h2>
                        <p className="mt-6 md:mt-8 text-sm max-w-2xl mx-auto font-poppins">
                            Creating high-quality content has never been easier. GMind AI’s
                            structured templates guide you through the process, allowing
                            creators to achieve polished, engaging results without needing
                            extensive prompting skills.
                        </p>
                        <div className="mt-8 md:mt-14 flex flex-col sm:flex-row justify-center gap-4">
                            <GetStartedButton
                                className="px-4 py-2 border border-white text-white rounded-2xl hover:bg-white hover:text-black transition duration-300"
                                isLoggedIn={isLoggedIn}
                                onclick={() => {
                                    if (isLoggedIn) {
                                        navigatePage(chatRoutes.chat);
                                    } else {
                                        navigatePage(`${acquisitionRoutes.getStarted}?forFree=1`);
                                    }
                                }}
                            />
                            <a
                                onClick={() =>
                                    navigatePage(isLoggedIn ? chatRoutes.pricing : acquisitionRoutes.pricing )
                                }
                                className="
                                    cursor-pointer
                                    w-full
                                    sm:w-auto
                                    flex
                                    justify-center
                                    md:items-center
                                    px-4
                                    py-3
                                    text-center
                                    border
                                    border-white
                                    text-white
                                    rounded-2xl
                                    hover:bg-white
                                    hover:text-black
                                    transition
                                    duration-300
                                "
                            >
                                Explore Pricing
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <section className="w-full bg-white relative">
                <div className="w-full py-16 px-6 md:px-24 3xl:max-w-[1800px] 3xl:mx-auto 3xl:px-8">
                    {/* Top-right Image */}
                    <img
                        src={top}
                        alt="Top decorative"
                        className="absolute -top-40 right-0 w-24 sm:w-32 md:w-48 lg:w-64"
                    />

                    {/* Our Story Section */}
                    <div className="flex flex-col md:flex-row items-center md:items-center md:justify-between gap-8 relative z-10">
                        {/* Text Content */}
                        <div className="w-full md:w-1/2 text-center md:text-left">
                            <p className="text-[#111111] text-2xl sm:text-3xl md:text-6xl font-medium mt-4 md:leading-[70px] tracking-wide font-jakarta ">
                                The Smart Way to Boost Productivity
                            </p>
                            <p className="text-[#7E8492] text-sm sm:text-base leading-relaxed md:leading-[32px] mt-4 md:mt-8 text-justify font-jakarta ">
                                Let’s face it: there’s only so much time in a day. GMind AI’s productivity tools are all about freeing up your time, simplifying the tough stuff, and letting you focus on what really counts. With a suite of tools designed to tackle your daily grind, you’re about to make every work hour count.
                            </p>
                        </div>

                        {/* Main Image */}
                        <div className="w-full md:w-2/5 flex justify-center">
                            <img
                                src={content}
                                alt="Story image"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>
                    </div>
                    <div className="w-full bg-white py-16">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                            {features.map((feature) => (
                                <div key={feature.id} className="flex flex-col justify-center h-full w-full p-6 rounded-lg shadow-md">
                                    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="70" height="70" rx="35" fill="#E55109" fill-opacity="0.09" />
                                        <path d="M28.3333 32.5882H28.35M35 32.5882H35.0167M41.6667 32.5882H41.6833M30 43.1765H23.3333C21.4924 43.1765 20 41.5963 20 39.6471V25.5294C20 23.5802 21.4924 22 23.3333 22H46.6667C48.5076 22 50 23.5802 50 25.5294V39.6471C50 41.5963 48.5076 43.1765 46.6667 43.1765H38.3333L30 49.5V43.1765Z" stroke="#E55109" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <h4 className="text-xl font-semibold font-archivo my-4">{feature.title}</h4>
                                    <p className="text-sm text-[#7E8492]">
                                        {feature.text}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Bottom-left Image */}
                    <img
                        src={bottom}
                        alt="Bottom decorative"
                        className="hidden md:block absolute -bottom-64 left-0 w-32 md:w-48 lg:w-64"
                    />
                </div>
            </section>
            <section className="w-full bg-white border-t border-[#E8E8E8]">
                <div className="w-full py-16 px-6 md:px-24 3xl:max-w-[1800px] 3xl:mx-auto 3xl:px-8">
                    {/* Centered Header Section */}
                    <div className="flex flex-col items-center text-center mb-12">
                        <span className="bg-[#FCEBBB59] px-4 py-1 text-sm font-medium rounded-full">
                            GMind AI Tools
                        </span>
                        <h4 className="text-2xl sm:text-3xl md:text-4xl font-semibold font-archivo mt-4 md:max-w-sm">
                            GMind AI Productivity Tools You’ll Love
                        </h4>
                        <p className="text-sm sm:text-base text-[#7E8492] mt-2">
                            Essential productivity tools to enhance workflow efficiency
                        </p>
                    </div>
                    {/* Features 1 Section */}
                    <div className="flex flex-wrap md:flex-nowrap gap-8 mb-8">
                        {/* First Feature */}
                        <div className="flex flex-col justify-between w-full md:w-2/5 py-6">
                            <h5 className="text-lg sm:text-xl md:text-6xl font-medium font-jakarta text-[#111111] mb-4 md:leading-[70px]">
                                Writing Canvas For Creators
                            </h5>
                            <p className="text-sm sm:text-lg text-[#7E8492] mb-6 font-jakarta">
                                Content Writing just got way easier. Writing marketing copies or
                                creating blog posts? GMind’s writing canvas keeps you on track (without
                                the tracking headaches).
                            </p>
                            {/* <div>
                            <a
                                href="#"
                                className="inline-flex items-center gap-2 px-6 py-2 bg-[#24252A] text-white rounded-2xl border border-[#24252A] hover:bg-white hover:text-black transition duration-300"
                            >
                                <span>Start Writing</span>
                                <span className="bg-white p-1.5 rounded-full">
                                    <svg
                                        width="12"
                                        height="10"
                                        viewBox="0 0 12 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.4879 5.33166L7.26914 9.55041C7.18118 9.63836 7.06189 9.68778 6.9375 9.68778C6.81311 9.68778 6.69381 9.63836 6.60586 9.55041C6.5179 9.46245 6.46849 9.34316 6.46849 9.21877C6.46849 9.09438 6.5179 8.97508 6.60586 8.88713L10.0248 5.46877H0.84375C0.71943 5.46877 0.600201 5.41938 0.512294 5.33147C0.424386 5.24357 0.375 5.12434 0.375 5.00002C0.375 4.8757 0.424386 4.75647 0.512294 4.66856C0.600201 4.58065 0.71943 4.53127 0.84375 4.53127H10.0248L6.60586 1.11291C6.5179 1.02495 6.46849 0.905656 6.46849 0.781267C6.46849 0.656877 6.5179 0.537582 6.60586 0.449626C6.69381 0.361669 6.81311 0.312256 6.9375 0.312256C7.06189 0.312256 7.18118 0.361669 7.26914 0.449626L11.4879 4.66838C11.5315 4.71191 11.566 4.76361 11.5896 4.82051C11.6132 4.87742 11.6254 4.93842 11.6254 5.00002C11.6254 5.06162 11.6132 5.12261 11.5896 5.17952C11.566 5.23643 11.5315 5.28812 11.4879 5.33166Z"
                                            fill="#24252A"
                                        />
                                    </svg>
                                </span>
                            </a>
                        </div> */}
                        </div>

                        {/* Second Feature */}
                        <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                            <div className=' bg-gray-100/40 p-6 md:pb-0'>

                                <img
                                    src={writing}
                                    alt="writing"
                                    className="w-full object-cover rounded-lg"
                                />
                            </div>
                            <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                                <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                    Content Writing
                                </h5>
                                <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                    Powerful text engine designed for high-end content generation.
                                </p>
                            </div>
                        </div>

                        {/* Third Feature */}
                        <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                            <div className=' bg-gray-100/40 p-6 md:pb-0'>

                                <img
                                    src={editor}
                                    alt="writing"
                                    className="w-full object-cover rounded-lg"
                                />
                            </div>
                            <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                                <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                    Streamline Workflow
                                </h5>
                                <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                    All-in-one solution: No additional writing tools needed.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Features 2 section */}
                    <div className="flex flex-wrap md:flex-nowrap gap-8 flex-row-reverse ">
                        {/* First Feature */}
                        <div className="flex flex-col justify-between w-full md:w-2/5 py-6">
                            <h5 className="text-lg sm:text-xl md:text-5xl font-medium font-jakarta text-[#111111] mb-4 md:leading-[70px]">
                                Real-Time Data Insights
                            </h5>
                            <p className="text-sm sm:text-lg text-[#7E8492] mb-6 font-jakarta">
                                No more second-guessing. GMind provides real-time insights, helping you make fast, informed decisions with instant access to reliable, current data.
                            </p>
                            {/* <div>
                            <a
                                href="#"
                                className="inline-flex items-center gap-2 px-6 py-2 bg-[#24252A] text-white rounded-2xl border border-[#24252A] hover:bg-white hover:text-black transition duration-300"
                            >
                                <span>See My Insights Now!</span>
                                <span className="bg-white p-1.5 rounded-full">
                                    <svg
                                        width="12"
                                        height="10"
                                        viewBox="0 0 12 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.4879 5.33166L7.26914 9.55041C7.18118 9.63836 7.06189 9.68778 6.9375 9.68778C6.81311 9.68778 6.69381 9.63836 6.60586 9.55041C6.5179 9.46245 6.46849 9.34316 6.46849 9.21877C6.46849 9.09438 6.5179 8.97508 6.60586 8.88713L10.0248 5.46877H0.84375C0.71943 5.46877 0.600201 5.41938 0.512294 5.33147C0.424386 5.24357 0.375 5.12434 0.375 5.00002C0.375 4.8757 0.424386 4.75647 0.512294 4.66856C0.600201 4.58065 0.71943 4.53127 0.84375 4.53127H10.0248L6.60586 1.11291C6.5179 1.02495 6.46849 0.905656 6.46849 0.781267C6.46849 0.656877 6.5179 0.537582 6.60586 0.449626C6.69381 0.361669 6.81311 0.312256 6.9375 0.312256C7.06189 0.312256 7.18118 0.361669 7.26914 0.449626L11.4879 4.66838C11.5315 4.71191 11.566 4.76361 11.5896 4.82051C11.6132 4.87742 11.6254 4.93842 11.6254 5.00002C11.6254 5.06162 11.6132 5.12261 11.5896 5.17952C11.566 5.23643 11.5315 5.28812 11.4879 5.33166Z"
                                            fill="#24252A"
                                        />
                                    </svg>
                                </span>
                            </a>
                        </div> */}
                        </div>

                        {/* Second Feature */}
                        <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                            <div className=' bg-gray-100/40 p-6 md:pb-0'>
                                <img
                                    src={research}
                                    alt="writing"
                                    className="w-full object-cover rounded-lg"
                                />
                            </div>
                            <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                                <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                    Research Toolkit
                                </h5>
                                <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                    All you need to get that paper ready before the deadline.
                                </p>
                            </div>
                        </div>

                        {/* Third Feature */}
                        <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                            <div className=' bg-gray-100/40 p-6 md:pb-0'>

                                <img
                                    src={analytics}
                                    alt="writing"
                                    className="w-full h-48 object-cover rounded-lg"
                                />
                            </div>
                            <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                                <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                    Data Insights
                                </h5>
                                <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                    Powerful data insights for up to date info on your favourite topics.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="w-full bg-white relative overflow-hidden">
                <div className="w-full py-16 px-6 md:px-24 3xl:max-w-[1800px] 3xl:mx-auto 3xl:px-8">
                    {/* Top-right Image */}
                    <img
                        src={top}
                        alt="Top decorative"
                        className="absolute -top-28 right-2 w-24 sm:w-32 md:w-48 lg:w-64"
                    />

                    {/* Content Section */}
                    <div className="flex flex-col md:flex-row items-center gap-8">
                        {/* Left - Image */}
                        <div className="flex-1">
                            <img
                                src={phone}
                                alt="Mobile"
                                className="w-full h-auto object-contain"
                            />
                        </div>

                        {/* Right - Text Content */}
                        <div className="flex-1">
                            <h4 className="text-2xl sm:text-3xl md:text-5xl font-medium font-archivo text-[#111111] mb-6 md:leading-[70px] tracking-wider">
                                What Makes GMind AI Different?
                            </h4>

                            {/* Features List */}
                            <div className="">
                                {/* Feature 1 */}
                                <div className="flex items-start gap-4">
                                    {/* Icon and Line */}
                                    <div className="flex flex-col items-center">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="mb-2"
                                        >
                                            <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                        </svg>
                                        <svg
                                            width="3"
                                            height="125"
                                            viewBox="0 0 3 125"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line
                                                x1="1.5"
                                                y1="125"
                                                x2="1.5"
                                                y2="0"
                                                stroke="#24252A"
                                                strokeWidth="2"
                                                strokeDasharray="6 6"
                                            />
                                        </svg>
                                    </div>

                                    {/* Text Content */}
                                    <div>
                                        <h5 className="text-xl font-medium text-[#24252A] mb-2 font-archivo md:text-2xl">
                                            Tailored For You
                                        </h5>
                                        <p className="text-sm text-[#7E8492] font-workSans" >
                                            Unlike cookie-cutter platforms, GMind AI learns from you. Each
                                            request is tailored to the context of the entire conversation,
                                            ensuring you’re always on point.
                                        </p>
                                    </div>
                                </div>

                                {/* Feature 2 */}
                                <div className="flex items-start gap-4">
                                    {/* Icon and Line */}
                                    <div className="flex flex-col items-center">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="mb-2"
                                        >
                                            <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                        </svg>
                                        <svg
                                            width="3"
                                            height="125"
                                            viewBox="0 0 3 125"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line
                                                x1="1.5"
                                                y1="125"
                                                x2="1.5"
                                                y2="0"
                                                stroke="#24252A"
                                                strokeWidth="2"
                                                strokeDasharray="6 6"
                                            />
                                        </svg>
                                    </div>

                                    {/* Text Content */}
                                    <div>
                                        <h5 className="text-xl font-semibold text-[#24252A] mb-2">
                                            Cutting-Edge Tech
                                        </h5>
                                        <p className="text-sm text-[#7E8492] font-workSans">
                                            Built on the latest in AI, GMind is constantly evolving. This
                                            isn’t just another productivity tool; it’s a fully loaded,
                                            AI-powered upgrade.
                                        </p>
                                    </div>
                                </div>

                                {/* Feature 3 */}
                                <div className="flex items-start gap-4">
                                    {/* Icon */}
                                    <div className="flex flex-col items-center">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                        </svg>
                                    </div>

                                    {/* Text Content */}
                                    <div>
                                        <h5 className="text-xl font-medium text-[#24252A] mb-2 font-archivo md:text-2xl">
                                            Made for Every Industry
                                        </h5>
                                        <p className="text-sm text-[#7E8492] font-workSans">
                                            Teachers, creators, managers, researchers—you name it, GMind is
                                            here to make work-life smoother for every professional.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="w-full bg-white py-16 px-6 md:px-24 relative overflow-hidden" ref={featuresRef}>
                <Playground />
            </section>
            <section className="bg-[#F9F7FF] py-16 px-6 flex flex-col items-center text-center">
                {/* Badge */}
                <span className="bg-[#FCEBBB59] px-4 py-1 text-sm font-medium rounded-full mb-4 font-archivo">
                    What you get
                </span>

                {/* Main Heading */}
                <h4 className="text-2xl sm:text-5xl font-medium text-[#24252A] mb-4 font-archivo md:max-w-3xl md:leading-[70px] md:px-8">
                    Ready to Give Your Productivity A Boost?
                </h4>

                {/* Description */}
                <p className="text-sm text-[#7E8492] mb-8 max-w-xl mx-auto font-workSans">
                    It’s time to see what GMind AI can do for you. Try it free and discover productivity the way it should be—simple, smart, and way more effective.
                </p>

                {/* Buttons */}
                <div className="flex flex-wrap justify-center gap-4">
                    {/* Try GMind AI Free Button */}
                    <GetStartedButton
                        isLoggedIn={isLoggedIn}
                        className="px-6 py-2 bg-[#24252A] text-white rounded-2xl border border-[#24252A] hover:bg-white hover:text-black transition duration-300"
                        onclick={() => {
                            // check if user is logged in
                            handleGuestLogin()
                        }}
                    />
                    {/* Explore Pricing Button */}
                    <a
                        onClick={() => navigatePage(isLoggedIn ? chatRoutes.pricing : acquisitionRoutes.pricing )}
                        className="cursor-pointer flex items-center gap-2 px-6 py-2 border border-[#E9EAE9] text-black rounded-2xl hover:bg-white hover:text-black transition duration-300"
                    >
                        <span>Explore Pricing</span>
                        <span>
                            <svg
                                width="12"
                                height="10"
                                viewBox="0 0 12 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.4879 5.33166L7.26914 9.55041C7.18118 9.63836 7.06189 9.68778 6.9375 9.68778C6.81311 9.68778 6.69381 9.63836 6.60586 9.55041C6.5179 9.46245 6.46849 9.34316 6.46849 9.21877C6.46849 9.09438 6.5179 8.97508 6.60586 8.88713L10.0248 5.46877H0.84375C0.71943 5.46877 0.600201 5.41938 0.512294 5.33147C0.424386 5.24357 0.375 5.12434 0.375 5.00002C0.375 4.8757 0.424386 4.75647 0.512294 4.66856C0.600201 4.58065 0.71943 4.53127 0.84375 4.53127H10.0248L6.60586 1.11291C6.5179 1.02495 6.46849 0.905656 6.46849 0.781267C6.46849 0.656877 6.5179 0.537582 6.60586 0.449626C6.69381 0.361669 6.81311 0.312256 6.9375 0.312256C7.06189 0.312256 7.18118 0.361669 7.26914 0.449626L11.4879 4.66838C11.5315 4.71191 11.566 4.76361 11.5896 4.82051C11.6132 4.87742 11.6254 4.93842 11.6254 5.00002C11.6254 5.06162 11.6132 5.12261 11.5896 5.17952C11.566 5.23643 11.5315 5.28812 11.4879 5.33166Z"
                                    fill="#24252A"
                                />
                            </svg>
                        </span>
                    </a>
                </div>
            </section>
            <Testimonials />
            <section ref={faqRef} className="w-full mt-5 md:mt-0 ">
                <div className="w-full px-10 md:px-20 3xl:max-w-[1800px] 3xl:mx-auto 3xl:px-10">
                    <Faq />
                </div>
            </section>
            <section>
                <Footer />
            </section>

            {/* <div className="relative w-full h-auto lg:h-[30rem] mb-10 md:mb-16 z-10">
                <div className="w-full h-full px-10 md:px-10">
                    <FeaturesAbout isLoggedIn={isLoggedIn} />
                </div>
                <img
                    className="absolute top-[70%] bottom-[0%] w-full h-[7rem] md:h-[10rem] z-0"
                    src={wavyDesign}
                    alt="wavyDesign"
                />
            </div> */}
            {/* <div className="flex md:hidden justify-center items-center">
                <GetStartedForFree
                    isLoggedIn={isLoggedIn}
                    className="w-[10rem]"
                    onclick={() => {
                        if (isLoggedIn) {
                            navigatePage('/dashboard/chat');
                        } else {
                            navigatePage('/get-started?forFree=1');
                        }
                    }}
                />
            </div> */}
            {/* <div className="w-full">
                {/* <section ref={featuresRef} className="w-full mt-5 md:mt-0 px-0 md:px-20">
                    <Playground />
                    {/* <Partners />
                </section> */}
            {/* <section className="w-full mt-10">
                    <AdvancedFeatures />
                </section> */}
            {/* <section className="w-full mt-20 md:mt-5 px-10 md:px-20 mb-10">
                    <Services />
                </section>
                <section ref={faqRef} className="w-full mt-5 md:mt-0 px-10 md:px-20">
                    <Faq />
                </section>
                <section>
                    <Footer />
                </section>
            </div> */}
        </div>
    );
};

export default FeaturesScreen;

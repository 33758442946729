import { newGenerationHomeDataProps } from '../interface';
import syllabusImg from '@icons/syllabus.svg';
import lessonPlanner from '@icons/lesson_planner.svg';
import ShareImg from '@public/share.svg';
import Copy from '@public/copy.svg';
import DownloadImage from '@public/download.svg';
import research from '@icons/research.svg';
import article from '@icons/article.svg';
import comprehensive from '@icons/comphrensive.svg';
import socialMedia from '@icons/social_media.svg';
import letterImg from '@icons/letter.svg';
import { copyData, handleShare } from '@src/utils/app_functions';
import { PromptOptionsType } from '@src/core/interfaces/general';
// import two_arrows from '@assets/chat/two_arrows.svg';
import two_arrows from '@icons/exit_ticket.svg';
// import support from '@assets/chat/support.svg';
import support from '@icons/hooks.svg';
import activity from '@assets/activity.svg';
import slide from '@assets/essay/google.png';
import { ReportCardSvg } from '../svg';
import { AssessementWhiteImage } from '@src/components/Sidebar/images';
import { chatRoutePages } from '@src/utils/app_routes';

const newGenerationHomeData: Array<newGenerationHomeDataProps> = [
    {
        img: lessonPlanner,
        imgElement: AssessementWhiteImage(),
        head: 'Lesson Planner',
        text: 'Versatile tool that aids educators in designing detailed and effective lesson plans',
        pageIndex: 13,
        pageName: 'Lesson Planner',
        naviagatePage: true,
        label: chatRoutePages.lesson,
    },

    {
        img: socialMedia,
        imgElement: AssessementWhiteImage(),
        head: 'Syllabus Generator',
        text: 'Streamline the creation of comprehensive and professional course syllabi',
      pageIndex: 11,
        pageName: 'Syllabus',
        naviagatePage: true,
        label: chatRoutePages.syllabus,
    },

    {
        img: research,
        imgElement: AssessementWhiteImage(),
        head: 'Learning Activity Generator',
        text: 'Dynamic tool designed to help educators create engaging and interactive learning activities.',
        pageIndex: 12,
        pageName: 'Learning Activity',
        naviagatePage: true,
        label: chatRoutePages.activity,
    },
    {
        img: syllabusImg,
        head: 'Text Rewriter',
        text: 'Revamp your words, transform your message—effortlessly rewrite your text.',
        pageIndex: 1,
        pageName: 'Text Writer',
        label: 'text_writer',
    },
    {
        img: comprehensive,
        head: 'Worksheet generator',
        text: 'Create tailored worksheets on any topic or text for personalized learning.',
        pageIndex: 2,
        pageName: 'Worksheet generator',
        label: 'worksheet_generator',
    },

    {
        img: letterImg,
        head: 'Quote of the Day',
        text: 'Start your day with a daily dose of inspiration—one quote at a time.',
        pageIndex: 3,
        pageName: 'Quote of the Day',
        label: 'quote_of_the_day',
    },
    {
        img: lessonPlanner,
        head: 'Report card Comment',
        text: 'Personalized feedback to guide and motivate your students.',
        pageIndex: 5,
        pageName: 'report',
        label: 'report_card_comment',
        imgElement: ReportCardSvg(),
    },

    // {
    //     img: research,
    //     head: 'Class newsletter',
    //     text: 'Stay informed about class news, upcoming events, and important announcements.',
    //     pageIndex: 4,
    //     pageName: "Class newsletter",
    //     label: 'class_newsletter',
    // },

    {
        img: two_arrows,
        head: 'Lesson Hook',
        text: 'Develop a captivating hook that draws students in and makes them eager to learn more.',
        pageIndex: 6,
        pageName: 'Lesson Hook',
        label: 'hooks',
    },

    {
        img: support,
        head: 'Exit Ticket',
        text: 'Develop effective exit strategies to assess student learning',
        pageIndex: 7,
        pageName: 'Exit Ticket',
        label: 'exit_ticket',
    },

    {
        img: slide,
        head: 'Slide Maker',
        text: 'Create engaging and interactive slides for your presentations',
        pageIndex: 8,
        pageName: 'Slide Maker',
        label: 'slide_maker',
    },

    {
        img: article,
        imgElement: AssessementWhiteImage(),
        head: 'Assessment Generator',
        text: 'Create and design assessments, such as quizzes, tests, and exams, in a efficient and organized manner.',
        pageIndex: 10,
        pageName: 'Assessment',
        naviagatePage: true,
        label: chatRoutePages.assessment,
    },

    
];

const contentPromptOptionsData = ({
    response,
    onSuccess,
    urlRef,
    showDownload,
}: {
    response: string;
    onSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    urlRef: React.MutableRefObject<string>;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
}): PromptOptionsType[] => [
    {
        name: 'Share',
        icon: ShareImg,
        onClick: () =>
            handleShare({
                response,
                onSuccess,
                urlRef,
            }),
    },
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];

const contentPromptOptionsData2 = ({
    response,
    showDownload,
}: {
    response: string;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
}): PromptOptionsType[] => [
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];

export { newGenerationHomeData, contentPromptOptionsData, contentPromptOptionsData2 };

import BackButton from "@src/components/BackButton";
import Profile from '@assets/Avatar.svg';
import ResponseTextbox from "@src/pages/dashboard/components/responseTextBox";
import MarkdownDesign from '@src/components/Markdown';
import { GeneratedResponseProps } from "../interface";
import { useEffect, useRef, useState } from "react";
import { Download } from "@src/pages/dashboard/components/Share/Download";
import { Share } from "@src/pages/dashboard/components/Share";
import { convertHTMLToPDF, convertHTMLToWord } from "../../essay/functions";
import markdownToTxt from 'markdown-to-txt';
import markdown from "@wcj/markdown-to-html";
import { marked } from "marked";
import { toast } from "react-toastify";
import Chart from "react-apexcharts";
import { openEditor } from "@src/utils/app_functions";
import { useNavigate } from "react-router";
import ShareImg from '@public/share.svg';
import Copy from '@icons/copy.svg';
import DownloadImage from '@public/download.svg';
import { BASE_URL } from '@src/utils/constants';
import axios from "axios";

const GeneratedResponse = ({onClickBack, promptSent, response, chartData, canMoveToEditor}: GeneratedResponseProps ) => {
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [pdfDownloading, setPdfDownloading] = useState(false);
    const [chatValue, setChatValue] = useState<any>(undefined);
    const [chartType, setChartType] = useState<"line" | "area" | "bar" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "candlestick" | "boxPlot" | "radar" | "polarArea" | "rangeBar" | "rangeArea" | "treemap" | undefined>(undefined);
    const UrlRef = useRef<string>('');

    

    const navigate = useNavigate();

    useEffect(() => {
        if ( canMoveToEditor ){
            setTimeout(() => {
                // handleMoveToEditor();
            }, 5000)
        }

    }, [canMoveToEditor])

    const handleMoveToEditor = () => {
        openEditor({
            response: response,
            promptSent: promptSent,
            promptType: 'Analysis',
            navigate: navigate,
            fromPage: "Data Insights"
        })
    }

    async function handleShare() {
        const baseurl = BASE_URL;
        console.log('share');
        const to_markdown = response;

        if (UrlRef.current) {
            setShowShareModal(true);
            return;
        }
        try {
            const response = await axios.post(`${baseurl}/document/markdown-to-doc`, { content: to_markdown }, {});
            const { data: d } = response;
            if (d?.statusCode !== 1) {
                toast.error(d?.message ?? 'Something went wrong while generating share link');
                return;
            }

            const newUrl = baseurl.replace('v1', '');

            const url = `${newUrl}${d?.url}`;
            UrlRef.current = url;
            setShowShareModal(true);
        } catch (error: any) {
            console.log(error, 'e');
            const server_message = error?.response?.data?.message;
            toast.error(server_message ?? 'Something went wrong.');
        }
    }

    function handleCopyText() {
       
        navigator.clipboard
            .writeText(response)
            .then(() => {
                console.log('Written');
                toast.success('Content copied to clipboard!');
            })
            .catch((err) => {
                console.log('Failed, ', err);
            });
    }


    const promptOptionsData = [
        {
            name: 'Share',
            icon: ShareImg,
            onClick: handleShare,
        },
        {
            name: 'Copy',
            icon: Copy,
            onClick: handleCopyText,
        },
        {
            name: 'Download',
            icon: DownloadImage,
            onClick: () => setShowDownload(true),
        },
    ];

    useEffect(() => {
        getType(chartData)
        setChatValue(chartData);
    }, [chartData])

    const getType = (data: any) => {
        const type = data?.options?.chart?.type || data?.chart?.type || data?.chartOptions?.chart?.type;
        setChartType(type);
    } 
    
    const handleLikeClick = () => {};
    const handleDisLikeClick = () => {};

    const regenerate = () => {
        
    };

    async function handlePDF() {
        // eslint-disable-next-line prefer-const
        let data = await marked(response);
        try {
            setPdfDownloading(true);
            await convertHTMLToPDF(data);
        } catch (error) {
            toast.error('Error downloading documents');
        } finally {
            setPdfDownloading(false);
        }
    }

    async function handleWord() {
        const to_html = markdown(response);
        convertHTMLToWord(to_html);
        
    }
    
    
    return (
        <div className="w-full flex flex-col gap-0 bg-custom-opacity">
            <div className="w-full flex flex-row gap-0 bg-custom-opacity">
                <div className="w-full flex flex-col gap-0 bg-custom-opacity px-2 md:px-10 py-12 md:py-10">
                    <div className="mt-1">
                        <div className="flex flex-row">
                            <BackButton
                                onclick={() => {
                                    onClickBack(); 
                                }}
                            />
                        </div>

                        {
                            chartData && chartType  && ( 
                                <div id="chart" className="w-full flex flex-row gap-0 bg-custom-opacity px-10 py-10 overflow-y-auto">
                                    <Chart
                                        options={chartData.options ? chartData.options : ( chartData.chartOptions ? chartData.chartOptions : undefined ) }
                                        series={chartData.series}
                                        type={chartType || 'line'}
                                        width="800"
                                    />
                                </div>
                            )
                        }
                        
                        
                        <div>
                            <ResponseTextbox
                                content={<MarkdownDesign className="">{response}</MarkdownDesign>}
                                options={promptOptionsData}
                                disLikeClick={handleDisLikeClick}
                                messageClick={() => {}}
                                likeClick={handleLikeClick}
                                regenerateClick={regenerate}
                                profileClick={() => {}}
                                canShowOptions={true}
                            />
                        </div>
                    </div>
                    <div className="sticky bottom-0 bg-custom-opacity">
                        <div className="advise">
                            Gmind can make mistakes. It's advisable to verify crucial information.
                        </div>
                    </div>
                    {showShareModal && <Share url={UrlRef.current} onClose={() => setShowShareModal(false)} />}
                    {showDownload && (
                        <Download
                            handlePDF={handlePDF}
                            handleWord={handleWord}
                            url={UrlRef.current}
                            onClose={() => setShowDownload(false)}
                            isPdfDownloading={pdfDownloading}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default GeneratedResponse;
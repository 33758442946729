/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import { Chats, ChatType, handleAddHistory } from "../../endpoints";
import { Download } from "../../components/Share/Download";
import ReusableDialog from "./components/CommentModal";
import NewGenerateContent from "./components/new_generate_home";
import { toast } from "react-toastify";
import { prompts } from "@src/utils/prompts";
import { io, Socket } from "socket.io-client";
import { Share } from "../../components/Share";
import moment from "moment";
import { convertStringToDelta, openCurriculumEditor } from "@src/utils/app_functions";
import saveAs from "file-saver";
import { pdfExporter } from "quill-to-pdf";
import axios from "axios";
import Profile from '@assets/Avatar.svg';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useOutletContext } from "react-router";
import { BASE_URL } from "@src/utils/constants";
import { ThemeContext } from "@src/theme_provider/theme";
import NewEditorPage from "../essay/editor/newInstanceOfEditor";
import NewSunEditorComponent from "../essay/sun_editor copy";
import { useDocumentContext } from "@src/context/DocumentContext";
import { useUserContext } from "@src/context/UseProvider";
import { useDebouncer } from "../../function/helper";


interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}


const SchoolResources = () => {
    const pageHistory = 'schoolresources';
    const navigate = useNavigate();
  
    const [histories, setHistories] = useState<any[]>([]);
    const [page, setPage] = useState<string>('');
    const [generating, setGenerating] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [sideBarVisible, setSidebarVisible] = useState<boolean| null>(null);
    const [basePage, setBasePage] = useState<number>(0);
    const [showEditorButton, setShowEditorButton] = useState(false);
    const UrlRef = useRef<string>('');
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
  
    const [newPromptSent, setNewPromptSent] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [message, setMessage] = useState('');
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [canNavigate, setCanNavigate] = useState(false);
    const [topic, setTopic] = useState('');
    const [basePrompt, setBasePrompt] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [conversation, setConversation ] = useState< ChatType[]>([]);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({role: 'assistant', content: ''});
    const editorInstanceRef = useRef<any>();
  

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const { documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const {
        setShowTop,
        setShowSidebar,
        setMobileNavStyle,
        setShowPagePath,
        setCanNavigateHome,
        setShowInput, 
        setShowHistoryOptions,
        setOnDeleteAllHistory,
        setShowRightComponent,
        setShowHistory,
        setPromptSent,
        setResponse,
        setShowingHistory,
        setHistoryType,
        setPageHistory,
        showingHistory,
        response,
        promptSent,
        getHistory,
        historyType,
        details,
    } = useOutletContext<any>();
    const {isMobile} = useContext(ThemeContext);
    const [template, setTemplate] = useState<any>();
    const [quiz, setQuiz] = useState<any>();

    
    const bottomRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if ( isTyping && response != '' ){
            console.log("scrolling")
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [isTyping, response]);
    
    



    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if ( currentPage == 1 || currentPage == 3 ){
            setHistoryType("content")
            setPageHistory("content")
        }else if ( currentPage == 2 ){
            setHistoryType("learning_materials")
            setPageHistory("learning_materials")
        }else if ( currentPage == 4 ){
            setHistoryType("letter")
            setPageHistory("letter")
        }else if ( currentPage == 5){
            setHistoryType("social")
            setPageHistory("social")
        }
        
        if (currentPage == 1 || currentPage == 3){
            setShowSidebar(false);
        }else{
            if (generate || showingHistory){
                setShowTop(false);
                setShowSidebar(false)
            }else{
                setShowTop(true);
                setShowSidebar(true)
            }
        }

        console.log(currentPage);
    }, [currentPage,generate, showingHistory])

    useEffect( () => {
        if ( conversation.length == 0 ) return;
        handleAddHistory(conversation, pageHistory, historyType );
        getHistory();
        setConversation([])
    }, [isTyping])




    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { assistant } = data;
            setStreamEndMessage(assistant);
            setShowEditorButton(true);
            setIsTyping(false);
            setCanNavigate(true);
            setConversation([assistant]);
            // if (streamId === StreamId.current) {
                StreamId.current = '';
                // setStreaming(false);
                const uuid = uuidv4();
                setDocId(uuid);
                DocumentId.current = uuid;
                const user = JSON.parse(localStorage.getItem('user') || '');
                try {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: promptSent,
                        value: assistant.content,
                        owner_id: user?.id,
                    });
                } catch (error) {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: promptSent,
                        value: assistant.content,
                    });
                }
                socket.emit('get-documents', user?.id);
            
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);


    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };

    
    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

  
    useEffect(() => {
        console.log(generate);

        if (generate || showingHistory) {
            openCurriculumEditor({
                response,
                pageId: docId,
                promptSent,
                promptType: prompts.curriculum,
                navigate,
                pageSocket: socket,
            });
            getDocIdFromUrl();
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);

    const handlePDF = async () => {
        const data = convertStringToDelta(response);
        const blob = await pdfExporter.generatePdf(data)
        const current_time = moment().format('YYYY-MM-DD HH:mm:ss');
        saveAs(blob as Blob, `gmind_document-${current_time}.pdf`);
    }
    const handleWord = async () => {
        const dataToDownload = response;
        const responseData = await axios.post(
            'https://api-v2.gmind.ai/v1/document/markdown-to-doc',
            { content: dataToDownload },
            {},
        );
        const { data: d } = responseData;
        if (d?.statusCode === 1 ) {
            const link = document.createElement('a');
            link.href = `https://api-v2.gmind.ai/${d?.url}`;
            const current_time = moment().format('YYYY-MM-DD HH:mm:ss');

            link.download = `gmind_document-${current_time}.docx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success('Download successful');
        }
    }






    

    const regenerate = () => {
        onGenerateHandler(promptSent);
        setGenerate(true);
        setGenerating(true);
        setIsTyping(true);
    };


const summaryTemplate = (condition:string) =>{
    const text =`
    Summarize the text provided in the 'Initial Text' section according to the following criteria:

    - Condense the content while preserving the key points and essential details.
    - If a specific word limit is provided in the 'Number of words to summarize to' section, adjust the summary to match that length.
    - Follow the specified condition: **${condition}**. 

    if ${condition} is 'Summarize these notes into bullet points then put it in a bullet point format' then the summary should be in bullet point format.
      Examples of conditions could include "summarize in bullet points", "focus on technical details", "summarize as a formal executive summary", or "simplify for a 5th-grade reading level."
    - If no specific condition is provided, default to a concise paragraph summary.
    - Include any requested specific details when applicable.
    - Ensure the summary is clear, concise, and retains the essential meaning of the original text.

    Initial Text: [Insert the text here]
    Number of words to summarize to: [Insert word limit here, if any]
    Condition: **${condition}**
`;
return text;
}

const quizTemplate = (condition: string) => {
    const text = `
    Create a quiz according to the following instructions:

    - The quiz should be based on the content provided in the 'Source Text' section.
    - The type of quiz should be aligned with the following condition: **${condition}**.
    - If the condition is 'Create a multiple-choice quiz', then each question should have 4 answer choices, with one correct answer clearly indicated.
    - If the condition is 'Create a true/false quiz', then each question should present a statement that can be answered as either true or false.
    - If the condition is 'Create a fill-in-the-blank quiz', then each question should have a blank space where the missing word or phrase should be provided by the participant.
    - If the condition is 'Create a short-answer quiz', then each question should be open-ended, requiring a brief, descriptive response.
    - Examples of conditions could include "multiple-choice quiz", "true/false quiz", "fill-in-the-blank quiz", or "short-answer quiz".
    - If no specific condition is provided, default to a mix of multiple-choice and short-answer questions.
    - The quiz should have clear and concise instructions for each question type.
    - Provide correct answers and explanations for each question, if applicable.
    - Ensure the quiz is engaging, clear, and relevant to the provided source material.

    Source Text: [Insert the source text here]
    Number of questions: [Insert number of questions, if specified]
    Condition: **${condition}**
    `;

    return text;
}



    const onFinish = async (promptMessage: string, letter = false, ) => {
        console.log(promptMessage);
        setMessage(promptMessage);
        console.log(message);
        setIsTyping(true);
        setPromptSent(promptMessage);
        await onGenerateHandler(promptMessage, letter,);
        setGenerate(true);
        setGenerating(true);
    };

    // const chatWithAI = async () => {
    //     const promptMessage = newPromptSent;
    //     setNewPromptSent(promptMessage);
    //     setPromptSent(promptMessage);

    //     await onGenerateHandler(promptMessage);
    //     setGenerate(true);
    //     setGenerating(true);
    //     const timeoutId = setTimeout(() => {
    //         setGenerating(false);
    //     }, 3000);
    // };

    const handleSaveComment = (comment: any) => {
        console.log('Saved comment:', comment);
        setDialogVisible(false);
    };

    const getPromptTypeBasedOnPage = () => {
        let prompt = prompts.chat;

        if (currentPage == 1) {
            prompt = summaryTemplate(template);
        } else if (currentPage == 2) {
            prompt = prompts.workSheetGenerator;
        } else if (currentPage == 3) {
            prompt = prompts.quoteOfTheDay;
        } else if (currentPage == 4) {
            prompt = prompts.reportCardComment;
        } else if (currentPage == 5) {
            prompt = quizTemplate(quiz);
        } else if (currentPage == 6) {
            prompt = prompts.hooksGenerator;
        } else if (currentPage == 7) {
            prompt = prompts.exitTicketGenerator;
        } else if (currentPage == 9) {
            prompt = prompts.textSummarizer;
        }

        return prompt;
    };

    const onGenerateHandler = async (message: string, letter = false) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message };
        // console.log(msgs);
        // return;
        setChats(msgs);
        // setNewPromptSent('');
        setResponse('');

        const base = getPromptTypeBasedOnPage();
        setBasePrompt(base);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: getPromptTypeBasedOnPage(),
                            type: 'gpt-4'
                        },
                        msgs,
                    ],
                    userId: details?.userId ?? '',
                },
            });
        } catch (error) {
            //   setIsTyping(false);
        } finally {
            //   setIsTyping(false);
        }
    };
    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    useEffect(() => {
        console.log('MyHistory',histories);
        getHistory();
    }, []);

    useEffect(() => {
     
        setShowRightComponent(false);
       
        setMobileNavStyle(!isMobile ? { position: 'fixed' } : {});
        setShowHistory(!isMobile)
        setShowInput(false);
        setShowPagePath(false); 
        setOnDeleteAllHistory(() => {});
        setCanNavigateHome(true);   
        setShowHistoryOptions(page != '');
        setHistoryType('contents')
         

    }, []);

    useEffect(() => {
        if ( response  ){
            setEditorContent(response);
        }
    }, [response])


    



    return (
       
          <div ref={bottomRef} className="h-screen overflow-auto ">
                {!generate && !showingHistory ? (
                    <NewGenerateContent 
                    quiz={quiz}
                    setQuiz={setQuiz}
                    template={template}
                    setTemplate={setTemplate}
                        onGenerate={(prompt, isLetter, promptSelected) => {
                         console.log(isLetter);
                         onFinish(prompt, isLetter)
                        }} 
                        setTopic={(e) => setTopic(e)}
                        setPageName={(e) => setPage(e) } 
                        setPageNumber={(i) => setCurrentPage(i)}
                        
                    />
                ) : (
                    <div className="w-full flex h-screen flex-col gap-0">
                        <div className="w-full flex flex-row gap-0 ">
                            <div className="w-full flex flex-col gap-0   ">

                            <NewEditorPage
                            editorInstanceRef={editorInstanceRef}
                            regenerate={regenerate}
                            latestRespone={latestRespone}
                            setLatestRespone={setLatestRespone}
                            highlightedTexts={highlightedTexts}
                            setHighlightedTexts={setHighlightedTexts}
                                content={
                                    <NewSunEditorComponent
                                    latestRespone={latestRespone}
                                    setLatestRespone={setLatestRespone}
                                    highlightedTexts={highlightedTexts}
                                    setHighlightedTexts={setHighlightedTexts}
                                    editorInstanceRef={editorInstanceRef}
                                        // humanizeded_content={Document.current?.data.ops}
                                        // hasAccess={hasAccess}
                                        hasAccess={
                                            !isTyping && (documentResponse?.role ?? '') !== 'view'
                                                ? true
                                                : false
                                        }
                                        initial_content={response}
                                        onChangeEditorContent={(e: string) => {
                                            setEditorContent(e);
                                            const newtext = e;
                                            console.log('newtext', newtext);

                                            console.log('docId', docId);
                                            console.log('userDetails?.userId', userDetails?.userId);

                                            sendChanges(docId, userDetails?.userId, e);
                                        }}
                                    />
                                }
                                showTopNavBar={false}
                                showInvite={true}
                                isTyping={isTyping}
                                Profile={Profile}
                                promptSent={promptSent}
                                showingHistory={showingHistory}
                                setGenerate={setGenerate}
                                setShowingHistory={setShowingHistory}
                                generate={generate}
                                setEditorContent={setEditorContent}
                                editorContent={editorContent}
                                translatedContent={translatedContent}
                                setTranslatedContent={setTranslatedContent}
                                humanizedContent={humanizedContent}
                                setHumanizedContent={setHumanizedContent}
                                setUserOnDoc={setUserOnDoc}
                                userOnDoc={userOnDoc}
                            />
                          



                                {/* <div className="mt-1">
                                    <div className="flex flex-col gap-4">
                                       <BackButton
                                            onclick={() => {
                                                if ( showingHistory ){
                                                    setShowingHistory(false)
                                                    setSidebarVisible(true);
                                                    if ( generate ){
                                                        setGenerate(false)
                                                    }
                                                    return; 
                                                }
                                                setGenerate(false);
                                            }}
                                        />
                                        { <ContentTypingComponent isTyping={isTyping} />} 
                                    </div>

                                    {
                                        showingHistory &&
                                        <div className="w-full flex flex-row gap-0  px-10 py-5 ">
                                            <div
                                                style={{
                                                    height: '120px',
                                                    backgroundColor: '#F9FAFC',
                                                    fontFamily: 'Inter',
                                                    fontSize: '0.8125rem',
                                                    fontWeight: '400',
                                                }}
                                                className="w-full flex flex-row gap-6 p-4 bg-white overflow-x-hidden overflow-y-scroll "
                                            >
                                                <img src={Profile} className="h-8" alt="profile" />
                                                <span>{promptSent}</span>
                                            </div>
                                        </div> */}

                                        {/* </div>
                                  

                                    
                                    {generating ? (
                                        <LoadingComponent isMobile={false} width={''} />
                                    ) : (
                                        <div>
                                            <ResponseTextbox
                                                content={<MarkdownDesign>{response}</MarkdownDesign>}
                                                options={[]}
                                                disLikeClick={handleDisLikeClick}
                                                messageClick={handleMessageClick}
                                                likeClick={handleLikeClick}
                                                regenerateClick={regenerate}
                                                profileClick={() => {}}
                                            />
                                        </div>
                                    )}
                                </div> */}
                                {/* <div className="sticky bottom-0 bg-custom-opacity">
                                    <Input
                                        type="text"
                                        onChange={(e) => setNewPromptSent(e.target.value)}
                                        value={newPromptSent}
                                        placeholder="Describe what you want"
                                        style={{
                                            height: '50px',
                                            borderRadius: '16px',
                                            border: '1px solid lightgrey',
                                            marginTop: '17px',
                                        }}
                                        suffix={
                                            <svg
                                                onClick={() => chatWithAI()}
                                                width="36"
                                                height="36"
                                                viewBox="0 0 36 36"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15 18L31.5 18"
                                                    stroke="#E55109"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M31.5004 18.0004L13.1254 26.6254C12.9116 26.7049 12.6711 26.6524 12.5098 26.4911C12.3485 26.3298 12.296 26.0893 12.3754 25.8754L15.0004 18.0004L12.3754 10.1254C12.296 9.91158 12.3485 9.67107 12.5098 9.50977C12.6711 9.34846 12.9116 9.29598 13.1254 9.37542L31.5004 18.0004"
                                                    stroke="#E55109"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        }
                                    />
                                    <div className="advise">
                                        Gmind can make mistakes. It's advisable to verify crucial information.
                                    </div>
                                </div> */}

                                <ReusableDialog
                                    visible={dialogVisible}
                                    title="Add Comment"
                                    inputPlaceholder="Enter your comment"
                                    saveLabel="Save"
                                    onCancel={() => setDialogVisible(false)}
                                    onSave={handleSaveComment}
                                />
                                {showShareModal && <Share url={UrlRef.current} onClose={() => setShowShareModal(false)} />}
                                {showDownload && (
                                    <Download
                                        handlePDF={handlePDF}
                                        handleWord={handleWord}
                                        url={UrlRef.current}
                                        onClose={() => setShowDownload(false)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div> 
       
    );
}


export default SchoolResources;
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import back from '@assets/chat/back_circle.svg';
import Arrow from '@src/assets/arrow';
import { GiShipWheel } from 'react-icons/gi';
import { UserLists } from './UserLists';
import cloudCheck from '@icons/cloud.png';
import { getRegion } from '@src/utils/app_functions';
import TweakerButton from './tweaker';
import { Dropdown, Menu } from 'antd';
import { ThemeContext } from '@src/theme_provider/theme';
import { DownOutlined } from '@ant-design/icons';

type ConditionType<T> = {
    translationLoading: T;
    humanizing: T;
};
export const SubNavBar = ({
    title = 'Title',
    selected,
    onBack: onClick,
    humanized = false,
    className,
    onHumanizeClick,
    showActions,
    loading = false,
    canHumanize,
    canTranslate,
    isTyping,
    isBack,
    handleImproveQuality,
    handleChangeTone,
    handleRewrite,
    isTweaking,
    handleLength,
    isContent,
}: {
    title: string;
    selected: (v: string) => void;
    onBack?: () => void;
    humanized?: boolean;
    onHumanizeClick?: () => void;
    className?: string;
    showActions?: boolean;
    loading: boolean;
    canHumanize: boolean;
    canTranslate: boolean;
    isTyping?: boolean;
    isBack?: boolean;
    handleImproveQuality?: (condition: string) => void;
    handleChangeTone?: (condition: string) => void;
    handleRewrite?: () => void;
    handleLength?: (condition: string) => void;
    isTweaking: {
        [key: string]: boolean;
    };
    isContent?: boolean;
}) => {
    const [conditions, setConditions] = useState<ConditionType<boolean>>({
        translationLoading: false,
        humanizing: false,
    });

    // const nigerianOptions = ['English', 'Yoruba', 'Igbo', 'Hausa', 'French', 'Spanish', 'Arabic'];
    const nigerianOptions = ['English', 'Yoruba', 'Igbo', 'Hausa'];
    const otherNationOptions = ['English', 'French', 'Spanish', 'Arabic'];

    const [languageOptions, setLanguageOptions] = useState<string[]>(['English']);
    const { isMobile } = useContext(ThemeContext);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        getRegion().then((data) => {
            if (data.location == 'ngn') {
                setLanguageOptions(nigerianOptions);
            } else {
                // setLanguageOptions(otherNationOptions);
                setLanguageOptions(nigerianOptions);
            }
        });
    }, []);

    useEffect(() => {}, []);

    useEffect(() => {
        setConditions((c) => ({ ...c, translationLoading: !loading }));
    }, [loading]);

    useEffect(() => {
        setConditions((c) => ({ ...c, humanizing: !humanized }));
    }, [humanized]);

    function handleLanguage(language: string) {
        selected(language);
        setConditions((c) => ({ ...c, translationLoading: true }));
    }

    const menu = (
        <Menu>
            <Menu.Item key="translate"   onClick={(e) => {
                e.domEvent.stopPropagation(); 
            }}>
                {canTranslate && (
                    <div className="flex items-center gap-3 relative" onClick={(e)=>{e.stopPropagation()}}>
                        {showActions && (
                            <>
                                <label htmlFor="translate">Translate</label>
                                <Select
                                    isTyping={isTyping ?? false}
                                    selected={(v) => {
                                        handleLanguage(v);
                                    }}
                                    options={['English', 'Yoruba', 'Igbo',]}
                                    // options={['English', 'Yoruba', 'Igbo', 'Hausa', 'French', 'Spanish', 'Arabic']}
                                />
                                {conditions.translationLoading && (
                                    <GiShipWheel className=" animate-spin absolute right-[-2rem] text-2xl" />
                                )}
                            </>
                        )}
                    </div>
                )}
            </Menu.Item>
            <Menu.Item key="humanize"   onClick={(e) => {
                e.domEvent.stopPropagation(); 
            }}>
                {canHumanize && (
                    <div className="relative  flex items-center" onClick={(e)=>{e.stopPropagation()}}>
                        {showActions && (
                            <button
                                onClick={onHumanizeClick}
                                className="bg-brandOrange text-sm p-2 py-1 text-white font-medium rounded-md"
                                type="button"
                                style={{flex:"1"}}
                            >
                                <span>Humanize</span>
                            </button>
                        )}
                        {conditions.humanizing && <GiShipWheel className=" animate-spin relative ml-2 text-2xl" />}
                    </div>
                )}
            </Menu.Item>
        </Menu>
    );

    return (
        <section className={'h-14 px-4 flex justify-between sticky top-0 bg-white z-[300] items-center ' + className}>
            <div className="flex items-center gap-4 relative">
                {!isBack && (
                    <button
                        className={` flex flex-row items-center gap-2 ${isContent ? '' : 'mr-14'}`}
                        onClick={onClick}
                        type="button"
                    >
                        <img className="w-6" src={back} alt="back" />
                        <span className="font-medium text-[0.9rem]">Back</span>
                    </button>
                )}
                {/* <img className="w-[1.3rem] h-[1.3rem]" src={cloudCheck} alt="cloud check" />
                <p className="font-medium text-[0.9rem]">Gmind Editor</p> */}
             {!isTyping &&   <TweakerButton
                    isTyping={isTweaking}
                    handleChangeTone={handleChangeTone}
                    handleImproveQuality={handleImproveQuality}
                    handleLength={handleLength}
                    handleRewrite={handleRewrite}
                />}

                {isTyping && (
                    <div className="flex items-center gap-3 pl-10 relative">
                        <GiShipWheel className=" animate-spin absolute right-[-2rem] text-2xl" />
                        <p className="font-medium text-[0.9rem]">Gmind is typing</p>
                    </div>
                )}
            </div>
            {canTranslate && !isMobile && (
                <div className="flex items-center gap-3 relative">
                    {showActions && (
                        <>
                            <label htmlFor="translate">Translate</label>
                            <Select
                                isTyping={isTyping ?? false}
                                selected={(v) => {
                                    handleLanguage(v);
                                }}
                                options={['English', 'Yoruba', 'Igbo', 'Hausa']}
                                // options={['English', 'Yoruba', 'Igbo', 'Hausa', 'French', 'Spanish', 'Arabic']}
                            />
                            {conditions.translationLoading && (
                                <GiShipWheel className=" animate-spin absolute right-[-2rem] text-2xl" />
                            )}
                        </>
                    )}
                </div>
            )}
            {canHumanize && !isMobile && (
                <div className="relative  flex items-center">
                    {showActions && (
                        <button
                            onClick={onHumanizeClick}
                            className="bg-brandOrange text-sm p-2 py-1 text-white font-medium rounded-md"
                            type="button"
                        >
                            <span>Humanize</span>
                        </button>
                    )}
                    {conditions.humanizing && <GiShipWheel className=" animate-spin relative ml-2 text-2xl" />}
                </div>
            )}

            {isMobile && (
                <div className="relative">
                    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                        <button className="p-2 rounded-full hover:bg-gray-200 focus:outline-none">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16m-7 6h7"
                                />
                            </svg>
                            
                        </button>
                    </Dropdown>
                </div>
            )}
            {
                // <div>{showActions ? <UserLists /> : <></>}</div>
            }
        </section>
    );
};

export const Select = ({ options, selected, isTyping }: { options: string[]; selected: (v: string) => void; isTyping: boolean; }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<string>(options[0] ?? '');

    const languagesAbbreviations: any = {
        English: 'English',
        Yoruba: 'Yoruba',
        Igbo: 'Igbo',
        Hausa: 'Hausa',
    };

    // const languagesAbbreviations: any = {
    //     English: 'en',
    //     Yoruba: 'yo',
    //     Igbo: 'ig',
    //     Hausa: 'ha',
    //     French: 'fr',
    //     Spanish: 'es',
    //     Arabic: 'ar',
    // };

    return (
        <section
            role="button"
            onClick={() => {
                if ( isTyping ) return;
                setIsOpen(!isOpen);
            }}
            className="border flex z-[300] items-center gap-10 justify-between border-gray-300 rounded-md p-1 relative h-fit w-[8rem]"
        >
            <p className="select-none">{selectedItem}</p>
            {isOpen && (
                <div className="absolute overflow-hidden left-0 right-0 bg-white shadow-md rounded-md translate-y-36 z-[10000]">
                    {options.map((option) => {
                        return (
                            <p
                                onClick={() => {
                                    setSelectedItem(option);
                                    selected(languagesAbbreviations[option]);
                                }}
                                className="select-none hover:bg-brandOrange text-sm p-2"
                            >
                                {option}
                            </p>
                        );
                    })}
                </div>
            )}
            <Arrow
                style={isOpen ? { transform: 'rotate(90deg)' } : {}}
                color="#e55109"
                className="w-4 transition-all -rotate-90"
            />
        </section>
    );
};

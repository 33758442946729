import "./suggestion.scss"
import minus from "@icons/minus.svg";
import plus from "@icons/plus.svg";
import { ThemeContext } from "@src/theme_provider/theme";
import { Minus, Plus } from "lucide-react";
import { useContext, useState } from "react";

interface SuggestionBubbleProps {
    suggestions: string[];
    handleSuggestionClick: (suggestion: string) => void;
}

const SuggestionBubble = ({ suggestions, handleSuggestionClick }: SuggestionBubbleProps) => {
    const [currentAccordion, setCurrentAccordion] = useState<number | undefined>(undefined);
    const {savedTheme} = useContext(ThemeContext)
    
    const handleAccordion = (index: number) => {
        if (currentAccordion === index) {
            setCurrentAccordion(-1);
        }else{
            setCurrentAccordion(index);
        }
    }

    function limitString(input: string, limit: number = 90): string {
        return input.length > limit ? input.substring(0, limit) + '...' : input;
    }

    return (
        <div className="flex  flex-wrap justify-center items-center gap-1 pl-[15%] pr-[15%] pb-5">
            {suggestions.map((suggestion, index) => (
                <div className="w-full flex justify-center flex-col gap-y-1">
                        <div onClick={() => handleAccordion(index)} key={index} className="flex items-center justify-between p-3" style={
                            {
                                borderBottom: savedTheme ==='dark'? "1px solid white": "1px solid black"
                            }
                        }>
                            <h3 className="font-medium text-[0.6875rem] dark:text-white text-[var(--gmind-black)]">{limitString(suggestion)}</h3>
                            {currentAccordion === index ?<Minus className="dark:text-white text-[0.7rem] cursor-pointer"/>:<Plus className="dark:text-white text-[0.7rem] cursor-pointer"/>}
                        </div>

                        <div onClick={() => handleSuggestionClick(suggestion) } className="cursor-pointer flex flex-col p-3 dark:text-white gap-y-3 dark:bg-gray-800 bg-[var(--gmind-playground-grey)] shadow-lg" style={{
                            borderLeft: "3px solid var(--gmind-orange)",
                            display: currentAccordion === index ? "block" : "none",
                        }}>
                            <h3 className="font-medium text-[0.6875rem] dark:text-white text-[var(--gmind-orange)]">{suggestion}</h3>
                        </div>

                    </div>
            ))}
        </div>
    );
};

export default SuggestionBubble;

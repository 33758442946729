import { CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';

interface CloseClickProps {
    closeClick?: () => void;
    isLayout?: boolean;
    showClose?: boolean;
}

function PromoHeader({ closeClick, isLayout, showClose }: CloseClickProps) {
    const navigate = useNavigate();

    return (
        <header className="relative bg-customOrange font-Poppins text-white px-2 py-2 sm:px-4 flex flex-col sm:flex-row justify-center items-center">
            <div className="flex flex-col sm:flex-row w-full items-center text-center sm:text-left justify-center ">
                <p className="text-xs sm:text-base mr-1 sm:mb-0 mb-2">
                🎉 Limited-Time Offer: 50% OFF! 🎉. Don't miss out on our biggest sale from January 1st to March 31st. Save big while it lasts!
                </p>
                {isLayout && (
                    <span
                        onClick={() => navigate('pricing')}
                        className="text-xs sm:text-base hover:text-green-300 hover:underline cursor-pointer"
                    >
                        Apply here
                    </span>
                )}
            </div>
            { showClose && <button 
                onClick={closeClick} 
                className="absolute top-2 right-2 bg-transparent px-2 py-1 rounded-md hover:color-gray-100"
            >
                <CloseOutlined />
            </button>}
        </header>
    );
}

export default PromoHeader;

import React, { useState } from 'react';
import { Card } from 'antd';

const styles = [
    'Middle School (Grades 6–8)',
    'High School (Grades 9–12)',
    'Undergraduate (College/University Freshman-Senior)',
    "Graduate Level (Master's/Ph.D.)",
];

export const RGrade = ({
    selectedGrade,
    setSelectedGrade,
}: {
    selectedGrade: string;
    setSelectedGrade: React.Dispatch<React.SetStateAction<string>>;
}) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <h2 className="text-2xl font-semibold mb-4 dark:text-gray-300">Grade level</h2>
            <p className="text-gray-600 dark:text-gray-300 mb-6">Select grade level</p>
            <div
                style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}
                className="w-full items-center justify-center"
            >
                {styles.map((style) => (
                    <Card
                        key={style}
                        hoverable
                        className='w-full md:w-auto flex flex-row items-center justify-center'
                        style={{
                            border: style === selectedGrade ? '2px solid #1890ff' : '1px solid #ddd',
                        }}
                        onClick={() => setSelectedGrade(style)}
                    >
                        {style}
                    </Card>
                ))}
            </div>
        </div>
    );
};

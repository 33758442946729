import * as pdfjsLib from 'pdfjs-dist';
import mammoth from 'mammoth';
import { extractFileData } from '../endpoints';

import fs from 'fs';
import pdf from 'pdf-parse';






// Extract Text from PDF
export const extractTextFromPDF = async (file: File) => {
    // pdfjsLib.GlobalWorkerOptions.workerSrc = window.location.origin + "/pdf.worker.min.mjs";
    // pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@4.7.76/build/pdf.worker.min.mjs`;
    let text = '';
    try {
        const documentValue = await extractFileData(file);
        if ( documentValue.success ){
            text = documentValue.content ?? '';
        }
        return text;   
    } catch (error) {
        console.log(error)
        return '';
    }
    
};

// Extract Text from DOCX
export const extractTextFromDOCX = async (file: File) => {
    // const arrayBuffer = await file.arrayBuffer();
    // const { value } = await mammoth.extractRawText({ arrayBuffer });
    // return value;
    let text = '';
    try {
        const documentValue = await extractFileData(file);
        if ( documentValue.success ){
            text = documentValue.content ?? '';
        }
        return text;   
    } catch (error) {
        console.log(error)
        return '';
    }
};

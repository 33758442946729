/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, Select, message } from 'antd';
import { LessonMaterialProps, LetterWriterProps, TextTranslatorProps, VisualizeDataPayloadProps } from '../interface';
import { toast } from 'react-toastify';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import "./style.scss";
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import { extractTextFromPDF } from '@src/pages/dashboard/function/pdf_doc_helper';
import { analysisOptions, chartOptions } from '../constants';
import { uploadChatPicture, visualizeData } from '@src/pages/dashboard/endpoints';
import BackButton from '@src/components/BackButton';
import { Tooltip } from 'react-tooltip'
import imageCompression from 'browser-image-compression';
import SkeletonLoader from '@src/pages/dashboard/components/skeleton_loader';
import cancelImg from '@assets/cancel.svg';
import { Spinner } from '../../slide/component/Forms/first-form';
import GeneratedResponse from '../components/response_generated';

const { Option } = Select;


const CreateChart = ({onClickBack}: {onClickBack?: () => void}) => {
    const recognitionRef = useRef<any>(null);
    const [recording, setRecording] = useState(false);
    const [form] = Form.useForm();
    const [finalTranscript, setFinalTranscript] = useState('');

    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [generated, setGenerated] = useState<boolean>(false);

    const [recordingField, setRecordingField] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null); // Ref for the file input
    const [docData, setDocData] = useState<string | ArrayBuffer | null>(null);

    const [chartData, setChartData] = useState<any>(undefined);
    const [promptSent, setPromptSent] = useState<string>('');
    const [response, setResponse] = useState<string>('');
    const [ analysisValue, setAnalysisValue ] = useState<string>('');
    const [img, setImg] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const [imageSelected, setImageSelected] = useState<{ url: string; loading: boolean }[]>([]);




  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
    
            reader.onload = async (e) => {
                const content = e.target?.result;
    
                if (file.type === 'application/pdf') {
                    const pdfText = await extractTextFromPDF(file);
    
                    setDocData(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData(result.value);
                    form.setFieldsValue({ [field]: result.value });
                }else if ( file.type.startsWith('image/') ){
                    await compressAndUploadImage(file);
                }else if (content) {
                    setDocData(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };    
            reader.onerror = () => console.error('Error reading file');
    
            reader.readAsArrayBuffer(file);
        }
    };

    const compressAndUploadImage = async ( img: File) => {
        if (!img) return;

        const options = {
            maxSizeMB: 1, // Maximum size in MB
            maxWidthOrHeight: 1920, // Resize dimensions
            useWebWorker: true, // Use web worker for performance
        };

        try {
            // Compress the image before uploading
            const compressedFile = await imageCompression(img, options);

            // Proceed with upload if compression is successful
            if (compressedFile) {
                await handlePictureUpload(compressedFile);
            } else {
                toast.error('Image compression failed');
            }
        } catch (error) {
            console.error('Error during image compression or upload:', error);
            toast.error('An error occurred while processing the image');
        } finally {
            setImg(null); // Reset img after processing
        }
    };


    const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.type.startsWith('image/')) {
                const file = item.getAsFile();
                console.log('File', file);
                if (file) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImg(file);
                    };
                    reader.readAsDataURL(file);
                }
                break; // Only handle the first image
            }
        }
    };


    const handlePictureUpload = async (file: File) => {
        const tempUrl = URL.createObjectURL(file);
        try {
            setUploading(true);
    
            // Set the new image with loading state as true
            setImageSelected((prev) => [...prev, { url: tempUrl, loading: true }]);
    
            const result = await uploadChatPicture(file);
    
            if (result.success) {
                if (imageSelected.length >= 5) {
                    toast.error('You can only upload 5 pictures');
                    return;
                }
                // Update the image URL and set loading to false
                setImageSelected((prev) =>
                    prev.map((image) =>
                        image.url === tempUrl // Compare with the initially created tempUrl
                            ? { ...image, url: result.url || '', loading: false }
                            : image,
                    ),
                );
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error('An error occurred while uploading the picture');
        } finally {
            setUploading(false);
        }
    };





    const onPaperClipClick = () => {
        // Programmatically trigger the file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleMicrophoneClicked = (field: string) => {
        if (recording && recordingField !== field) {
            toast.warning('Please stop the current recording before starting a new one.');
            return; // Prevent starting a new recording
        }

        setRecordingField(field); // Set the current field being recorded
        handleMicrophone(field);
        console.log(field);
    };
    async function handleMicrophone(field: string) {
        if (recording) {
            stopRecording();
        } else {
            startRecording(field);
        }
    }

    const startRecording = (field: string) => {
        try {
            toast.success('Recording in progress...');
            setRecording(true);
            recognitionRef.current = new (window as any).webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setFinalTranscript(texts.join(' '));
                console.log('text', texts);
                console.log('final:', finalTranscript);
                // Use getFieldsValue and setFieldsValue for Antd form
                // Use getFieldsValue and setFieldsValue for Antd form
                const fields = form.getFieldsValue();
                console.log('Current fields:', fields);

                // Set the updated value in the correct field
                form.setFieldsValue({
                    [field as string]: texts.join(''), // Update the specific field
                });
                console.log('Current fields:', fields);

                console.log(`Updated ${field}:`, texts);
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const stopRecording = () => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                recognitionRef.current.stop();
                setRecording(false); // Reset after stopping
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };
    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
          }
    }, [fileInputRef]);

    const onFinish = async (values: any) => {
        console.log('hello');
        let promptMessage = '';

        const {
            projectName,
            chart,
            additional,
        } = values;

        // check if all data is passed
        if (!projectName || !analysisValue  ) {
            toast.error('Please fill all fields');
            return;
        }

        if ( analysisValue == 'visualize' && !chart ){
            toast.error('Kindly specify the chart to use.');
            return;
        }

        if ( imageSelected.length == 0 && !docData ){
            toast.error('Kindly enter the project data');
            return;
        }

        const imagesToBeSent: string[] = imageSelected.map((img) => (img.url));


        promptMessage = `Visualize the data for this project with this data: \nProject Name: ${projectName}\nChart:${chart}\nData to visualize:${docData}.`;


        const info = `\n${additional ? `Additional Info: ${additional}`: ''}`

        setPromptSent(promptMessage);
        await callVisualizeData({
            analysis: analysisValue,
            type: chart,
            data: `${docData}${info}`,
            images: imagesToBeSent,
        })
    };

    const callVisualizeData = async (payload: VisualizeDataPayloadProps) => {
        try {
            setIsGenerating(true);
            const response = await visualizeData(payload); 
            
            if ( response.statusCode == 0 ){
                toast.error(response.message)
                return;
            }

            setGenerated(true);

            setResponse(response.response)
            setChartData(response.chartData)


        } catch (error: any) {
            toast.error(error.message || 'Error occurred');
        }finally {
            setIsGenerating(false);
        }
    }




    return (
        <div className='flex flex-col gap-5'>
            {!generated && onClickBack && <BackButton
                                onclick={() => {
                                    onClickBack(); 
                                }}
                            />}
            {
                generated && (
                    <GeneratedResponse 
                        response={response}
                        chartData={chartData}
                        onClickBack={() => {
                            setGenerated(false);
                        }}
                        promptSent={promptSent}
                        canMoveToEditor={analysisValue === 'analyze'}
                    />
                )
            }

            {
                !generated && (
                <Form form={form} onFinish={onFinish} className="flex flex-col px-4 py-4 space-y-4 w-full lg:w-[80%]" layout="vertical">
                    <h1 className="text-xl font-bold dark:text-white">Data Insights</h1>
                    <p className='dark:text-white' >Collect data summaries and generate insightful charts for your project.</p>

                    <Form.Item label="Project Type" name="analysis" rules={[{ required: true, message: 'Please select type of analysis' }]}>
                        <Select 
                            placeholder="Choose project type" 
                            onChange={(e) => {
                                setAnalysisValue(e);
                            }}
                        >
                            {
                                analysisOptions.map((value, index) => (
                                    <Option key={index} value={value.value}>{value.name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Project Name"
                        name="projectName"
                        rules={[{ required: true, message: 'Please enter the project name' }]}
                        
                    >
                        <Input placeholder="Specify the project name" />
                    </Form.Item>

                    <Form.Item
                        label={`${analysisValue === 'visualize' ? "Project Data": "Attach image or chart"}`}
                        name="projectData"
                        rules={ analysisValue === 'visualize' ? [{ required: true, message: 'Please enter the project data' }] : undefined }
                        
                    >
                        <div className="flex items-start border dark:border-white border-gray-700 rounded">
                            <div className=" flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                                <PaperClipOutlined className='mt-5 dark:text-white' data-tooltip-id='tip' data-tooltip-content="Attach image / content of dataset you want to analyze / visualize" onClick={onPaperClipClick} />
                                <Tooltip id="tip" />
                            
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept=".doc,.docx,.pdf,.txt, image/*"
                                    style={{ display: 'none' }} // Hide the input element
                                    onChange={(e) => handleFileChange(e, 'originalText')}
                                />
                            </div>

                            <div className="flex flex-col gap-y-4 w-full">
                                <div className="flex items-center ">
                                    {imageSelected.map((image, index) => (
                                        <div key={index} className="mx-5 mt-5 rounded-md w-16 h-16 relative">
                                            {image.loading ? (
                                                <SkeletonLoader />
                                            ) : (
                                                <img src={image.url} alt="" className="w-full h-full rounded-md" />
                                            )}
                                            <div
                                                className="w-6 h-6 border border-gray-400 rounded-full bg-white absolute right-[-10px] top-[-10px]"
                                                onClick={() => {
                                                    const newImages = imageSelected.filter((_, i) => i !== index);
                                                    setImageSelected(newImages);
                                                }}
                                            >
                                                <img src={cancelImg} alt="cancel" loading="lazy" className="w-full h-full" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <TextArea
                                    onPaste={handlePaste}
                                    value={form.getFieldValue('originalText')} // This makes it controlled
                                    onChange={(e) => {
                                        setDocData(e.target.value); // Update both docData and form values
                                        form.setFieldsValue({ originalText: e.target.value });
                                    }}
                                    rows={6}
                                    className="p-2 rounded-none border-none w-full focus:outline-none bg-transparent hover:bg-transparent placeholder-gray-700"
                                    placeholder={`${analysisValue === 'visualize' ? "Please enter the data you want to visualize": "Attach image or chart"}`}
                                    style={{ outline: 'none', boxShadow: 'none',borderColor: 'transparent',  }} // Remove outline and box shadow
                                />
                            </div>
                        </div>
                    </Form.Item>
                    { analysisValue === 'visualize' && ( <Form.Item label="Chart Type" name="chart" rules={[{ required: true, message: 'Please select chart type' }]}>
                        <Select placeholder="Choose language">
                            {
                                chartOptions.map((value, index) => (
                                    <Option key={index} value={value.value}>{value.name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item> )}
                    <Form.Item label="Additional Instruction" name="additional">
                        <TextArea 
                            placeholder="Enter additional Information">
                            value={form.getFieldValue('additional')} // This makes it controlled
                            onChange={(e: { target: { value: SetStateAction<string | ArrayBuffer | null>; }; }) => {
                                setDocData(e.target.value); // Update both docData and form values
                                form.setFieldsValue({ additional: e.target.value });
                            }}
                            rows={4}
                            className="p-2 rounded-none border-none w-full focus:outline-none bg-transparent hover:bg-transparent placeholder-gray-700"
                            style={{ outline: 'none', boxShadow: 'none',borderColor: 'transparent',  }} 
                        </TextArea>
                    </Form.Item>
                    <Form.Item>
                        <button
                            type="submit"
                            className=" no-hover-effect mt-2 py-2 px-4 bg-customOrange text-white rounded hover:bg-[var(--gmind-orange)]"
                        >
                             {
                                !isGenerating ? (
                                    "Generate"
                                ): (
                                    <div className="flex items-center text-sm text-center">
                                        <Spinner />
                                        <small>Generating your analysis...</small>
                                    </div> 
                                )

                             }
                        </button>
                    </Form.Item>
                </Form>
            )}
        </div>

       
    );
};

export default CreateChart;

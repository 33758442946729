import React, { Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import agate from 'react-syntax-highlighter/dist/esm/styles/hljs/agate';
import 'github-markdown-css';
import './index.css';
// import './index_dark.css';
import { ThemeContext } from '@src/theme_provider/theme';
import { EditOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import { getUrlPath } from '@src/utils/app_functions';
import ReferenceTooltip from './referenceTooltip';


interface ChatContent {
    text?: string;
}

interface ChatMessageProps {
    id: number; // Added id to uniquely identify the message
    role: string;
    className?: string;
    content: string | ChatContent[];
    sendUpdated?: () => void;
    editedContent: string;
    setEditedContent: Dispatch<SetStateAction<string>>;
}

function isChatContentArray(content: any): content is ChatContent[] {
    return (
        Array.isArray(content) &&
        content.every((item) => typeof item.text === 'string' || typeof item.text === 'undefined')
    );
}

function ChatMessage({ id, role, content, className, sendUpdated, editedContent, setEditedContent }: ChatMessageProps) {
    const [editingMessages, setEditingMessages] = useState<Record<number, boolean>>({}); // Track editing state for each message
    const [isVisible, setIsVisible] = useState<Record<number, boolean>>({});
    const mouseEnter = () => {
        setIsVisible((prevState) => ({
            ...prevState,
            [id]: true,
        }));
    };
    const mouseLeave = () => {
        setIsVisible((prevState) => ({
            ...prevState,
            [id]: false,
        }));
    };
    // Track edited content for each message
    const messageContent =
        typeof content === 'string'
            ? content
            : isChatContentArray(content) && content.length > 0
                ? content[0].text || ''
                : '';

    const handleEditClick = () => {
        setEditingMessages((prevState) => ({
            ...prevState,
            [id]: true, // Mark this message as being edited
        }));

        setEditedContent(
            messageContent, // Use current content if not already set
        );
        // console.log(messageContent);
    };

    const handleCancel = () => {
        setEditingMessages((prevState) => ({
            ...prevState,
            [id]: false, // Mark this message as not being edited
        }));

        setEditedContent(messageContent);
        console.log('messageContent');
    };

    const handleSave = () => {
        if (sendUpdated) {
            sendUpdated(); // Pass id and updated content
        }
        setEditingMessages((prevState) => ({
            ...prevState,
            [id]: false, // Mark this message as not being edited
        }));
    };

    return (
        <>
            {messageContent && (
                <div className={`flex flex-row gap-x-2 ${role === 'assistant' ? 'justify-start' : 'justify-end'}`} onMouseEnter={mouseEnter}
                    onMouseLeave={mouseLeave}>
                    {role !== 'assistant' && (
                        <div className="flex items-center justify-center" >
                            {isVisible[id] && (
                                <Tooltip
                                    className="custom-tooltip flex"
                                    title="Edit Chat"
                                    placement="left"
                                    overlayInnerStyle={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        border: '1px solid #ddd',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <button
                                        onClick={handleEditClick}
                                        className="flex items-center justify-center ml-6 w-6 h-6 rounded-full bg-transparent border-[1.5px] border-gray-700 dark:border-white text-gray-700 dark:text-white  focus:outline-none shadow-md"
                                    >
                                        <EditOutlined />
                                    </button>
                                </Tooltip>
                            )}
                        </div>
                    )}
                    <div
                        className={`flex rounded-xl ${role === 'assistant' ? 'text-left w-[700px] bg-transparent' : ' px-3 text-left max-w-[90%] dark:bg-[#424242] bg-white'}`}

                    >
                        {editingMessages[id] ? (
                            <div>
                                <Input.TextArea
                                    value={editedContent}
                                    onChange={(e) => setEditedContent?.(e.target.value)}
                                    className="w-full p-2 border rounded dark:text-white"
                                />
                                <div className="flex justify-end gap-2 mt-2">
                                    <button onClick={handleCancel} className="bg-gray-300 px-3 py-1 rounded">
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSave}
                                        className="bg-customOrange text-white px-3 py-1 rounded"
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`flex ${role === 'assistant' ? 'justify-start' : 'flex  justify-end  '} font_Poppins text-[#333237] p-1 m-0 text-[16px]`}
                            >
                                { role === 'assistant' ? (
                                    <ChatMarkDown className={className} messageContent={messageContent} role={role}/>): (
                                   <p className='text-black dark:text-white ' >{messageContent}</p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default ChatMessage;

export const ChatMarkDown = ({
    className,
    messageContent = '',
    role,
}: {
    className?: string;
    messageContent?: string;
    role?: string;
}) => {
    const { savedTheme } = useContext(ThemeContext);
    const path = getUrlPath().pagename;
    const markdownRef = useRef<HTMLDivElement | null>(null);

    // console.log('message', messageContent);


    const extractReferences = (content: string) => {
        const references: Record<string, string> = {};

        // Match both "References:" and "#### References:"
        const refMatch = content.match(/(?:####\s)?References:?\s*([\s\S]*)/);
        if (refMatch) {
            // Split the section into lines and filter out empty lines
            const refLines = refMatch[1].trim().split('\n').filter(Boolean);

            refLines.forEach((line) => {
                // Match both formats: with a dot or a space after the number/superscript
                const match = line.match(/^(\d+|[¹²³⁴⁵⁶⁷⁸⁹⁰]+)[.\s]?\s*(.*)$/);
                if (match) {
                    const key = match[1]; // Arabic numeral or superscript
                    const value = match[2].trim(); // Reference text
                    references[key] = value;
                }
            });
        }

        // Match inline references like [Title](URL)¹
        const inlineRefRegex = /\[([^\]]+)\]\(([^)]+)\)([¹²³⁴⁵⁶⁷⁸⁹⁰]+)/g;
        let inlineMatch;
        while ((inlineMatch = inlineRefRegex.exec(content)) !== null) {
            const key = inlineMatch[3]; // Superscript
            const value = `${inlineMatch[1]} - ${inlineMatch[2]}`; // Title and URL
            references[key] = value;
        }

        return references;
    };

    const references = extractReferences(messageContent);
    


    const superscriptToNumberMap: Record<string, string> = {
        '¹': '1',
        '²': '2',
        '³': '3',
        '⁴': '4',
        '⁵': '5',
        '⁶': '6',
        '⁷': '7',
        '⁸': '8',
        '⁹': '9',
        '⁰': '0',
    };

    const renderSuperscripts = (text: string) => {
        return text.split(/(\d+|[¹²³⁴⁵⁶⁷⁸⁹⁰])/).map((part, index) => {
            // Convert superscript to Arabic numeral if necessary
            const referenceKey = superscriptToNumberMap[part] || part;

            if (references[referenceKey]) {
                const refValue = references[referenceKey];
                const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/;
                const match = linkRegex.exec(refValue);

                let linkLabel = 'No label';
                let linkUrl = '#';

                if (match) {
                    linkLabel = match[1];
                    linkUrl = match[2];
                }

                return (
                    <Tooltip color='white' key={index} title={
                        <a
                            href={linkUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="rounded-md text-black hover:underline"
                        >
                            {references[referenceKey]}
                        </a>
                    }>
                        <span className="inline-flex items-center justify-center w-5 h-5 rounded-full bg-gray-300 dark:bg-slate-600 cursor-pointer mx-1">
                            <span className="text-xs text-black dark:text-white hover:text-blue-800">
                                {referenceKey}
                            </span>
                        </span>
                    </Tooltip>
                    // <ReferenceTooltip key={index} referenceKey={referenceKey} references={references}>
                    //     <span className="inline-flex items-center justify-center w-5 h-5 rounded-full bg-slate-600 cursor-pointer mx-1">
                    //         <span className="text-xs text-white hover:text-blue-800">
                    //             {referenceKey}
                    //         </span>
                    //     </span>
                    // </ReferenceTooltip>
                );
            }

            return part;
        });
    };

    const processChildren = (children: ReactNode): ReactNode => {
        return React.Children.map(children, (child) => {
            if (typeof child === 'string') {
                // Apply superscript rendering to text nodes
                return renderSuperscripts(child);
            }
            if (React.isValidElement(child)) {
                // Recursively process child elements
                return React.cloneElement(child, {
                    children: processChildren(child.props.children as ReactNode),
                });
            }
            return child;
        });
    };

    // This pattern will remove content after either
    // 1) A heading that includes "References:"
    // 2) Or a line that starts with "For more detailed information"
    const sanitizedContent = path === 'smart_chat'
        ? messageContent.replace(
            /(?:#+\s*)?References:?\s*([\s\S]*)|For more detailed information[\s\S]*/i,
            ''
        )
        : messageContent;

    return (
        <div
            className={clsx(
                className,
                `${savedTheme === 'dark' ? ` mode ${role === 'assistant' ? '' : `${path === 'chat' || path === 'smart_chat'|| path === 'flow_chart' ? 'user-response' : ''}`}` : 'markdown-body'}`,
                '!font-optimistic'
            )}
        >
            <ReactMarkdown
                children={sanitizedContent}
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeRaw, rehypeKatex]}
                components={{
                    p({ children }) {
                        return <p>{processChildren(children)}</p>;
                    },
                    ul({ children }) {
                        return <ul>{processChildren(children)}</ul>;
                    },
                    ol({ children }) {
                        return <ol>{processChildren(children)}</ol>;
                    },
                    li({ children }) {
                        return <li>{processChildren(children)}</li>;
                    },
                    code({ className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '');
                        return match ? (
                            <SyntaxHighlighter
                                style={agate}
                                language={match[1]}
                                showLineNumbers
                                lineNumberStyle={{ color: '#999' }}
                                PreTag="div"
                                {...props}
                            >
                                {String(children).replace(/\n$/, '')}
                            </SyntaxHighlighter>
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        );
                    },
                }}
            />
        </div>
    );
};
import {  Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import './style.scss';
import { toast } from 'react-toastify';
import AIGenerate from '@icons/generate.svg';
import { CircularProgress } from '@mui/material';
import { onGenerateWithAI } from '../../curriculum/functions';
import { BASE_URL } from '@src/utils/constants';
import { io, Socket } from 'socket.io-client';
import { useOutletContext } from 'react-router';


const { TextArea } = Input;


interface CopywritingPlanInterface {
  onGenerateCopy: (v: string) => void;
  setTopic: (e: string) => void;
}


interface LoadingStates {
  benefits: boolean;
  keyword: boolean;
  proposition: boolean;
}

const CustomTextAreaWithButton = ({
  placeholder,
  uniqueKey,
  loadingStates,
  setLoadingStates,
  value,
  onChange,
  onClickButton,
}: {
  placeholder: string;
  uniqueKey: keyof LoadingStates;
  loadingStates: LoadingStates;
  setLoadingStates: React.Dispatch<React.SetStateAction<LoadingStates>>;
  value?: string; // Optional value prop
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void; // Optional onChange prop
  onClickButton: () => boolean;
}) => {
  const handleButtonClick = () => {
      const response = onClickButton();
      if ( response ){
          setLoadingStates((prevState) => ({
              ...prevState,
              [uniqueKey]: true,
          }));
      }

  };

  return (
      <div className="relative">
          <TextArea
              autoSize={{ minRows: 4}}
              placeholder={placeholder}
              className="p-2 border border-gray-500 focus:outline-none"
              style={{ outline: 'none', boxShadow: 'none' }}
              value={value}
              onChange={onChange}
          />
          {!loadingStates[uniqueKey] ? (
              <div
                  onClick={handleButtonClick}
                  className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
              >
                  <img src={AIGenerate} alt="" />
                  <span>Use Gmind AI</span>
              </div>
          ) : (
              <div className="absolute top-[65px] right-[20px] ">
                  <CircularProgress size={15} className="w-1 h-1" />
              </div>
          )}
      </div>
  );
};

const CopywritingPlanTemplate = ({ onGenerateCopy, setTopic  }: CopywritingPlanInterface) => {
  const [form] = Form.useForm();
  const [isPageOne, setIsPageOne] = useState(true); // Control page visibility

  const [pageOneValues, setPageOneValues] = useState<{[key: string]: string}>({
    'purpose': '',
    'audience': '',
    'tone': '',
    'constraints': '',
    'context': '',
    'example': '',
  });

  const [loadingStates, setLoadingStates] = useState<LoadingStates>({
    benefits: false, 
    keyword: false, 
    proposition: false,
  });
  
  const [sockets, setSockets] = useState<{ [key: string]: Socket | null}>({});
  const baseurl = BASE_URL;
  const socketKey = ['benefits', 'keyword', 'proposition'];
  const {
    details: userDetails,
} = useOutletContext<any>();

  useEffect(() => {
      const newSockets: { [key: string]: Socket } = {};
      socketKey.forEach(item => {
          newSockets[item] = io(baseurl.slice(0, -2));
      });
      setSockets(newSockets);

      return () => {
          Object.values(newSockets).forEach(socket => {
              socket.close();
          });
      };
  }, []);

  useEffect(() => {
      socketKey.forEach(item => {
          const socket = sockets[item];
          if (!socket) return;

          const handleData = (data: string) => {
              const previousValue = form.getFieldValue(item);
              form.setFieldValue( item, previousValue + data);
          };

          const handleStreamEnd = () => {
              setLoadingStates((prevState) => ({
                  ...prevState,
                  [item]: false,
              }));
          };

          socket.on('data', handleData);
          socket.on('stream_end', handleStreamEnd);
          return () => {
              socket.off('data', handleData);
              socket.off('stream_end', handleData);
          };
      });
  }, [sockets]);

  useEffect(() => {
    console.log(pageOneValues);
  }, [pageOneValues])

  const onFinish = (values: any) => {
    let promptMessage = '';

    const {
      purpose,
      audience,
      tone,
      constraints,
      context,
      example,
    } = pageOneValues

    const {
      benefits,
      action,
      word_count,
      content_type,
      keyword,
      proposition,
    } = values;

    // Validate if all required fields are filled
    if (
      !purpose ||
      !audience ||
      !tone ||
      !constraints ||
      !context ||
      !example ||
      !benefits ||
      !action ||
      !action ||
      !word_count ||
      !content_type ||
      !keyword ||
      !proposition
    ) {
      toast.error('Please fill all the required fields before generating.');
      return;
    }

    // Create a structured prompt
    const informationData = `
       Purpose/Goal: ${purpose}
      \nTarget Audience: ${audience}
      \nTone/Brand Voice: ${tone}
      \nConstraints/Guidelines: ${constraints}
      \nContext/Background Information: ${context}
      \nExamples/Inspiration: ${example}
      \nKey Benefits/Features: ${benefits}
      \nCall-to-Action (CTA): ${action}
      \nWord Count/Length: ${word_count}
      \nContent Type: ${content_type}
      \nKeywords (for SEO): ${keyword}
      \nUnique Selling Proposition (USP): ${proposition}
    `;

    promptMessage = `Generate a copy based on the following details: ${informationData}. Kindly ensure to follow the details provided`;
    onGenerateCopy(promptMessage);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        projectName: '',
        objective: '',
        numberOfWords: '',
        headline: '',
        valueProposition: '',
        offer: '',
        coreMessage: '',
        cta: '',
      }}
    >
      <h1 className="text-xl font-bold dark:text-white font-Poppins mb-[16px] mt-[16px]">Copywriting Tool </h1>
      <p className="text-md font-Poppins  dark:text-gray-300 mb-[16px]">
       Create a comprehensive copywriting plan for your project.
      </p>

      {/* Page 1 Inputs */}
      {isPageOne && (
        <>
          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item
                label="Purpose/Goal"
                name="purpose"
                rules={[{ required: true, message: 'Purpose/Goal is required' }]}
              >
                <Input placeholder={`E.g., "Sell a product," "Generate leads," "Inform readers."`} className= 'dark dark:placeholder:text-gray-400 ' />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Target Audience"
                name="audience"
                rules={[{ required: true, message: 'Target Audience is required' }]}
              >
                <Input placeholder={`E.g., "Adults aged 25–40, tech enthusiasts, environmentally conscious."`} className= 'dark dark:placeholder:text-gray-400 '  />
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item
                label="Tone/Brand Voice"
                name="tone"
                rules={[{ required: true, message: 'Tone/Brand Voice is required' }]}
              >
                <Input placeholder={`E.g., "Professional," "Conversational," "Humorous."`}  className= 'dark dark:placeholder:text-gray-400 ' />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                label="Constraints/Guidelines"
                name="constraints"
                rules={[{ required: true, message: 'Constraints/Guidelines is required' }]}
              >
                <TextArea autoSize={{minRows: 2}} placeholder={`E.g., "Follow brand style guide," "Adhere to regulatory standards."`}  className= 'dark dark:placeholder:text-gray-400 ' />
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item
                label="Context/Background Information"
                name="context"
                rules={[{ required: true, message: 'Context/Background Information is required' }]}
              >
                <TextArea autoSize={{minRows: 2}} placeholder={`E.g., "Launching in Q4," "Backed by research," "Industry-first innovation."`}  className= 'dark dark:placeholder:text-gray-400 ' />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                label="Examples/Inspiration"
                name="example"
                rules={[{ required: true, message: 'Examples/Inspiration is required' }]}
              >
                <TextArea autoSize={{minRows: 2}} placeholder={`E.g., "Link to previous campaign," "Screenshot of inspiring ad."`}  className= 'dark dark:placeholder:text-gray-400 ' />
              </Form.Item>
            </div>
          </div>

          {/* Next Button */}
          <Row gutter={16}>
            <Col span={24}>
              <button
                onClick={() => form.validateFields().then(() => {
                  setPageOneValues({
                    'purpose': form.getFieldValue('purpose'),
                    'audience': form.getFieldValue('audience'),
                    'tone': form.getFieldValue('tone'),
                    'constraints': form.getFieldValue('constraints'),
                    'context': form.getFieldValue('context'),
                    'example': form.getFieldValue('example'),
                  });
                  setIsPageOne(false);
                })}
                className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
              >
                Next
              </button>
            </Col>
          </Row>
        </>
      )}

      {/* Page 2 Inputs */}
      {!isPageOne && (
        <>
          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item
                label="Key Benefits/Features"
                name="benefits"
                rules={[{ required: true, message: 'Key Benefits/Features is required' }]}
              >
                <CustomTextAreaWithButton 
                  placeholder={`E.g., "Cost-effective," "High durability," "Eco-friendly."`} 
                  uniqueKey='benefits'
                  loadingStates={loadingStates}
                  setLoadingStates={setLoadingStates}
                  value={form.getFieldValue('benefits')} // Bind value to form field
                  onChange={(e) => form.setFieldValue('benefits', e.target.value)} // Update form value on change
                  onClickButton={() => {
                      form.setFieldValue('benefits', '')
                      const details = `
                          Purpose/Goal: ${pageOneValues['purpose']}
                          \nTarget Audience: ${pageOneValues['audience']}
                          \nTone/Brand Voice: ${pageOneValues['tone']}
                          \nConstraints/Guidelines: ${pageOneValues['constraints']}
                          \nContext/Background Information: ${pageOneValues['context']}
                          \nExamples/Inspiration: ${pageOneValues['example']}
                      `;
                      const prompt = `Generate a copy Key Benefits/Features with not more than 500 characters for this Copywriting Tool with details ${details}.`;
                      const socket = sockets["benefits"];
                      onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                      return true;
                  }}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Call-to-Action (CTA)"
                name="action"
                rules={[{ required: true, message: 'Call-to-Action (CTA) is required' }]}
              >
                <Input placeholder={` E.g., "Shop Now," "Sign Up," "Learn More."`} className= 'dark:placeholder:text-gray-400 '  />
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item
                label="Word Count/Length"
                name="word_count"
                rules={[{ required: true, message: 'Word Count/Length is required' }]}
              >
                <Input placeholder={`E.g., "150 words," "30-second read," "2-page document."`}  className= 'dark:placeholder:text-gray-400 ' />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Content Type"
                name="content_type"
                rules={[{ required: true, message: 'Content Type is required' }]}
              >
                <Input placeholder={`E.g., "Product description," "Email," "Social media post."`} className= 'dark:placeholder:text-gray-400 ' />
              </Form.Item>
            </div>
          </div>

          <div  className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item
                label="Keywords (for SEO)"
                name="keyword"
                rules={[{ required: true, message: 'Keywords (for SEO) is required' }]}
              >
                <CustomTextAreaWithButton 
                  placeholder={`E.g., "Sustainable materials," "Innovative technology," "Affordable pricing."`} 
                  uniqueKey='keyword'
                  loadingStates={loadingStates}
                  setLoadingStates={setLoadingStates}
                  value={form.getFieldValue('keyword')} // Bind value to form field
                  onChange={(e) => form.setFieldValue('keyword', e.target.value)} // Update form value on change
                  onClickButton={() => {
                      form.setFieldValue('keyword', '')
                      const details = `
                          Purpose/Goal: ${pageOneValues['purpose']}
                          \nTarget Audience: ${pageOneValues['audience']}
                          \nTone/Brand Voice: ${pageOneValues['tone']}
                          \nConstraints/Guidelines: ${pageOneValues['constraints']}
                          \nContext/Background Information: ${pageOneValues['context']}
                          \nExamples/Inspiration: ${pageOneValues['example']}
                      `;
                      const prompt = `Generate a copy Keywords (for SEO) with not more than 500 characters for this Copywriting Tool with details ${details}.`;
                      const socket = sockets["keyword"];
                      onGenerateWithAI(socket, prompt, userDetails?.userId ?? "",);
                      return true;
                  }}
                />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                label="Unique Selling Proposition (USP)"
                name="proposition"
                rules={[{ required: true, message: 'Unique Selling Proposition (USP) is required' }]}
              >
                <CustomTextAreaWithButton 
                  placeholder={`E.g., "Sustainable materials," "Innovative technology," "Affordable pricing."`} 
                  uniqueKey='proposition'
                  loadingStates={loadingStates}
                  setLoadingStates={setLoadingStates}
                  value={form.getFieldValue('proposition')} // Bind value to form field
                  onChange={(e) => form.setFieldValue('proposition', e.target.value)} // Update form value on change
                  onClickButton={() => {
                      form.setFieldValue('proposition', '')
                      const details = `
                          Purpose/Goal: ${pageOneValues['purpose']}
                          \nTarget Audience: ${pageOneValues['audience']}
                          \nTone/Brand Voice: ${pageOneValues['tone']}
                          \nConstraints/Guidelines: ${pageOneValues['constraints']}
                          \nContext/Background Information: ${pageOneValues['context']}
                          \nExamples/Inspiration: ${pageOneValues['example']}
                      `;
                      const prompt = `Generate a copy Unique Selling Proposition (USP) (for SEO) with not more than 500 characters for this Copywriting Tool with details ${details}.`;
                      const socket = sockets["proposition"];
                      onGenerateWithAI(socket, prompt, userDetails?.userId ?? "",);
                      return true;
                  }}
                />
              </Form.Item>
            </div>
          </div>

          {/* Submit and Previous Buttons */}
          <div className='flex flex-row justify-between'>
            <div>
              <button
                onClick={() => setIsPageOne(true)}
                className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
              >
                Prev
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-customOrange text-white rounded-lg"
              >
                Generate
              </button>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

export default CopywritingPlanTemplate;

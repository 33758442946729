import React, { useState, useEffect } from "react";

const WorkflowLoading = () => {
  const [currentStep, setCurrentStep] = useState(0); // Track the current step

  const steps = [
    { label: "Analyzing", status: "pending" },
    { label: "Thinking", status: "pending" },
    { label: "Responding", status: "pending" },
  ];

 
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prevStep) =>
        prevStep < steps.length ? prevStep + 1 : prevStep
      );
    }, 1000); 
    return () => clearInterval(interval);
  }, []);

  return (
    <div className=" dark:bg-gray-900 dark:text-white p-4 rounded-md w-64">
     
      
  
      <div className="space-x-4 flex flex-row items-center">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center">
          
            {index < currentStep ? (
             
              <div className="w-6 h-6 bg-green-500 rounded-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4  text-black dark:text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            ) : index === currentStep ? (
              // Loading Step
              <div className="w-6 h-6 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            ) : (
              // Pending Step
              <div className="w-6 h-6 bg-gray-500 rounded-full"></div>
            )}
            {/* Step Label */}
            <span className="ml-2">{step.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkflowLoading;

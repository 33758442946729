import { prompts } from "@src/utils/prompts";
import { Socket } from "socket.io-client";

const onGenerateWithAI = (socket: Socket | null, messageSent: string, userId: string, preferredPrompt?: string ) => {
    let msgs = { role: 'user', content: messageSent };

    try {
        socket?.emit('data', {
            data: {
                messages: [
                    {
                        role: 'system',
                        content: preferredPrompt ?? prompts.shortChat,
                    },
                    msgs,
                ],
                userId: userId,
            },
        });
    } catch (error) {
        console.error(error);
    }
};




export {
    onGenerateWithAI,
}
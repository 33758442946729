export const Icon1 = ({ isSelected }: { isSelected: boolean }) => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* First Path */}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6097 11.5292C12.6097 11.5292 12.6643 11.4925 12.7514 11.4299C14.0569 10.4799 14.875 9.1032 14.875 7.57053C14.875 4.7132 12.0204 2.39453 8.50142 2.39453C4.981 2.39453 2.125 4.7132 2.125 7.57053C2.125 10.4292 4.97958 12.6679 8.5 12.6679C8.80033 12.6679 9.29333 12.6492 9.979 12.6119C10.8729 13.1585 12.1777 13.6072 13.3195 13.6072C13.673 13.6072 13.8394 13.3339 13.6127 13.0552C13.2685 12.6579 12.7939 12.0212 12.6097 11.5285V11.5292Z"
            stroke={isSelected ? "white" : "#001F73"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        {/* Other paths */}
    </svg>
); export default Icon1;


export const Icon2 = ({ isSelected }: { isSelected: boolean }) => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            x="3.54297"
            y="2"
            width="9.91667"
            height="12"
            rx="2"
            stroke={isSelected ? "white" : "#28C76F"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="3.54297"
            y="2"
            width="9.91667"
            height="12"
            rx="2"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.375 4.66536H10.625"
            stroke={isSelected ? "white" : "#28C76F"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.375 4.66536H10.625"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.375 7.33333H10.625"
            stroke={isSelected ? "white" : "#28C76F"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.375 7.33333H10.625"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.375 10.0013H9.20833"
            stroke={isSelected ? "white" : "#28C76F"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.375 10.0013H9.20833"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>


);



export const Icon3 = ({ isSelected }: { isSelected: boolean }) => {
    const color = isSelected ? "white" : "#FF9F43"; // Change colors based on the isActive prop

    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="2.83203"
                y="2.66797"
                width="4.25"
                height="4"
                rx="1"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="9.91797"
                y="2.66797"
                width="4.25"
                height="4"
                rx="1"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="2.83203"
                y="9.33203"
                width="4.25"
                height="4"
                rx="1"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="9.91797"
                y="9.33203"
                width="4.25"
                height="4"
                rx="1"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const Icon4 = ({ isSelected }: { isSelected: boolean }) => {
    const color = isSelected ? "white" : "#FC5555";

    return (

        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.125 12.668C4.48587 11.3346 5.66667 10.0013 5.66667 8.66797C5.66667 6.66797 4.95833 6.66797 4.25 6.66797C3.54167 6.66797 2.81067 7.3913 2.83333 8.66797C2.85742 10.0333 4.00775 10.586 4.60417 11.3346C5.66667 12.668 6.375 13.0013 7.08333 12.0013C7.55579 11.3346 7.90996 10.7793 8.14583 10.3346C8.85417 11.89 9.79837 12.668 10.9792 12.668H12.75"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.125 12.668C4.48587 11.3346 5.66667 10.0013 5.66667 8.66797C5.66667 6.66797 4.95833 6.66797 4.25 6.66797C3.54167 6.66797 2.81067 7.3913 2.83333 8.66797C2.85742 10.0333 4.00775 10.586 4.60417 11.3346C5.66667 12.668 6.375 13.0013 7.08333 12.0013C7.55579 11.3346 7.90996 10.7793 8.14583 10.3346C8.85417 11.89 9.79837 12.668 10.9792 12.668H12.75"
                stroke="white"
                strokeOpacity="0.25"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1654 11.3333V3.33333C14.1654 2.586 13.5427 2 12.7487 2C11.9547 2 11.332 2.586 11.332 3.33333V11.3333L12.7487 12.6667L14.1654 11.3333Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1654 11.3333V3.33333C14.1654 2.586 13.5427 2 12.7487 2C11.9547 2 11.332 2.586 11.332 3.33333V11.3333L12.7487 12.6667L14.1654 11.3333Z"
                stroke="white"
                strokeOpacity="0.25"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.332 4.66536H14.1654"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.332 4.66536H14.1654"
                stroke="white"
                strokeOpacity="0.25"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}



export const Icon5 = ({
    isSelected,
    color = isSelected ? "white" : "#7B61FF",
}: {
    isSelected: boolean;
    color?: string;
}) => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.6241 4.0013H10.6311"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.6241 4.0013H10.6311"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="2.125"
            y="2"
            width="12.75"
            height="9.33333"
            rx="3"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="2.125"
            y="2"
            width="12.75"
            height="9.33333"
            rx="3"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.125 8.66794L4.95833 6.00127C5.61581 5.40583 6.42586 5.40583 7.08333 6.00127L9.91667 8.66794"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.125 8.66794L4.95833 6.00127C5.61581 5.40583 6.42586 5.40583 7.08333 6.00127L9.91667 8.66794"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.20703 7.99866L10.6237 6.66533C11.2812 6.06989 12.0912 6.06989 12.7487 6.66533L14.8737 8.66533"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.20703 7.99866L10.6237 6.66533C11.2812 6.06989 12.0912 6.06989 12.7487 6.66533L14.8737 8.66533"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.66703 14.0013H5.67411"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.66703 14.0013H5.67411"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.49906 14.0013H8.50615"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.49906 14.0013H8.50615"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.335 14.0013H11.3421"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.335 14.0013H11.3421"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);


export const Icon6 = ({
    isSelected,
    color = isSelected ? "white" : "#C2B013",
}: {
    isSelected: boolean;
    color?: string;
}) => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.125 8.0013H4.95833L7.08333 13.3346L9.91667 2.66797L12.0417 8.0013H14.875"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.125 8.0013H4.95833L7.08333 13.3346L9.91667 2.66797L12.0417 8.0013H14.875"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);



export const Icon7 = ({
    isSelected,
    color = isSelected ? "white" : "#22A9D4",
}: {
    isSelected: boolean;
    color?: string;

}) => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="2.83203"
            y="2.66797"
            width="4.25"
            height="3.33333"
            rx="1.66667"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="2.83203"
            y="2.66797"
            width="4.25"
            height="3.33333"
            rx="1.66667"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="2.83203"
            y="8.66797"
            width="4.25"
            height="4.66667"
            rx="2"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="2.83203"
            y="8.66797"
            width="4.25"
            height="4.66667"
            rx="2"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="9.91797"
            y="2.66797"
            width="4.25"
            height="10.6667"
            rx="2"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="9.91797"
            y="2.66797"
            width="4.25"
            height="10.6667"
            rx="2"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);





export const Icon8 = ({ isSelected }: { isSelected: boolean }) => {
    // Define colors based on the isActive prop
    const strokeColor = isSelected ? 'white' : '#333237'; // Change these colors as needed


    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"

        >
            <path
                d="M7.79167 12.6654H3.54167C2.75926 12.6654 2.125 12.0684 2.125 11.332V4.66536C2.125 3.92898 2.75926 3.33203 3.54167 3.33203H13.4583C14.2407 3.33203 14.875 3.92898 14.875 4.66536V7.33203"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.79167 12.6654H3.54167C2.75926 12.6654 2.125 12.0684 2.125 11.332V4.66536C2.125 3.92898 2.75926 3.33203 3.54167 3.33203H13.4583C14.2407 3.33203 14.875 3.92898 14.875 4.66536V7.33203"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="9.91797"
                y="9.33203"
                width="4.95833"
                height="3.33333"
                rx="1"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="9.91797"
                y="9.33203"
                width="4.95833"
                height="3.33333"
                rx="1"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};


export const Icon9 = ({ isSelected }: { isSelected: boolean }) => {
    // Define colors based on the isSelected prop
    const strokeColorMain = isSelected ? 'white' : 'black';
    const strokeOpacity = isSelected ? 0.25 : 0.25;

    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.625 6.66577L13.85 5.14844C14.0696 5.0452 14.3302 5.05625 14.539 5.17764C14.7477 5.29903 14.8749 5.51348 14.875 5.74444V10.2538C14.8749 10.4847 14.7477 10.6992 14.539 10.8206C14.3302 10.942 14.0696 10.953 13.85 10.8498L10.625 9.33244V6.66577Z"
                stroke={strokeColorMain}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.625 6.66577L13.85 5.14844C14.0696 5.0452 14.3302 5.05625 14.539 5.17764C14.7477 5.29903 14.8749 5.51348 14.875 5.74444V10.2538C14.8749 10.4847 14.7477 10.6992 14.539 10.8206C14.3302 10.942 14.0696 10.953 13.85 10.8498L10.625 9.33244V6.66577Z"
                stroke="black"
                strokeOpacity={strokeOpacity}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="2.125"
                y="4"
                width="8.5"
                height="8"
                rx="1.41667"
                stroke={strokeColorMain}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="2.125"
                y="4"
                width="8.5"
                height="8"
                rx="1.41667"
                stroke="black"
                strokeOpacity={strokeOpacity}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.96094 7.9974H7.79427"
                stroke={strokeColorMain}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.96094 7.9974H7.79427"
                stroke="black"
                strokeOpacity={strokeOpacity}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.3776 6.67188V9.33854"
                stroke={strokeColorMain}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.3776 6.67188V9.33854"
                stroke="black"
                strokeOpacity={strokeOpacity}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};


export const LogoutIcon = ({ isSelected }: { isSelected: boolean }) => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.45821 0.545847L7.45824 0.54584C8.9035 0.265329 9.88901 1.3287 9.88901 2.29941V6.68563H5.66673C5.30561 6.68563 4.94703 6.80867 4.67239 7.04549C4.39543 7.2843 4.22228 7.62548 4.22228 8C4.22228 8.37453 4.39543 8.71571 4.67239 8.95452C4.94703 9.19133 5.30561 9.31437 5.66673 9.31437H9.88901V13.7006C9.88901 14.6713 8.9035 15.7347 7.45824 15.4542L7.45821 15.4542C3.42225 14.6711 0.5 11.5883 0.5 8C0.5 4.41167 3.42225 1.3289 7.45821 0.545847ZM14.1023 6.80696L12.8812 5.75403C12.8812 5.75401 12.8812 5.754 12.8812 5.75398C12.804 5.68737 12.7781 5.61431 12.7781 5.55689C12.7781 5.49948 12.8039 5.42645 12.8811 5.35985C12.9607 5.29123 13.0826 5.24269 13.2224 5.24269C13.3622 5.24269 13.4841 5.29123 13.5637 5.35985L16.3969 7.80286C16.3969 7.80288 16.3969 7.80289 16.397 7.80291C16.4742 7.86953 16.5 7.94258 16.5 8C16.5 8.05742 16.4742 8.13048 16.397 8.19709C16.3969 8.19711 16.3969 8.19713 16.3969 8.19714L13.5675 10.6369C13.4867 10.703 13.3662 10.7485 13.2295 10.7475C13.0914 10.7464 12.9713 10.6981 12.8927 10.6303C12.8162 10.5644 12.7901 10.4921 12.7895 10.4351C12.789 10.379 12.8129 10.3082 12.8851 10.2427L14.1023 9.19304L15.1214 8.31437H13.7758H10.889V7.68563H13.7758H15.1214L14.1023 6.80696Z"
            stroke="#E55109"
        />
    </svg>
);


export const TicketIcon = ({ isSelected }: { isSelected: boolean }) => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.125 12.666C4.48587 11.3327 5.66667 9.99935 5.66667 8.66602C5.66667 6.66602 4.95833 6.66602 4.25 6.66602C3.54167 6.66602 2.81067 7.38935 2.83333 8.66602C2.85742 10.0313 4.00775 10.584 4.60417 11.3327C5.66667 12.666 6.375 12.9993 7.08333 11.9993C7.55579 11.3327 7.90996 10.7773 8.14583 10.3327C8.85417 11.888 9.79837 12.666 10.9792 12.666H12.75"
            stroke="#333237"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.125 12.666C4.48587 11.3327 5.66667 9.99935 5.66667 8.66602C5.66667 6.66602 4.95833 6.66602 4.25 6.66602C3.54167 6.66602 2.81067 7.38935 2.83333 8.66602C2.85742 10.0313 4.00775 10.584 4.60417 11.3327C5.66667 12.666 6.375 12.9993 7.08333 11.9993C7.55579 11.3327 7.90996 10.7773 8.14583 10.3327C8.85417 11.888 9.79837 12.666 10.9792 12.666H12.75"
            stroke="white"
            strokeOpacity="0.1"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1666 11.3333V3.33333C14.1666 2.586 13.544 2 12.7499 2C11.9559 2 11.3333 2.586 11.3333 3.33333V11.3333L12.7499 12.6667L14.1666 11.3333Z"
            stroke="#333237"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1666 11.3333V3.33333C14.1666 2.586 13.544 2 12.7499 2C11.9559 2 11.3333 2.586 11.3333 3.33333V11.3333L12.7499 12.6667L14.1666 11.3333Z"
            stroke="white"
            strokeOpacity="0.1"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.3333 4.66732H14.1666"
            stroke="#333237"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.3333 4.66732H14.1666"
            stroke="white"
            strokeOpacity="0.1"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);


export const StaffIcon = ({ isSelected }: { isSelected: boolean }) => (
    <svg
        width="13"
        height="15"
        viewBox="0 0 13 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.4998 7.1875V4.375L8.68726 1.25H2.12476C1.77958 1.25 1.49976 1.52982 1.49976 1.875V13.125C1.49976 13.4702 1.77958 13.75 2.12476 13.75H5.24976"
            stroke="#333237"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.4998 7.1875V4.375L8.68726 1.25H2.12476C1.77958 1.25 1.49976 1.52982 1.49976 1.875V13.125C1.49976 13.4702 1.77958 13.75 2.12476 13.75H5.24976"
            stroke="white"
            strokeOpacity="0.1"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.62476 11.25C10.3151 11.25 10.8748 10.6904 10.8748 10C10.8748 9.30964 10.3151 8.75 9.62476 8.75C8.9344 8.75 8.37476 9.30964 8.37476 10C8.37476 10.6904 8.9344 11.25 9.62476 11.25Z"
            stroke="#333237"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.62476 11.25C10.3151 11.25 10.8748 10.6904 10.8748 10C10.8748 9.30964 10.3151 8.75 9.62476 8.75C8.9344 8.75 8.37476 9.30964 8.37476 10C8.37476 10.6904 8.9344 11.25 9.62476 11.25Z"
            stroke="white"
            strokeOpacity="0.1"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.1248 13.75C12.1248 12.3693 11.0055 11.25 9.62476 11.25C8.24404 11.25 7.12476 12.3693 7.12476 13.75"
            stroke="#333237"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.1248 13.75C12.1248 12.3693 11.0055 11.25 9.62476 11.25C8.24404 11.25 7.12476 12.3693 7.12476 13.75"
            stroke="white"
            strokeOpacity="0.1"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.37476 1.25V4.375H11.4998"
            stroke="#333237"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.37476 1.25V4.375H11.4998"
            stroke="white"
            strokeOpacity="0.1"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const DashboardIcon = ({ isSelected }: { isSelected: boolean }) => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6097 11.5272C12.6097 11.5272 12.6643 11.4906 12.7514 11.4279C14.0569 10.4779 14.875 9.10124 14.875 7.56858C14.875 4.71124 12.0204 2.39258 8.50142 2.39258C4.981 2.39258 2.125 4.71124 2.125 7.56858C2.125 10.4272 4.97958 12.6659 8.5 12.6659C8.80033 12.6659 9.29333 12.6472 9.979 12.6099C10.8729 13.1566 12.1777 13.6052 13.3195 13.6052C13.673 13.6052 13.8394 13.3319 13.6127 13.0532C13.2685 12.6559 12.7939 12.0192 12.6097 11.5266V11.5272Z"
            stroke="#E55109"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.3125 9C7.08333 10.6667 9.91667 10.6667 11.6875 9"
            stroke="#E55109"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
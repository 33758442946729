/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { RouterProvider, useNavigate } from 'react-router-dom';
// import Acquisition from './acquisitionRouter';
import Dashboard from './dashboardRouter';
import './App.scss';

import { getUrlPath } from './utils/app_functions';
import Loading from './components/Loading';
import { ThemeContext } from './theme_provider/theme';
import { acquisitionRoutePages, AcquisitionRouteValues } from './utils/app_routes';

export default function App() {
    const [mounted, setPageMounting] = useState(false);
    const [page, setPage] = useState('');
    const { savedTheme } = useContext(ThemeContext);




    // const setFavicon = (theme: string) => {
    //     const link =
    //       document.querySelector<HTMLLinkElement>("link[rel*='icon']") ||
    //       document.createElement('link');
    //     link.type = 'image/x-icon';
    //     link.rel = 'shortcut icon';
    //     link.href = theme === 'dark' ? '/src/assets/essay/GMind_Icon_Orange_compressed.png' : '/src/assets/essay/GMind_Icon_Orange_compressed.png';
    //     document.head.appendChild(link);
    //   };
      

    const getTokenFromUrl = () => {
        const urlObj = new URL(window.location.href);
        const extractedToken = urlObj.searchParams.get('auth_redirect');

        if ( !extractedToken ) return null;

        localStorage.setItem('token', extractedToken);

        urlObj.searchParams.delete('auth_redirect');

        window.history.replaceState({}, '', urlObj.pathname);

        return extractedToken;
    }

    useEffect(() => {
        const urlDetails = getUrlPath();
        const folder = urlDetails.folder;
        const page  = urlDetails.pagename;

        const queryParams = window.location.search;

        const acquisitionPages = [
            acquisitionRoutePages.home,
            acquisitionRoutePages.login,
            acquisitionRoutePages.company,
            acquisitionRoutePages.forgotPassword,
            acquisitionRoutePages.getStarted,
            acquisitionRoutePages.cookies,
            acquisitionRoutePages.termsOfService,
            acquisitionRoutePages.privacyPolicy,
            acquisitionRoutePages.success,
            acquisitionRoutePages.cancel,
            acquisitionRoutePages.verifyMail,
            acquisitionRoutePages.verify,
            acquisitionRoutePages.productivity,
            acquisitionRoutePages.creation,
            acquisitionRoutePages.assistant,
            acquisitionRoutePages.researchers,
            acquisitionRoutePages.status,
        ];

        if ( folder === 'dashboard' && page === 'chat' ){
            window.location.href = `/${queryParams}`;
        }else if ( folder === '' && page === 'chat' ){
            window.location.href = `/${queryParams}`;
        }else if ( folder === "" && acquisitionPages.includes(page) ){
            window.location.href = `/index/${page}${queryParams}`;
        }else if ( folder === 'dashboard' ){
            window.location.href = `/${page}${queryParams}`
        }

        // // check if user token exist
        // let tokenFromUrl = getTokenFromUrl();
        // const user = tokenFromUrl ?? localStorage.getItem('token');
        // const isLoggedOut = localStorage.getItem('isLoggedOut');
        // const userData = localStorage.getItem('user');
        // const pagePathDetails = getUrlPath();
        // if ( isLoggedOut || ( user  && pagePathDetails.pagename != AcquisitionRouteValues.verify.page && pagePathDetails.pagename != AcquisitionRouteValues.verifyMail.page )) {
        //     // get url path
        //     if (
        //         pagePathDetails.folder !== 'dashboard' &&
        //         ( pagePathDetails.pagename === AcquisitionRouteValues.login.page || pagePathDetails.pagename === AcquisitionRouteValues.getStarted.page )
        //     ) {
        //         // if ( userDetails.plan?.details !== undefined ){
        //         //     window.location.href = '/dashboard/chat';
        //         // }else {
        //         //     window.location.href = '/dashboard/pricing';   
        //         // }
        //         window.location.href = '/chat';   
        //     }
        //     setTimeout(() => {
        //         setPage('dashboard');
        //     }, 1000);
        // } else {
        //     const { pathname } = window.location;
        //     const split = pathname.split('/');
        //     if (split[1] && split[1] === "dashboard" && pagePathDetails.pagename !=='editor' ) {
        //         const { pathname, origin, href } = window.location;
        //         localStorage.setItem("callback", href)
        //         window.location.href = `${AcquisitionRouteValues.login.route}?callback=${href}`;
        //     }
        //     setPage('dashboard');
        // }

        setPage('dashboard');
        setPageMounting(true);
    }, []);

    return mounted ? (
        // page == 'acquisition' ? (
        //     <RouterProvider router={Acquisition} />
        // ) : 
        page == 'dashboard' ? (
            <RouterProvider router={Dashboard} />
        ) : (
            // <p className="w-full flex items-center justify-center text-4xl font-semibold h-screen border">Loading...</p>
            <Loading />
        )
    ) : <Loading />;
}

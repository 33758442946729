import React, { useState } from 'react';
import { Card } from 'antd';

const styles = [
    'Basic Research',
    'Applied Research',
    'Quantitative Research',
    'Qualitative Research',
    'Experimental Research',
    'Observational Research',
    'Exploratory Research',
    'Descriptive Research',
    'Correlational Research',
    'Evaluative Research',
];

export const ArticleStyle = ({
    selectedStyle,
    setSelectedStyle,
}: {
    selectedStyle: string;
    setSelectedStyle: React.Dispatch<React.SetStateAction<string>>;
}) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <h2 className="text-2xl font-semibold mb-4 dark:text-gray-300">Research types</h2>
            <p className="text-gray-600 dark:text-gray-300 mb-6 ">Select your research type</p>
            <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }} className='justify-center items-center '>
                {styles.map((style) => (
                    <Card
                        key={style}
                        hoverable
                        className='w-full md:w-auto flex justify-center items-center '
                        style={{
                            border: style === selectedStyle ? '2px solid #1890ff' : '1px solid #ddd',
                        }}
                        onClick={() => setSelectedStyle(style)}
                    >
                        {style}
                    </Card>
                ))}
            </div>
        </div>
    );
};

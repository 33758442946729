import About from '../components/about';
import wavyDesign from '../../../assets/Wave element.png';
import Playground from '../components/playground/index.tsx';
import Testimonies from '../components/testimonies';
import { Footer } from '../components/footer/footer';
import Faq from '../components/faq';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { landingPagePrps } from '@src/core/interfaces/general.tsx';
import AdvancedFeatures from '../features/components/advanced_features.tsx';
import { useEffect, useRef, useState } from 'react';
import InformationCard from '../components/info.tsx';
import { landingPageStructuredData, landingPageStructuredDataForBusiness } from '../strutured_data/structured_data.ts';
import { acquisitionRoutes } from '@src/utils/app_routes.ts';


const LandingPage = ({ faqRef, featuresRef, isLoggedIn }: landingPagePrps) => {
    const navigatePage = useNavigate();

    const [isMobile, setIsMobile] = useState(true);
    const newFaqRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    return (
        <div className="w-full h-full lg:h-screen bg-gradient-to-b from-[#001F73] to-[#00040D]">
            {/* <Helmet>
                <script type="application/ld+json">{JSON.stringify(landingPageStructuredData)}</script>
                <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
                <meta
                    name="description"
                    content="Boost productivity and streamline your workflow with GMind AI, the ultimate AI-powered productivity tool. Automate daily tasks, optimize workflows, and enhance efficiency with smart automation."
                />

                 <title>GMind AI Pricing | Affordable Plans for Every Professional</title>
                <link rel="canonical" href="https://www.gmind.ai/" />
                <meta name="keywords" content="gmind, ai, chat, gmindai, teachers ai" />
            </Helmet> */}
            <Helmet>
                <title>GMind AI: AI Productivity For Everyone</title>
                <meta name="description" content="Create high-quality content, boost productivity, and enhance work efficiency with this simple-to-use AI tool: GMind AI, which is the future at your fingertips. Find quick in-depth answers to your question. Explore the internet's wide array of information all within your fingertips with our powerful inbuilt search engine." />
                <meta
                    name="keywords"
                    content="AI productivity tool, GMind AI, task automation, business efficiency, education tools, research tools, content creation, global productivity"
                />
                <meta name="author" content="GMind AI Team" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://gmind.ai${acquisitionRoutes.home}`} />
                <meta property="og:title" content="GMind AI: AI Productivity For Everyone" />
                <meta property="og:description" content="Create high-quality content, boost productivity, and enhance work efficiency with this simple-to-use AI tool: GMind AI, which is the future at your fingertips. Find quick in-depth answers to your question. Explore the internet's wide array of information all within your fingertips with our powerful inbuilt search engine." />
                <meta property="og:image" content="https://gmind.ai/images/homepage-og-image.jpg" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={`https://gmind.ai${acquisitionRoutes.home}`} />
                <meta property="twitter:title" content="GMind AI: AI Productivity For Everyone" />
                <meta property="twitter:description" content="Create high-quality content, boost productivity, and enhance work efficiency with this simple-to-use AI tool: GMind AI, which is the future at your fingertips. Find quick in-depth answers to your question. Explore the internet's wide array of information all within your fingertips with our powerful inbuilt search engine." />
                <meta property="twitter:image" content="https://gmind.ai/images/homepage-twitter-image.jpg" />
                <link rel="icon" href="https://gmind.ai/favicon.ico" type="image/x-icon" />

                <link rel="canonical" href={`https://gmind.ai/${acquisitionRoutes.home}`} />
                <meta name="robots" content="index, follow" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="author" content="GMind AI Team" />
                <link rel="alternate" hrefLang="en" href={`https://gmind.ai/${acquisitionRoutes.home}`} />

                <script type="application/ld+json">
                    {JSON.stringify(landingPageStructuredData)}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(landingPageStructuredDataForBusiness)}
                </script>
            </Helmet>

            <div className="bg-gradient-to-b from-[#001F73] to-[#00040D] h-full xl:h-full">
                {/* <div className=" w-full "> */}

                <div className=" w-full ">
                    {/* <div className='flex flex-col gap-y-5'> */}

                    <div className="w-full">
                        <About isLoggedIn={isLoggedIn} faqRef={faqRef ?? newFaqRef} />
                    </div>

                </div>
                <div className="w-full pt-2 md:hidden">
                    <hr />
                    <p className="text-white font-[Poppins] pb-4 xl:pb-0 text-[0.8rem] md:text-[1rem] mt-4 font-normal  px-10 md:px-20" >Copyright © 2024 Gmind. All rights reserved.</p>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;

import { Form, Input,Select as Selected } from 'antd';
import { on } from 'events';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import BrowseModal, { CardData } from './browseModal';
import { title } from 'process';
import { log } from 'console';
import GenerateLessonPlan from './GetLessonPlan';
import { curriculumOptions, gradeOptions } from '../constants';
import instructionalModels, { InstructionalModel } from '@src/utils/instruction_model';
import { activityGeneratorPrompt } from '../interfaces';
import { toast } from 'react-toastify';
import Close from '@assets/close.svg';
import Notes from '@assets/notes.svg';
import { Select } from "@src/pages/dashboard/components/Select";
import { Option } from 'antd/es/mentions';
import { Chats, ChatType, getHistory } from '@src/pages/dashboard/endpoints';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import { BASE_URL } from '@src/utils/constants';

import { io, Socket } from 'socket.io-client';
import { useOutletContext, useNavigate } from 'react-router';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import { openCurriculumEditor } from '@src/utils/app_functions';
import { prompts } from '@src/utils/prompts';

import { v4 as uuidv4 } from 'uuid';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import Profile from '@assets/Avatar.svg'


import NewSunEditorComponent from '../../essay/sun_editor copy';
import BackButton from '@src/components/BackButton';
import { chatRoutePages } from '@src/utils/app_routes';
import { ThemeContext } from '@src/theme_provider/theme';



interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

export function MyDownArrowIcon() {
    return (
        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.75 1.125L5.5 5.875L10.25 1.125"
                stroke="#E55109"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}

export function MyFileIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1101_24587)">
                <path opacity="0.2" d="M14.25 3V8.25H19.5L14.25 3Z" fill="#E55109" />
                <path
                    d="M18.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H14.25L19.5 8.25V20.25C19.5 20.4489 19.421 20.6397 19.2803 20.7803C19.1397 20.921 18.9489 21 18.75 21Z"
                    stroke="#E55109"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M14.25 3V8.25H19.5"
                    stroke="#E55109"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9.75 13.5L12 11.25L14.25 13.5"
                    stroke="#E55109"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12 17.25V11.25"
                    stroke="#E55109"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1101_24587">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

const LessonPlanner = () => {
    const [rememberMe, setRememberMe] = useState(false);
    const [visble, setVisble] = useState(false);
    const [content, setContent] = useState<InstructionalModel | null>(null);
    const [start, setStart] = useState(false);
    const [curriculum, setCurriculum] = useState('');
    const [purpose, setPurpose] = useState('');
    const [topic, setTopic] = useState('');
    const [information, setInformation] = useState('');
    const [resouceLink, setResouceLink] = useState('');
    const [basePrompt, setBasePrompt] = useState('');
    const [resoucefile, setResouceFile] = useState<File | null>(null);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [uploadedFile, setUploadedFile] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [description, setDescription] = useState('');
    const [template, setTemplate] = useState('');
    
    const [histories, setHistories] = useState<any>([]);
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [generate, setGenerate] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [promptSent, setPromptSent] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [canNavigate, setCanNavigate] = useState(false);
    const [option, setOption] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const { loading, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const editorInstanceRef = useRef<any>();
     const {isMobile}= useContext(ThemeContext);

    const {
        setShowTop,
        setShowSidebar,
        setHistory,
        setMobileNavStyle,
        setShowPagePath,
        setShowHistoryOption,
        setShowRightComponent,
        setShowHistory,
        response,
        setResponse,
        details,
    } = useOutletContext<any>();

  
    const navigate = useNavigate();
    const baseurl = BASE_URL;
    const bottomRef = useRef<HTMLDivElement>(null);
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const [userInput, setUserInput] = useState('');
    const [isloading, setisLoading] = useState(false);
    const [extractedFile, setExtractedFile] = useState<string>('');

    useEffect(() => {
        const newSocket = io(baseurl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
            console.log('Received data:', data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            // setShowEditorButton(true);
            const { assistant } = data;
            setStreamEndMessage(assistant);
            setIsTyping(false);
            setCanNavigate(true);
            // handle stream end
            const uuid = uuidv4();
            setDocId(uuid);

            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                });
            }
            socket?.emit('get-documents', user?.id);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };

    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

    useEffect(() => {
        console.log(generate);

        if (generate || showingHistory) {
            openCurriculumEditor({
                response,
                pageId: docId,
                promptSent,
                promptType: prompts.curriculum,
                navigate,
                pageSocket: socket,
            });
            getDocIdFromUrl();
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);



    useEffect(() => {
        if (generate || showingHistory) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (response) {
            console.log(response);
            setEditorContent(response);
            setTranslatedContent(response);
        }
    }, [response]);

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message, type: 'gpt-4' };
        setChats(msgs);
        setResponse('');
        setIsTyping(true);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: prompts.curriculum,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId ?? '',
                },
            });
            // await handleAddHistory([{ role: 'user', content: message }, msgs], pageHistory, {});
            // getPageHistory();
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };


    const handleGetStarted = () => {

        if (!content) {
            toast.error('Kindly select an instruction Model');
            return;
        }

        const model = `Instruction: ${content.instruction}\nDescription: ${content.description}\nApplicability: ${content.applicability}\nStrategies: ${content.keyStrategies}`;

        // check for form data
        if (!curriculum || !purpose || !topic || !information) {
            toast.error('Kindly fill form data');
            return;
        }

        const subject = `\nSubject: ${purpose}`;
        const topicData = `\nTopic: ${topic}`;
        const gradeData = `\nGrade Level: ${information}`;
        const resourceLinkData = resouceLink ? `\nResource Link: ${resouceLink}` : '';

        const info = `${subject}${topicData}${gradeData}${resourceLinkData}`;

        const prompt = `Create a Lesson Plan using ${curriculum} with the following information ${info} and this instruction model: ${model}`;
        setBasePrompt(prompt);
        setStart(true);
    };

    const handleSetBrowseModel = (card: InstructionalModel) => {
        setContent(card);
        setVisble(false);
    };

    const handleBrowse = () => {
        console.log('browse');
    };

    const [showRemove, setShowRemove] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleMouseEnter = () => {
        setShowRemove(true);
    };

    const handleMouseLeave = () => {
        setShowRemove(false);
    };

    const handleRemove = () => {
        setContent(null);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    /**
     * Function to handle the upload button click event
     */
    const handleUploadButtonClick = () => {
        // Create an input element for file selection
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '*'; // Allow all file types
        fileInput.multiple = false; // Allow only single file selection

        // Add event listener for file selection
        fileInput.addEventListener('change', (event) => {
            const target = event.target as HTMLInputElement;
            // Trigger function to handle file selection
            handleFileSelect({ target } as React.ChangeEvent<HTMLInputElement>);
        });

        // Simulate a click on the input element to open file selection dialog
        fileInput.click();
    };
    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Your implementation goes here
        const file = event.target.files?.[0];
        if (file) {
            setResouceFile(file);
        }
    };

    const handleBrowseModel = () => {
        setVisble(true);
    };
    const handleCancel = () => {
        setVisble(false);
    };

    const generateResponse = (prompt: string) => {
        const finalPrompt = basePrompt + prompt + `Kindly ensure all the details sent is shown in the response. Also ensure that the response is grade level appropriate and strictly follows the requested curriculum.`;
        onGenerateHandler(finalPrompt );
        setGenerate(true);

    };

    return (
        <div className={`flex flex-col gap-y-2 ${!generate ? 'pb-20' : isMobile ?'pb-10':''}`}>
        {!generate && !showingHistory ?<div className='px-10 w-full flex flex-col gap-y-0'>
            {start ? (
                <GenerateLessonPlan onGenerate={generateResponse} subject={topic} setStart={setStart} />
            ) : (
                <div className="flex flex-col gap-y-2 pb-10">
                       <div className=' pt-5'> 
                    <BackButton onclick={() => navigate(`/${chatRoutePages.educatorResources}`)} /> </div>
                    <div className="flex flex-col gap-y-0">
                    <div className="text-[2rem] md:text-[2.5rem] dark:text-white curriculum--title ">Lesson Planner</div>
                    <div className="curriculum--subtitle dark:text-gray-300 mb-2 text-[1.125rem] md:text-[0.875rem] w-full md:w-[27rem]">
                        Provide us the information requested below to get your content ready.
                    </div>
                    </div>

                    <Form layout="vertical" initialValues={{ rememberMe }}>
                        <div className="flex flex-col md:flex-row gap-y-0 gap-x-4 flex-wrap">
                            <Form.Item
                                className='w-full md:w-[48%] flex flex-col gap-y-0 mb-3'
                                name={'choose'}
                                label={<label className="curriculum--label"> Curriculum*</label>}
                                rules={[
                                    {
                                        message: 'field is required',
                                        required: false,
                                    },
                                ]}
                            >
                                <Selected
                                    className="w-full  rounded-md "
                                    value={curriculum}
                                    onChange={(e) => {
                                        setCurriculum(e);
                                    }}
                                    placeholder=" Select Preferred Curriculum"
                                    
                                    style={{
                                     
                                        borderRadius: '4px',
                                        backgroundColor: 'transparent',
                                        
                                    }}
                                >
                                
                                    {curriculumOptions.map((item, index) => {
                                        return (
                                            <Option
                                                className={`${curriculum == item ? 'bg-[var(--gmind-orange8)] text-[var(--gmind-orange)]' : ''} font-normal text-[0.9rem] rounded-[0.3rem]`}
                                              
                                                value={item}
                                            >
                                                {item}
                                            </Option>
                                        );
                                    })}
                                </Selected>
                            </Form.Item>

                            <Form.Item
                                className='w-full md:w-[48%] flex flex-col gap-y-0 mb-3'
                                name={'subject'}
                                label={<label className="curriculum--label"> Subject*</label>}
                                rules={[
                                    {
                                        message: 'purpose is required',
                                        required: false,
                                    },
                                ]}
                            >
                                <Input
                                    value={purpose}
                                    onChange={(e) => setPurpose(e.target.value)}
                                    className="w-full placeholder-gray-700"
                                    placeholder="e.g Physics, Project Management etc"
                                    style={{
                                        border: '1px solid gray',
                                        borderRadius: '4px',
                                        backgroundColor: 'transparent',
                                        padding: '7px 10px',
                                    }}
                                    type="text"
                                />
                            </Form.Item>

                            <Form.Item
                                className='w-full md:w-[48%] flex flex-col gap-y-0 mb-3'
                                name={'topic'}
                                label={<label className="curriculum--label"> Topic*</label>}
                                rules={[
                                    {
                                        message: 'purpose is required',
                                        required: false,
                                    },
                                ]}
                            >
                                <Input
                                    value={topic}
                                    onChange={(e) => {
                                        setTopic(e.target.value);
                                      
                                    }}
                                    className="w-full placeholder-gray-700"
                                    placeholder="e.g Refraction"
                                    style={{
                                        border: '1px solid gray',
                                        borderRadius: '4px',
                                        backgroundColor: 'transparent',
                                        padding: '7px 10px',
                                    }}
                                    type="text"
                                />
                            </Form.Item>

                            <Form.Item
                                className="w-full md:w-[48%] flex flex-col gap-y-1 mb-3"
                                name={'grade'}
                                label={<label className="curriculum--label">Grade / Level</label>}
                                rules={[
                                    {
                                        message: 'field is required',
                                        required: false,
                                    },
                                ]}
                            >
                                <Select
                                    options={[ '', ...gradeOptions() ]} 
                                    onSelect={(v) => setInformation(v)}
                                    className='w-full placeholder-gray-700'
                                    
                                />
                            </Form.Item>

                            <Form.Item
                                className="w-full md:w-[48%] flex flex-col mb-3"
                                name={'choose'}
                                label={<label className="curriculum--label"> </label>}
                                rules={[
                                    {
                                        message: 'field is required',
                                        required: false,
                                    },
                                ]}
                            >
                                {
                                    resoucefile ?
                                    (
                                        <div className='flex gap-x-4'>
                                            <img src={Notes} alt="Music Icon" />
                                            <span className="file--name">{resoucefile.name}</span>
                                            <img
                                                src={Close}
                                                alt="Close Icon"
                                                onClick={() => setResouceFile(null)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    ): (

                                        <div
                                            className="w-full px-[2rem] py-[0.75rem] dark:border-white border-0.5 rounded-custom border-dashed border-gray-700 gap-5 flex flex-row justify-between items-center"
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleUploadButtonClick}
                                        >
                                            <div className="flex flex-row items-center gap-2">
                                                <MyFileIcon />
                                                <div className="text-sm dark:text-white font-[Inter,sans-serif] font-normal text-black-400 ">
                                                    Upload resources here (optional)
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                
                            </Form.Item>

                            <Form.Item
                                className="w-full md:w-[48%] flex flex-col gap-y-1 mt-3 mb-3"
                                name={'link'}
                                label={<label className="curriculum--label">Link to Resource (optional) </label>}
                                rules={[
                                    {
                                        message: 'purpose is required',
                                        required: false,
                                    },
                                ]}
                            >
                                <Input
                                    value={resouceLink}
                                    onChange={(e) => setResouceLink(e.target.value)}
                                    className="w-full placeholder-gray-700"
                                    placeholder="Link to resource"
                                    style={{
                                        border: '1px solid gray',
                                        borderRadius: '4px',
                                        backgroundColor: 'transparent',
                                        padding: '7px 10px',
                                    }}
                                    type="text"
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <div className="font-[Inter,sans-serif]  dark:text-white font-normal text-black-400 text-[0.8rem] mb-3">
                                Select an instructional Model ( Pedagogy )
                            </div>

                            {content ? (
                                <div>
                                    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                        {/* Rectangle */}
                                        <div
                                            className="w-full h-[128px] "
                                            style={{
                                                border: '1px solid #DBDADE',
                                                borderRadius: '4px',
                                                backgroundColor: 'transparent',
                                                flex: 1,
                                            }}
                                        >
                                            <div className="p-4">
                                                <div className="font-Inter text-black-500 dark:text-white text-[1rem] ">
                                                    {content.instruction}
                                                </div>
                                                <div className="font-Inter text-textColor-400 dark:text-white text-sm ">
                                                    {content.description}
                                                </div>
                                            </div>
                                            {showRemove && (
                                                <div
                                                    style={{
                                                        flex: '1',
                                                        marginTop: '12px',
                                                        cursor: 'pointer',

                                                        height: '38px',
                                                        backgroundColor: '#DBDADE',
                                                    }}
                                                    onClick={handleRemove}
                                                    className=" gap-2 w-full flex items-center  justify-center font-Inter text-black-500 text-[0.75rem]"
                                                >
                                                    <button>
                                                        <svg
                                                            width="10"
                                                            height="12"
                                                            viewBox="0 0 10 12"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M0.714286 10.6667C0.714286 11.0203 0.864795 11.3594 1.1327 11.6095C1.40061 11.8595 1.76398 12 2.14286 12H7.85714C8.23602 12 8.59939 11.8595 8.8673 11.6095C9.1352 11.3594 9.28571 11.0203 9.28571 10.6667V2.66667H0.714286V10.6667ZM2.14286 4H7.85714V10.6667H2.14286V4ZM7.5 0.666667L6.78571 0H3.21429L2.5 0.666667H0V2H10V0.666667H7.5Z"
                                                                fill="#333237"
                                                            />
                                                            <path
                                                                d="M0.714286 10.6667C0.714286 11.0203 0.864795 11.3594 1.1327 11.6095C1.40061 11.8595 1.76398 12 2.14286 12H7.85714C8.23602 12 8.59939 11.8595 8.8673 11.6095C9.1352 11.3594 9.28571 11.0203 9.28571 10.6667V2.66667H0.714286V10.6667ZM2.14286 4H7.85714V10.6667H2.14286V4ZM7.5 0.666667L6.78571 0H3.21429L2.5 0.666667H0V2H10V0.666667H7.5Z"
                                                                fill="white"
                                                                fill-opacity="0.1"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <span font-Inter text-black text-lg>
                                                        Remove Template
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <button
                                        onClick={handleGetStarted}
                                        className="py-2 px-8 rounded mt-[3.125rem]"
                                        style={{
                                            backgroundColor: '#E55109',
                                            color: 'white',
                                        }}
                                    >
                                        Get Started
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={handleBrowseModel}
                                    className="py-2 px-8 rounded mt-[0.5rem]"
                                    style={{
                                        backgroundColor: '#E55109',
                                        color: 'white',
                                    }}
                                >
                                    Browse Instructional Models
                                </button>
                            )}
                        </div>
                    </Form>
                    <BrowseModal
                        onCancel={handleCancel}
                        visible={visble}
                        cardsData={instructionalModels}
                        click={(card) => handleSetBrowseModel(card)}
                    />
                </div>
            )}
        </div>:<div className="w-full flex flex-col gap-0 ">
                    <NewEditorPage
                        editorInstanceRef={editorInstanceRef}
                        content={
                            <NewSunEditorComponent
                                latestRespone={latestRespone}
                                setLatestRespone={setLatestRespone}
                                highlightedTexts={highlightedTexts}
                                setHighlightedTexts={setHighlightedTexts}
                                editorInstanceRef={editorInstanceRef}
                                // humanizeded_content={Document.current?.data.ops}
                                // hasAccess={hasAccess}
                                hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                                initial_content={response}
                                onChangeEditorContent={(e: string) => {
                                    setEditorContent(e);
                                    const newtext = e;
                                    console.log('newtext', newtext);

                                    console.log('docId', docId);
                                    console.log('userDetails?.userId', userDetails?.userId);

                                    sendChanges(docId, userDetails?.userId, e);
                                }}
                            />
                        }
                        showTopNavBar={false}
                        showInvite={true}
                        isTyping={isTyping}
                        Profile={Profile}
                        promptSent={promptSent}
                        showingHistory={showingHistory}
                        setGenerate={setGenerate}
                        setShowingHistory={setShowingHistory}
                        generate={generate}
                        setEditorContent={setEditorContent}
                        editorContent={editorContent}
                        translatedContent={translatedContent}
                        setTranslatedContent={setTranslatedContent}
                        humanizedContent={humanizedContent}
                        setHumanizedContent={setHumanizedContent}
                        setUserOnDoc={setUserOnDoc}
                        userOnDoc={userOnDoc}
                        latestRespone={latestRespone}
                        setLatestRespone={setLatestRespone}
                        highlightedTexts={highlightedTexts}
                        setHighlightedTexts={setHighlightedTexts}
                        regenerate={generateResponse}
                    />
                </div>}
        </div>
    );
};

export default LessonPlanner;

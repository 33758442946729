import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import Loading from './components/Loading/index.tsx';
import { affiliateRoutePages } from './utils/app_routes.ts';

const Affiliate = React.lazy(() => import('./pages/dashboard/pages/affiliate/dashboard/index.tsx'));
const Referrals = React.lazy(() => import('./pages/dashboard/pages/affiliate/dashboard/referrals.tsx'));
const Transactions = React.lazy(() => import('./pages/dashboard/pages/affiliate/dashboard/transactions.tsx'));
const DashboardFrame = React.lazy(() => import('./components/DashboardFrame.tsx'));
const NotFoundPage = React.lazy(() => import('./pages/404'));


export const affiliateRoutes = (): React.ReactElement =>  {
    return (
        <Route
                path={'affiliate'}
                element={
                    <Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <DashboardFrame />
                    </Suspense>
                }
                errorElement={
                    <Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>
                }
            >
                <Route
                    path={affiliateRoutePages.dashboard}
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <Affiliate />
                        </Suspense>
                    }
                />
                <Route
                    path={affiliateRoutePages.referrals}
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <Referrals />
                        </Suspense>
                    }
                />
                <Route
                    path={affiliateRoutePages.transactions}
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <Transactions />
                        </Suspense>
                    }
                />
            </Route>
    );
}

import { Button, Form, Modal } from 'antd';
import { LessonMaterialProps } from '../interface';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import Input from '@src/pages/dashboard/components/Input';
import { FileContent } from '@src/core/interfaces/chat';
import { ChatContent } from '@src/pages/dashboard/components/interface/interface';
import { Chats, ChatType, getHistory } from '@src/pages/dashboard/endpoints';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import { BASE_URL } from '@src/utils/constants';

import { io, Socket } from 'socket.io-client';
import { useOutletContext, useNavigate } from 'react-router';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import { openCurriculumEditor } from '@src/utils/app_functions';
import { prompts } from '@src/utils/prompts';

import { v4 as uuidv4 } from 'uuid';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import Profile from '@assets/Avatar.svg';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import BackButton from '@src/components/BackButton';
import { extractTextFromPDF, extractTextFromDOCX } from '@src/pages/dashboard/function/pdf_doc_helper';
import { chatRoutePages } from '@src/utils/app_routes';

import {
    FileTextOutlined,
    HighlightOutlined,
    EditOutlined,
    CloseOutlined,
    FilePdfFilled,
    PaperClipOutlined,
    SendOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons';
import { MdFormatListBulleted } from 'react-icons/md';
import { CircularProgress } from '@mui/material';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const NoteSummarizer = () => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [Value, setValue] = useState('');

    const [uploadedFile, setUploadedFile] = useState('');

    const [description, setDescription] = useState('');
    const [template, setTemplate] = useState<[]>([]);
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState<any>([]);
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [generate, setGenerate] = useState<boolean>(false);
    const [generating, setGenerating] = useState(false);
    const [promptSent, setPromptSent] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [canNavigate, setCanNavigate] = useState(false);
    const [option, setOption] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const { documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const editorInstanceRef = useRef<any>();

    const {
        setShowTop,
        setShowSidebar,
        details,

        response,
        setResponse,
    } = useOutletContext<any>();

    const navigate = useNavigate();
    const baseurl = BASE_URL;
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const [userInput, setUserInput] = useState('');
    const [isloading, setisLoading] = useState(false);
    const [extractedFile, setExtractedFile] = useState<string>('');
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]); // Supports multiple selection
    const [currentStep, setCurrentStep] = useState<number>(1); // Step management
    const steps = ['Attach a document', 'Select summarization options', 'Generate summary']; // Step descriptions

    const handleStepChange = (step: number) => setCurrentStep(step);

    const handleCardClick = (option: string) => {
        setSelectedOptions((prevOptions) => {
            if (prevOptions.includes(option)) {
                // If the option exists, remove it
                return prevOptions.filter((opt) => opt !== option);
            } else {
                // If the option doesn't exist, add it
                return [...prevOptions, option];
            }
        });

        setTemplate((prev: any) =>
            prev === null
                ? template
                : typeof prev === 'string'
                  ? template
                  : prev.includes(template)
                    ? prev.filter((o) => o !== template)
                    : [...prev, template],
        );
        console.log(template);
    };

    const optionStyle = (template: string) =>
        `px-4 py-6 rounded-md shadow-sm cursor-pointer flex flex-col items-center text-center
      ${selectedOptions.includes(template) ? 'bg-gray-200 border-[0.2rem] border-blue-500' : 'bg-gray-100 hover:bg-gray-200'}`;

    useEffect(() => {
        const newSocket = io(baseurl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
            console.log('Received data:', data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            // setShowEditorButton(true);
            const { assistant } = data;
            setStreamEndMessage(assistant);
            setIsTyping(false);
            setCanNavigate(true);
            // handle stream end
            const uuid = uuidv4();
            setDocId(uuid);

            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                });
            }
            socket?.emit('get-documents', user?.id);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };

    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

    useEffect(() => {
        console.log(generate);

        if (generate || showingHistory) {
            openCurriculumEditor({
                response,
                pageId: docId,
                promptSent,
                promptType: prompts.curriculum,
                navigate,
                pageSocket: socket,
            });
            getDocIdFromUrl();
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (generate || showingHistory) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (response) {
            console.log(response);
            setEditorContent(response);
            setTranslatedContent(response);
        }
    }, [response]);

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }

        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message, type: 'gpt-4' };
        setChats(msgs);
        setResponse('');
        setIsTyping(true);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: summaryTemplate(String(selectedOptions), Value),
                            type: 'gpt-4',
                        },

                        msgs,
                    ],
                    userId: details?.userId,
                },
            });
            // await handleAddHistory([{ role: 'user', content: message }, msgs], pageHistory, {});
            // getPageHistory();
        } catch (error) {
            console.log(error);
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0];

        console.log('Selected file:', file);
        setisLoading(true);

        if (!file) return;
        console.log('Selected file:', file);

        const fileType = file.type;

        if (
            fileType === 'application/pdf' ||
            fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            // Handle PDF and DOCX files
            const fileTypeValue: 'PDF' | 'DOCX' = fileType === 'application/pdf' ? 'PDF' : 'DOCX';
            try {
                // }

                if (fileType === 'application/pdf') {
                    const doc = await extractTextFromPDF(file);
                    setExtractedFile(doc);
                    setUserInput(doc);
                    console.log(doc);
                    console.log('exract', extractedFile);
                } else {
                    const doc = await extractTextFromDOCX(file);
                    setExtractedFile(doc);
                    console.log(doc);
                    setUserInput(doc);
                }
            } catch (error) {
                console.error('Error during file upload:', error);
                toast.error('File upload failed');
            } finally {
                setisLoading(false);
            }
        } else {
            console.warn('Unsupported file type:', fileType);
            return;
        }
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleDontShowAgain = () => {
        localStorage.setItem('showAttachDocumentModal', 'true'); // Store the user's preference
        setIsModalVisible(false);
    };

    const onFinish = (values: string) => {
        const promptMessage = `
        You are a helpful assistant.
        Follow the instructions below:
        Using this ${template} create a summary of the following notes:
        ${extractedFile ? ` ${template} template. The file is attached below. ${extractedFile}` : ` Summarize the following notes ${values} using the ${template} template.`}

        - Use this ${Value} as the instruction to summarize {!Important}
       
        `;

        onGenerateHandler(promptMessage);
        setGenerate(true);
        setGenerating(true);
    };

    return (
        <div
            className={`${!generate && !showingHistory ? 'px-10 pt-5' : ''} w-full flex flex-col h-full overflow-y-auto md:h-screen  items-start`}
        >
            <div className="flex flex-row items-start justify-start mt-5 w-full">
                {!generate && !showingHistory && (
                    <BackButton onclick={() => navigate(`/${chatRoutePages.studentResources}`)} />
                )}
            </div>
            {!generate && !showingHistory ? (
                <>
                    <div className="flex flex-col  items-start text-start py-6">
                        <h1 className="text-3xl font-bold text-gray-800 dark:text-white">Note Summarizer</h1>
                        <p className="text-gray-600 mt-2 dark:text-white">Summarize your notes with ease</p>
                    </div>

                    {/* Steps Navigation */}

                    {/* Current Step */}
                    <div className="flex flex-col items-start mt-5 w-full">
                        {currentStep === 1 && (
                            <div className="w-full max-w-2xl">
                                <div className="flex flex-row items-center gap-3  w-full  border border-gray-700 dark:border-gray-200 p-2 rounded-lg focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-0">
                                    <div className="relative flex-grow ">
                                        {!isloading ? (
                                            <label className="flex items-center text-gray-600 dark:text-gray-300 cursor-pointer">
                                                {extractedFile ? (
                                                    <div className="relative flex items-center justify-start w-20 h-12 bg-white rounded-md shadow-md">
                                                        {/* PDF Icon */}
                                                        <FilePdfFilled className="text-2xl text-red-600 z-10 ml-4" />

                                                        {/* Close Icon */}

                                                        <CloseCircleOutlined
                                                            className="absolute top-0 right-0 z-[100]  text-red-500 cursor-pointer hover:text-red-700"
                                                            onClick={() => setExtractedFile('')}
                                                        />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <input
                                                            type="file"
                                                            accept=".pdf,.docx"
                                                            onChange={handleFileChange}
                                                            className="hidden"
                                                        />
                                                        <PaperClipOutlined className="absolute top-[1rem] text-gray-600 dark:text-gray-200 text-2xl " />
                                                    </>
                                                )}
                                            </label>
                                        ) : (
                                            <div className="h-6 w-6">
                                                <CircularProgress className="text-md" />{' '}
                                            </div>
                                        )}

                                        <div className="">
                                            <textarea
                                                value={Value}
                                                onChange={(e) => setValue(e.target.value)}
                                                className="w-full mt-2 pr-12 pl-10 rounded-lg border-none text-black dark:text-white focus:outline-none placeholder-gray-600 dark:placeholder-gray-300 "
                                                placeholder="Attach document or enter your prompt to summarize"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentStep === 2 && (
                            <div className="grid grid-cols-2 gap-4 md:grid-cols-4 w-full lg:max-w-6xl ">
                                {[
                                    'Bullet Points',
                                    'Short Paragraph',
                                    'Detailed Summary',
                                    'Most used key words',
                                    'soundbite',
                                ].map((op) => (
                                    <div
                                        key={op}
                                        className={optionStyle(op)}
                                        onClick={() => {
                                            console.log('Option:', op);
                                            handleCardClick(op);
                                        }}
                                    >
                                        <span className="text-gray-800">{op}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    {currentStep === 1 && (
                        <div className="flex flex-row justify-start  mt-4">
                            <button
                                className={`mx-2 px-4 py-2 text-sm font-medium rounded ${
                                    currentStep === steps.length
                                        ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                                        : 'bg-customOrange text-white '
                                }`}
                                onClick={() => currentStep < steps.length && handleStepChange(currentStep + 1)}
                                disabled={currentStep === steps.length}
                            >
                                Next
                            </button>
                        </div>
                    )}

                    {/* Input field always pinned to bottom */}
                    {currentStep === 2 && (
                        <div className="flex mt-4  flex-row gap-3 items-center ">
                            <button
                                className={`mr-2 px-4 py-2 text-sm font-medium rounded ${
                                    currentStep === 1
                                        ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                }`}
                                onClick={() => currentStep > 1 && handleStepChange(currentStep - 1)}
                                disabled={currentStep === 1}
                            >
                                Previous
                            </button>
                            <button
                                onClick={() => {
                                    if (!extractedFile || !template) {
                                        toast.info('Please upload a file or provide a template');
                                        return;
                                    }

                                    onFinish(Value);
                                }}
                                className="px-4 py-2 rounded bg-customOrange text-white w-full md:w-auto"
                            >
                                {' '}
                                Generate
                            </button>
                        </div>
                    )}

                    {isModalVisible && (
                        <Modal
                            title="Attach a Document"
                            open={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleOk}
                            footer={[
                                <Button key="dontShowAgain" onClick={handleDontShowAgain}>
                                    Don't Show Again
                                </Button>,
                                <button
                                    className=" ml-3 p-2 rounded bg-customOrange  text-white"
                                    key="ok"
                                    onClick={handleOk}
                                >
                                    OK
                                </button>,
                            ]}
                        >
                            <p>Please attach a document to summarize or write.</p>
                        </Modal>
                    )}
                </>
            ) : (
                <div className="w-full flex flex-col gap-0 ">
                    <NewEditorPage
                        editorInstanceRef={editorInstanceRef}
                        content={
                            <NewSunEditorComponent
                                latestRespone={latestRespone}
                                setLatestRespone={setLatestRespone}
                                highlightedTexts={highlightedTexts}
                                setHighlightedTexts={setHighlightedTexts}
                                editorInstanceRef={editorInstanceRef}
                                // humanizeded_content={Document.current?.data.ops}
                                // hasAccess={hasAccess}
                                hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                                initial_content={response}
                                onChangeEditorContent={(e: string) => {
                                    setEditorContent(e);
                                    const newtext = e;
                                    console.log('newtext', newtext);

                                    console.log('docId', docId);
                                    console.log('userDetails?.userId', userDetails?.userId);

                                    sendChanges(docId, userDetails?.userId, e);
                                }}
                            />
                        }
                        showTopNavBar={false}
                        showInvite={true}
                        isTyping={isTyping}
                        Profile={Profile}
                        promptSent={promptSent}
                        showingHistory={showingHistory}
                        setGenerate={setGenerate}
                        setShowingHistory={setShowingHistory}
                        generate={generate}
                        setEditorContent={setEditorContent}
                        editorContent={editorContent}
                        translatedContent={translatedContent}
                        setTranslatedContent={setTranslatedContent}
                        humanizedContent={humanizedContent}
                        setHumanizedContent={setHumanizedContent}
                        setUserOnDoc={setUserOnDoc}
                        userOnDoc={userOnDoc}
                        latestRespone={latestRespone}
                        setLatestRespone={setLatestRespone}
                        highlightedTexts={highlightedTexts}
                        setHighlightedTexts={setHighlightedTexts}
                        regenerate={onFinish}
                    />
                </div>
            )}
        </div>
    );
};

export default NoteSummarizer;

const summaryTemplate = (condition: string, value: string) => {
    const text = `
    Summarize the text provided in the 'Initial Text' section according to the following criteria:

    
    - Condense the content while preserving the key points and essential details.
    - If a specific word limit is provided in the 'Number of words to summarize to' section, adjust the summary to match that length.
    - Follow the specified condition: **${condition}**. 
    - Use ${value} as the template for the instruction.

    if (${condition} === 'Bullet Points') {
        // Provide the summary in bullet point format.
        Examples: 
        - Key Point 1: Summarize the main idea in a concise way.
        - Key Point 2: Focus on the technical or essential aspects.
        - Key Point 3: Avoid unnecessary details, keeping each bullet concise.
    } else if (${condition} === 'Short Paragraph') {
        // Provide a short paragraph summary without any bullet points.
        Examples:
        - "This is a compact summary that captures the essence of the text in a few sentences."
        - "The content has been condensed into a brief paragraph for clarity and quick understanding."
        - "All key points are summarized in a concise and readable format."
    } else if (${condition} === 'Detailed Summary') {
        // Write a detailed and expanded summary covering all key aspects.
        Examples:
        - "This summary expands on the key details, explaining the content comprehensively."
        - "Each critical point is addressed, providing a deep dive into the topic."
        - "This detailed summary ensures no information is left out while maintaining readability."
    } else if (${condition} === 'Analysis') {
        // Provide an analytical breakdown of the text.
        Examples:
        - "This section critically evaluates the text, providing insights into its implications."
        - "The analysis identifies patterns, themes, and potential impacts of the content."
        - "A breakdown of the strengths, weaknesses, opportunities, and threats in the text."
    } else if (${condition} === 'Most used key words') {
        // Highlight the most frequently used keywords in the text.
        Examples:
        - "Key terms: governance, compliance, risk, assessment, auditing."
        - "Frequently used words: innovation, strategy, leadership, execution."
        - "Highlighted terms: framework, security, policy, development."
    } else if (${condition} === 'Soundbite') {
        // Create a concise, impactful soundbite.
        Examples:
        - "A punchy takeaway like 'Simplifying compliance for a secure future.'"
        - "A memorable one-liner: 'Driving risk management excellence, one framework at a time.'"
        - "Short impactful phrase: 'Securing IT systems, safeguarding business growth.'"
    } else {
        // Default to a concise paragraph summary if no valid condition is provided.
        Examples:
        - "A brief paragraph summarizing the main ideas of the text."
        - "This is a default summary that captures the key points in a compact way."
        - "All essential details are provided in a single, concise paragraph."
    }

    - Include any requested specific details when applicable.
    - Ensure the summary is clear, concise, and retains the essential meaning of the original text.
    - Do not explain the summary or give context; simply provide the summary itself.

   
    Condition: **${condition}**
`;
    return text;
};

import { useEffect, useRef, useState } from 'react';
import BaseForm from './base_form';
import { Form, Input } from 'antd';
import { activityGeneratorPrompt, InputType } from '../interfaces';
import {
    activityOptions,
    availabilityOptions,
    curriculumOptions,
    durationOptions,
    lessonOptions,
    pedagogicalStatements,
    pedagogyOptions,
    pedgogyDescription,
    sizeOptions,
    skillOptions,
    standardOptions,
} from '../constants';
import BrowseModal from './browseModal';
import BrowseTeachingActivities from './browser_activities';
import teachingStandardSets from '@src/utils/teaching_standards';
import { TeachingStandardSet } from '@src/utils/teaching_standards';
import AIGenerate from '@icons/generate.svg';
import { toast } from 'react-toastify';

import { onGenerateWithAI } from '../functions';
import { BASE_URL, socketUrl } from '@src/utils/constants';
import { Chats, ChatType, getHistory } from '@src/pages/dashboard/endpoints';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';

import { io, Socket } from 'socket.io-client';
import { useOutletContext, useNavigate } from 'react-router';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import { openCurriculumEditor } from '@src/utils/app_functions';
import { prompts } from '@src/utils/prompts';

import { v4 as uuidv4 } from 'uuid';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import Profile from '@assets/Avatar.svg';
import { extractTextFromPDF, extractTextFromDOCX } from '@src/pages/dashboard/function/pdf_doc_helper';
import { chatRoutePages } from '@src/utils/app_routes';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import BackButton from '@src/components/BackButton';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}
const ActivityGenerator = () => {
    const [subject, setCurrentSubject] = useState('');

    const [basePrompt, setBasePrompt] = useState('');
    const [conceptForm, setConceptForm] = useState('');
    const [skill, setSkill] = useState('');
    const [activity, setActivity] = useState('');
    const [lesson, setLesson] = useState('');
    const [groupSize, setGroupSize] = useState('');
    const [duration, setDuration] = useState('');
    const [specialNeedsAvailability, setSpecialNeedsAvailability] = useState('');
    const [interest, setInterest] = useState('');
    const [visible, setVisible] = useState(false);
    const [firstPrompt, setFirstPrompt] = useState<string>('');
    const [selectedActivity, setSelectedActivity] = useState<TeachingStandardSet>({ name: '', description: '' });
    // const [sockets, setSockets] = useState<Socket | null>(null);
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({});
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [inputTyping, setInputTyping] = useState<{ [key: string]: boolean }>({});

    const [pedagogy, setPedgogy] = useState('');
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [uploadedFile, setUploadedFile] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [description, setDescription] = useState('');
    const [template, setTemplate] = useState('');
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState<any>([]);
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [generate, setGenerate] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [promptSent, setPromptSent] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [canNavigate, setCanNavigate] = useState(false);
    const [option, setOption] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const { loading, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const editorInstanceRef = useRef<any>();

    const {
        setShowTop,
        setShowSidebar,
        setHistory,
        setMobileNavStyle,
        setShowPagePath,
        setShowHistoryOption,
        setShowRightComponent,
        setShowHistory,
        response,
        setResponse,
        details,
    } = useOutletContext<any>();

    const pageHistory = 'essay';
    const navigate = useNavigate();
    const baseurl = BASE_URL;
    const bottomRef = useRef<HTMLDivElement>(null);
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const [userInput, setUserInput] = useState('');
    const [isloading, setisLoading] = useState(false);
    const [extractedFile, setExtractedFile] = useState<string>('');

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const allInputs: Array<InputType> = [
        {
            value: conceptForm,
            type: 1,
            name: 'conceptForm',
            label: 'Activity Focus*',
            placeholder:
                'The activity focus or theme that the learning activity should emphasize\ne.g students should be able identify and describe refraction of light',
            onChange: (e) => {
                setConceptForm(e);
            },
            options: [],
            onGenrate: () => {
                const prompt = `Generate a short activity focus for the activity for ${subject}`;
                const socket = sockets['conceptForm'];
                onGenerateWithAI(socket, prompt, details?.userId ?? "");
            },
            setData: setConceptForm,
        },
        {
            value: skill,
            type: 2,
            name: 'skill',
            label: 'Skill Focus*',
            placeholder: 'Select',
            onChange: (e) => {
                setSkill(e);
            },
            options: skillOptions,
            onGenrate: () => {},
            setData: setSkill,
        },
        {
            value: activity,
            type: 2,
            name: 'activity',
            label: 'Activity Type*',
            placeholder: 'Select',
            onChange: (e) => {
                setActivity(e);
            },
            options: activityOptions,
            onGenrate: () => {},
            setData: setActivity,
        },
        {
            value: lesson,
            type: 2,
            name: 'lesson',
            label: 'Lesson Stage*',
            placeholder: 'Select',
            onChange: (e) => {
                setLesson(e);
            },
            options: lessonOptions,
            onGenrate: () => {},
            setData: setLesson,
        },
        {
            value: groupSize,
            type: 2,
            name: 'group',
            label: 'Group Size*',
            placeholder: 'Select',
            onChange: (e) => {
                setGroupSize(e);
            },
            options: sizeOptions,
            onGenrate: () => {},
            setData: setGroupSize,
        },
        {
            value: duration,
            type: 2,
            name: 'duration',
            label: 'Duration*',
            placeholder: 'Select',
            onChange: (e) => {
                setDuration(e);
            },
            options: durationOptions,
            onGenrate: () => {},
            setData: setDuration,
        },
        {
            value: pedagogy,
            type: 2,
            name: 'Activity Pedagogy',
            label: 'Activity Pedagogy*',
            placeholder: 'Select',
            onChange: (e) => {
                setPedgogy(e);
            },
            options: pedagogyOptions,
            onGenrate: () => {},
            setData: setPedgogy,
        },
        {
            value: specialNeedsAvailability,
            type: 2,
            name: 'availabity',
            label: 'Special Needs Availability*',
            placeholder: 'Select',
            onChange: (e) => {
                setSpecialNeedsAvailability(e);
            },
            options: availabilityOptions,
            onGenrate: () => {},
            setData: setSpecialNeedsAvailability,
        },
        {
            value: interest,
            type: 1,
            name: 'interest',
            label: 'Student Interests*',
            placeholder: 'Any specific student interest to consider?\ne.g. My students like experiements',
            onChange: (e) => {
                setInterest(e);
            },
            options: [],
            onGenrate: () => {
                const prompt = `Generate a short student interests for the activity for ${subject}`;
                const socket = sockets['interest'];
                onGenerateWithAI(socket, prompt, details?.userId ?? "");
            },
            setData: setInterest,
        },
    ];

    useEffect(() => {
        const newSocket = io(baseurl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
            console.log('Received data:', data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            // setShowEditorButton(true);
            const { assistant } = data;
            setStreamEndMessage(assistant);
            setIsTyping(false);
            setCanNavigate(true);
            // handle stream end
            const uuid = uuidv4();
            setDocId(uuid);

            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                });
            }
            socket?.emit('get-documents', user?.id);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };

    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

    useEffect(() => {
        console.log(generate);

        if (generate || showingHistory) {
            openCurriculumEditor({
                response,
                pageId: docId,
                promptSent,
                promptType: prompts.curriculum,
                navigate,
                pageSocket: socket,
            });
            getDocIdFromUrl();
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (generate || showingHistory) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (response) {
            console.log(response);
            setEditorContent(response);
            setTranslatedContent(response);
        }
    }, [response]);

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message, type: 'gpt-4' };
        setChats(msgs);
        setResponse('');
        setIsTyping(true);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: prompts.activity,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId ?? '',
                },
            });
            // await handleAddHistory([{ role: 'user', content: message }, msgs], pageHistory, {});
            // getPageHistory();
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        const newVisibility: { [key: string]: boolean } = {};
        const newTyping: { [key: string]: boolean } = {};
        allInputs.forEach((item) => {
            newSockets[item.name] = io(socketUrl.slice(0, -2));
            newVisibility[item.name] = true;
            newTyping[item.name] = false;
        });
        setSockets(newSockets);
        setVisibility(newVisibility);
        setInputTyping(newTyping);

        return () => {
            Object.values(newSockets).forEach((socket) => {
                socket.close();
            });
        };
    }, []);

    const setTyping = (key: string, value: boolean) => {
        setInputTyping((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    useEffect(() => {
        allInputs.forEach((item) => {
            const socket = sockets[item.name];
            if (!socket) return;

            const handleData = (data: string) => {
                item.setData((response) => response + data);
            };
            const handleStreamEnd = () => {
                setTyping(item.name, false);
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);

            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleStreamEnd);
            };
        });
    }, [sockets]);

    const onInitialGenerate = () => {
        if (
            !conceptForm ||
            !skill ||
            !activity ||
            !lesson ||
            !groupSize ||
            !duration ||
            !specialNeedsAvailability ||
            !interest ||
            !pedagogy
        ) {
            toast.error('Input field cannot be empty');
            return;
        }

        const conceptData = `Concept the learning activity should emphasize: ${conceptForm}\n`;
        const skillData = `Skill to focus on: ${skill}\n`;
        const activityData = `Activity Type: ${activity}\n`;
        const lessonData = `Lesson Stage: ${lesson}\n`;
        const groupSizeData = `Learning group size: ${groupSize}\n`;
        const durationData = `Learning Duration: ${duration}`;
        const specialNeedsData = `Special Needs Availability: ${specialNeedsAvailability}`;
        const interestData = `Student interests: ${interest}`;
        const pedagogyData = `Pedagogy: ${pedagogy} with description ${pedgogyDescription(pedagogy)}`;

        const prompt = `Kindly generate a set of learning activities using this details below:\n${conceptData}${skillData}${activityData}${lessonData}${groupSizeData}${durationData}${specialNeedsData}${interestData}${pedagogyData}. Kindly ensure all the details sent is shown in the response. Also ensure that the response is grade level appropriate and strictly follows the requested curriculum
        Use this subject as template ${basePrompt}
        `;

        onGenerateHandler(prompt);
        // setVisible(true);
        setGenerate(true);
    };

    const generates = () => {
        const teachingStandard = `Using this teaching standard, Standard: ${selectedActivity.name}\n Standard Description: ${selectedActivity.description}`;
        const promptGenerated = `${firstPrompt} ${teachingStandard} with the following base info ${basePrompt}
        Include the grade level in this ${subject}
        `;
        setVisible(false);
        onGenerateHandler(promptGenerated);
    };

    const setAIVisible = (key: string) => {
        setVisibility((prevData) => ({
            ...prevData,
            [key]: false,
        }));
    };

    const hasTrueValue: boolean = Object.keys(inputTyping).some((key: string) => inputTyping[key]);

    return (
       <div className={`flex flex-col gap-y-3 w-full h-full  overflow-auto  ${!generate ? 'pb-20' : ''}`}>
    {!generate && !showingHistory ? (
        <div className="px-4 md:px-10 w-full flex flex-col gap-y-3 overflow-y-auto">
            {!isloading && (
                <div className='pb-10'>
                    <div className="flex flex-col gap-y-2 w-full mb-2">
                        <div className="pt-5">
                            <BackButton onclick={() => navigate(`/${chatRoutePages.educatorResources}`)} />
                        </div>
                        <h2 className="font-medium dark:text-white text-[2rem] md:text-[2.75rem] text-[var(--gmind-black)]">
                            Learning Activity Generator
                        </h2>
                        <p className="text-[1.125rem] dark:text-gray-300 md:text-[0.875rem] text-[var(--gmind-white60)] text-normal">
                            Provide us the information requested below to get your activity ready.
                        </p>
                    </div>
                    <BaseForm
                        dropDownOptions={curriculumOptions}
                        firstText="Curriculum*"
                        onSubmit={(baseValue) => {
                            setCurrentSubject(baseValue.subject);
                            setisLoading(true);
                            setBasePrompt(baseValue.basePrompt);
                        }}
                    />
                </div>
            )}
            {isloading && (
                <div className="flex flex-col gap-y-3 w-full mb-20 overflow-y-auto">
                    <div className="pt-5">
                        <BackButton onclick={() => setisLoading(false)} />
                    </div>
                    <h2
                        className="whitespace-normal font-medium text-[2rem] md:text-[2.75rem] dark:text-white text-[var(--gmind-black)]"
                        style={{ lineHeight: '2.8rem' }}
                    >
                        Generate several learning activities and choose one.
                    </h2>
                    <p className="text-[1.125rem] md:text-[0.875rem] text-[var(--gmind-white60)] text-normal dark:text-white">
                        Outline the criteria you have in mind for the learning activity you'd like to employ.
                    </p>
                    <Form onFinish={onInitialGenerate} className="flex flex-col gap-y-3" layout="vertical">
                        {allInputs.map((item, index) =>
                            item.type === 1 ? (
                                <div key={index}>
                                    <Form.Item
                                        className="w-full"
                                        name={item.name}
                                        label={
                                            <label className="font-normal text-[0.9rem] text-[var(--gmind-light-black)] dark:text-white">
                                                {item.label}
                                            </label>
                                        }
                                        rules={[{ message: 'field is required', required: false }]}
                                    >
                                        <div className="relative">
                                            <Input.TextArea
                                                ref={textAreaRef}
                                                className="rounded-[0.25rem] placeholder-gray-700 bg-transparent hover:bg-transparent"
                                                autoSize={{ minRows: 4 }}
                                                style={{ border: '1px solid gray' }}
                                                value={item.value}
                                                onChange={(e) => item.onChange(e.target.value)}
                                                placeholder={item.placeholder}
                                            />
                                            {visibility[item.name] && (
                                                <div
                                                    onClick={() => {
                                                        item.onGenrate();
                                                        setAIVisible(item.name);
                                                        setTyping(item.name, true);
                                                    }}
                                                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                                                >
                                                    <img src={AIGenerate} alt="" />
                                                    <span>Use Gmind AI</span>
                                                </div>
                                            )}
                                        </div>
                                    </Form.Item>
                                </div>
                            ) : (
                                <Form.Item
                                    key={index}
                                    className="w-full flex flex-col gap-y-3"
                                    name={item.name}
                                    label={
                                        <label className="dark:text-white curriculum--label">
                                            {item.label}
                                        </label>
                                    }
                                    rules={[{ message: 'field is required', required: false }]}
                                >
                                    <select
                                        className="w-full placeholder-gray-700 dark:text-white"
                                        value={item.value}
                                        onChange={(e) => item.onChange(e.target.value)}
                                        style={{
                                            border: '1px solid gray',
                                            borderRadius: '4px',
                                            backgroundColor: 'transparent',
                                            padding: '7px 10px',
                                        }}
                                    >
                                        <option
                                            className={`font-normal text-[0.9rem] dark:text-black rounded-[0.3rem] px-[1rem] py-[0.5rem]`}
                                            value=""
                                        >
                                            Select {item.label.replace('*', '')}
                                        </option>
                                        {item.options.map((item2, index2) => (
                                            <option
                                                key={index2}
                                                className={`${
                                                    item.value === item2 ? 'bg-[var(--gmind-orange8)] text-[var(--gmind-orange)]' : ''
                                                } font-normal text-[0.9rem] rounded-[0.3rem] px-[1rem] py-[0.5rem] dark:text-black`}
                                                value={item2}
                                            >
                                                {item2}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Item>
                            )
                        )}
                        <button
                            disabled={hasTrueValue}
                            type="submit"
                            className="w-[11rem] p-3 rounded-[0.375rem] text-white bg-[var(--gmind-orange)]"
                        >
                            Generate Activities
                        </button>
                    </Form>
                </div>
            )}
        </div>
    ) : (
        <div className="w-full flex flex-col h-full ">
                    <NewEditorPage
                        editorInstanceRef={editorInstanceRef}
                        content={
                            <NewSunEditorComponent
                                latestRespone={latestRespone}
                                setLatestRespone={setLatestRespone}
                                highlightedTexts={highlightedTexts}
                                setHighlightedTexts={setHighlightedTexts}
                                editorInstanceRef={editorInstanceRef}
                                // humanizeded_content={Document.current?.data.ops}
                                // hasAccess={hasAccess}
                                hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                                initial_content={response}
                                onChangeEditorContent={(e: string) => {
                                    setEditorContent(e);
                                    const newtext = e;
                                    console.log('newtext', newtext);

                                    console.log('docId', docId);
                                    console.log('userDetails?.userId', userDetails?.userId);

                                    sendChanges(docId, userDetails?.userId, e);
                                }}
                            />
                        }
                        showTopNavBar={false}
                        showInvite={true}
                        isTyping={isTyping}
                        Profile={Profile}
                        promptSent={promptSent}
                        showingHistory={showingHistory}
                        setGenerate={setGenerate}
                        setShowingHistory={setShowingHistory}
                        generate={generate}
                        setEditorContent={setEditorContent}
                        editorContent={editorContent}
                        translatedContent={translatedContent}
                        setTranslatedContent={setTranslatedContent}
                        humanizedContent={humanizedContent}
                        setHumanizedContent={setHumanizedContent}
                        setUserOnDoc={setUserOnDoc}
                        userOnDoc={userOnDoc}
                        latestRespone={latestRespone}
                        setLatestRespone={setLatestRespone}
                        highlightedTexts={highlightedTexts}
                        setHighlightedTexts={setHighlightedTexts}
                        regenerate={onInitialGenerate}
                    />
                </div>
    )}
</div>

    );
};

export default ActivityGenerator;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { handleAssessInterviewAnswers, handleAutoGenerateInterviewQuestions } from '../helper';
import { CloseOutlined, EditOutlined, FilePdfFilled, PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Paper } from '@mui/material';
import { extractTextFromPDF, extractTextFromDOCX } from '@src/pages/dashboard/function/pdf_doc_helper';
import { toast } from 'react-toastify';
import { File } from 'lucide-react';
import BackButton from '@src/components/BackButton';
import { isHtmlString } from '@src/utils/app_functions';
import { marked } from 'marked';
import { convertHTMLToPDF, convertHTMLToWord } from '../../essay/functions';
import { Download } from '@src/pages/dashboard/components/Share/Download';

interface Interview {
    question: string;
    answer?: string;
    assessment?: string;
}

const LoadingSpinner: React.FC = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-4 rounded-lg shadow-lg">
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
            </div>
            <p className="text-center mt-4">Generating...</p>
        </div>
    </div>
);

const InterviewPrep: React.FC = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState<string[]>([]);
    const [userInput, setUserInput] = useState('');
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const [interviewQuestion, setInterviewQuestion] = useState<Interview[]>([]);
    const [loading, setLoading] = useState(false);
    const { setShowTop, setShowSidebar, details } = useOutletContext<any>();
    const [extractedFile, setExtractedFile] = useState<string>('');
    const [isLoading, setisLoading] = useState(false);
    const navigate = useNavigate();
    const [pdfDownloading, setPdfDownloading] = useState(false);
    const [wordDownloading, setWordDownloading] = useState(false);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const UrlRef = useRef<string>('');
    const questions = [
        `Hi ${'there'}! Welcome to the Interview Prep Zone 🎉. We're excited to help you ace your next interview! Let's start with something simple: What's your name?`,

        "Awesome! Let's dive in 🏆. What role or job title are you aiming for? Dream big—we’re here to help you get there!",
        'Fantastic! 🚀 Can you provide your resume ',
        'Let’s get specific! 🌟 What key skills or qualifications are essential for this role? Think about what will make you stand out!',
        'Great! What is the job description for this role? What are the key responsibilities and expectations?',
        'Time for some self-reflection! 💡 What are your superpowers (strengths)? And if you had a kryptonite (weakness), what would it be?',
    ];

    const handleGenerate = async () => {
        setLoading(true);
        try {
            const result = await handleAutoGenerateInterviewQuestions(answers);
            setInterviewQuestion((prevInterviewQuestion) => [...prevInterviewQuestion, ...result]);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const result = await handleAssessInterviewAnswers(interviewQuestion);
            if (result.length > 0) {
                setInterviewQuestion([]);
            }
            setInterviewQuestion((prevInterviewQuestion) => [...prevInterviewQuestion, ...result]);
        } finally {
            setLoading(false);
        }
    };
    const handleSend = () => {
        if (userInput.trim() !== '' || extractedFile) {
            // Save the answer

            if (extractedFile) {
                console.log('User input:', extractedFile);
                setAnswers((prevAnswers) => [...prevAnswers, extractedFile]);
            } else {
                setAnswers((prevAnswers) => [...prevAnswers, userInput]);
            }

            console.log('User input:', answers);
            setUserInput(''); // Clear input
            setExtractedFile('');
            if (currentQuestion < questions.length - 1) {
                setCurrentQuestion((prevIndex) => prevIndex + 1); // Move to the next question
            } else {
                console.log('All questions answered:', [...answers, userInput]);
                alert('Thank you for answering all the questions!');
            }
        }
    };

    async function handlePDF() {
        const metaTable = document.getElementById('interview-question');
        if (!metaTable) {
            toast.error('No content to download');
            return;
        }

        const clonedContent = metaTable.cloneNode(true) as HTMLElement;

        clonedContent.querySelectorAll('textarea, button').forEach((el) => el.remove());

        removeUnwantedText(clonedContent);

        const cleanHTML = clonedContent.innerHTML;

        try {
            setPdfDownloading(true);
            await convertHTMLToPDF(cleanHTML);
        } catch (error) {
            toast.error('Error downloading PDF');
        } finally {
            setPdfDownloading(false);
        }
    }

    async function handleWord() {
        const metaTable = document.getElementById('interview-question');
        if (!metaTable) {
            toast.error('No content to download');
            return;
        }

        const clonedContent = metaTable.cloneNode(true) as HTMLElement;

        clonedContent.querySelectorAll('textarea, button').forEach((el) => el.remove());

        removeUnwantedText(clonedContent);

        const cleanHTML = clonedContent.innerHTML;

        try {
            setWordDownloading(true);
            await convertHTMLToWord(cleanHTML);
        } catch (error) {
            toast.error('Error downloading Word document');
        } finally {
            setWordDownloading(false);
        }
    }

    function removeUnwantedText(element: HTMLElement) {
        const unwantedTexts = ['Download', 'cancel'];
        element.childNodes.forEach((node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                const textContent = node.textContent?.trim();
                if (textContent && unwantedTexts.includes(textContent)) {
                    node.parentNode?.removeChild(node);
                }
            }
        });
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0];

        console.log('Selected file:', file);
        setisLoading(true);

        if (!file) return;
        console.log('Selected file:', file);

        const fileType = file.type;

        if (
            fileType === 'application/pdf' ||
            fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            // Handle PDF and DOCX files
            const fileTypeValue: 'PDF' | 'DOCX' = fileType === 'application/pdf' ? 'PDF' : 'DOCX';
            try {
                // }

                if (fileType === 'application/pdf') {
                    const doc = await extractTextFromPDF(file);
                    setExtractedFile(doc);
                    setUserInput(doc);
                    console.log(doc);
                    console.log('exract', extractedFile);
                } else {
                    const doc = await extractTextFromDOCX(file);
                    setExtractedFile(doc);
                    console.log(doc);
                    setUserInput(doc);
                }
            } catch (error) {
                console.error('Error during file upload:', error);
                toast.error('File upload failed');
            } finally {
                setisLoading(false);
            }
        } else {
            console.warn('Unsupported file type:', fileType);
            return;
        }
    };

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [answers, currentQuestion]);

    // Handle Enter key for sending messages
    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            handleSend();
        }
    };

    useEffect(() => {
        setShowTop(true);
        setShowSidebar(true);
    }, []);

    return (
        <div className="flex flex-col overflow-y-scroll h-[90%]">
            {loading ? (
                <LoadingSpinner />
            ) : interviewQuestion.length > 0 ? (
                <div className="flex flex-col md:h-screen px-10 py-5 overflow-y-auto" id="interview-question">
                    <h1 className="text-xl dark:text-white py-2">Interview Questions</h1>
                    {interviewQuestion.map((item, index) => (
                        <div key={index} className="mb-4">
                            <div className="mb-2 max-w-5xl  bg-gray-100 text-black  p-3 rounded-lg">
                                <p>
                                    {' '}
                                    {index + 1}. {item.question}
                                </p>
                            </div>
                            {item.assessment !== undefined ? (
                                <div className="p-4 bg-green-100 border border-green-300 mt-3 max-w-5xl rounded-lg">
                                    <h4 className="font-bold text-green-700">Assessment:</h4>
                                    <p className="text-gray-700 mt-2">{item.assessment}</p>
                                </div>
                            ) : (
                                <div className="max-w-5xl">
                                    <textarea
                                        value={item.answer || ''}
                                        onChange={(e) => {
                                            const updatedQuestions = [...interviewQuestion];
                                            updatedQuestions[index].answer = e.target.value;
                                            setInterviewQuestion(updatedQuestions);
                                        }}
                                        className="w-full p-2 rounded-lg dark:text-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Type your answer..."
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                    <div className="flex flex-col md:flex-row items-center justify-between w-full md:max-w-5xl">
                        <button
                            className=" mt-5 bg-customOrange  text-white rounded px-3 py-2 w-full md:w-auto h-auto"
                            onClick={() => handleGenerate()}
                        >
                            Generate more
                        </button>
                        <div className="flex flex-col md:flex-row gap-3 md:justify-end w-full mt-3 md:mt-0">
                            <button
                                className=" bg-gray-200 rounded px-3 py-2 w-full md:w-auto"
                                onClick={() => setInterviewQuestion([])}
                            >
                                Back
                            </button>

                            <button
                                className="w-full  md:w-auto px-3 w-auto  bg-green-600 text-white rounded py-2 hover:bg-green-700"
                                onClick={() => handleSubmit()}
                            >
                                Submit Answers
                            </button>

                            <button
                                className=" w-full md:w-auto text-white bg-customOrange rounded  px-3 py-2  "
                                onClick={() => setShowDownload(true)}
                            >
                                Download
                            </button>

                            {showDownload && (
                                <Download
                                    handlePDF={handlePDF}
                                    handleWord={handleWord}
                                    url={UrlRef.current}
                                    onClose={() => setShowDownload(false)}
                                    isPdfDownloading={pdfDownloading}
                                    isWordDownloading={wordDownloading}
                                />
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col h-full px-10 pt-10 relative">
                    <BackButton onclick={() => navigate(-1)} className="my-3" />
                    <div className="flex-grow overflow-y-scroll rounded p-4" ref={chatContainerRef}>
                        <Tooltip
                            className="custom-tooltip md:flex z-50"
                            title="Create New Chat"
                            placement="right"
                            overlayInnerStyle={{
                                backgroundColor: 'white',
                                color: 'black',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                            }}
                        >
                            <button
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    left: '0',
                                    right: '0',
                                    top: '0',
                                }}
                                onClick={() => {
                                    setAnswers([]);
                                    setCurrentQuestion(0);
                                    if (chatContainerRef.current) {
                                        chatContainerRef.current.scrollTop = 0;
                                    }
                                }}
                                className="flex items-center  justify-center ml-6 w-6 h-6 lg:w-10 lg:h-10  md:bg-transparent border-[1.5px] border-gray-700 dark:border-white text-gray-700 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none shadow-md z-[9999999]"
                            >
                                <EditOutlined />
                            </button>
                        </Tooltip>
                        {answers.map((answer, index) => (
                            <div key={index} className="mb-4  ">
                                <div className="mb-2 max-w-xs dark:bg-gray-700 bg-gray-100 dark:text-white p-3 rounded-lg">
                                    {questions[index]}
                                </div>
                                <div className="text-right">
                                    <div className="inline-block max-w-xs bg-blue-600 text-white p-3 rounded-lg">
                                        {index === 2 ? (
                                            <div className="flex flex-row gap-2 items-center">
                                                <FilePdfFilled /> <span>Attachment</span>
                                            </div>
                                        ) : (
                                            answer
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                        {currentQuestion < questions.length - 1 && (
                            <div className="mb-2 max-w-xs dark:bg-gray-700 bg-gray-100 text-black dark:text-white p-3 rounded-lg">
                                {questions[currentQuestion]}
                            </div>
                        )}
                    </div>
                    {currentQuestion < questions.length - 1 ? (
                        <div className="sticky bottom-0 p-3 gap-3 flex">
                        <div className="relative flex-grow">
                            <label className="absolute inset-y-0 left-3 flex items-center text-gray-600 dark:text-gray-300 cursor-pointer">
                                {extractedFile ? (
                                    <div className="relative flex items-center justify-center w-12 h-12">
                                        <FilePdfFilled className="text-2xl z-10" />
                                        <CloseOutlined
                                            className="absolute top-1/2 left-1/2 z-[100] transform -translate-x-1/2 -translate-y-1/2 text-red-500 cursor-pointer hover:text-red-500"
                                            onClick={() => setExtractedFile('')}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <input
                                            type="file"
                                            accept=".pdf,.docx"
                                            onChange={handleFileChange}
                                            className="hidden"
                                        />
                                        <PaperClipOutlined className="text-gray-600 dark:text-gray-200 text-2xl z-10" />
                                    </>
                                )}
                            </label>
                            <div className="relative">
                                <textarea
                                    value={userInput}
                                    onKeyDown={handleKeyPress}
                                    onChange={(e) => setUserInput(e.target.value)}
                                    className="w-full p-3 pr-12 pl-10 rounded-lg dark:bg-gray-700 border border-gray-600 text-black dark:text-white focus:outline-none"
                                    placeholder="Type your answer..."
                                />
                                <button
                                    onClick={() => handleSend()}
                                    className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-transparent border-none p-0"
                                >
                                    <SendOutlined className="text-gray-600 dark:text-gray-200 text-2xl" />
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    ) : (
                        <button
                            onClick={handleGenerate}
                            className="px-6 py-3 w-[12rem] bg-green-600 text-white rounded-lg hover:bg-green-700"
                        >
                            Generate Interview Questions
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default InterviewPrep;

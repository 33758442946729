import { QuizQuestion } from "./interface";
import { BASE_URL } from '@src/utils/constants';
import axios from 'axios';
const token = localStorage.getItem('token');
type Chats = { role: string; content: string };

let chats: Chats = { role: '', content: '' };

function repairJSON(incompleteJSON: string): string {
    // Trim whitespace
    incompleteJSON = incompleteJSON.trim();
  
    // Ensure the JSON ends properly with a closing bracket
    if (!incompleteJSON.endsWith("]")) {
      incompleteJSON += "]";
    }
  
    // Check if each object ends properly
    const lastComma = incompleteJSON.lastIndexOf(",");
    const lastBrace = incompleteJSON.lastIndexOf("}");
    
    if (lastComma > lastBrace) {
      incompleteJSON = incompleteJSON.slice(0, lastComma) + incompleteJSON.slice(lastComma + 1);
    }
  
    return incompleteJSON;
  }
  

export const handleAutoGenerateQuiz = async (number: number, condition: string, gradeLevel: string, file: string, value: string): Promise<QuizQuestion[]> => {
    const sytemPrompt = `You are an AI-powered slide creation assistant designed to help users create engaging, informative, and visually appealing slides for various purposes, such as presentations, lectures, or educational content. Your goal is to generate slide content based on the user's input, ensuring that the slides are well-structured, concise, and effectively convey the intended message.

    
    Note: only generate this ${number} quiz. Do not generate more or less than this number.
     Create a quiz according to the following instructions:
            - Number of questions: ${number}
        
         
            - The type of quiz should be aligned with the following condition: **${condition}** and the grade level is ${gradeLevel}.
       
        
         

            Quiz Questions format:

           
            **{question}**: **{question}**
            **options**: **{options}**
            **correctAnswer**: **{correctAnswer}**
            **type**: **{type}**
}
           [ {
            "question": "What is the capital of France?",
            "options": ["Paris", "London", "Berlin", "Madrid"],
            "correctAnswer": "Paris",
            "type": "multiple-choice"
            }
            {
            "question": "Is the Earth round?",
            "correctAnswer": "true",
            "options": ["true", "false"],
            "type": "true-false"
            }

            {
            "question": "The _____ is the capital of France.",
            "correctAnswer": "Paris",
            "type": "fill-in-the-blank"
            }

            {
            "question": "What is the capital of France?",
            "correctAnswer": "Paris",
            "type": "short-answer"
            }
            {
            "question": "What is the capital of France?",
            "correctAnswer": "Paris",
            "type": "flashcard"
            }

            ]
           
            response must be in JSON format.

           
            Do not give any explanation or context.

            ${file ? `${value}: ${file} as the context` : `Using this value content generate a quiz: ${value}  as the context` }

            Make sure you generate this type ${condition} of quiz

            Response must be in JSON format and an array.
            it must be closed at the end [] and array must be complete

       

            


             `;

    try {
        let msgs = chats;
        msgs = { role: 'user', content: sytemPrompt };
        const response = await axios.post(
            `${BASE_URL}/knowledge-base`,
            {
                model: 'gpt-4',
                messages: [
                    {
                        role: 'system',
                        content: sytemPrompt,
                    },
                    msgs,
                ],
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        
        // Get the response content (the quiz data)
        const AIRESULT = response?.data?.data?.choices[0]?.message;
        const generatedContent = AIRESULT?.content;
        // Parse the content into a QuizQuestion array
        const formattedData = JSON.parse(generatedContent) as QuizQuestion[];
        console.log(formattedData);

    
     

   

        // Return only the quiz questions in the expected format
        return formattedData;
    } catch (error: any) {
        return [];
    } finally {
    }
};


export default{
    handleAutoGenerateQuiz
}


export const handleAutoGenerateInterviewQuestions = async (
    qalist: any[]  // Accept the list of topics or concepts
): Promise<{ question: string }[]> => {
    // Create a prompt using the list of topics
    const topicList = qalist.map((topic, index) => `"Topic ${index + 1}": "${topic}"`).join("\n");

    const sytemPrompt = `
    You are an AI-powered interview assistant designed to generate professional interview questions based on a list of topics or concepts. Your goal is to generate a list of relevant interview questions for each of the provided topics.

    Note: Generate interview questions for exactly ${5} topics. Do not generate more or less than this number.
    Note: question must be a mixture of open-ended and closed-ended questions.
    Note: Do not explain or provide any context.

    Here are the topics to generate questions for:
    Role: ${qalist[1]}
    Requirements: ${qalist[2]}
    Skills: ${qalist[3]}
    job description: ${qalist[4]}
    Number of questions: ${5}
   

    Response must be in JSON format, with an array of objects.
    Ensure to provide only the questions related to the topics.

    Response should look like:
    [
        {
            "question": "How do you handle conflicts in a team setting?"
        },
        {
            "question": "How do you approach challenges related to skills"
        }
    ]
    
    Make sure to generate relevant professional interview questions for each topic.
    Response must be in JSON format and closed at the end.
    `;

    try {
        let msgs = chats;
        msgs = { role: 'user', content: sytemPrompt };
        const response = await axios.post(
            `${BASE_URL}/knowledge-base`,
            {
                model: 'gpt-4',
                messages: [
                    {
                        role: 'system',
                        content: sytemPrompt,
                    },
                    msgs,
                ],
                
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        // Get the response content (the interview questions data)
        const AIRESULT = response?.data?.data?.choices[0]?.message;
        const generatedContent = AIRESULT?.content;

        // Parse the content into an array of questions
        const formattedData = JSON.parse(generatedContent) as { question: string }[];
        console.log(formattedData);

        // Return the generated questions
        return formattedData;
    } catch (error: any) {
        console.error("Error generating interview questions:", error);
        return [];
    }
};


interface Interview {
    question: string;
    answer?: string;
    assessment?: string;
}


export const handleAssessInterviewAnswers = async (
    qaList: { question: string; answer?: string }[] // Accept a list of questions and answers
): Promise<Interview[]> => {
    // Create a formatted list of questions and answers for the prompt
    const formattedQAList = qaList
        .map(
            (qa, index) =>
                `Question ${index + 1}: "${qa.question}"\nAnswer: "${qa.answer}"`
        )
        .join("\n\n");

    const systemPrompt = `
    You are an AI-powered interview assistant specializing in evaluating interview answers. Your goal is to assess the quality of answers provided for each question. 
    Provide constructive feedback, including strengths and areas for improvement, for every answer. 

    Here is the format you must follow for the response:
    [
        {
            "question": "What is your greatest strength?",
            "answer": "I am highly organized and excel at managing multiple tasks simultaneously.",
            "assessment": "The answer effectively highlights a strength relevant to many roles. However, it could be improved by including a specific example to demonstrate this skill."
        },
        {
            "question": "How do you handle tight deadlines?",
            "answer": "I prioritize my tasks and work efficiently under pressure.",
            "assessment": "The answer is clear and demonstrates confidence, but it would be more impactful with an example of a time the candidate successfully handled a tight deadline."
        }
    ]

    Note: Ensure the response is professional and concise. Provide feedback in JSON format only.

    Here are the questions and answers to assess:
    ${formattedQAList}
    `;

    try {
        const response = await axios.post(
            `${BASE_URL}/knowledge-base`,
            {
                model: "gpt-4",
                messages: [
                    {
                        role: "system",
                        content: systemPrompt,
                    },
                ],
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        // Get the response content (the assessment data)
        const AIRESULT = response?.data?.data?.choices[0]?.message;
        const generatedContent = AIRESULT?.content;

        // Parse the content into an array of assessed questions
        const formattedData = JSON.parse(generatedContent) as {
            question: string;
            answer: string;
            assessment: string;
        }[];

        console.log(formattedData);

        // Return the assessed questions and answers
        return formattedData;
    } catch (error: any) {
        console.error("Error assessing interview answers:", error);
        return [];
    }
};


export const handleCustomerDetails = async (value:string): Promise<any> => {
    const token = localStorage.getItem("token");
    const systemPrompt = `
  You are an AI-powered customer details generator designed to create realistic and random personal details for customers. Generate a list of customers with the following details:
  1. Name: A random full name.
  2. Email: A realistic email address.
  3. Phone: A 10-digit phone number.
  4. Address: A random address including street, city, and zip code.

  Rules:
  - Use ${value} as the context for generating the details.
  - Generate at least 10 customers.

  Response format (valid JSON only, strictly adhering to this structure):
  {
    "customers": [
      {
        "name": "Random full name",
        "email": "Realistic email address",
        "phone": "10-digit phone number",
        "address": "Street, City, Zip Code"
      }
    ]
  }

  Do not include any explanations, code blocks, or characters outside of the JSON object.
  `;

    try {
        const response = await axios.post(
            `${BASE_URL}/knowledge-base`,
            {
                model: "gpt-4",
                messages: [
                    {
                        role: "system",
                        content: systemPrompt,
                    },
                ],
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const AIRESULT = response?.data?.data?.choices[0]?.message;
        console.log("Raw AI Result:", AIRESULT);

        if (!AIRESULT?.content) throw new Error("No content in AI response.");

        const cleanJSON = AIRESULT.content.replace(/```json|```/g, "").trim();
        const generatedCustomers = JSON.parse(cleanJSON);

        console.log("Parsed Customer Details:", generatedCustomers);
        return generatedCustomers;
    } catch (error: any) {
        console.error("Error generating customer details:", error);
        return { customers: [] };
    }
};


import React from "react";

export const Review = ({ contents }: { contents: string }) => {
  // Split the content into lines using a regex to capture sections like "1.", "2.", etc.
  const contentLines = contents.split(/\d+\.\d* /).filter(Boolean); // Split by "1. ", "2. ", etc.

  return (
    <div>
      <h2 className="text-xl font-bold mb-4 dark:text-gray-300">Review & Edit Your Table of Contents</h2>
      <div className="mt-4 flex flex-col space-y-2">
        {contentLines.map((line, index) => (
          <div key={index} className="bg-white rounded shadow p-2 w-full md:w-[70%]">
            {index + 1}. {line.trim()}
          </div>
        ))}
      </div>
    </div>
  );
};

import { GetHistory } from "@src/core/endpoints/chat";
import { getUrlPath } from "@src/utils/app_functions";
import { chatRoutePages } from "@src/utils/app_routes";

export const getAllHistory = () => {
   const path = getUrlPath();
   const queryString = window.location.search;
   const urlParams = new URLSearchParams(queryString);

   // use query parameters path
   let subPage: string = urlParams.get('type') || '';

   const extraUrlPath = subPage === "" ? '' : `/${subPage}`

   if ( getUrlPath().pagename === chatRoutePages.chat ){
      const data = GetHistory(localStorage.getItem('token'),'chats');
      return data;
   }else if (path.pagename.includes('chat')){
      
         const data = GetHistory(localStorage.getItem('token'),'chats/internet');
         return data;
    }else if (path.pagename.includes('curriculum')){
       const data = GetHistory(localStorage.getItem('token'),'curriculum');
       return data;
    }else if (path.pagename.includes('generate_content')){
       const data = GetHistory(localStorage.getItem('token'),'contents');
       
        return data;
    }else if (path.pagename.includes('essay')){
      const data =  GetHistory(localStorage.getItem('token'),'essay');
      return data;
    }else if (path.pagename.includes('assessments')){
       const data = GetHistory(localStorage.getItem('token'),'assessments');
       return data;
    }else if (path.pagename.includes('educator_resources')){
       const data = GetHistory(localStorage.getItem('token'), `class-resources${extraUrlPath}`);
       return data;
    }else if (path.pagename.includes('business_resources')){
      const data = GetHistory(localStorage.getItem('token'), `business-resources${extraUrlPath}`);
      return data;
   }else if (path.pagename.includes('media_resources')){
      const data = GetHistory(localStorage.getItem('token'), `media-resources${extraUrlPath}`);
      return data;
   }else if (path.pagename.includes('research')){
       const data =  GetHistory(localStorage.getItem('token'),'research');
       return data;
    } else if (path.pagename.includes('slide')){
      const data =  GetHistory(localStorage.getItem('token'),'slide');
      return data;

    }
    else{
        return null;
    }

}
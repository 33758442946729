import testimonial2 from '@assets/testimonial2.png';
import testimonial from '@assets/features/user.png';
import testimonial4 from '@assets/testimonial4.png';
import testimonial5 from '@assets/testimonial5.png';
import { useState } from 'react';
import top from '@assets/top-right.png';
import { getUrlPath } from '@src/utils/app_functions';


const Testimonials = () => {
    const testimonials = [
        {
            picture: testimonial4, // Replace with actual image URL
            name: 'John M.',
            role: 'Educator',
            testimony: 'GMIND AI has transformed my teaching approach. Its intuitive templates and multi-linguistic capabilities have made lesson planning a breeze, allowing me to engage with my students like never before and cater to their diverse language needs.',
            header: 'Simplifying Lesson Planning'
        },
        {
            picture: testimonial2, // Replace with actual image URL
            name: 'Mary A.',
            role: 'Business Analyst',
            testimony: 'As a business analyst, time is crucial, and GMIND AI has been a game-changer for me. The AI-powered insights and streamlined task templates have significantly enhanced my efficiency and decision-making processes.',
            header: 'Boosting Efficiency with AI Insights'
        },
        {
            picture: testimonial5,  // Replace with actual image URL
            name: 'Mike J',
            role: 'Freelancer',
            testimony: 'Navigating multiple projects can be overwhelming, but GMIND AI has simplified my workflow. Its human-like response generation and seamless collaboration tools make client communication and content creation effortless.',
            header: 'Streamlining Freelance Workflows'
        },
        {
            picture: testimonial, // Replace with actual image URL
            name: 'Emily D.',
            role: 'Business Dev Manager',
            testimony: "GMIND AI proves that you don't need a dozen different tools to excel at project management. Its all-in-one platform allows me to manage tasks, communicate with my team, and generate insightful reports without the hassle of switching between multiple applications. This streamlined approach not only saves time but also reduces costs, making GMIND AI an indispensable asset for our organization.",
            header: 'Revolutionizing Project Management'
        },
    ];

    const [testimonyIndex, setTestimonyIndex] = useState(0);

    const handlePrev = () => {
        setTestimonyIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setTestimonyIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

    const currentTestimonial = testimonials[testimonyIndex];

    return (
        <section className="w-full bg-white relative overflow-hidden">
            <div
                className="
            w-full
            py-16
            px-6
            md:px-24
            3xl:max-w-[1800px]
            3xl:mx-auto
            3xl:px-8
          "
            >
                {/* Top-right Image */}
                {getUrlPath().pagename !== 'researchers' && (
                    <img
                        src={top}
                        alt="Top decorative"
                        className="absolute -top-48 right-0 w-24 sm:w-32 md:w-48 lg:w-64 xl:w-72"
                    />
                )}

                {/* Main Content Container */}
                <div className="flex flex-col md:flex-row items-center md:space-x-12">
                    {/* Left Column */}
                    <div className="flex flex-col space-y-8 md:w-1/2">
                        <h2
                            className="
                  text-3xl
                  font-medium
                  font-archivo
                  md:text-6xl
                  3xl:text-7xl
                  3xl:leading-[80px]
                "
                        >
                            Our users know the value we provide
                        </h2>

                        <div>
                            <h4 className="text-xl font-semibold font-archivo md:text-2xl">
                                Testimonials Speak Volumes.
                            </h4>
                            <p className="text-lg text-gray-600 font-workSans font-normal md:text-sm">
                                We take great pride in the satisfaction of our users, as evidenced
                                by their glowing feedback about our exceptional platform.
                            </p>
                        </div>

                        <div className="flex flex-col sm:flex-row items-center gap-8">
                            <img
                                src={currentTestimonial.picture}
                                alt={currentTestimonial.name}
                                className="rounded-2xl w-36 h-36 sm:w-52 sm:h-52"
                            />
                            <div>
                                <h6
                                    className="
                      text-lg
                      font-semibold
                      md:text-2xl
                      font-archivo
                    "
                                >
                                    -{currentTestimonial.name}
                                </h6>
                                <span className="text-sm text-gray-500 font-medium font-workSans md:text-base">
                                    {currentTestimonial.role}
                                </span>
                                <div className="flex mt-4">
                                    <span
                                        className="bg-white p-1.5 rounded-full shadow-md mr-2 cursor-pointer"
                                        onClick={handlePrev}
                                    >
                                        <svg
                                            width="24"
                                            height="24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M11.6486 20.0484C11.18 20.5171 10.4202 20.5171 9.95157 20.0484L2.75156 12.8484C2.28293 12.3798 2.28293 11.62 2.75156 11.1514L9.95157 3.95137C10.4202 3.48275 11.18 3.48275 11.6486 3.95138C12.1173 4.42 12.1173 5.1798 11.6486 5.64843L6.49715 10.7999L20.4001 10.7999C21.0628 10.7999 21.6001 11.3372 21.6001 11.9999C21.6001 12.6626 21.0628 13.1999 20.4001 13.1999L6.49715 13.1999L11.6486 18.3514C12.1173 18.82 12.1173 19.5798 11.6486 20.0484Z"
                                                fill="#CED1D8"
                                            />
                                        </svg>
                                    </span>
                                    <span
                                        className="bg-[#001F73] p-1.5 rounded-full shadow-md cursor-pointer"
                                        onClick={handleNext}
                                    >
                                        <svg
                                            width="24"
                                            height="24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12.3514 3.95157C12.82 3.48294 13.5798 3.48294 14.0484 3.95157L21.2484 11.1516C21.7171 11.6202 21.7171 12.38 21.2484 12.8486L14.0484 20.0486C13.5798 20.5173 12.82 20.5173 12.3514 20.0486C11.8827 19.58 11.8827 18.8202 12.3514 18.3516L17.5028 13.2001L3.5999 13.2001C2.93716 13.2001 2.3999 12.6628 2.3999 12.0001C2.3999 11.3374 2.93716 10.8001 3.5999 10.8001H17.5028L12.3514 5.64863C11.8827 5.18 11.8827 4.4202 12.3514 3.95157Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="flex flex-col md:w-1/2 space-y-4 text-center">
                        <svg
                            width="67"
                            height="54"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M67 8.43384C56.9649 13.1193 51.9474 18.6247 51.9474 24.9501C56.2247 25.4187 59.7616 27.0781 62.5583 29.9284C65.3549 32.7788 66.7532 36.0781 66.7532 39.8265C66.7532 43.8091 65.396 47.167 62.6816 49.9002C59.9672 52.6334 56.5537 54 52.441 54C47.8347 54 43.8454 52.2234 40.4729 48.6703C37.1005 45.1171 35.4143 40.8026 35.4143 35.7267C35.4143 20.4988 44.3799 8.59007 62.3115 0L67 8.43384ZM31.5857 8.43384C21.4684 13.1193 16.4098 18.6247 16.4098 24.9501C20.7693 25.4187 24.3473 27.0781 27.144 29.9284C29.9406 32.7788 31.3389 36.0781 31.3389 39.8265C31.3389 43.8091 29.9612 47.167 27.2057 49.9002C24.4501 52.6334 21.016 54 16.9033 54C12.297 54 8.32828 52.2234 4.99696 48.6703C1.66564 45.1171 0 40.8026 0 35.7267C0 20.4988 8.92452 8.59007 26.7738 0L31.5857 8.43384Z"
                                fill="#FF5705"
                            />
                        </svg>

                        <h4 className="text-xl font-semibold text-gray-800 font-archivo md:text-2xl text-left">
                            {currentTestimonial.header}
                        </h4>

                        {/* testimony quote */}
                        <p
                            className="
                  text-sm
                  text-[#474747]
                  bg-[#EC53060A]
                  p-4
                  rounded-2xl
                  opacity-60
                  font-workSans
                  text-left
                "
                        >
                            {currentTestimonial.testimony}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonials;
"use client";
import { useEffect, useRef, useState } from "react";
import { PricingCard, PricingSwitch } from "../components/pricing";
import { plans } from "../constants";
import { CurrencyDropDown } from "../components/currencyDropdown";
import Footer from "../../components/footer";
import { getRegion, getUrlPath } from "@src/utils/app_functions";
import Loading from "@src/components/Loading";
import { Helmet } from "react-helmet-async";
import RequestPricing from "@src/pages/dashboard/pages/dashboard_pricing/components/request_pricing";
import PromoHeader from "../components/promo_price";
import Header from "@src/pages/acquisition/components/header";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import { pricingData } from "../../strutured_data/structured_data";


function calculatePercentage(price: number): number {
  const percentage = 0.70; // 70%
  return price * percentage;
}

export default function PricingHome() {
  const [pricingPeriod, setPricingPeriod] = useState<
    "monthly" | "quarterly" | "yearly"
  >("monthly");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [visible, setVisible] = useState(false);
  const [isPromo, setIsPromo] = useState(true);
  const location = useLocation();
  const [isRoot, setIsRoot] = useState<boolean>(location.pathname === '/');
  const [isFeatures, setIsFeatures] = useState<boolean>(location.pathname === '/features');
  const faqRef = useRef<HTMLDivElement | null>(null);
  const featuresRef = useRef<HTMLDivElement | null>(null);
  const [isMobile, setIsMobile] = useState(true);
  const [showFrame, setShowFrame] = useState<boolean>(false);
  const [editorView, setEditorView] = useState<boolean>(false);
  const path = getUrlPath().pagename
  const [isHome, setIsHome] = useState(false);
  const navigate = useNavigate();

  const {
    setShowTop,
  } = useOutletContext<any>();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setShowTop(true)
    } else {
      setShowTop(false)
    }
  }, [window.location.pathname]);

  useEffect(() => {
    getRegion().then((data) => {
      setSelectedCurrency(data.location)
    });
  }, []);
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCurrencyChange = (value: string) => {
    setSelectedCurrency(value);
  };

  const togglePricingPeriod = (value: string) => {
    switch (value) {
      case "0":
        setPricingPeriod("monthly");
        break;
      case "1":
        setPricingPeriod("quarterly");
        break;
      case "2":
        setPricingPeriod("yearly");
        break;
      default:
        setPricingPeriod("monthly");
    }
  };

  return (
    selectedCurrency == '' ? <Loading /> :
      <div className="flex flex-col gap-y-2">
        <Helmet>
          <title>GMind AI Pricing | Affordable Plans for Every Professional</title>
          <meta
            name="description"
            content="Choose a plan that fits your needs and access powerful content creation, research, and business features. Boost efficiency with affordable options tailored for professionals."
          />
          <meta
            name="keywords"
            content="GMind AI pricing, affordable AI plans, professional AI tools pricing, AI content creation pricing, research tools, business features, productivity plans, GMind AI plans"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://gmind.ai/pricing" />
          <meta
            property="og:title"
            content="GMind AI Pricing | Affordable Plans for Every Professional"
          />
          <meta
            property="og:description"
            content="Choose a plan that fits your needs and access powerful content creation, research, and business features. Boost efficiency with affordable options tailored for professionals."
          />
          <meta
            property="og:image"
            content="https://gmind.ai/images/pricing-og-image.jpg"
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://gmind.ai/pricing" />
          <meta
            property="twitter:title"
            content="GMind AI Pricing | Affordable Plans for Every Professional"
          />
          <meta
            property="twitter:description"
            content="Choose a plan that fits your needs and access powerful content creation, research, and business features. Boost efficiency with affordable options tailored for professionals."
          />
          <meta
            property="twitter:image"
            content="https://gmind.ai/images/pricing-twitter-image.jpg"
          />

          <link rel="canonical" href="https://gmind.ai/pricing" />

          <meta name="robots" content="index, follow" />

          <script type="application/ld+json">
            {JSON.stringify(pricingData)}
          </script>
        </Helmet>

        {/* Top-right controls */}
        <div className="mr-5 self-end flex flex-wrap sm:flex-nowrap justify-center sm:space-x-5 items-end pt-3 sm:pt-0 3xl:mr-10 3xl:pt-6">
          {/* <CurrencyDropDown onValueChange={handleCurrencyChange} /> */}
        </div>

        {/* Header (hidden if user is logged in) */}
        {/* <div
          className="w-full bg-[#001F73]"
          style={{ display: localStorage.getItem('token') ? 'none' : 'flex' }}
        >
          <Header
            onClickFaQ={() => navigate('/')}
            onClickFeature={() =>
              isFeatures ? scrollToSection(featuresRef) : navigate('/features')
            }
            onClickPricing={() => navigate('/pricing')}
            isLoggedIn={false}
            isPromo={false}
            showHeader={true}
          />
        </div> */}

        {/* Promo Header */}
        {!visible && (
          <PromoHeader closeClick={() => setVisible(true)} showClose={true} />
        )}

        {/* Hero section */}
        <div className="bg-pricing-hero pb-0 w-full flex justify-center items-center 3xl:pb-4">
          <div
            className="
          flex flex-col justify-center items-center
          max-w-[21.5rem] sm:max-w-[31.5rem] 3xl:max-w-[45rem]
          text-center space-y-1 py-[0.1rem]
        "
          >
            <p className="text-[1.8rem] font-medium text-primary 3xl:text-[2.2rem]">
              Affordable pricing
            </p>
            <h3 className="text-[2rem] font-medium text-primary 3xl:text-[2.4rem]">
              Choose the best GMIND AI plan
            </h3>
            <p className="text-[1rem] text-primary-light 3xl:text-[1.1rem]">
              We offer three comprehensive packages to cater to your needs:
              <br /> Basic, Premium, and Supported/Partners.
            </p>
          </div>
        </div>

        {/* Pricing switch */}
        <div className="flex flex-wrap sm:flex-nowrap justify-center sm:space-x-8 items-end pt-8 sm:pt-0 3xl:pt-10">
          <PricingSwitch onSwitch={togglePricingPeriod} />
        </div>

        {/* Pricing cards */}
        <div className="py-14 px-5 sm:px-0 flex flex-col 3xl:py-20 3xl:px-10">
          <section
            className="
          flex flex-col sm:flex-row sm:flex-wrap
          justify-center gap-8 mt-5 3xl:gap-12
        "
          >
            {plans.map((plan, index) => {
              return (
                (selectedCurrency === 'usd' && index > 0) ||
                selectedCurrency === 'ngn'
              ) && (
                  <PricingCard
                    key={plan.title}
                    {...plan}
                    pricingPeriod={pricingPeriod}
                    selectedCurrency={selectedCurrency}
                    isPromo={isPromo}
                  />
                );
            })}
          </section>

          {/* Request Pricing button or link */}
          {/* <div className="flex flex-row justify-center pt-10 3xl:pt-12">
            <RequestPricing />
          </div> */}
        </div>
        <Footer />
      </div>
  );
}

import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import React from 'react';
import { AcquisitionRoutes } from './acquisitionRouter.tsx';
import { affiliateRoutes } from './afilliateRouter.tsx';
import { chatDashboardRoutes } from './chatdashboardRouter.tsx';


const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            {
              chatDashboardRoutes()
            }
            {
              AcquisitionRoutes()
            },
            {
              affiliateRoutes()
            }
            
        </>,
    ),
);

export default router;


{/* <Route
                path={'/'}
                element={
                    <Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <DashboardFrame />
                    </Suspense>
                }
                errorElement={
                    <Suspense
                        fallback={
                            <div>
                                <Loading />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>
                }
            >
                <Route
                    path="chat"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <Chat />
                        </Suspense>
                    }
                />
                <Route
                    path="smart_chat"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <SmartSearchPage />
                        </Suspense>
                    }
                />
                <Route
                    path="writer_genie"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <Generate />
                        </Suspense>
                    }
                />
                <Route
                    path="assessment"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <Assessment />
                        </Suspense>
                    }
                />
                <Route
                    path="article"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <ArticleWriter />
                        </Suspense>
                    }
                />
                <Route
                    path="article/editor"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <EditorPage />
                        </Suspense>
                    }
                />
                <Route
                    path="curriculum"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <Curriculum />
                        </Suspense>
                    }
                />
                <Route
                    path="keyword_researcher"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <KeywordResearcher />
                        </Suspense>
                    }
                />

                <Route
                    path="cover_letter_writer"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <CoverLetterWritter />
                        </Suspense>
                    }
                />

                <Route
                    path="interview_prep"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <InterviewPrep />
                        </Suspense>
                    }
                />
                <Route
                    path="speech_synthesizer"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <Speech />
                        </Suspense>
                    }
                />
                <Route
                    path="video_mode"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <ComingSoonPage />
                        </Suspense>
                    }
                />
                <Route
                    path="image_maker"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <Image />
                        </Suspense>
                    }
                />
                <Route
                    path="image_maker/edit"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <ImageEditor />
                        </Suspense>
                    }
                />
                <Route
                    path="flow_chart"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <FlowChartAnalyzer />
                        </Suspense>
                    }
                />
                <Route
                    path="research"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <ResearchWriter />
                        </Suspense>
                    }
                />
                <Route
                    path="educator_resources"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <SchoolLetters />
                        </Suspense>
                    }
                />
                <Route
                    path="product_description"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <ProductDescriptionGenerator />
                        </Suspense>
                    }
                />
                <Route
                    path="media_resources"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <MediaResources />
                        </Suspense>
                    }
                />
                <Route
                    path="business_resources"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <BusinessResources />
                        </Suspense>
                    }
                />
                <Route
                    path="meta_tag"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <MetaTagGenerator />
                        </Suspense>
                    }
                />
                <Route
                    path="school-admin"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <SchoolAdmin />
                        </Suspense>
                    }
                />
                <Route
                    path="corporate-admin"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <CorporateAdmin />
                        </Suspense>
                    }
                />
                <Route
                    path="corporate-admin/staff"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <CorporateAdminStaff />
                        </Suspense>
                    }
                />
                <Route
                    path="corporate-admin/staff/add-staff"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <AddStaff />
                        </Suspense>
                    }
                />
                <Route
                    path="corporate-admin/ticket"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <CorporateAdminTicket />
                        </Suspense>
                    }
                />
                <Route
                    path="school-admin/ticket"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <SchoolAdminTicket />
                        </Suspense>
                    }
                />
                <Route
                    path="youtube_transcript"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <YouTubeTranscript />
                        </Suspense>
                    }
                />
                <Route
                    path="school-profile"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <SchoolProfileScreen />
                        </Suspense>
                    }
                />
                <Route
                    path="student_resources"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <SchoolResources />
                        </Suspense>
                    }
                />
                <Route
                    path="school-admin/school-teacher"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <Teachers />
                        </Suspense>
                    }
                />
                <Route
                    path="school-admin/school-teacher/add-teacher"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <AddTeachers />
                        </Suspense>
                    }
                />
                <Route
                    path="pricing"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <DashboardPricing />
                        </Suspense>
                    }
                />
                <Route
                    path="a_plus_essay"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <APlusEssay />
                        </Suspense>
                    }
                />
                <Route
                    path="quiz_maker"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <QuizMaker />
                        </Suspense>
                    }
                />
                <Route
                    path="payment-success"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <SuccessPage />
                        </Suspense>
                    }
                />
                <Route
                    path="profile"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <ProfileScreen />
                        </Suspense>
                    }
                />
                <Route
                    path="visualizer"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <DataVisualizer />
                        </Suspense>
                    }
                />
                <Route
                    path="assignment_helper"
                    element={
                        <Suspense
                            fallback={
                                <div>
                                    <Loading />
                                </div>
                            }
                        >
                            <AssignmentSolver />
                        </Suspense>
                    }
                />
            </Route>, */}

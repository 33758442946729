import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import BackButton from '@components/BackButton';
import { toast } from 'react-toastify';
import useEndpoint from '../../auth/auth_endpoints';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import GoogleSignInComponents from '../../components/google_component/googleSignInComponents';
import GoogleSignUpComponents from '../../components/google_component/googleSignUpComponents ';
import { googleSignIn } from '../../google_auth/google';
import { acquisitionRoutes } from '@src/utils/app_routes';

interface LoginProps {
    handleSignIn: () => void;
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const CreateAcount = ({ handleSignIn }: LoginProps) => {
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refcode, setRefcode] = useState('');
    const [passwordVisibility, setPasswordVisibility] = useState<{ [key: string]: boolean }>({
        password: false,
        confirmPassword: false,
    });

    const [form] = Form.useForm();

    const query = useQuery();
    const navigate = useNavigate();
    const [freeTrial, setFreeTrial] = useState<boolean>(false);

    useEffect(() => {
        console.log('herr');
        const free = query.get('forFree') || '';
        const referrer = query.get('referrer') || '';

        console.log(referrer);


        setRefcode(referrer);

        form.setFieldsValue({
            refcode:  referrer
        });

        setFreeTrial(free == '1' ? true : false);

        // navigate({
        //   pathname: window.location.pathname,
        //   search: ''
        // }, { replace: true });
    }, []);

    useEffect(() => {
        console.log(refcode);
    }, [refcode])

    const handleClickPassVisibleIcon = (key: string) => {
        setPasswordVisibility((prevData) => ({
            ...prevData,
            [key]: !prevData[key],
        }));
    };

    const onFinish = (values: any) => {
        try {
            const { email, firstname, lastname, phonenumber, password, confirmpassword, refcode } = values;

            if (!email || !firstname || !lastname || !phonenumber || !password || !confirmpassword) {
                toast.error('All fields are required');
                return;
            }

            if (password !== confirmpassword) {
                toast.error('Passwords do not match');
                return;
            }

            const dataToSend = {
                firstName: firstname,
                lastName: lastname,
                phonenumber,
                email: email,
                password: password,
                cpassword: confirmpassword,
                freeTrial: 1,
                refcode: refcode,
            };

            console.log(dataToSend);

            setLoading(true);
            useEndpoint()
                .signup(dataToSend)
                .then((res: any) => {
                    setLoading(false);
                    if (res?.success) {
                        localStorage.setItem('token', res?.data?.token);
                        localStorage.removeItem('isLoggedOut');
                        const { id, firstName, lastName, email, isVerified, plan } = res.data;
                        localStorage.setItem('user', JSON.stringify({ id, firstName, lastName, email, isVerified }));
                        const callback = localStorage.getItem('callback') ?? '';

                        console.log(`callback is `, callback);

                        // if (callback) {

                        //     // window.location.href = callback;
                        // } else {
                        window.location.href = acquisitionRoutes.verify + '?email=' + email;
                        // }
                        toast.success(res?.message);
                    } else {
                        toast.error(res?.message);
                    }
                });

            console.log(email);
        } catch (e) {
            console.log(e);
        }
    };
    const handleSignUp = async () =>  {
        // console.log(`this is called`)
        const result = await googleSignIn();
        
    };

    return (
        <div className="create-account-container  gap-y-3 w-full  md:w-[88%] py-0 px-4 md:px-4">
            <BackButton />
            <div className="ctitle text-[1.5rem] md:text-[2.5rem] mb-3">Create Account</div>
            <Form layout="vertical" onFinish={onFinish} form={form}>
                <div className="w-full flex flex-1 flex-col mb-3   justify-center">
                    <GoogleSignUpComponents handleSignUp={handleSignUp} />
                </div>
                <div className="flex flex-col md:flex-row md:gap-x-5 md:gap-y-2">
                    <Form.Item
                        name="firstname"
                        label={<label className="label">First Name</label>}
                        rules={[
                            {
                                message: 'First name is required',
                                required: true,
                            },
                        ]}
                        initialValue=""
                        className="w-full md:w-1/2"
                    >
                        <input className="px-5 w-full border border-gray-300 rounded-md h-10" type="text" />
                    </Form.Item>
                    <Form.Item
                        name="lastname"
                        label={<label className="label">Last Name</label>}
                        rules={[
                            {
                                message: 'Last name is required',
                                required: true,
                            },
                        ]}
                        initialValue=""
                        className="w-full md:w-1/2"
                    >
                        <input className="px-5 w-full border border-gray-300 rounded-md h-10" type="text" />
                    </Form.Item>
                </div>
                <div className="flex flex-col md:flex-row md:gap-x-5 md:gap-y-2">
                    <Form.Item
                        name="phonenumber"
                        label={<label className="label">Phone Number</label>}
                        rules={[
                            {
                                message: 'Phone number is required',
                                required: true,
                            },
                        ]}
                        initialValue=""
                        className="w-full md:w-1/2"
                    >
                        <input className="px-5 w-full border border-gray-300 rounded-md h-10" type="text" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={<label className="label">Email</label>}
                        rules={[
                            {
                                message: 'Email is required',
                                required: true,
                            },
                        ]}
                        initialValue=""
                        className="w-full md:w-1/2"
                    >
                        <input className="px-5 w-full border border-gray-300 rounded-md h-10" type="email" />
                    </Form.Item>
                </div>
                <Form.Item
                    name="password"
                    label={<label className="label">Set Password</label>}
                    rules={[
                        {
                            message: 'Password is required',
                            required: true,
                        },
                    ]}
                    initialValue=""
                >
                    <div className="flex items-center relative">
                        <input
                            className="px-5 w-full border border-gray-300 rounded-md h-10"
                            type={passwordVisibility['password'] ? 'text' : 'password'}
                        />
                        <span
                            onClick={() => handleClickPassVisibleIcon('password')}
                            className="cursor-pointer absolute right-4"
                        >
                            {passwordVisibility['password'] ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </span>
                    </div>
                </Form.Item>
                <Form.Item
                    name="confirmpassword"
                    label={<label className="label">Confirm Password</label>}
                    rules={[
                        {
                            message: 'Confirm password is required',
                            required: true,
                        },
                    ]}
                    initialValue=""
                >
                    <div className="flex items-center relative">
                        <input
                            className="px-5 w-full border border-gray-300 rounded-md h-10"
                            type={passwordVisibility['confirmPassword'] ? 'text' : 'password'}
                        />
                        <span
                            onClick={() => handleClickPassVisibleIcon('confirmPassword')}
                            className="cursor-pointer absolute right-4"
                        >
                            {passwordVisibility['confirmPassword'] ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </span>
                    </div>
                </Form.Item>
                <Form.Item
                    name="refcode"
                    label={<label className="label">Referral Code ( Optional ) </label>}
                    initialValue={refcode}
                >
                    <input
                        value={refcode}
                        onChange={(e) => setRefcode(e.target.value)}
                        className="px-5 w-full border border-gray-300 rounded-md h-10"
                        type={'text'}
                    />
                    
                </Form.Item>
                <div
                    className="block md:hidden"
                    style={{
                        fontSize: '13px',
                        fontWeight: '400',
                        fontFamily: "'Inter', sans-serif",
                        width: '218px',
                    }}
                >
                    Already have an account?{' '}
                    <span style={{ color: '#e55109', cursor: 'pointer' }} onClick={handleSignIn}>
                        Sign In
                    </span>
                </div>
                <button
                    type="submit"
                    className="create-account px-5 w-full md:w-40 py-2 bg-orange-500 text-white rounded-md mb-3 md:mb-0"
                >
                    Create Account
                </button>

                <div className="flex flex-row mt-2 mb-3 justify-start">
                    <div
                        className="hidden md:block flex flex-row mt-3 justify-center gap-1"
                        style={{
                            fontSize: '13px',
                            fontWeight: '400',
                            fontFamily: "'Inter', sans-serif",
                            width: '218px',
                        }}
                    >
                        Already have an account?{' '}
                        <span style={{ color: '#e55109', cursor: 'pointer' }} onClick={handleSignIn}>
                            Sign In
                        </span>
                    </div>
                </div>
            </Form>
        </div>
    );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import LoadingComponent from '@src/components/LoadingComponent';
import { ChatType } from '@src/core/interfaces/chat';
import { handleAddHistory } from '@src/pages/dashboard/endpoints';
import { prompts } from '@src/utils/prompts';
import { Checkbox, Form, Input } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import MarkdownDesign from '@src/components/Markdown';
import Like from '@public/like.svg';
import DisLike from '@public/dislike.svg';
import Message from '@public/message-circle-2.svg';
import Regenerate from '@assets/repeat.svg';
import Profile from '@assets/user-circle.svg';
import { contentPromptOptionsData2 } from '../../constants';
import { openEditor } from '@src/utils/app_functions';
import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { InputTypeProps } from '../../../assessment_2/interface';
import { gradeOptions, socialMedias } from '../../../curriculum/constants';
import { BASE_URL } from '@src/utils/constants';
import { Chats } from '@src/pages/dashboard/components/interface/interface';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import NewEditorPage from '../../../essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '../../../essay/sun_editor copy';
import NewContentEditorPage from '../../../essay/editor/newInstanceOfEditor copy';
import BackButton from '@src/components/BackButton';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Lesson } from '../../components/lesson';
import { ThemeContext } from '@src/theme_provider/theme';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const NewLetterWriter = ({
    response,
    setResponse,
    setCurrentPage,
    setPageNumber,
    setShowSidebar,
    setShowTop,
    pageName,

    setPageName,
    setSubPage,
}: {
    response: string;
    setResponse: any;
    setCurrentPage: any;
    setPageNumber: any;
    setShowSidebar: any;
    setShowTop: any;
    setPageName: any;
    setSubPage: any;
    pageName: string;
}) => {
    const [isTyping, setIsTyping] = useState(false);
    const [isMobileStep, setIsMobileStep] = useState(0);

    const [description, setDescription] = useState<string>('');
    const [prompt, setPrompt] = useState<string>('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const pageHistory = 'contents';
    const [generating, setGenerating] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [navigate, setNavigate] = useState(false);
    const [pageId, setPageId] = useState('');
    const [showRefrence, setShowReference] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<Chats>({ role: 'assistant', content: '' });
    const bottomRef = useRef<HTMLDivElement>(null);
    const [rememberMe, setRememberMe] = useState(false);
    const Document = useRef<any>();
    const [histories, setHistories] = useState<any>([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [topic, setTopic] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const currentEntity = useRef<ChatType>();
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [page, setPage] = useState<string>('');
    const [pageClick, setPageClick] = useState<() => void>(() => {});
    const historyCloseId = 'historyClose';
    const [canNavigate, setCanNavigate] = useState(false);
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [editorContent, setEditorContent] = useState<string>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const location = useLocation();
    const { error, loading, fetchDocument, document, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [isEditContent, setIsEditContent] = useState(false);
    // const [response, setResponse] = useState('');
    const [latestRespone, setLatestRespone] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const editorInstanceRef = useRef<any>();
    const { isMobile } = useContext(ThemeContext);
    const grades = gradeOptions();
    const {
        details,
    } = useOutletContext<any>();

    useEffect(() => {
        if (isTyping && response != '') {
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [isTyping, response]);

    const naviagtePage = useNavigate();

    const options = contentPromptOptionsData2({
        response,
        showDownload: () => {},
    });

    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    const onGenerateHandler = async (message: string, humanize = false) => {
        // console.log(isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent,);
        // return;

        toast.info('Please sit tight, your beautiful content is on its way.');

        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message };
        // console.log(msgs);
        // return;
        setChats(msgs);
        setResponse('');
        console.log(description);
        setResponse('');

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: message,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId ?? '',
                },
            });
        } catch (error) {
            //   setIsTyping(false);
        } finally {
            //   setIsTyping(false);
        }
    };

    useEffect(() => {
        console.log(`edit content: ${isEditContent}`);
    }, [isEditContent]);

    useEffect(() => {
        if (response) {
            setEditorContent(response);
        }
    }, [response]);

    useEffect(() => {
        if (!socket) return;
        let tempBuffer = '';

        socket.on('data', (data: string) => {
            // setResponse((response: any) => response + data);

            setResponse((response: any) => response + data);
            console.log(data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;
            // console.log(`-------------------Testing ooo------------------`);

            // const assistantMessage = assistant[assistant.length - 1].content;
            // // setLatestRespone(assistantMessage);
            // setEditorContent(assistantMessage);
            setIsTyping(false);
            toast.success('Content generated successfully');
            setIsEdit(true);

            const uuid = uuidv4();
            DocumentId.current = uuid;
            const user = JSON.parse(localStorage.getItem('user') || '');
            setStreamEndMessage(data.assistant);
            setIsTyping(false);
            setDescription('');
            // if (streamId === StreamId.current) {
            StreamId.current = '';
            socket.emit('get-documents', user?.id);
            setNavigate(true);
            setPageId(uuid);
            // }
        });

        return () => {
            socket.off('message');
        };
    }, [socket, isEditContent]);

    const handleStreamEnd = async (prompt: string, data: Chats) => {
        // let dataToSave: Chats[]  = [
        //     {
        //         role: 'user',
        //         content: prompt,
        //     },
        //     data
        // ]
        let dataToSave = data;

        const promptSent = prompt;

        let docData = {
            content: response,
            doc_id: pageId,
            promptSent: prompt,
            promptType: promptSent,
        };

        await handleAddHistory(dataToSave, pageHistory, docData);
        if (pageId) fetchDocument(pageId ?? '', true);
    };

    useEffect(() => {
        if (!isTyping && response != '') {
            handleStreamEnd(prompt, streamEndMessage);
        }
        // const promptSent = isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent;
        // if (navigate && response != '') {
        //     openEditor({
        //         response,
        //         pageId,
        //         promptSent: prompt,
        //         promptType: promptSent,
        //         navigate: naviagtePage,
        //         pageSocket: socket,
        //     });
        // }
    }, [response, navigate, isTyping, socket]);

    const onFinish = async (value: any) => {
        const { description, letterType, address, wordCount, name } = value;
        if (!description) {
            toast.error('Enter your description');
            return;
        }
        setIsMobileStep(1);

        setIsTyping(true);
        setPrompt(generateLetterPrompt(description, letterType, address, wordCount, name));
        console.log(wordCount);
        console.log(description);
        const message = generateLetterPrompt(description, letterType, address, wordCount, name);
        console.log(message);
        console.log(prompt);

        await onGenerateHandler(generateLetterPrompt(description, letterType, address, wordCount, name));
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const onFinish2 = async (value: any) => {
        const { purpose, description, information, gradeLevel } = value;
        if (!description) {
            toast.error('Enter your description');
            return;
        }

        setIsMobileStep(1);

        setIsTyping(true);

        const message = lessonPrompt(purpose, description, information, gradeLevel);
        setPrompt(lessonPrompt(purpose, description, information, gradeLevel));
        console.log(message);
        console.log(prompt);

        await onGenerateHandler(lessonPrompt(purpose, description, information, gradeLevel));
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };
    const regenerate = async (humanize = false) => {
        let newPrompt = prompt;
        setPrompt(newPrompt);
        await onGenerateHandler(newPrompt, humanize);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    useEffect(() => {
        setShowTop(false);
        setShowSidebar(false);
    }, []);

    return (
        <div className="w-full px-0 md:px-5  xl:pt-3  h-screen overflow-y-scroll lg:h-screen xl:h-screen flex flex-col md:flex-row gap-x-10 gap-y-3">
            <div
                style={{ boxShadow: 'rgba(229, 81, 9, 0.6) 0px 19px 38px, rgba(255, 20, 147, 0.5) 0px 15px 12px' }}
                className={`${isMobile ? (isMobileStep === 0 ? 'flex ' : 'hidden') : 'flex'} bg-white  flex-col gap-y-3 h-auto overflow-y-scroll mb-24 md:mb-0  lg:h-[84%] xl:h-[97%] w-full   xl:w-[30%] rounded-[0.5rem] dark:shadow-none px-10 py-5 shadow-lg relative`}
            >
                {' '}
                <div className="flex flex-row gap-x-2 items-center justify-start">
                    <button
                        className="bg-gray-300 h-6 w-6 rounded-full p-2 flex flex-row gap-x-2 items-center justify-center "
                        onClick={() => {
                            setCurrentPage(0);
                            setPageNumber(0);
                            setShowSidebar(true);
                            setShowTop(true);
                            setResponse('');

                            setPageName('');
                            setSubPage('');
                        }}
                    >
                        {' '}
                        <LeftOutlined />{' '}
                    </button>
                    <span>Back</span>
                </div>

                <div className="flex flex-col overflow-y-scroll ">
                <p className="font-bold  text-[1rem] text-[var(--gmind-light-black)]">{pageName}</p>
                {pageName === 'Letter Writer' ? (
                    <Form layout="vertical" className="flex flex-col flex-wrap " onFinish={onFinish}>
                        {/* Letter Description */}
                        <div className='lg:grid xl:flex xl:flex-col w-full lg:grid-cols-2 gap-3'>
                        <Form.Item
                            className="w-full mb-4"
                            name={'description'}
                            label={<p className="text-black">Description</p>}
                            rules={[
                                {
                                    message: 'This field is required',
                                    required: true,
                                },
                            ]}
                        >

                           
                            <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className="w-full text-black placeholder-gray-700 border  border-gray-500 focus:ring-1 focus:ring-blue-500 "
                                placeholder="Describe your letter (e.g., Write a formal letter to request a job recommendation)"
                                style={{
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                rows={isMobile ? 2 : 3}
                            />
                        </Form.Item>

                        {/* Letter Type */}

                        <Form.Item
                            name="letterType"
                            label={<p className="text-black">Letter Type</p>}
                            rules={[
                                {
                                    message: 'Please select a letter type',
                                    required: true,
                                },
                            ]}
                        >
                            <select className="w-full p-2 border rounded text-black placeholder-gray-700 dark:border border-gray-500 focus:ring-1 focus:ring-blue-500">
                                <option value="formal">Formal</option>
                                <option value="informal">Informal</option>
                                <option value="business">Business</option>
                                <option value="personal">Personal</option>
                            </select>
                        </Form.Item>

                        {/* Tone Selection */}

                        <Form.Item
                            name="name"
                            label={<p className="text-black">Recipent Name</p>}
                            rules={[
                                {
                                    message: 'Please select a tone',
                                    required: true,
                                },
                            ]}
                        >
                            <input
                                className="w-full placeholder-gray-700"
                                placeholder="Enter Recipent Name"
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="address"
                            label={<p className="text-black">Recipent Address</p>}
                            rules={[
                                {
                                    message: 'Please select a tone',
                                    required: true,
                                },
                            ]}
                        >
                            <textarea
                                className="w-full placeholder-gray-700"
                                placeholder="Enter Recipent Address"
                                rows={isMobile ? 2 : 3}
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                            />
                        </Form.Item>

                        {/* Number of Words */}
                        <Form.Item
                            name="wordCount"
                            label={<p className="text-black">Number of Words</p>}
                            rules={[
                                {
                                    message: 'Please specify the number of words',
                                    required: true,
                                    pattern: /^\d+$/, // Ensures only numbers are entered
                                },
                            ]}
                        >
                            <input
                                type="number"
                                min="50"
                                className="w-full p-2 border rounded text-black placeholder-gray-700 dark:border border-gray-500 focus:ring-1 focus:ring-blue-500"
                                placeholder="Enter the number of words (e.g., 100)"
                            />
                        </Form.Item>
                        </div>

                        {/* Submit Button */}
                        <button disabled={isTyping} type="submit" className="mt- 2 text-white transcribe">
                            Generate
                        </button>
                    </Form>
                ) : (
                    <Form
                        layout="vertical"
                        className="flex flex-col md:flex-row flex-wrap "
                        onFinish={onFinish2}
                    >
                        <div className='lg:grid xl:flex xl:flex-col w-full lg:grid-cols-2 gap-3'>
                        <Form.Item
                            className="w-full "
                            name={'purpose'}
                            label={
                                <label className="curriculum--label text-black">
                                    {' '}
                                    What is the subject of the content?
                                </label>
                            }
                            rules={[
                                {
                                    message: 'purpose is required',
                                    required: false,
                                },
                            ]}
                        >
                            <input
                                className="w-full placeholder-gray-700 "
                                placeholder="e.g., Science, History, Literature, Art, Business"
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="text"
                            />
                        </Form.Item>
                        <Form.Item
                            className="w-full "
                            name={'description'}
                            label={<label className="curriculum--label text-black">What is the specific topic?</label>}
                            rules={[
                                {
                                    message: 'description is required',
                                    required: false,
                                },
                            ]}
                        >
                            <input
                                className="w-full placeholder-gray-700"
                                placeholder="e.g., climate change, World War II, social media marketing"
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="text"
                            />
                        </Form.Item>

                        <Form.Item
                            className="w-full"
                            name={'information'}
                            label={
                                <label className="curriculum--label text-black">
                                    {' '}
                                    Additional Information (optional)
                                </label>
                            }
                            rules={[
                                {
                                    message: 'field is required',
                                    required: false,
                                },
                            ]}
                        >
                            <textarea
                                className="w-full placeholder-gray-700"
                                placeholder="e.g Make it comprehensive"
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    height: '64px',
                                    padding: '7px 10px',
                                }}
                                rows={2}
                            />
                        </Form.Item>
                        {/* <Form.Item
                            className="w-full "
                            name={'audience'}
                            label={<label className="curriculum--label text-black">Who is the target audience?</label>}
                            rules={[
                                {
                                    message: 'content target audience is required',
                                    required: false,
                                },
                            ]}
                        >
                            <input
                                className="w-full placeholder-gray-700"
                                placeholder="e.g., Elementary School, High School, College Students, General Public"
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="text"
                            />
                        </Form.Item> */}

                        <Form.Item
                            required={true}
                            label={<p className="text-black">Grade Level</p>}
                            name="gradeLevel"
                            className="w-full"
                        >
                            <select className="p-2  border  border-gray-700 rounded w-full placeholder-gray-700">
                                {grades.map((item, index) => (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </Form.Item>
                        </div>
                        <button type="submit" className="text-white transcribe" disabled={isTyping}>
                            Generate
                        </button>
                    </Form>
                )}
            </div>
            </div>
            <div
                className={`${isMobile ? (isMobileStep === 1 ? 'flex mb-20' : 'hidden') : 'flex'} w-full xl:w-[70%] md:w-auto  overflow-y-scroll  h-[90%] xl:h-[90%] overflow-y-scroll   flex-col gap-y-3 `}
                style={{ boxShadow: 'rgba(229, 81, 9, 0.6) 0px 19px 38px, rgba(255, 20, 147, 0.5) 0px 15px 12px' }}
            >
                {/* {
                        !generate ? (
                            <div className="mt-5 h-full flex-col flex items-center justify-between">
                                <div className="flex flex-col gap-y-3">
                                    <p className="text-center font-medium text-[1.5rem] dark:text-white text-[var(--gmind-black)]">Create Content</p>
                                    <p className="text-center text-gray-400 font-normal">Generate content for course</p>
                                </div>

                                <p className="mb-10 text-center text-[1rem] text-gray-400 font-normal">Enter content description & click Generate to get started</p>
                            </div>
                        ):(
                            <div>
                                {generating ? 
                                    (
                                        <LoadingComponent isMobile={false} width={''} />
                                    ) : (
                                        <MarkdownDesign className="dark:!text-white">{response}</MarkdownDesign>
                                    )
                                }

                            </div>
                        )
                    } */}

                <NewEditorPage
                    setIsMobileStep={setIsMobileStep}
                    isContent={true}
                    regenerate={regenerate}
                    isBack={isMobile ? false : true}
                    latestRespone={latestRespone}
                    setLatestRespone={setLatestRespone}
                    highlightedTexts={highlightedTexts}
                    setHighlightedTexts={setHighlightedTexts}
                    content={
                        <NewSunEditorComponent
                            latestRespone={latestRespone}
                            setLatestRespone={setLatestRespone}
                            highlightedTexts={highlightedTexts}
                            setHighlightedTexts={setHighlightedTexts}
                            isContent={true}
                            editorInstanceRef={editorInstanceRef}
                            // humanizeded_content={Document.current?.data.ops}
                            // hasAccess={hasAccess}
                            hasAccess={(documentResponse?.role ?? '') !== 'view' ? true : false && !isTyping}
                            initial_content={response}
                            onChangeEditorContent={(e: string) => {
                                setEditorContent(e);

                                const newtext = e;
                                // // console.log('newtext', newtext);
                                // console.log('docId', docId);
                                // console.log('userDetails?.userId', userDetails?.userId);
                                // sendChanges(docId, userDetails?.userId, e);
                            }}
                            contentHeight={true}
                            // setHighlightedTexts={setHighlightedTexts}
                            // highlightedTexts={highlightedTexts}
                            Response={response}
                            setResponse={setResponse}
                            setIsEditContent={(e) => {
                                setIsEditContent(e);
                            }}
                            contentIsTyping={isTyping}
                            editorContent={editorContent}
                            setEditorContent={setEditorContent}
                        />
                    }
                    showTopNavBar={false}
                    showInvite={documentResponse?.owner ?? false}
                    isTyping={isTyping}
                    Profile={Profile}
                    setGenerate={setGenerate}
                    generate={generate}
                    setEditorContent={setEditorContent}
                    editorContent={editorContent}
                    translatedContent={translatedContent}
                    setTranslatedContent={setTranslatedContent}
                    humanizedContent={humanizedContent}
                    setHumanizedContent={setHumanizedContent}
                    setUserOnDoc={setUserOnDoc}
                    userOnDoc={userOnDoc}
                    editorInstanceRef={editorInstanceRef}
                />

                {/* <div id="controls" className="w-full flex items-center justify-between">
                    <div className="flex gap-x-5 w-[50%] justify-start">
                        <div role="button" onClick={() => regenerate(true)} className="flex items-center">
                            <img src={Profile} alt="" />
                            <p>Humanize</p>
                        </div>
                        {options.map((option, index) => (
                            <div
                                key={index}
                                onClick={option.onClick}
                                className="rounded-[1rem] px-[1rem] py-[0.3rem] bg-[var(--gmind-header)] flex gap-x-3 cursor-pointer"
                            >
                                <img className='w-[1rem] h-[1rem]' src={option.icon} alt="" />
                                <div className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]">
                                    {option.name}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex gap-x-5 w-[50%] justify-end">
                        <div
                            className="hidden md:flex items-center flex-row"
                            style={{ cursor: 'pointer' }}
                            onClick={() => regenerate()}
                        >
                            <img src={Regenerate} alt="" />{' '}
                            <span
                                className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]"
                                style={{ marginLeft: '2px' }}
                            >
                                Regenerate
                            </span>
                        </div>
                        <img style={{ cursor: 'pointer' }} onClick={() => {}} src={Message} alt="" />
                        <img style={{ cursor: 'pointer' }} onClick={() => {}} src={Like} alt="" />
                        <img
                            style={{ cursor: 'pointer', marginRight: '20px' }}
                            onClick={()=> {}}
                            src={DisLike}
                            alt=""
                        />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default NewLetterWriter;

const generateLetterPrompt = (
    description: string,
    letterType: string,
    tone: string,
    wordCount: number,
    name: string,
): string => {
    return `
    Generate a letter based on the following specifications:

    **Description**: "${description}"
    **Letter Type**: "${letterType}"
    **Recipient Address**: Include the recipient's address at the beginning.
    **Recipient Name**: "${name}"
    **Tone**: "${tone}"
    **Word Count**: Approximately ${wordCount} words.
    **Date**: Include the current date in the letter.

    **Guidelines**:
    - Match the specified letter type (e.g., formal, informal, business, or personal).
    - Maintain the specified tone throughout the letter (e.g., persuasive, friendly, empathetic, neutral, or formal).
    - Ensure the letter is concise, clear, and engaging, avoiding unnecessary fluff.
    - Include a proper salutation (e.g., "Dear [Recipient's Name],").
    - Include the recipient's address and name at the beginning (in separate lines).
    - Provide a clear introduction, body, and closing.
    - Use an appropriate closing phrase (e.g., "Sincerely," or "Best regards,").
    - Include the sender's name and contact information at the end.
    - Avoid using HTML tags, special formatting, or citations in the letter.

    **Formatting**:
    - The address and date should appear at the top of the letter.
    - Use separate lines for each section, such as addresses, salutation, and content.
    - Example format for the address and date:

        123 Main Street  
        New York, NY 10001  
        January 1, 2025  

        Ms. Jane Doe  
        Director of Operations  
        Acme Corporation  
        456 Elm Street  
        Los Angeles, CA 90001  

    **Note**: Provide only the letter content without any metadata, headers, or additional explanations.
    `;
};


const lessonPrompt = (purpose: string, description: string, information: string, audience: string): string => {
    return `
Create a lesson Material based on the following details:

1. **Subject of Content:** ${purpose || 'N/A'}
2. **Specific Topic:** ${description || 'N/A'}
3. **Additional Information:** ${information || 'N/A'}
4. **Grade Level:** ${audience || 'N/A'}

The lesson material should include an introduction, key concepts, engaging activities, and a conclusion tailored to the target audience. Make it concise, easy to follow, and include any additional considerations provided in the details.
`;
};

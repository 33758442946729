/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import logoWhite from '@assets/essay/Gmind Logo White.png';
import logo from '@assets/gmind.png';
import avatar from '@assets/default_user.png';
import collapsedLogo from "@assets/essay/GMind_Icon_Orange_compressed.png"
import collapse from '@assets/collapse.svg';
import Option from './option';
import ask from '@icons/brand-hipchat.svg';
import notes from '@assets/notes.svg';
import layout_grid from '@assets/layout-grid.svg';
import bookIcon from '@icons/book-svgrepo.svg';
import slideshow from '@assets/slideshow.svg';
import writing from '@assets/writing-sign.svg';
import image from '@assets/picture-in-picture.svg';
import transcriber from '@assets/transcriber.svg';
import activity from '@assets/activity.svg';
import speakerPhone from '@icons/speakerphone.svg';
import videoPlus from '@icons/video-plus.svg';
import { IoMdLogOut } from 'react-icons/io';
import classR from '@assets/essay/noun-educational-resources-41336.png';
import lectureR from '@assets/essay/resource.png';
import researchR from '@assets/essay/study.png';

import { RxCross2 } from 'react-icons/rx';
import { ReactNode, useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router';
import {
    AssessementImage,
    ContentImage,
    CurriculumImage,
    ImageGeneratorImage,
    SchoolLettersImage,
    SlideImage,
    SpeechImage,
    TranscriberImage,
    VideoMakerImage,
} from './images';
import { getUrlPath } from '@src/utils/app_functions';
import { UserDetailsData } from '@src/core/interfaces/user';
import { useUserContext } from '@src/context/UseProvider';
import { GlobalOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu, Switch } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon1, { Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8, Icon9 } from './components/IconsSvg';
import { Icon } from 'lucide-react';
import globalIcon from '@icons/world.png';

import './sidebar.scss';
import { ThemeContext } from '@src/theme_provider/theme';
import { CgDarkMode } from 'react-icons/cg';
import { MdDarkMode } from 'react-icons/md';
import Collapse from '@src/pages/dashboard/svgs/collapse';
import { affiliateRoutePages, affiliateRoutes, chatRoutes } from '@src/utils/app_routes';
import { chatRoutePages } from '../../utils/app_routes';
import { GiUpgrade } from 'react-icons/gi';

type OptionType = {
    icon: JSX.Element;
    text: string;
    label: string;
    comingSoon?: boolean;
    element?: JSX.Element;
    children?: OptionType[];
};

const optionsValue = (isSelected: boolean, selectedLabel: string): OptionType[] => [
    // { icon: <Icon1 isSelected={isSelected} />, text: 'G-MInd Chaterbot', label: 'chat' },
    { icon: <Icon1 isSelected={isSelected} />, text: 'G-MInd Chat', label: chatRoutePages.chat },
    { icon: <GlobalOutlined className="dark:text-white" />, text: 'Smart Search', label: chatRoutePages.smartChat },
    // {
    //     icon: <Icon3 isSelected={isSelected} />,
    //     text: 'Curriculum Planner',
    //     label: chatRoutePages.curriculum,
    //     element: CurriculumImage(),
    // },
    {
        icon: <Icon4 isSelected={isSelected} />,
        text: 'WriterGenie',
        label: chatRoutePages.writerGenie,
        element: ContentImage(),
    },
    {
        icon: <Icon2 isSelected={isSelected} />,
        text: 'Resources',
        label: 'resources',
        children: [
            {
                icon: <Icon8 isSelected={isSelected} />,
                text: 'Student Resources',
                label: chatRoutePages.studentResources,
            },
            {
                icon: <Icon7 isSelected={isSelected} />,
                text: 'Educator Resources',
                label: chatRoutePages.educatorResources,
            },

            {
                icon: <Icon6 isSelected={isSelected} />,
                text: 'Business Resources',
                label: chatRoutePages.businessResources,
            },
            {
                icon: <Icon5 isSelected={isSelected} />,
                text: 'Media Resources',
                label: chatRoutePages.mediaResources,
            },
        ],
    },
    {
        icon: <MdDarkMode className="hover:text-none" />,
        text: 'Dark Mode',
        label: 'dark_mode',
        comingSoon: false,
        element: SpeechImage(),
    },

    // {
    //     icon: <Icon9 isSelected={isSelected} />,
    //     text: 'Video Mode ',
    //     label: 'video_mode',
    //     comingSoon: true,
    //     element: VideoMakerImage(),
    // },
];
const affiliateValue = (isSelected: boolean, selectedLabel: string): OptionType[] => [
    { icon: <Icon1 isSelected={isSelected} />, text: 'Dashboard', label: affiliateRoutePages.dashboard },
    {
        icon: <Icon2 isSelected={isSelected} />,
        text: 'Referrals',
        label: affiliateRoutePages.referrals,
        element: CurriculumImage(),
    },
    {
        icon: <Icon3 isSelected={isSelected} />,
        text: 'Transactions',
        label: affiliateRoutePages.transactions,
        element: ContentImage(),
    },

    { icon: <Icon5 isSelected={isSelected} />, text: 'Log Out', label: 'chat', element: SlideImage() },
];

const Sidebar = React.memo(
    ({
        selected,
        className,
        style,
        onMobileClose,
        collapsed,
        isMobile,
        userIsAffiliate,
        hasPendingAffiliateRequest,
        onBecomeAffiliate,
        isDarkMode,
        fetchPage,
        canShowAffiliate,
        onReturnToChat,
        isGuest,
        isSmartSearch,
    }: {
        selected: (v: string) => void;
        className?: string;
        style?: React.CSSProperties;
        collapsed?: (v: boolean) => void;
        onMobileClose?: () => void;
        isMobile?: boolean;
        userIsAffiliate?: boolean;
        hasPendingAffiliateRequest?: boolean;
        onBecomeAffiliate?: () => void;
        isDarkMode?: boolean;
        fetchPage?: () => void;
        canShowAffiliate: boolean;
        onReturnToChat?: () => void;
        isGuest?: boolean;
        isSmartSearch?: boolean;
    }) => {
        const user = JSON.parse(localStorage.getItem('user') ?? '{}');
        const [isCollapsed, setCollapsed] = useState(false);
        const navigate = useNavigate();
        const [userOptions, setUserOptions] = useState(false);
        const [selectedOption, setSelectedOption] = useState<string>('');
        const { userDetails } = useUserContext();
        const [openKeys, setOpenKeys] = useState<string[]>([]);
        const [affiliate, setAffiliate] = useState('Become an Affiliate');
        const [isAffiliate, setIsAffiliate] = useState(false);
        const [open,setOpen]= useState(true);
        const [isSelected, setIsSelected] = useState<{ [key: string]: boolean }>(() => {
            // Get saved isSelected state from localStorage when component mounts
            const savedSelection = localStorage.getItem('isSelected');
            
            return savedSelection
                ? JSON.parse(savedSelection)
                : {
                      chat: false,
                      curriculum: false,
                      generate_content: false,
                      class_resources: false,
                      business_resources: false,
                      media_resources: false,
                      speech_synthesizer: false,
                      image_maker: false,
                      video_mode: false,
                      dashboard: false,
                      referrals: false,
                      transactions: false,
                  };
        });
        const [darkMode, setDarkMode] = useState(false);

        const toggleDarkMode = () => {
            setDarkMode(!darkMode);
            if (!darkMode) {
                document.documentElement.classList.add('dark');
                localStorage.setItem('theme', 'dark'); // Save user's preference
            } else {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('theme', 'light'); // Save user's preference
            }
        };

        const [isDark, setIsDark] = useState<{ [key: string]: boolean }>({
            chat: false,
            curriculum: false,
            generate_content: false,
            class_resources: false,
            business_resources: false,
            media_resources: false,
            speech_synthesizer: false,
            image_maker: false,
            video_mode: false,
            dashboard: false,
            referrals: false,
            transactions: false,
        });

        const [showBecomeAnAffiliate, setShowBecomeAnAffiliate] = useState<boolean>(false);
        const [showPendingAffiliate, setShowPendingAffiliate] = useState<boolean>(false);
        const path = window.location.pathname;

        const { savedTheme, toggleTheme } = useContext(ThemeContext);

        useEffect(() => {
            if (savedTheme === 'dark') {
                document.documentElement.classList.add('dark');
            }
        }, [savedTheme]);
        useEffect(() => {
            setIsDark({
                chat: true,
                curriculum: true,
                generate_content: true,
                class_resources: true,
                business_resources: true,
                media_resources: true,
                speech_synthesizer: true,
                image_maker: true,
                video_mode: true,
                dashboard: true,
                referrals: true,
                transactions: true,
            });
        }, [savedTheme]);

        useEffect(() => {
            setShowBecomeAnAffiliate(userIsAffiliate ?? false);
            setShowPendingAffiliate(hasPendingAffiliateRequest ?? false);
        }, [userIsAffiliate, hasPendingAffiliateRequest]);

        const handleAffiliate = () => {
            setIsAffiliate(true);
        };

        const openAffiliateModal = () => {
            if (onBecomeAffiliate) {
                onBecomeAffiliate();
            }
        };

        useEffect(() => {
            if (path.includes('affiliate')) {
                setIsAffiliate(true);
            } else {
                setIsAffiliate(false);
            }
        }, [path]);

        const handleSelect = useCallback(
            (label: string) => {
                fetchPage?.();

                setSelectedOption(label);
                selected(label);
                console.log(label);
                const pagePath = getUrlPath();
                if (pagePath.folder === 'affiliate' && label === "chat") {
                    if (onReturnToChat) onReturnToChat();;
                    navigate(chatRoutes.chat);
                }else if ( pagePath.folder === 'affiliate' && label != "chat" ) {
                    navigate(`/affiliate/${label}`);
                } else {
                    navigate(`/${label}`);
                }


                const parentKey = optionsValue(savedTheme === 'dark', selectedOption).find((option) =>
                    option.children?.some((child) => child.label === label),
                )?.label;
                if (parentKey && !openKeys.includes(parentKey)) {
                    setOpenKeys([...openKeys, parentKey]);
                }
                // if (label === 'chat') {
                //     localStorage.removeItem('activeChat');
                // }
            },
            [selected, openKeys, selectedOption, isSelected],
        );

        useEffect(() => {
            const savedOpenKeys = localStorage.getItem('openKeys');
            if (savedOpenKeys) {
                setOpenKeys(JSON.parse(savedOpenKeys));
            }
        }, []);

        const handleOpenChange = (keys: string[]) => {
            setOpenKeys(keys); // Update the open keys state
            localStorage.setItem('openKeys', JSON.stringify(keys)); // Save open keys to localStorage
        };

        useEffect(() => {
            const { pathname } = window.location;
            const selected = pathname.split('/').slice(-1);
            setSelectedOption(selected[0]);
            fetchPage?.();
        }, [window.location.pathname]);

        // useEffect(() => {
        //     if (window.innerWidth <= 820 && !isMobile) {
        //         setCollapsed(true);
        //     }
        // }, [window.innerWidth]);

        return (
            <nav
                style={style}
                className={
                    `relative transition-all bg-custom-opacity dark:bg-[#1a1a1a] flex flex-col  px-0 pb-0 ${!isCollapsed ? ' shadow-md border-t-0  h-full sidebar ' : ' px-3 h-auto '} ` +
                    className
                }
            >
                <div
                    style={isCollapsed ? { justifyContent: 'center' } : { justifyContent: 'space-between ' }}
                    className="flex px-6 py-5 h-16 items-center bg-transparent  relative "
                >
                    {!isCollapsed && (
                        <img
                            onClick={() => {
                                navigate(chatRoutes.chat);
                            }}
                            role="button"
                            src={savedTheme === 'dark' ? logoWhite : logo}
                            className="w-[8rem]"
                        />
                    )}
                       {isCollapsed && !isMobile && (
                        <img
                            onClick={() => {
                                navigate(chatRoutes.chat);
                            }}
                            role="button"
                            src={collapsedLogo}
                            className="mr-4 w-6"
                        />
                    )}
                    {isMobile && (
                        <RxCross2
                            role="button"
                            onClick={onMobileClose}
                            className="absolute text-2xl top-0 right-5 text-[#48474b]"
                        />
                    )}
                    <div
                        role="button"
                        onClick={() => {
                            setCollapsed(!isCollapsed);
                            if (collapsed) {
                                collapsed(!isCollapsed);
                            }
                        }}
                    >
                        {!isMobile && (
                            <Collapse className="max-md:hidden w-[1.5rem]" isDarkMode={savedTheme === 'dark'} />
                            // <img
                            //     style={isCollapsed ? { width: '1rem' } : {}}
                            //     role="button"
                            //     src={collapse}
                            //     className="max-md:hidden w-[1.5rem]"
                            // />
                        )}
                    </div>
                 
                </div>

                {!isAffiliate && !isCollapsed && (
                    <div className="mt-6 relative px-4 h-full">
                        <Menu
                            mode="inline"
                            selectedKeys={[selectedOption]}
                            openKeys={['Resources']} // Control open submenus
                            onOpenChange={()=>{
                                setOpen(!open);
                            }}
                            
                            className="mt-6  bg-defaultWhiteLighter"
                            style={{
                                fontSize: '12.8px',
                                height: '85%',
                                borderRight: 0,
                                backgroundColor: 'transparent',
                                padding: '0px',
                                margin: '0px',
                            }}
                        >
                            {optionsValue(savedTheme === 'dark', selectedOption).map(
                                ({
                                    icon,
                                    text,
                                    label,
                                    children,
                                }: {
                                    icon: JSX.Element;
                                    text: string;
                                    label: string;
                                    children?: Array<{ icon: JSX.Element; text: string; label: string }>;
                                }) =>
                                    children && children.length > 0 ? (
                                        <Menu.SubMenu
                                            key={open ?'Resources': ''}
                                            icon={icon}
                                            
                                            title={
                                                <span className="ml-1 text-black  hover:text-none  dark:text-white text-[12.8px]">
                                                    {!isCollapsed && text}
                                                </span>
                                            }
                                        >
                                            {children.map(
                                                ({ icon: childIcon, text: childText, label: childLabel }, index) => (
                                                    <Menu.Item
                                                        key={childLabel}
                                                        icon={childIcon}
                                                        className="text-[#000] dark:text-white !no-hover !dark:hover:text-white "
                                                        onClick={() => fetchPage?.()}
                                                        style={{
                                                            backgroundColor:
                                                                selectedOption === childLabel
                                                                    ? savedTheme === 'dark'
                                                                        ? 'rgba(240, 239, 242, 0.4)'
                                                                        : '#dad9df'
                                                                    : 'transparent',
                                                        }}
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                handleSelect(childLabel);
                                                            }}
                                                            className="ml-1 !no-hover !dark:hover:text-white"
                                                        >
                                                            {' '}
                                                            {!isCollapsed && childText}
                                                        </div>
                                                    </Menu.Item>
                                                ),
                                            )}
                                        </Menu.SubMenu>
                                    ) : (
                                        <Menu.Item
                                            key={label}
                                            icon={icon}
                                            onClick={() => fetchPage?.()}
                                            style={{
                                                paddingRight: '0px',
                                                backgroundColor:
                                                    selectedOption === label
                                                        ? savedTheme === 'dark'
                                                            ? 'rgba(240, 239, 242, 0.4)'
                                                            : '#dad9df'
                                                        : 'transparent',
                                            }}
                                            className={`${label === chatRoutePages.chat && savedTheme === 'dark' ? ' dark text-[#001F73]  dark:text-white   ' : 'text-[#000] dark:text-white  '}  flex relative ] !no-hover items-center gap-2 `}
                                        >
                                            {!isCollapsed && label !== 'dark_mode' && (
                                                <div
                                                    onClick={() => {
                                                        if (label !== 'dark_mode') {
                                                            handleSelect(label);
                                                        }
                                                    }}
                                                    className="flex  items-center gap-3 no-hover !bg-transparent "
                                                >
                                                    {text}
                                                    <span className="" style={{ zIndex: 400 }}>
                                                        {' '}
                                                        {label === 'video_mode' && (
                                                            <div
                                                                style={{}}
                                                                className="   overflow-hidden h-6 font-bold text-[0.5rem] text-[#00027d] text-center flex flex-col p-2 justify-center rounded-[3rem] bg-[#dad9df]"
                                                            >
                                                                Coming soon
                                                            </div>
                                                        )}
                                                    </span>

                                                    <span>
                                                        {' '}
                                                        {label === 'dark_mode' && (
                                                            <Switch
                                                                checked={savedTheme === 'dark'} // Reflects the current mode
                                                                onChange={toggleTheme} // Toggles mode on change
                                                                checkedChildren="Dark" // Label when switch is ON
                                                                unCheckedChildren="Light" // Label when switch is OFF
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            )}

                                            {!isCollapsed && label === 'dark_mode' && (
                                                <div className="flex  items-center gap-3 !hover:text-[#000] !bg-transparent !dark:hover:text-white">
                                                    {text}

                                                    <span>
                                                        {' '}
                                                        {label === 'dark_mode' && (
                                                            <Switch
                                                                className="bg-customOrange"
                                                                checked={savedTheme === 'dark'} // Reflects the current mode
                                                                onChange={toggleTheme} // Toggles mode on change
                                                                checkedChildren="Dark" // Label when switch is ON
                                                                unCheckedChildren="Light" // Label when switch is OFF
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    ),
                            )}
                        </Menu>
                    </div>
                )}

                {isAffiliate && path.includes('affiliate') && (
                    <div className="mt-6 mx-5 h-full">
                        <Menu
                            mode="inline"
                            selectedKeys={[selectedOption]}
                            openKeys={openKeys} // Control open submenus
                            onOpenChange={handleOpenChange}
                            className="mt-6"
                            style={{
                                fontSize: '12.8px',
                                height: '85%',
                                overflowY: 'scroll',
                                borderRight: 0,
                                scrollbarWidth: 'none',
                                backgroundColor: 'transparent',
                            }}
                        >
                            {affiliateValue(savedTheme === 'dark', selectedOption).map(({ icon, text, label }) => (
                                <Menu.Item
                                    key={label}
                                    icon={icon}
                                    onClick={() => handleSelect(label)}
                                    style={{
                                        transition: 'none',
                                        backgroundColor:
                                            selectedOption === label
                                                ? savedTheme === 'dark'
                                                    ? 'rgba(240, 239, 242, 0.4)'
                                                    : '#dad9df'
                                                : 'transparent',
                                    }}
                                    className="text-[#000] dark:text-white !dark:hover:text-white "
                                >
                                    <div className="pl-2">{!isCollapsed && text}</div>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </div>
                )}

                <div className="flex flex-col justify-end h-full">
                    {canShowAffiliate && !isCollapsed && (
                        <>
                            {!isAffiliate ? (
                                <>
                                    {showPendingAffiliate ? (
                                        <button
                                            onClick={() => {}}
                                            className="dark:bg-transparent  dark:border  bg-[#dad9df] dark:border-white text-black dark:text-white hover:text-black hover:dark:text-white font-medium text-xs flex items-center justify-center h-10 rounded-md mx-4 mb-4 transition-all cursor-pointer"
                                            style={isCollapsed ? { userSelect: 'none' } : {}}
                                        >
                                            {!isCollapsed && <span>Pending Affiliate Request</span>}
                                        </button>
                                    ) : showBecomeAnAffiliate ? (
                                        <Link
                                            onClick={handleAffiliate}
                                            to={affiliateRoutes.dashboard}
                                            className="dark:bg-transparent  dark:border  bg-[#dad9df] dark:border-white text-black dark:text-white hover:text-black dark:hover:text-white font-medium text-xs flex items-center justify-center h-10 rounded-md mx-4 mb-4 transition-all cursor-pointer"
                                            style={isCollapsed ? { userSelect: 'none' } : {}}
                                        >
                                            {!isCollapsed && <span>View Affiliate Dashboard</span>}
                                        </Link>
                                    ) : (
                                        <button
                                            onClick={openAffiliateModal}
                                            className="dark:bg-transparent  dark:border  bg-[#dad9df] dark:border-white text-black dark:text-white dark:hover:text-white hover:text-black font-medium text-xs flex items-center justify-center h-10 rounded-md mx-4 mb-4 transition-all cursor-pointer"
                                            style={isCollapsed ? { userSelect: 'none' } : {}}
                                        >
                                            {!isCollapsed && <span>Become an Affiliate</span>}
                                        </button>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Link
                                        onClick={handleAffiliate}
                                        to={chatRoutes.chat}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="dark:bg-transparent  dark:border  bg-[#dad9df] dark:border-white text-black dark:text-white dark:hover:text-white  hover:text-black font-medium text-xs flex items-center justify-center h-10 rounded-md mx-4 mb-4 transition-all cursor-pointer"
                                        style={isCollapsed ? { userSelect: 'none' } : {}}
                                    >
                                        {!isCollapsed && <span>Back to Gmind</span>}
                                    </Link>
                                </>
                            )}
                        </>
                    )} 
                    <Link
                        to={chatRoutes.pricing}
                        className="dark:bg-transparent  dark:border  bg-[#dad9df] dark:border-white text-black dark:text-white hover:text-black dark:hover:text-white font-medium text-xs flex items-center justify-center h-8 rounded-md mx-2 mb-2 transition-all cursor-pointer gap-x-3"
                        style={isCollapsed ? { userSelect: 'none' } : {}}
                    >
                        {!isCollapsed ? (
                            <>
                                <GiUpgrade className="h-3 w-3 text-black dark:text-white" />
                                <span>UpgradePlan</span>
                            </>
                        ) : (
                            <GiUpgrade className="h-3 w-3 text-black dark:text-white" />
                        )}
                    </Link>
                </div>

                {!isGuest && (
                    <section
                        role="button"
                        style={isCollapsed ? { userSelect: 'none' } : {}}
                        onClick={() => navigate(chatRoutePages.profile)}
                        className="bg-transparent relative  mt-auto flex flex-col select-none transition-all h-[4rem] p-4 cursor-pointer gap-4 border-t"
                    >
                        <div className="flex items-start gap-2 transition-all" >
                            <img
                            
                                src={userDetails?.profilePic ? userDetails?.profilePic : avatar}
                                className="w-7 h-7 max-lg:w-5 bg-cover bg-center rounded-full"
                                style={
                                    isCollapsed
                                        ? {
                                              position: 'absolute',
                                              top: '50%',
                                              left: '50%',
                                              transform: 'translate(-50%, -50%)',
                                          }
                                        : {}
                                }
                            />
                            {!isCollapsed && (
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex flex-col">
                                        <p className="font-medium dark:text-white text-xs max-lg:text-[0.6rem]">
                                            {user?.firstName ?? 'User'} {user?.lastName ?? ''}{' '}
                                        </p>
                                        <p className="text-[0.6rem] dark:text-white max-lg:hidden">
                                            {user?.email ?? ''}
                                        </p>
                                    </div>

                                    <SettingOutlined className="space-x-2" style={{ color: 'var(--gmind-orange)' }} />
                                </div>
                            )}
                        </div>
                    </section>
                )}
            </nav>
        );
    },
);

export default Sidebar;
import { getUrlPath } from "@src/utils/app_functions"
import { acquisitionRoutes, chatRoutes } from "@src/utils/app_routes"

// const landingPageStructuredData = {
//   "@context": "https://schema.org",
//   "@type": "WebSite",
//   "url": "https://gmind.ai/",
//   "name": "GMind AI",
//   "description": "Create high-quality content, boost productivity, and enhance work efficiency with this simple-to-use AI tool: GMind AI, which is the future at your fingertips. Find quick in-depth answers to your question. Explore the internet's wide array of information all within your fingertips with our powerful inbuilt search engine.",
//   "potentialAction": {
//   "@type": "SearchAction",
//   "target": "https://gmind.ai/search?q={search_term_string}",
//   "query-input": "required name=search_term_string"
//   }
// }

const landingPageStructuredData = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "GMind AI",
  "url": `https://gmind.ai${acquisitionRoutes.home}`,
  "logo": "https://gmind.ai/images/logo.png",
  "description": `Create high-quality content, boost productivity, and enhance work efficiency with this simple-to-use AI tool: GMind AI, which is the future at your fingertips.`,
  "sameAs": [
    "https://www.facebook.com/gmindai",
    "https://www.twitter.com/gmindai",
    "https://www.linkedin.com/company/gmindai",
    "https://en.wikipedia.org/wiki/GMind_AI"
  ],
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "123 AI Street",
    "addressLocality": "Houston",
    "addressRegion": "TX",
    "postalCode": "77001",
    "addressCountry": "US"
  },
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+1-800-123-4567",
    "contactType": "customer service",
    "areaServed": ["US", "Global"],
    "availableLanguage": ["English"]
  }
}


const landingPageStructuredDataForBusiness = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "GMind AI",
  "image": "https://gmind.ai/images/local-business.jpg",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "123 AI Street",
    "addressLocality": "Houston",
    "addressRegion": "TX",
    "postalCode": "77001",
    "addressCountry": "US"
  },
  "telephone": "+1-800-123-4567",
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": "29.7604",
    "longitude": "-95.3698"
  },
  "url": `https://gmind.ai${acquisitionRoutes.home}`,
  "priceRange": "$$"
}
  

const dashboardData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Gmind Chat | GMind AI",
  "url": "https://gmind.ai/dashboard/chat",
  "description": `Find quick in-depth answers to your questions. Explore the internet's wide array of information all within your fingertips with our powerful inbuilt search engine.`,
  "primaryImageOfPage": {
    "@type": "ImageObject",
    "url": "https://gmind.ai/images/search-og-image.jpg",
    "width": 1200,
    "height": 630
  },
}

const creationData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "AI-Driven Content Creation",
  "url": "https://gmind.ai/creation",
  "keywords": ["gmind", "ai", "chat"],
  "description": "Unlock the power of AI-driven content creation. Craft high-quality blogs, social media posts, and marketing materials effortlessly with GMind AI's intuitive tools.",
  "primaryImageOfPage": {
    "@type": "ImageObject",
    "url": "https://gmind.ai/images/creation-og-image.jpg",
    "width": 1200,
    "height": 630
  }
}

const assistantData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Assistant",
    "url": "https://gmind.ai/assistant",
    "description": "Super-charge your productivity with the best AI tool. Streamline workflows, and enhance efficiency with powerful AI features tailored to your needs.",
    "keywords": ["gmind", "ai", "chat"],
    "publisher": {
      "@type": "Organization",
      "name": "GMind",
      "url": "https://gmind.ai/",
      "logo": {
        "@type": "ImageObject",
        "url": "https://gmind.ai/assets/logo.png",
        "width": 512,
        "height": 512
      }
    },
    "author": {
      "@type": "Organization",
      "name": "GMind"
    },
}

const researchersData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Researchers",
  "url": "https://gmind.ai/researchers",
  "description": "Super-charge your productivity with the best AI tool. Streamline workflows, and enhance efficiency with powerful AI features tailored to your needs.",
  "keywords": ["gmind", "ai", "chat"],
  "publisher": {
    "@type": "Organization",
    "name": "GMind",
    "url": "https://gmind.ai/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://gmind.ai/assets/logo.png",
      "width": 512,
      "height": 512
    }
  },
  "author": {
    "@type": "Organization",
    "name": "GMind"
  },
}

const featuresData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": `https://gmind.ai/${acquisitionRoutes.productivity}`,
  "name": "AI-Powered Productivity | GMind AI",
  "description": "Supercharge your productivity with the best AI tool. Streamline workflows, and enhance efficiency with powerful AI features tailored to your needs.",
}


const loginStructuredData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "GMind AI Login",
  "url": `https://gmind.ai${acquisitionRoutes.login}`,
  "description": `Log in to GMind AI and access a full suite of productivity tools. Manage your projects, streamline workflows, and boost efficiency with AI-driven solutions for every professional.`,
  "mainEntity": {
    "@type": "Action",
    "name": "Login",
    "target": {
      "@type": "EntryPoint",
      "urlTemplate": `https://gmind.ai${acquisitionRoutes.login}`
    }
  },
  "publisher": {
    "@type": "Organization",
    "name": "GMind AI",
    "logo": {
      "@type": "ImageObject",
      "url": "https://gmind.ai/images/logo.png"
    }
  }
}

const pricingData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "GMind AI Pricing",
  "url": `https://gmind.ai${chatRoutes.pricing}`,
  "description": "Choose a plan that fits your needs and access powerful content creation, research, and business features. Boost efficiency with affordable options tailored for professionals.",
  "mainEntity": {
    "@type": "OfferCatalog",
    "name": "GMind AI Plans",
    "itemListElement": [
      {
        "@type": "Offer",
        "name": "Basic Plan",
        "description": "Access essential tools for productivity and content creation at an affordable price.",
        "priceCurrency": "USD",
        "price": "XX.XX"
      },
      {
        "@type": "Offer",
        "name": "Premium Plan",
        "description": "Unlock additional features for teams and professionals with advanced needs.",
        "priceCurrency": "USD",
        "price": "XX.XX"
      },
      {
        "@type": "Offer",
        "name": "Advance Plan",
        "description": "Maximize productivity with the full suite of GMind AI features.",
        "priceCurrency": "USD",
        "price": "XX.XX"
      }
    ]
  },
  "publisher": {
    "@type": "Organization",
    "name": "GMind AI",
    "logo": {
      "@type": "ImageObject",
      "url": "https://gmind.ai/images/logo.png"
    }
  }
}

const searchPage = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "AI Search Engine | GMind AI",
  "url": "https://gmind.ai/search",
  "description": `Find quick in-depth answers to your questions. Explore the internet's wide array of information all within your fingertips with our powerful inbuilt search engine.`,
  "primaryImageOfPage": {
    "@type": "ImageObject",
    "url": "https://gmind.ai/images/search-og-image.jpg",
    "width": 1200,
    "height": 630
  }
}

const signupStructuredData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Get Started for Free",
  "url": `https://gmind.ai${acquisitionRoutes.getStarted}`,
  "description": `Sign up for GMind AI and explore its powerful productivity features for free. Begin your journey to enhanced efficiency and smarter workflows today.`,
  "potentialAction": {
    "@type": "RegisterAction",
    "target": `https://gmind.ai${acquisitionRoutes.getStarted}`,
    "name": "Sign Up"
  },
  "publisher": {
    "@type": "Organization",
    "name": "GMind AI",
    "logo": {
        "@type": "ImageObject",
        "url": "https://gmind.ai/images/logo.png"
      }
  }
}
  



export {
  landingPageStructuredData,
  landingPageStructuredDataForBusiness,
  dashboardData,
  creationData,
  assistantData,
  researchersData,
  featuresData,
  loginStructuredData,
  pricingData,
  signupStructuredData,
  searchPage
}
import logo from '@assets/gmind_white.svg';
import blue from '@assets/gmind_blue.svg';
import mailBlack from '@assets/mail-black.svg';
import facebook from '@socials/Facebook.svg';
import youtube from '@socials/Youtube.svg';
import instagram from '@socials/Instragram.svg';
import linkedin from '@socials/LinkedIn.svg';
import mail from '@icons/mail.svg';
import call from '@icons/Call.svg';
import location from '@icons/Location.svg';
import { Link, useNavigate } from 'react-router-dom';
import { GetStartedButton } from '@components/GetStartedButton';
import { getDeviceId } from '@src/utils/app_functions';
import useEndpoint from '../../auth/auth_endpoints';
import { toast } from 'react-toastify';
import { acquisitionRoutes, chatRoutes } from '@src/utils/app_routes';

type socialsType = {
    icon: string;
    link: string;
};

type contactType = {
    icon: string;
    onClick: () => void;
    details: string;
};

type FooterProps = {
    bg?: string;  // Optional bg prop to override the default background color
}

const Footer: React.FC<FooterProps> = ({ bg }) => {
    const navigate = useNavigate();
    const isLoggedIn: boolean = localStorage.getItem('token') ? true : false;
    const contactSection: contactType[] = [
        {
            icon: mailBlack,
            onClick: () => { },
            details: 'info@gmind.AI',
        },
    ];

    const socials: socialsType[] = [
        {
            icon: facebook,
            link: 'https://www.facebook.com',
        },
        {
            icon: youtube,
            link: 'https://youtu.be/rWwjRuLm92w?si=fNGD_T0KtoxG2LQu ',
        },
        {
            icon: instagram,
            link: 'https://www.instagram.com',
        },
        {
            icon: linkedin,
            link: 'https://www.linkedin.com',
        },
    ];

    const handleGuestLogin = async () => {
        function handleCallback() {
            const userData = localStorage.getItem('user');
            if (userData) {
                const userDetails = JSON.parse(userData);

                // if ( userDetails?.plan?.details !== undefined ){
                //     window.location.href = '/dashboard/chat';
                // }else {
                //     window.location.href = '/dashboard/pricing';
                // }
                window.location.href = chatRoutes.chat;
            }
        }

        const deviceId = await getDeviceId();

        useEndpoint()
            .guestLogin({ deviceId })
            .then((res: any) => {
                if (res?.success) {
                    localStorage.setItem('token', res?.data?.token);
                    const { id, firstName, lastName, email, isVerified, plan } = res.data;
                    localStorage.setItem('user', JSON.stringify({ id, firstName, lastName, email, isVerified, plan }));
                    handleCallback();
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            });
    };

    return (
        // <footer className="w-full flex flex-col md:flex-row items-center md:items-start justify-between px-10 md:px-20 py-[4rem]" style={{ backgroundColor: bg || 'var(--gmind-footer)' }}>
        //     <div className="flex flex-col gap-y-5 w-full lg:w-[40%]">
        //         <div className="flex flex-col gap-y-5 lg:gap-y-2">
        //             <img src={logo} loading='lazy' alt="logo" className="self-center lg:self-start w-[10rem] h-[3rem]" />
        //             <h2 className='self-center lg:self-start text-center lg:text-start mx-3 font-normal text-[0.8rem] lg:text-[0.9rem] text-white'>GMind AI, standing for Gotocourse Mind AI, is a state-of-the-art educational technology developed by Gotocourse. This AI model is designed to create robust and comprehensive educational content for teachers and schools.</h2>
        //         </div>


        //         <GetStartedButton
        //             onclick={() => {}}
        //             className="flex lg:hidden self-center h-[3rem] w-[8.75rem]"
        //             style={{
        //                 padding: '1rem 2rem',
        //             }}
        //         />

        //         <div className="flex flex-col gap-y-10 lg:hidden w-full">
        //             <div className="self-center flex gap-x-3">
        //                 {socials.map((social, index) => (
        //                     <Link to={social.link} key={index}>
        //                         <img src={social.icon} alt="social" className="w-[2rem] h-[2.25rem]" />
        //                     </Link>
        //                 ))}
        //             </div>

        //             <h2 className="self-center font-normal text-white text-[0.8rem]">Get Support</h2>

        //             {contactSection.map((contact, index) => (
        //                 <div onClick={contact.onClick} key={index} className="self-center flex gap-x-3 items-center">
        //                     <img src={contact.icon} alt="social" className="w-[1.5rem] h-[1.5rem]" />
        //                     <p className="font-normal text-[0.8rem] text-white">{contact.details}</p>
        //                 </div>
        //             ))}
        //         </div>

        //         <p className="mt-10 self-center lg:self-start text-white text-[0.8rem] lg:font-normal">
        //             Copyright © 2023 GotoCourse. All rights reserved.
        //         </p>

        //         <div className="hidden lg:flex gap-x-3 items-center " style={{marginRight: '48px'}}>
        //             {socials.map((social, index) => (
        //                 <Link to={social.link} key={index}>
        //                     <img src={social.icon} alt="social" className="w-[2rem] h-[2.25rem]" />
        //                 </Link>
        //             ))}
        //         </div>
        //     </div>

        //     <div className="w-[50%] hidden lg:flex gap-x-5">
        //         <div className='text-white flex flex-col gap-8 h-full -translate-x-10'>
        //             <Link to={"/"}>Home</Link>
        //             <Link to={"/"}>FAQs</Link>
        //             <Link to={"/"}>Features</Link>
        //         </div>
        //         <div className='text-white flex flex-col gap-8 h-full -translate-x-10'>
        //             <Link to={"/cookies"}>Cookie Policy</Link>
        //             <Link to={"/terms-of-service"}>Terms of Service</Link>
        //             <Link to={"/privacy-policy"}>Privacy Policy</Link>
        //         </div>

        //         <div className="flex flex-col gap-y-5">
        //             <h2 className="font-bold text-white text-[1.25rem]">Get Support</h2>

        //             {contactSection.map((contact, index) => (
        //                 <div onClick={contact.onClick} key={index} className="flex gap-x-3 items-center">
        //                     <img src={contact.icon} alt="social" className="w-[1.5rem] h-[1.5rem]" />
        //                     <p className="font-normal text-white">{contact.details}</p>
        //                 </div>
        //             ))}
        //         </div>

        //         <GetStartedButton onclick={() => {}} className="hidden lg:flex h-[3rem] w-[8rem]" />
        //     </div>
        // </footer>
        <footer className="w-full flex flex-col lg:flex-row justify-between px-10 lg:px-20 py-16 bg-[#F9F9FF] md:items-center">
            {/* Left Section */}
            <div className="flex flex-col gap-6 lg:w-1/3 mb-4">
                {/* Logo and Description */}
                <div className="flex flex-col gap-4">
                    <img src={blue} alt="GMind AI Logo" className="self-center lg:self-start w-40 h-12" />
                    <p className="text-center lg:text-left text-gray-600 text-sm leading-relaxed font-workSans">
                        GMind AI, by Gotocourse, is a versatile platform offering AI-powered tools for search, analysis, and collaboration, enhancing productivity and streamlining workflows for users of all expertise levels.
                    </p>
                </div>

                {/* Social Icons */}
                <div className="flex justify-center lg:justify-start gap-4">
                    {socials.map((social, index) => (
                        <a href={social.link} key={index} target="_blank" rel="noopener noreferrer">
                            <img src={social.icon} alt={`${social.icon} Icon`} className="w-8 h-8" />
                        </a>
                    ))}
                </div>

                {/* Copyright */}
                <p className="text-center lg:text-left text-gray-500 text-xs font-workSans">
                    © {new Date().getFullYear()} Gotocourse. All rights reserved.
                </p>
            </div>

            {/* Right Section */}
            <div className="flex flex-col lg:flex-row gap-12 lg:w-7/12 lg:justify-between">
                {/* Features */}
                <div className="flex flex-col gap-4">
                    <h3 className="font-medium text-gray-800 text-sm md:text-xl font-archivo">Features</h3>
                    <a href={acquisitionRoutes.productivity} className="text-gray-600 text-sm hover:underline font-archivo">Productivity</a>
                    <a href={acquisitionRoutes.researchers} className="text-gray-600 text-sm hover:underline font-archivo">Researchers</a>
                    <a href={acquisitionRoutes.assistant} className="text-gray-600 text-sm hover:underline font-archivo">AI Assistant</a>
                    <a href={acquisitionRoutes.creation} className="text-gray-600 text-sm hover:underline font-archivo">Content Creation</a>
                </div>

                {/* Policies */}
                <div className="flex flex-col gap-4">
                    <h3 className="font-medium text-gray-800 text-sm md:text-xl font-archivo">Policies</h3>
                    <a href="/terms-of-service" className="text-gray-600 text-sm hover:underline font-archivo">Terms of Service</a>
                    <a href="/privacy-policy" className="text-gray-600 text-sm hover:underline font-archivo">Privacy Policy</a>
                </div>

                {/* Contact */}
                <div className="flex flex-col gap-4">
                    <h3 className="font-medium text-gray-800 text-sm md:text-xl font-archivo">Contact</h3>
                    {contactSection.map((contact, index) => (
                        <div key={index} className="flex items-center gap-2">
                            <img src={contact.icon} alt="Contact Icon" className="w-5 h-5" />
                            <a href={`mailto:${contact.details}`} className="text-gray-600 text-sm hover:underline font-archivo">
                                {contact.details}
                            </a>
                        </div>
                    ))}
                </div>

                {/* Get Started Button */}
                <GetStartedButton 
                    isLoggedIn={isLoggedIn} 
                    onclick={() => {
                        if ( isLoggedIn ){
                            navigate(chatRoutes.chat);
                        }else{
                            handleGuestLogin();
                        }
                     }} 
                    className="hidden lg:flex h-[3rem] bg-[#ED5405]" 
                />
            </div>
        </footer>
    );
};

export default Footer;

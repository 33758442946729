/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import send from '@assets/send.svg';
import microphone from '@assets/microphone.svg';
import photoPicker from '@icons/photo.svg';
import cancelImg from '@assets/cancel.svg';
import earthImg from '@icons/earth.png';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Input as FormInput, Tooltip } from 'antd';
import { uploadChatFile, uploadChatPicture } from '../endpoints';
import { Spin, Skeleton } from 'antd';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import imageCompression from 'browser-image-compression';
import { ThemeContext } from '@src/theme_provider/theme';
import { FileContent } from '@src/core/interfaces/chat';
import pdfIcon from '@icons/pdf.png';
import docxIcon from '@icons/docx.png';
import { extractTextFromDOCX, extractTextFromPDF } from '../function/pdf_doc_helper';

const Input = ({
    value,
    showLeftField,
    placeholder = 'Ask your question',
    img,
    setImg,
    onPaste,
    isNotChat,
    extractedFile,
    setExtractedFile,
    Value,
    setValue,
}: {
    value: (v: string, img: string[], files: FileContent []) => void;
    showLeftField?: boolean;
    placeholder?: string;
    img?: File | null;
    onPaste?: (e: any) => void;
    setImg?: React.Dispatch<React.SetStateAction<File | null>>;
    isNotChat?: boolean;
    extractedFile?:string;
    setExtractedFile?: React.Dispatch<React.SetStateAction<string>>;
    Value:string;
    setValue:React.Dispatch<React.SetStateAction<string>>;
}) => {
    const inputRef = useRef<any>();
    const recognitionRef = useRef<any>(null);
    const [recording, setRecording] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const [rows, setRows] = useState(1);
    const [imageSelected, setImageSelected] = useState<{ url: string; loading: boolean }[]>([]);
    const [filesSelected, setFilesSelected] = useState<{ file: FileContent; loading: boolean }[]>([]);
    const [uploading, setUploading] = useState(false);
    const { savedTheme } = useContext(ThemeContext);

    const imageRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // setIsVisible(true); // Load image once it enters the viewport
                        observer.disconnect(); // Stop observing once the image is loaded
                    }
                });
            },
            { threshold: 0.1 }, // Load when 10% of the image is visible
        );

        if (imageRef.current) {
            observer.observe(imageRef.current);
        }

        return () => {
            if (imageRef.current) {
                observer.unobserve(imageRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const compressAndUploadImage = async () => {
            if (!img) return;

            const options = {
                maxSizeMB: 1, // Maximum size in MB
                maxWidthOrHeight: 1920, // Resize dimensions
                useWebWorker: true, // Use web worker for performance
            };

            try {
                // Compress the image before uploading
                const compressedFile = await imageCompression(img, options);

                // Proceed with upload if compression is successful
                if (compressedFile) {
                    await handlePictureUpload(compressedFile);
                } else {
                    toast.error('Image compression failed');
                }
            } catch (error) {
                console.error('Error during image compression or upload:', error);
                toast.error('An error occurred while processing the image');
            } finally {
                setImg?.(null); // Reset img after processing
            }
        };

        compressAndUploadImage();
    }, [img]);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0];

        console.log('Selected file:', file);

        if (!file) return;
        console.log('Selected file:', file);

        const fileType = file.type;


        if (fileType.startsWith('image/') ) {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };
    
            try {
                const compressedFile = await imageCompression(file, options);
                setImg?.(compressedFile ?? null);
            } catch (error) {
                console.error('Error during image compression:', error);
                toast.error('Image compression failed');
            }
            //    await handlePictureUpload(file);
            //     return;
        }else if (fileType === 'application/pdf' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            // Handle PDF and DOCX files
            const fileTypeValue : 'PDF' | 'DOCX' = fileType === 'application/pdf' ? 'PDF' : 'DOCX';
            try {
                // if ( fileType === 'application/pdf' ){
                //     await handleFileUpload(file, fileTypeValue);
                // }else {
                    await handleDocumentUpload(file, fileTypeValue);
                // }

                  if ( fileType === 'application/pdf' ){
                   const doc = await extractTextFromPDF(file);
                   setExtractedFile?.(doc);
                   console.log(doc)
                }else {
                  const doc =  await extractTextFromDOCX(file);
                  setExtractedFile?.(doc);
                  console.log(doc)
                }
            } catch (error) {
                console.error('Error during file upload:', error);
                toast.error('File upload failed');
            }
        } else {
            console.warn('Unsupported file type:', fileType);
            return;
        }
    };

    const handlePictureUpload = async (file: File) => {
        const tempUrl = URL.createObjectURL(file); // Create the object URL once
        try {
            setUploading(true);

            // Set the new image with loading state as true
            setImageSelected((prev) => [...prev, { url: tempUrl, loading: true }]);

            const result = await uploadChatPicture(file);

            if (result.success) {
                if (imageSelected.length >= 5) {
                    toast.error('You can only upload 5 pictures');
                    return;
                }
                // Update the image URL and set loading to false
                setImageSelected((prev) =>
                    prev.map((image) =>
                        image.url === tempUrl // Compare with the initially created tempUrl
                            ? { ...image, url: result.url || '', loading: false }
                            : image,
                    ),
                );
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error('An error occurred while uploading the picture');
        } finally {
            setUploading(false);
        }
    };


    const handleFileUpload = async (file: File, type: 'PDF' | 'DOCX' ) => {
        const tempUrl = URL.createObjectURL(file); // Create the object URL once
        try {
            if (filesSelected.length >= 1) {
                toast.error('You can only upload 1 file');
                return;
            }

            setUploading(true);

            // Set the new image with loading state as true
            setFilesSelected((prev) => [...prev, { file: {
                url: tempUrl,
                title: file.name,
                type: type,
            }, loading: true }]);

            const result = await uploadChatPicture(file);

            if (result.success) {
                // Update the image URL and set loading to false
                setFilesSelected((prev) =>
                    prev.map((file) =>
                        file.file.url === tempUrl // Compare with the initially created tempUrl
                            ? { ...file, file: {
                                    url: result.url || '',
                                    title: file.file.title,
                                    type: type,
                                }, loading: false, 
                            }
                            : file,
                    ),
                );
            } else {
                setFilesSelected((prev) =>
                    prev.filter((file) => file.file.url !== tempUrl) // Remove the file matching the tempUrl
                );
                toast.error(result.message);
            }
        } catch (error) {
            // Remove the temporary file
            setFilesSelected((prev) =>
                prev.filter((file) => file.file.url !== tempUrl) // Remove the file matching the tempUrl
            );
            toast.error('An error occurred while uploading the picture');
        } finally {
            setUploading(false);
        }
    };


    const handleDocumentUpload = async (file: File, type: 'PDF' | 'DOCX' ) => {
        const tempUrl = URL.createObjectURL(file); // Create the object URL once
        try {
            if (filesSelected.length >= 1) {
                toast.error('You can only upload 1 file');
                return;
            }

            setUploading(true);

            // Set the new image with loading state as true
            setFilesSelected((prev) => [...prev, { file: {
                url: tempUrl,
                title: file.name,
                type: type,
            }, loading: true }]);

            const result = await uploadChatFile(file);
            console.log(result)

            if (result.success) {
                // Update the image URL and set loading to false
                setFilesSelected((prev) =>
                    prev.map((file) =>
                        file.file.url === tempUrl // Compare with the initially created tempUrl
                            ? { ...file, file: {
                                    url: result.url || '',
                                    title: file.file.title,
                                    type: type,
                                }, loading: false, 
                            }
                            : file,
                    ),
                );
            } else {
                setFilesSelected((prev) =>
                    prev.filter((file) => file.file.url !== tempUrl) // Remove the file matching the tempUrl
                );
                toast.error(result.message);
            }
        } catch (error) {
            // Remove the temporary file
            setFilesSelected((prev) =>
                prev.filter((file) => file.file.url !== tempUrl) // Remove the file matching the tempUrl
            );
            toast.error('An error occurred while uploading the picture');
        } finally {
            setUploading(false);
        }
    };

    const calculateRows = (e: any) => {
        const textarea = e.target;
        const lineBreaks = (textarea.value.match(/\n/g) || []).length;

        const scrollHeight = textarea.scrollHeight;
        const computedStyle = window.getComputedStyle(textarea);
        const lineHeight = parseInt(computedStyle.lineHeight, 10) || 20;
        const newRows = Math.ceil(scrollHeight / lineHeight);

        setRows(Math.max(lineBreaks, newRows));
    };

    const handleInput = (e: any) => {
        setValue(e.target.value);
        calculateRows(e); // Update rows on input
    };

    const startRecording = () => {
        try {
            toast.success('Recording in progress...');
            setRecording(true);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            recognitionRef.current = new window.webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setValue(texts.join(' '));
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const stopRecording = () => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                recognitionRef.current.stop();
                setRecording(false);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        return () => {
            // Stop the speech recognition if it's active
            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
        };
    }, []);

    function handleSent() {
        // if (Value.trim() == '' && imageSelected.length == 0) return;
        value(
            Value,
            imageSelected.map((img) => img.url),
            filesSelected.map( (fileContent) => fileContent.file ), 
        );
        console.log(value)
        setValue('');
        setImageSelected([]);
        setFilesSelected([]);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSent();
        }
    }

    async function handleMicrophone() {
        if (recording) {
            stopRecording();
        } else {
            startRecording();
        }
    }

    return (
        <div className="space-y-2 max-md:h-fit flex flex-col max-md:py-1 bg-custom-opacity dark:bg-[#212121]" style={{flex:"1"}}>
            <section className="flex max-md:flex-col items-center gap-2 h-full ">
                {showLeftField && (
                    <input
                        type="text"
                        className="border flex-1 h-12 placeholder:font-normal placeholder:text-gray-400 placeholder:text-xs w-full basis-[20%] border-[#f5b99d] transition-all focus:border-brandOrange p-2 px-4 text-nowrap rounded-xl  text-xs flex items-center outline-none max-md:py-4"
                        placeholder="Enter your field"
                    />
                )}
                <div
                    style={
                        loading
                            ? { animation: 'pulse 1s ease infinite', userSelect: 'none' }
                            : { userSelect: 'auto', animation: 'none' }
                    }
                    className={`flex flex-col gap-y-2 border bg-[#eeeee4] dark:bg-[#212121] border-gray-400 rounded-2xl h-full w-full px-3`}
                >
                    <div className="flex items-center ">
                        {imageSelected.map((image, index) => (
                            <div key={index} className="mx-5 mt-5 rounded-md w-16 h-16 relative">
                                {image.loading ? (
                                    <SkeletonLoader />
                                ) : (
                                    <img src={image.url} alt="" className="w-full h-full rounded-md" />
                                )}
                                <div
                                    className="w-6 h-6 border border-gray-400 rounded-full bg-white absolute right-[-10px] top-[-10px]"
                                    onClick={() => {
                                        const newImages = imageSelected.filter((_, i) => i !== index);
                                        setImageSelected(newImages);
                                    }}
                                >
                                    <img src={cancelImg} alt="cancel" loading="lazy" className="w-full h-full" />
                                </div>
                            </div>
                        ))}
                        {filesSelected.map((file, index) => (
                            <div key={index} className="mx-5 mt-5 px-4 border border-gray-300 rounded-md relative h-14">
                                {file.loading && extractedFile ? (
                                    <SkeletonLoader />
                                ) : (
                                    <div className='flex gap-x-3 gap-y-2 h-full'>
                                        <img src={file.file.type === 'DOCX' ? docxIcon : pdfIcon } alt={file.file.type === 'DOCX' ? 'doc image' : 'pdf image'} className="w-10 h-full rounded-md" />
                                        <div className='flex flex-col gap-y-3'>
                                            <p className='dark:text-white'>{file.file.title.slice(0, 30)}</p>
                                            <p className='dark:text-white'>{file.file.type}</p>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className="w-6 h-6 border border-gray-400 rounded-full bg-white absolute right-[-10px] top-[-10px]"
                                    onClick={() => {
                                        const newFiles = filesSelected.filter((_, i) => i !== index);
                                        setFilesSelected(newFiles);
                                        setExtractedFile?.('')
                                    }}
                                >
                                    <img src={cancelImg} alt="cancel" loading="lazy" className="w-full h-full" />
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={`flex flex-1 basis-[90%] ${rows > 3 ? 'items-end' : 'items-center'}`}>
                        <div className="flex items-center gap-x-2">
                            {/* <img className='h-5' src={earthImg} alt="world icon" /> */}
                            {!recording && (
                                <AudioOutlined
                                    onClick={() => {
                                        if (loading) {
                                            toast.info('Relax, your speech will soon be converted to text 🙂');
                                        }
                                        !loading && handleMicrophone();
                                    }}
                                    role="button"
                                    className="h-10 dark:text-white flex-1 "
                                />
                            )}
                            {recording && (
                                <BounceLoading
                                    onClick={() => {
                                        if (loading) {
                                            toast.info('Relax, your speech will soon be converted to text 🙂');
                                        }
                                        !loading && handleMicrophone();
                                    }}
                                    className="h-6 w-6 flex-1 basis-[5%]"
                                />
                            )}
                                <Tooltip title={`${isNotChat ? "Attach a document (PDF, Word) you want to summarize":"Attach a document (PDF, Word, or image)" }`} placement="bottom">
                            <label htmlFor="fileInput" className="flex-1 basis-[5%] w-full md:h-8 h-12 cursor-pointer">
                                <PaperClipOutlined className="w-full dark:text-white h-full" />
                                <input
                                    id="fileInput"
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                />
                            </label>
                            </Tooltip>
                        </div>

                        <div className="py-2 w-full mx-2">
                            <FormInput.TextArea
                          
                                ref={inputRef}
                                onPaste={onPaste}
                                onInput={(e: any) => {
                                    handleInput(e);
                                    setValue(e.target.value);
                                }}
                                style={{
                                    backgroundColor: 'transparent',
                                    color: savedTheme === 'dark' ? 'white' : '#000',
                                }}
                                value={Value}
                                onKeyDown={handleKeyDown}
                                // type="text"
                                className={`w-full min-h-[80px] flex-1 basis-[90%] dark:bg-[#212121]  outline-none border-none focus:ring-0 no-resize-textarea border-gray-300 text-sm px-2 placeholder:font-normal placeholder:text-gray-400 placeholder:text-sm`}
                                placeholder={placeholder}
                                disabled={loading}
                                autoSize={{ minRows: 1, maxRows: 5 }}
                            />
                        </div>

                       { !isNotChat && <img
                            onClick={handleSent}
                            role="button"
                            src={send}
                            alt="send"
                            className="block w-full h-7 flex-1 basis-[5%]  send"
                        />}
                    </div>
                </div>
            </section>
            <p className=" dark:bg-[#212121] text-gray-400 text-xs">
                Gmind can make mistakes. It's advisable to verify crucial information.
            </p>
        </div>
    );
};
const BounceLoading = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
    return (
        <section
            role="button"
            onClick={onClick && onClick}
            className={`bounce_parent flex justify-center items-center gap-[0.1rem] ${className}`}
        >
            <div className="bounce rounded-xl h-full w-2 bg-brandOrange"></div>
            <div className="bounce rounded-xl h-full w-2 bg-brandOrange"></div>
            <div className="bounce rounded-xl h-full w-2 bg-brandOrange"></div>
        </section>
    );
};

const SkeletonLoader = () => {
    return (
        <div className="relative w-full h-full bg-gray-200 animate-pulse rounded-md">
            <div className="absolute inset-0 bg-gray-300 animate-pulse rounded-md"></div>
        </div>
    );
};

export default Input;

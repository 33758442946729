import React, { useState } from 'react';
import { Card } from 'antd';

const styles = [
    'Opinion Pieces',
    'Creative Writing',
    'News Articles',
    'Professional/Industry Articles',
    'Academic Articles',
];

export const ArticleStyle = ({
    selectedStyle,
    setSelectedStyle,
}: {
    selectedStyle: string;
    setSelectedStyle: React.Dispatch<React.SetStateAction<string>>;
}) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <h2 className="text-2xl font-semibold mb-4 dark:text-gray-300">Article type</h2>
            <p className="text-gray-600 dark:text-gray-300 mb-6">Select your article type</p>
            <div  className=' flex flex-wrap items-center justify-center gap-x-2 gap-y-3 '>
                {styles.map((style) => (
                    <Card
                        key={style}
                        hoverable
                        className='w-full md:w-auto flex flex-row items-center justify-center'
                        style={{
                            border: style === selectedStyle ? '2px solid #1890ff' : '1px solid #ddd',
                        }}
                        onClick={() => setSelectedStyle(style)}
                    >
                        {style}
                    </Card>
                ))}
            </div>
        </div>
    );
};

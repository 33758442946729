import { BASE_URL } from '@src/utils/constants';
import axios from 'axios';

interface tags {
    title: string;
    description: string;
    
    keywords:string;
}

export const handleAutoGenerateMetaTag = async (content: string): Promise<tags> => {
    const token = localStorage.getItem('token');
    const systemPrompt = `
  You are an AI-powered meta tag generator designed to create optimized HTML meta tags for webpages. Using the ${content}, generate:
  1. A title for the page (concise and engaging, maximum of 60 characters).
  2. A meta description (concise and informative, maximum of 160 characters).
  3. A meta keyword (comma-separated list of relevant keywords).

  
  Content to analyze:
  "${content}"
  
  Response format (JSON only):
  {
    "title": "Generated title based on the content",
    "description": "Generated description based on the content"
    "title_explanation":" Explain title given"
    "keywords": "Comma-separated keywords",
  
  }
  
  Do not provide any additional explanation or context. Ensure the response is a valid JSON object.
  `;

    try {
        const response = await axios.post(
            `${BASE_URL}/knowledge-base`,
            {
                model: 'gpt-4',
                messages: [
                    {
                        role: 'system',
                        content: systemPrompt,
                    },
                ],
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        const AIRESULT = response?.data?.data?.choices[0]?.message;
        const generatedMetaTag = JSON.parse(AIRESULT?.content);
        console.log(generatedMetaTag);

        return generatedMetaTag;
    } catch (error: any) {
        console.error('Error generating meta tags:', error);
        return { title: '', description: '' };
    }
};


export const handleKeywordResearch = async (content: string): Promise<any> => {
    const token = localStorage.getItem("token");
    const systemPrompt = `
  You are an AI-powered keyword research generator designed to create relevant and optimized keywords for a given content or topic. Using the provided content, generate a list of related keywords with the following details:
  1. Keyword: The related keyword or phrase.
  2. Search Volume: An estimated number of monthly searches for this keyword.
  3. Competition: The level of competition (Low, Medium, or High).
  4. CPC: The average cost-per-click in dollars (e.g., "$1.50").

  Content to analyze:
  "${content}"

  Response format (valid JSON only, strictly adhering to this structure):
  {
    "keywords": [
      {
        "keyword": "Generated keyword",
        "searchVolume": "Estimated search volume",
        "competition": "Low/Medium/High",
        "cpc": "Cost-per-click in dollars"
      }
    ]
  }

  Do not include any explanations, code blocks, or characters outside of the JSON object.
  `;

    try {
        const response = await axios.post(
            `${BASE_URL}/knowledge-base`,
            {
                model: "gpt-4",
                messages: [
                    {
                        role: "system",
                        content: systemPrompt,
                    },
                ],
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const AIRESULT = response?.data?.data?.choices[0]?.message;
        console.log("Raw AI Result:", AIRESULT);

        if (!AIRESULT?.content) throw new Error("No content in AI response.");

        const cleanJSON = AIRESULT.content.replace(/```json|```/g, "").trim();
        const generatedKeywords = JSON.parse(cleanJSON);

        console.log("Parsed Keywords:", generatedKeywords);
        return generatedKeywords;
    } catch (error: any) {
        console.error("Error generating keywords:", error);
        return { keywords: [] };
    }
};


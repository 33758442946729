import { Button, Form, Input, Select } from 'antd';
import { LessonMaterialProps, LetterWriterProps } from '../interface';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { InputTypeProps } from '../../assessment_2/interface';
import { gradeOptions, socialMedias, toneOptions } from '../../curriculum/constants';
import { prompts } from '@src/utils/prompts';
import { io, Socket } from 'socket.io-client';
import AIGenerate from '@icons/generate.svg';
import { onGenerateWithAI } from '../../curriculum/functions';
import { BASE_URL } from '@src/utils/constants';
import TextArea from 'antd/es/input/TextArea';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import "./style.scss";
import { extractTextFromPDF } from '@src/pages/dashboard/function/pdf_doc_helper';
import { useOutletContext } from 'react-router';

const Report = ({ onGenerate, setTopic }: LetterWriterProps) => {
    let grades = gradeOptions();
    const [socialInput, setSocialInput] = useState<{ [key: string]: string }>({
        title: '',
        media: '',
        tone: '',
        message: '',
        hastags: '',
        action: '',
    });
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [inputTyping, setInputTyping] = useState<{ [key: string]: boolean }>({});
    const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({});
    const recognitionRef = useRef<any>(null);
  
    const [recording, setRecording] = useState<{[key:string]:boolean}>({
        strength:false,
        growth:false,
    

    });
    const [form] = Form.useForm();
    const [finalTranscript, setFinalTranscript] = useState('');

    const [recordingField, setRecordingField] = useState<string | null>(null);

    const fileInputRef = useRef<HTMLInputElement>(null); // Ref for the file input
    const fileInputRef2 = useRef<HTMLInputElement>(null); // Ref for the file input
    const [docData1, setDocData1] = useState<string | ArrayBuffer | null>(null);
    const [docData2, setDocData2] = useState<string | ArrayBuffer | null>(null);

    const {
        details: userDetails,
    } = useOutletContext<any>();

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
    
            reader.onload = async (e) => {
                const content = e.target?.result;
    
                if (file.type === 'application/pdf') {
                    const pdfText = await extractTextFromPDF(file);
    
                    setDocData1(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData1(result.value);
                    form.setFieldsValue({ [field]: result.value });
                } else if (content) {
                    setDocData1(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };    
            reader.onerror = () => console.error('Error reading file');
    
            reader.readAsArrayBuffer(file);
        }
    };

    const handleFileChange1 = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
    
            reader.onload = async (e) => {
                const content = e.target?.result;
    
                if (file.type === 'application/pdf') {
                    const pdfText = await extractTextFromPDF(file);
    
                    setDocData2(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData2(result.value);
                    form.setFieldsValue({ [field]: result.value });
                } else if (content) {
                    setDocData2(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };    
            reader.onerror = () => console.error('Error reading file');
    
            reader.readAsArrayBuffer(file);
        }
    };

    const onPaperClipClick = () => {
        // Programmatically trigger the file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onPaperClipClick2 = () => {
        // Programmatically trigger the file input click
        if (fileInputRef2.current) {
            fileInputRef2.current.click();
        }
    };

    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
          }
    }, [fileInputRef]);
      useEffect(() => {
        if (fileInputRef2.current) {
            fileInputRef2.current.value = ''; // Reset the file input value
          }
    }, [fileInputRef2]);


    const handleMicrophoneClicked = (field: string) => {
        if (recording[field] && recordingField !== field) {
            toast.warning('Please stop the current recording before starting a new one.');
            return; // Prevent starting a new recording
        }
     
        {setRecordingField(field); // Set the current field being recorded
        handleMicrophone(field);
        console.log(recordingField);}
       
    };
    async function handleMicrophone(field:string) {
        if (recording[field] ) {
            stopRecording(field);
        } else {
            startRecording(field);
        }
    }

    const startRecording = (field:string) => {
        try {
            toast.success('Recording in progress...');
            setRecording((prevRecording) => ({ ...prevRecording, [field]: true }));
            recognitionRef.current = new (window as any).webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setFinalTranscript(texts.join(' '));
                console.log('text', texts);
                console.log('final:', finalTranscript);
                // Use getFieldsValue and setFieldsValue for Antd form
                // Use getFieldsValue and setFieldsValue for Antd form
                const fields = form.getFieldsValue();
                console.log('Current fields:', fields);

                // Set the updated value in the correct field
                form.setFieldsValue({
                    [field as string]: texts.join(''), // Update the specific field
                });
                console.log('Current fields:', fields);

                console.log(`Updated ${field}:`, texts);
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const stopRecording = (field:string) => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                recognitionRef.current.stop();
                setRecording((prevRecording) => ({ ...prevRecording, [field]: false })); // Reset after stopping
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleOnChange = (value: string, keyString: string) => {
        setSocialInput({
            ...socialInput,
            [keyString]: value,
        });
    };

    const handleOnChangeFromAI = (value: string, key: string) => {
        setSocialInput((prevFormData) => ({
            ...prevFormData,
            [key]: prevFormData[key] + value,
        }));
    };

    const setTyping = (key: string, value: boolean) => {
        setInputTyping((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const setItemVisibility = (key: string, value: boolean) => {
        setVisibility((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const inputData: Array<InputTypeProps> = [
        {
            name: 'Title*',
            placeholder: 'Enter a title for your social media post',
            value: 'title',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'input',
            required: true,
        },
        {
            name: 'Social Media Platform*',
            placeholder: 'e.g Physics, Project Management etc',
            value: 'media',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'select',
            options: socialMedias,
            required: true,
        },
        {
            name: 'Tone*',
            placeholder: 'Enter tone of your social media test',
            value: 'tone',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'select',
            options: toneOptions,
            required: true,
        },
        {
            name: 'Main Message*',
            placeholder: 'Main message of your media content',
            value: 'message',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'input',
            required: true,
        },
        {
            name: 'Hashtags*',
            placeholder: 'Enter content hastags',
            value: 'hastags',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'input',
            required: true,
        },
        {
            name: 'Call to Action',
            placeholder: 'Enter call action',
            value: 'action',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'textarea',
            minRows: 2,
            onGenerate: (e, t) => {
                let message = `Generate a short call to action for this social media post with title ${t}`;
                onGenerateWithAI(sockets[e], message, userDetails?.userId ?? "" ,prompts.socialContent);
            },
        },
    ];

    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        const newTyping: { [key: string]: boolean } = {};
        const visibility: { [key: string]: boolean } = {};
        inputData.forEach((item) => {
            if (item.type == 'textarea') {
                newSockets[item.name] = io(BASE_URL.slice(0, -2));
                newTyping[item.name] = false;
                visibility[item.name] = true;
            }
        });
        setSockets(newSockets);
        setInputTyping(newTyping);
        setVisibility(visibility);

        return () => {
            Object.values(newSockets).forEach((socket) => {
                socket.close();
            });
        };
    }, []);

    useEffect(() => {
        inputData.forEach((item) => {
            if (item.type == 'textarea') {
                const socket = sockets[item.name];
                if (!socket) return;

                const handleData = (data: string) => {
                    handleOnChangeFromAI(data, item.value);
                };

                const handleStreamEnd = () => {
                    setTyping(item.name, false);
                };

                socket.on('data', handleData);
                socket.on('stream_end', handleStreamEnd);
                return () => {
                    socket.off('data', handleData);
                    socket.off('stream_end', handleData);
                };
            }
        });
    }, [sockets]);

    const onFinish = (values: any) => {
        let promptMessage = '';

        let gradeLevel = values.gradeLevel;
        let pronouns = values.pronouns;
        let strength = values.strength;
        let growth = values.growth;
       

        let baseToSend = '';

        let canProcced = ( gradeLevel && strength && pronouns && growth );

        if (!canProcced) {
            toast.error('Kindly enter all values');
            return;
        }

        let informationData = `
            Grade Level: ${gradeLevel}
            Student Name: ${pronouns}
            Strength: ${strength}
            Growth: ${growth}
        `;

        promptMessage = `Generate a report card comment with details: ${informationData}`;

        onGenerate(promptMessage, true);
    };



    return (
        <Form  form={form} className="flex flex-col px-4 py-4 space-y-3" onFinish={onFinish} layout="vertical">
            <h1 className="text-xl font-bold dark:text-white">Report Card Comments</h1>
            <p className='dark:text-gray-300'>Generate report card comments with a student's strengths and areas for improvement.</p>
            
        
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <Form.Item label="Grade Level" name="gradeLevel" className='w-full '>
                        <Select placeholder="Select grade level">
                            {
                                grades.map((item, index) => (
                                    <Select.Option value={item}>{item}</Select.Option>
                                ))
                            }
                            {/* <Select.Option value="grade1">Grade 1</Select.Option>
                            <Select.Option value="grade2">Grade 2</Select.Option> */}
                            {/* Add more options as needed */}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Student Name" name="pronouns" className='w-full '>
                        <div className="flex items-start  rounded">
                            {/* <div className=" flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                                <AudioOutlined />
                            </div> */}
                            <Input
                                className="p-2  w-full focus:outline-none"
                                placeholder="Enter student's name"
                                style={{ outline: 'none', boxShadow: 'none' }} // Remove outline and box shadow
                            />
                        </div>
                    </Form.Item>
                </div>

                <div className=' grid grid-cols-1 md:grid-cols-2 gap-4'>

            <Form.Item label="Areas of Strength:" name="strength" className='w-full '>
                <div className="flex items-start border dark:border-white border-gray-700 rounded">
                    <div className=" flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                        <AudioOutlined className={`hover:text-green-500 transition-colors duration-200 ${recording['strength'] ?'dark:text-customOrange':'dark:text-white text-gray-500 '}`} style={{cursor:"pointer"}} onClick={()=> handleMicrophoneClicked('strength')}  />
                            
                        <PaperClipOutlined className='dark:text-white' onClick={onPaperClipClick} />
                      
                        <input
                        id='file-input'
                        
                            ref={fileInputRef}
                            type="file"
                            accept=".doc,.docx,.pdf"
                            style={{ display: 'none' }} // Hide the input element
                            onChange={(e) => handleFileChange(e, 'strength')}
                        />

                     
                    </div>
                    <TextArea
                               value={form.getFieldValue('strength')|| docData1} // This makes it controlled
                               onChange={(e:any) => {
                                  { setDocData1(e.target.value);} // Update both docData and form values
                                   form.setFieldsValue({ strength: e.target.value });
                               }}
                        rows={4}
                        className="p-2 rounded-none border-none w-full focus:outline-none"
                        placeholder="Enter a topic"
                        style={{ outline: 'none', boxShadow: 'none' }} // Remove outline and box shadow
                    />
                </div>
            </Form.Item>

            <Form.Item label="Area for improvement:" name="growth" className='w-full '>
                <div className="flex items-start border dark:border-white border-gray-700 rounded mb-4">
                    <div className=" flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                        <AudioOutlined style={{cursor:"pointer"}} onClick={()=> handleMicrophoneClicked('growth')}  className={`hover:text-green-500 transition-colors duration-200 ${recording['growth'] ?'dark:text-customOrange':'dark:text-white text-gray-500 '}`}/>

                        
                        <PaperClipOutlined className ="dark:text-white" onClick={onPaperClipClick2} />
                      
                        <input
                        
                            ref={fileInputRef2}
                            type="file"
                            accept=".doc,.docx,.pdf"
                            style={{ display: 'none' }} // Hide the input element
                            onChange={(e) => handleFileChange1(e, 'growth')}
                        />
                    </div>
                    <TextArea
                             value={form.getFieldValue('growth') || docData2} // This makes it controlled
                             onChange={(e:any) => {
                                 setDocData2(e.target.value); // Update both docData and form values
                                 form.setFieldsValue({ growth: e.target.value });
                             }}
                             rows={4}
                        className="p-2 rounded-none border-none w-full focus:outline-none"
                        placeholder="Enter a topic"
                        style={{ outline: 'none', boxShadow: 'none' }} // Remove outline and box shadow
                    />
                </div>
                
            </Form.Item>
            </div>

            {/* <Form.Item name="additional" className='w-full md:w-[60%]'>
                <TextArea
                    rows={4}
                    className="p-2 border border-gray-300 rounded focus:outline-none"
                    placeholder="Enter additional content"
                />
            </Form.Item> */}

            <Form.Item>
                <button
                    type="submit"
                    className=" no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-[var(--gmind-orange)] text-white rounded hover:bg-[var(--gmind-orange)]-600"
                >
                    Generate
                </button>
            </Form.Item>

           
        </Form>
    );
};
export default Report;

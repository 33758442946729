import React from "react";
import Heading from "../../../../components/Heading";
import { CreateAcount } from "./CreateAcount";
import Gallery from "../../../../components/Gallery";
import Login from "../../login/components/Login";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../../../../components/ForgotPassword";
import 'react-toastify/dist/ReactToastify.css';
import { acquisitionRoutes } from "@src/utils/app_routes";

const SignUp = () => {
  const [step, setStep] = React.useState(1);

  const navigate = useNavigate();

  return (
    <div className=" w-full py-0">
        <div >
          <div >
            <div >
              <CreateAcount handleSignIn={() => navigate(acquisitionRoutes.login)} />
            </div>
          </div>
        </div>  
    </div>
  );
};

export default SignUp;

import { Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { MyDownArrowIcon } from './lesson_planner';
import AIGenerate from '@icons/generate.svg';
import { activityGeneratorPrompt, LessonPlanInputType, lessonPlannerPrompt } from '../interfaces';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import { onGenerateWithAI } from '../functions';
import { BASE_URL, socketUrl } from '@src/utils/constants';
import BackButton from '@src/components/BackButton';
import { useOutletContext } from 'react-router';

const GenerateLessonPlan = ({ onGenerate, subject, setStart }: lessonPlannerPrompt) => {
    const [rememberMe, setRememberMe] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(true);
    const [isMobile, setIsMobile] = useState(true);
    // const onFinish = (values: any) => {
    //     console.log('Success:', values);
    // };

    const [formData, setFormData] = useState({
        goal: '',
        duration: '',
        lessonProcedure: '',
        homework: '',
        extension: '',
    });

    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({});

    const baseurl = BASE_URL;

    const updateFormData = (key: keyof typeof formData, value: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [key]: prevFormData[key] + value,
        }));
    };

    const {
        details: userDetails,
    } = useOutletContext<any>();

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const inputValues: Array<LessonPlanInputType> = [
        {
            value: formData.goal,
            name: 'goal',
            label: 'Goals And Objectives*',
            textarea: true,
            additionalInput: false,
            onChange: (e) => {
                setFormData({
                    ...formData,
                    goal: e,
                });
            },
            placeholder:
                'What should students know or be able to do? e.g., Students will be able to identify and describe the phenomenon of refraction, including its causes and effects on light propagation',
            onGenrate: () => {
                let prompt = `Generate a short goal and objective for ${subject} lesson plan`;
                console.log(prompt);
                const socket = sockets['goal'];
                onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
            },
            setData: (e) => updateFormData('goal', e),
        },

        {
            value: formData.duration,
            name: 'duration',
            label: 'Duration*',
            textarea: false,
            additionalInput: false,
            onChange: (e) => {
                setFormData({
                    ...formData,
                    duration: e,
                });
            },
            placeholder: 'e.g 30 minutes',
            onGenrate: () => {},
            setData: (e) => updateFormData('duration', e),
        },
        {
            value: formData.lessonProcedure,
            name: 'lesson_procedure',
            label: 'Lesson Procedure*',
            textarea: true,
            additionalInput: false,
            onChange: (e) => {
                setFormData({
                    ...formData,
                    lessonProcedure: e,
                });
            },
            placeholder:
                'Describe the lesson procedures or stages... for e.g\n-Introduction (5 mins)-\nWatch video on the refraction (10 mins)',
            onGenrate: () => {
                let prompt = `Describe the lesson procedures or stages for ${subject}`;
                const socket = sockets['lesson_procedure'];
                onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
            },
            setData: (e) => updateFormData('lessonProcedure', e),
        },
        {
            value: formData.homework,
            name: 'homework',
            textarea: true,
            label: 'Homework & Assignments*',
            additionalInput: true,
            onChange: (e) => {
                setFormData({
                    ...formData,
                    homework: e,
                });
            },
            placeholder:
                'What should students know or be able to do?\ne.g., Students will be able to identify and describe the phenomenon of refraction, including its causes and effects on light propagation',
            onGenrate: () => {
                let prompt = `Generate homeworks testing what should students know or be able to do for ${subject}`;
                const socket = sockets['homework'];
                onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
            },
            setData: (e) => updateFormData('homework', e),
        },
        {
            value: formData.extension,
            name: 'extension',
            textarea: true,
            label: 'Extension & Enrichment*',
            additionalInput: true,
            onChange: (e) => {
                setFormData({
                    ...formData,
                    extension: e,
                });
            },
            placeholder:
                'What should students know or be able to do?\ne.g., Students will be able to identify and describe the phenomenon of refraction, including its causes and effects on light propagation',
            onGenrate: () => {
                let prompt = `Generate other details for ${subject} lesson plan`;
                const socket = sockets['extension'];
                onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
            },
            setData: (e) => updateFormData('extension', e),
        },
    ];

    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        const newVisibility: { [key: string]: boolean } = {};
        inputValues.forEach((item) => {
            newSockets[item.name] = io(socketUrl.slice(0, -2));
            newVisibility[item.name] = true;
        });
        setSockets(newSockets);
        setVisibility(newVisibility);

        return () => {
            Object.values(newSockets).forEach((socket) => {
                socket.close();
            });
        };
    }, []);

    useEffect(() => {
        inputValues.forEach((item) => {
            const socket = sockets[item.name];
            if (!socket) return;

            const handleData = (data: string) => {
                item.setData(data);
            };

            socket.on('data', handleData);

            return () => {
                socket.off('data', handleData);
            };
        });
    }, [sockets]);

    const handleGenerate = () => {
        // validation
        if (!formData.goal || !formData.duration || !formData.lessonProcedure) {
            toast.error('Kindly enter all lesson details');
            return;
        }

        let goalData = `Goals and Objective: ${formData.goal}`;
        let durationData = `Duration: ${formData.duration}\n`;
        let procedureData = `Lesson Procedure: ${formData.lessonProcedure}\n`;
        let homeworkData = formData.homework != '' ? `Homework: ${formData.duration}\n` : '';
        let extensionData = formData.extension != '' ? `Extension & Enrichment: ${formData.extension}\n` : '';

        let data = `${goalData}${durationData}${procedureData}${homeworkData}${extensionData}`;

        let promptGenerated = `with this following lesson Details: ${data}`;

        onGenerate(promptGenerated);
    };

    const setAIVisible = (key: string) => {
        setVisibility((prevData) => ({
            ...prevData,
            [key]: false,
        }));
    };

    return (
        <div className="w-full pr-3 md:pr-0">
            {/* <div className="font-[Poppins,sans-serif]  text-black-600 text-[0.8125rem] gap-[6px]">
                Refraction{' '}
                <span
                    className="text-brandOrange-600 px-2 py-1"
                    style={{ border: '1px solid #E55109', color: '#E55109', borderRadius: '4px', gap: '6px' }}
                >
                    Lesson Planner
                </span>
            </div> */}
            <div className=" pt-5">
                <BackButton onclick={() => setStart(false)} />{' '}
            </div>

            <div className="mt-3 md:mt-0 mb-[10px] text-[2rem] md:text-[2.75rem] dark:text-white curriculum--title ">
                Develop a lesson plan
            </div>
            <div className="curriculum--subtitle text-[1.125rem] md:text-[0.875rem] dark:text-white">
                Let's start by brainstorming and strategizing various facets of your lesson
            </div>
            <Form layout="vertical" className="flex flex-col md:flex-row gap-x-3 " initialValues={{ rememberMe }}>
                <div className="w-full md:w-[45%] flex flex-col gap-y-3 md:gap-y-0">
                    {inputValues.map((item, index) =>
                        item.textarea && !item.additionalInput ? (
                            <Form.Item
                                className="w-full"
                                key={index}
                                name={item.name}
                                label={<label className="curriculum--label">{item.label}</label>}
                                rules={[
                                    {
                                        message: `${item.name} field is required`,
                                        required: false,
                                    },
                                ]}
                            >
                                <div className="relative">
                                    <Input.TextArea
                                        className="w-full"
                                        autoSize={{ minRows: 4 }}
                                        value={item.value}
                                        onChange={(e) => item.onChange(e.target.value)}
                                        placeholder={item.placeholder}
                                        style={{
                                            border: '1px solid #DBDADE !important',
                                            borderRadius: '4px',
                                            backgroundColor: 'transparent',
                                            outline: 'none',
                                        }}
                                    />
                                    {visibility[item.name] && (
                                        <div
                                            onClick={() => {
                                                item.onGenrate();
                                                setAIVisible(item.name);
                                            }}
                                            className="cursor-pointer shadow-md rounded-[1rem] absolute top-[60px] right-[1rem] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                                        >
                                            <img src={AIGenerate} alt="" />
                                            <span>Use Gmind AI</span>
                                        </div>
                                    )}
                                </div>
                            </Form.Item>
                        ) : !item.textarea && !item.additionalInput ? (
                            <Form.Item
                                className="w-full"
                                key={index}
                                name={item.name}
                                label={<label className="curriculum--label">{item.label}</label>}
                                rules={[
                                    {
                                        message: `${item.name} is required`,
                                        required: false,
                                    },
                                ]}
                            >
                                <Input
                                    className="w-full"
                                    value={item.value}
                                    onChange={(e) => item.onChange(e.target.value)}
                                    placeholder={item.placeholder}
                                    style={{
                                        border: '1px solid #DBDADE !important',
                                        borderRadius: '4px',
                                        backgroundColor: 'transparent',
                                        padding: '7px 10px',
                                    }}
                                />
                            </Form.Item>
                        ) : (
                            <></>
                        ),
                    )}

                    {/* <div
                        className="w-full flex flex-row justify-between items-center font-[Poppins,sans-serif]  text-black-400 text-[1rem]"
                        style={{
                            border: ' 1px solid #e55109 ',
                            background: 'rgba(229, 81, 9, 0.08)',
                            borderRadius: '4px',
                            padding: '10px 7px',
                            color: '#E55109',
                        }}
                        onClick={() => setShowAdvanced(!showAdvanced)}
                    >
                        {!showAdvanced ? <span>Show Advanced Inputs</span> : <span>Hide Advanced Inputs</span>}
                        <span>
                            {!showAdvanced ? (
                                <svg
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10.0938 8.90625V4.75H8.90625V8.90625H4.75V10.0938H8.90625V14.25H10.0938V10.0938H14.25V8.90625H10.0938Z"
                                        fill="#E55109"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    width="13"
                                    height="3"
                                    viewBox="0 0 13 3"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M12.0418 2.29036H0.958496V0.707031H12.0418V2.29036Z" fill="#E55109" />
                                </svg>
                            )}
                        </span>
                    </div> */}

                    <button
                        onClick={handleGenerate}
                        className="py-2 px-6 rounded"
                        style={{
                            backgroundColor: '#E55109',
                            color: 'white',
                            marginTop: '20px',
                        }}
                    >
                        Generate Plan
                    </button>
                </div>

                <div className="w-full md:w-[50%] flex flex-col gap-y-3 px-3 py-3">
                    {showAdvanced && (
                        <div className="border-[2px] border-dashed border-[var(--gmind-orange)] rounded-[0.5rem] px-5 py-3 flex flex-col">
                            <h2 className="mb-5 self-center hidden md:flex text-center font-medium text-[1.5rem] dark:text-white">
                                Advance Options
                            </h2>
                            {inputValues.map((item, index) =>
                                item.textarea && item.additionalInput ? (
                                    <Form.Item
                                        className="w-full"
                                        key={index}
                                        name={item.name}
                                        label={<label className="curriculum--label">{item.label}</label>}
                                        rules={[
                                            {
                                                message: `${item.label} field is required`,
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <div className="relative">
                                            <Input.TextArea
                                                className="w-full"
                                                autoSize={{ minRows: 4 }}
                                                value={item.value}
                                                onChange={(e) => item.onChange(e.target.value)}
                                                placeholder={item.placeholder}
                                                style={{
                                                    border: '1px solid #DBDADE !important',
                                                    borderRadius: '4px',
                                                    backgroundColor: 'transparent',
                                                    outline: 'none',
                                                }}
                                            />
                                            {visibility[item.name] && (
                                                <div
                                                    onClick={() => {
                                                        item.onGenrate();
                                                        setAIVisible(item.name);
                                                    }}
                                                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[1rem] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                                                >
                                                    <img src={AIGenerate} alt="" />
                                                    <span>Use Gmind AI</span>
                                                </div>
                                            )}
                                        </div>
                                    </Form.Item>
                                ) : (
                                    <></>
                                ),
                            )}
                        </div>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default GenerateLessonPlan;

import { GetStartedButton, GetStartedForFree } from '@src/components/GetStartedButton';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { landingPagePrps } from '@src/core/interfaces/general';
import { getDeviceId } from '@src/utils/app_functions';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import useEndpoint from '../auth/auth_endpoints';
import { toast } from 'react-toastify';
import hero from '@assets/features/girl.svg'
import doodle from '@assets/features/doodle.svg'
import top from '@assets/top-right.png';
import bottom from '@assets/bottom-left.png';
import one from '@assets/1.svg';
import two from '@assets/2.svg';
import three from '@assets/3.svg';
import four from '@assets/4.svg';
import five from '@assets/5.svg';
import six from '@assets/6.svg';
import syllabus from "../../../assets/features/syllabus.svg"
import activity from "../../../assets/features/activity.svg"
import planner from "../../../assets/features/lesson-plan.svg"
import campaign from "../../../assets/features/campaign.svg"
import insight from "../../../assets/features/insights.svg"
import tool from "../../../assets/features/tool.svg"
import writer from "../../../assets/features/writer.svg"
import maker from "../../../assets/features/maker.svg"
import model from "../../../assets/features/model.svg"
import slide from "../../../assets/features/slides.svg"
import sheet from "../../../assets/features/worksheet.svg"
import assesment from "../../../assets/features/assesment.svg"
import today from "../../../assets/features/today.svg"
import Testimonials from '../components/about/testimonial';
import Faq from '../components/faq';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet-async';
import { researchersData } from '../strutured_data/structured_data';
import RequestDemoModal from '../components/request_demo_modal';





interface Feature {
    id: number;
    title: string;
    text: string;
    icon: any,

}
const Features4 = ({ isLoggedIn }: landingPagePrps) => {
    const navigatePage = useNavigate()
    const [showRequestModal, setShowRequestModal] = useState(false);


    const handleGuestLogin = async () => {
        function handleCallback() {
            const userData = localStorage.getItem('user');
            if (userData) {
                // const userDetails = JSON.parse(userData);

                // if ( userDetails?.plan?.details !== undefined ){
                //     window.location.href = '/dashboard/chat';
                // }else {
                //     window.location.href = '/dashboard/pricing';
                // }
                window.location.href = '/dashboard/chat';
            }
        }

        const deviceId = await getDeviceId();

        useEndpoint()
            .guestLogin({ deviceId })
            .then((res: any) => {
                if (res?.success) {
                    localStorage.setItem('token', res?.data?.token);
                    const { id, firstName, lastName, email, isVerified, plan } = res.data;
                    localStorage.setItem('user', JSON.stringify({ id, firstName, lastName, email, isVerified, plan }));
                    handleCallback();
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            });
    }

    const features: Feature[] = [
        {
            id: 1,
            icon: two,
            title: "Create High Quality Content",
            text: "Brainstorm content ideas and bring them to life. Write articles, blog posts, image and social media captions and lots more."
        },
        {
            id: 2,
            icon: two,
            title: "AI-Powered Insights",
            text: "Leverage real-time data and predictive analysis to make smarter decisions, improve workflow efficiency, and enhance productivity.",
        },
        {
            id: 3,
            icon: three,
            title: "Automated Task Management",
            text: "GMind AI automates routine tasks, from scheduling to data analysis, freeing up your time to focus on more critical projects.",
        },
        {
            id: 4,
            icon: four,
            title: "Customizable Workflow Automation",
            text: "Tailor our AI tools to your unique needs. Whether you're a teacher, GMind AI adapts to your workflow for optimal efficiency.",
        },
        {
            id: 5,
            icon: five,
            title: "Real-Time Internet Access",
            text: "Stay up to date with the latest information, research, and data by integrating real-time internet access into your productivity tools.",
        },
        {
            id: 6,
            icon: six,
            title: "Comprehensive Resource Integration",
            text: "Access a wide array of resources for content generation, curriculum planning, project management, and more, all in one platform.",
        },
    ];

    return (
        <div>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(researchersData)}</script>
                <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
                <meta
                    name="description"
                    content="Super-charge your productivity with the best AI tool. Streamline workflows, and enhance efficiency with powerful AI features tailored TO your needs."
                />
                <meta
                    name="title"
                    content="AI Tools And Features | GMind AI"
                />
                <title>Researchers</title>
                <link rel="canonical" href="https://gmind.ai/researchers" />
                <meta name="keywords" content="gmind, ai, chat" />
            </Helmet>
            <RequestDemoModal isVisible={showRequestModal} onCancel={() => setShowRequestModal(false)} />
            <div className="w-full bg-[#031A57]">
                <div className="w-full md:min-h-screen flex flex-col items-center justify-center text-white 3xl:max-w-[1800px] 3xl:mx-auto">
                    {/* hero content */}
                    <div className="text-center px-6 md:px-12 mt-24">
                        <h2 className="text-3xl md:text-6xl font-medium md:max-w-5xl md:leading-[64px]">
                            <span className="text-white font-archivo">
                                Unlock Next-Level Productivity For
                                <br />
                                (
                                <span className="text-[#FF5733]">Educators</span>,
                                Creators,
                                <span className="text-[#FF5733]">Researchers</span>,
                                Business Owners
                                )
                            </span>
                        </h2>
                        <p className="mt-6 md:mt-8 text-sm max-w-2xl mx-auto font-poppins leading-6">
                            Whether you're managing projects, creating content, or analyzing data, GMind AI has the features you need to work smarter, not harder.
                        </p>
                        <div className="mt-8 md:mt-14 flex flex-col sm:flex-row justify-center gap-4">
                            <GetStartedButton
                                isLoggedIn={isLoggedIn}
                                className="px-4 py-2 border border-white text-white rounded-2xl hover:bg-white hover:text-black transition duration-300"
                                onclick={() => {
                                    if (isLoggedIn) {
                                        navigatePage('/dashboard/chat')
                                        return;
                                    }
                                    handleGuestLogin()
                                }}
                            />
                            <a
                                // onClick={() => navigatePage('/features?showSection=1')}
                                onClick={() => {
                                    setShowRequestModal(true);
                                    // const email = "support@gmind.ai ";
                                    // const subject = "Request Demo for Gmind AI";
                                    // const body = "Hi,\n\nI am interested in scheduling a demo for Gmind AI. Please let me know the available dates and times.\n\nThank you.";

                                    // window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                                }}
                                className="cursor-pointer w-full sm:w-auto flex justify-center md:items-center px-4 py-3 text-center border border-white text-white rounded-2xl hover:bg-white hover:text-black transition duration-300"
                            >
                                Request Demo
                            </a>
                        </div>
                    </div>
                    {/* hero image */}
                    <div className="mt-12 flex justify-center">
                        <img src={hero} alt="hero-image" className="w-full max-w-3xl shadow-md" />
                    </div>
                </div>
            </div>
            <section className="w-full bg-white relative">
                <div className="w-full py-16 px-6 md:px-24 3xl:max-w-[1800px] 3xl:mx-auto 3xl:px-8">
                    {/* Top-right Image */}
                    <img
                        src={top}
                        alt="Top decorative"
                        className="hidden md:block absolute -top-56 right-2 w-24 sm:w-32 md:w-48 lg:w-64"
                    />
                    {/* Our Story Section */}
                    <div className="flex flex-col md:flex-row items-center md:items-center md:justify-between gap-8 relative z-10">
                        {/* Text Content */}
                        <div className="w-full md:w-1/2 text-left mt-8 md:mt-0">

                            {/* Main Heading */}
                            <p className="text-[#111111] text-3xl md:text-6xl font-medium md:leading-[70px] max-w-md md:max-w-xl tracking-wide font-jakarta">
                                Powerful Features for Every Workflow
                            </p>

                            {/* Supporting Description */}
                            <p className="text-[#7E8492] text-sm  md:text-base leading-relaxed mt-8 text-justify font-workSans">
                                GMind AI provides a range of features that adapt to your specific needs, saving you time and boosting your performance. Whether you're managing projects, creating content, or analyzing data, GMind AI has the features you need to work smarter, not harder.

                            </p>
                        </div>
                        {/* Main Image */}
                        <div className="w-full md:w-2/5 flex justify-center">
                            <img
                                src={doodle}
                                alt="Story image"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>
                    </div>
                    <div className="w-full bg-white py-16 h-full">
                        <Swiper
                            pagination={{
                                clickable: true,
                            }}
                            autoHeight={true}
                            modules={[Pagination]}
                            spaceBetween={16} // Default spacing between slides
                            slidesPerView={1} // Default for smaller screens
                            breakpoints={{
                                640: {
                                    slidesPerView: 1, // Single slide view on small screens
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2, // Two slides for medium screens
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 3, // Three slides for large screens
                                    spaceBetween: 40,
                                },
                            }}
                            className="w-full h-full"
                        >
                            {features.map((feature) => (
                                <SwiperSlide
                                    key={feature.id}
                                    className="flex flex-col items-center justify-between h-full w-full p-6 rounded-lg shadow-md bg-white border border-[#E5E5E5]"
                                >
                                    {/* Card Content */}
                                    <div className="flex flex-col items-center sm:items-start h-full">
                                        {/* Icon */}
                                        <div className="flex-shrink-0 mb-4">
                                            <img
                                                alt="icon"
                                                src={feature.icon}
                                                className="w-16 h-16 object-contain"
                                            />
                                        </div>
                                        {/* Title */}
                                        <h4 className="text-lg font-semibold font-archivo my-4 mb-2 text-left">
                                            {feature.title}
                                        </h4>
                                        {/* Text */}
                                        <p className="text-sm text-[#7E8492] text-left flex-1 tracking-tight">
                                            {feature.text}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    {/* Bottom-left Image */}
                    <img
                        src={bottom}
                        alt="Bottom decorative"
                        className="hidden md:block absolute -bottom-64 left-0 w-32 md:w-48 lg:w-64"
                    />
                </div>
            </section>
            <section className="w-full bg-white border-t border-[#E8E8E8]">
                <div className="w-full py-16 px-6 md:px-24 3xl:max-w-[1800px] 3xl:mx-auto 3xl:px-8">
                    {/* Centered Header Section */}
                    <div className="flex flex-col items-center text-center mb-12">
                        <span className="bg-[#FCEBBB59] px-4 py-1 text-sm font-medium rounded-full font-archivo">
                            GMind AI Tools
                        </span>
                        <h4 className="text-2xl sm:text-3xl md:text-5xl font-semibold font-archivo mt-4 md:max-w-xl md:leading-[56px]">
                            Special Features For Professionals!
                        </h4>
                        <p className="text-sm sm:text-base text-[#7E8492] mt-2 font-workSans">
                            Unlock powerful tools tailored for professionals
                        </p>
                    </div>

                    {/* Features 1 Section */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full mb-8 px-4 md:px-0">
                        {/* Feature Card */}
                        <div className="col-span-2 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden">
                            {/* Image Section */}
                            <div className="flex bg-gray-100/40 gap-4 p-4 md:pb-0">
                                {/* Smaller Image */}
                                <img
                                    src={syllabus}
                                    alt="Writing"
                                    className="w-1/2 object-cover rounded-lg"
                                />
                                {/* Larger Image */}
                                <img
                                    src={planner}
                                    alt="Organizing"
                                    className="w-1/2  object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:p-8">
                                <h5 className="text-xl md:text-2xl font-medium font-archivo text-[#111111] mb-4">
                                    Curriculum Planner
                                </h5>
                                <p className="text-sm md:text-base text-[#7E8492] font-workSans leading-relaxed">
                                    Transform you class room experience: Effortlessly create, organize, and optimize your lesson plans with our smart government approved  AI-powered curriculum planner.

                                </p>
                            </div>
                        </div>

                        {/* Placeholder for Additional Content */}
                        <div className="col-span-2 md:col-span-1 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden">
                            {/* Image Section */}
                            <div className="bg-gray-100/40 p-4 md:p-6">
                                <img
                                    src={activity}
                                    alt="Workflow"
                                    className="w-full object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:p-8">
                                <ul className="pl-5 space-y-4">
                                    <li className="flex items-center gap-2 text-sm md:text-base font-workSans text-[#7E8492]">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#24252A" />
                                        </svg>
                                        Syllabus Generator
                                    </li>
                                    <li className="flex items-center gap-2 text-sm md:text-base font-workSans text-[#7E8492]">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#24252A" />
                                        </svg>
                                        Lesson Planner

                                    </li>
                                    <li className="flex items-center gap-2 text-sm md:text-base font-workSans text-[#7E8492]">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#24252A" />
                                        </svg>
                                        Learning Activity Generator
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Features 2 */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full mb-8 px-4 md:px-0">
                        {/* Feature Card */}
                        <div className="col-span-2 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden order-2">
                            {/* Image Section */}
                            <div className="flex bg-gray-100/40 gap-4 p-4 md:pb-0">
                                {/* Smaller Image */}
                                <img
                                    src={campaign}
                                    alt="Writing"
                                    className="w-1/2 object-cover rounded-lg"
                                />
                                {/* Larger Image */}
                                <img
                                    src={insight}
                                    alt="Organizing"
                                    className="w-1/2  object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:p-8">
                                <h5 className="text-xl md:text-2xl font-medium font-archivo text-[#111111] mb-4">
                                    Business Resources
                                </h5>
                                <p className="text-sm md:text-base text-[#7E8492] font-workSans leading-relaxed">
                                    Access comprehensive AI tools that enhance productivity and decision-making for business professionals.
                                </p>
                            </div>
                        </div>


                        {/* Placeholder for Additional Content */}
                        <div className="col-span-2 md:col-span-1 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden order-1">
                            {/* Image Section */}
                            <div className="bg-gray-100/40 p-4 md:p-6">
                                <img
                                    src={tool}
                                    alt="Workflow"
                                    className="w-full object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:py-4">
                                <ul className="pl-5 space-y-4">
                                    <li className="flex items-center gap-2 text-sm md:text-base font-workSans text-[#7E8492]">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#24252A" />
                                        </svg>
                                        Copywriting Tool
                                    </li>
                                    <li className="flex items-center gap-2 text-sm md:text-base font-workSans text-[#7E8492]">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#24252A" />
                                        </svg>
                                        Campaign Ad Generator
                                    </li>
                                    <li className="flex items-center gap-2 text-sm md:text-base font-workSans text-[#7E8492]">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#24252A" />
                                        </svg>
                                        Data Visualization Tool
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    {/* Features 3 section */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full mb-8 px-4 md:px-0">
                        {/* Feature Card */}
                        <div className="col-span-2 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden">
                            {/* Image Section */}
                            <div className="flex bg-gray-100/40 gap-4 p-4 md:pb-0">
                                {/* Smaller Image */}
                                <img
                                    src={writer}
                                    alt="Writing"
                                    className="w-1/2 object-cover rounded-lg"
                                />
                                {/* Larger Image */}
                                <img
                                    src={maker}
                                    alt="Organizing"
                                    className="w-1/2  object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:p-8">
                                <h5 className="text-xl md:text-2xl font-medium font-archivo text-[#111111] mb-4">
                                    Media Resources
                                </h5>
                                <p className="text-sm md:text-base text-[#7E8492] font-workSans leading-relaxed">
                                    Create, edit, and manage media effortlessly with advanced AI tools for content creation and multimedia projects

                                </p>
                            </div>
                        </div>

                        {/* Placeholder for Additional Content */}
                        <div className="col-span-2 md:col-span-1 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden">
                            {/* Image Section */}
                            <div className="bg-gray-100/40 p-4 md:p-6">
                                <img
                                    src={model}
                                    alt="Workflow"
                                    className="w-full object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:p-8">
                                <ul className="pl-5 space-y-4">
                                    <li className="flex items-center gap-2 text-sm md:text-base font-workSans text-[#7E8492]">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#24252A" />
                                        </svg>
                                        Blog/News Writer
                                    </li>
                                    <li className="flex items-center gap-2 text-sm md:text-base font-workSans text-[#7E8492]">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#24252A" />
                                        </svg>
                                        Image Generator
                                    </li>
                                    <li className="flex items-center gap-2 text-sm md:text-base font-workSans text-[#7E8492]">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#24252A" />
                                        </svg>
                                        Speech Sythesizer
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Features 4*/}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full mb-8 px-4 md:px-0">
                        {/* Feature Card */}
                        <div className="col-span-2 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden order-2">
                            {/* Image Section */}
                            <div className="flex bg-gray-100/40 gap-4 p-4 md:pb-0">
                                {/* Smaller Image */}
                                <img
                                    src={sheet}
                                    alt="Writing"
                                    className="w-1/2 object-cover rounded-lg"
                                />
                                {/* Larger Image */}
                                <img
                                    src={assesment}
                                    alt="Organizing"
                                    className="w-1/2  object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:p-8">
                                <h5 className="text-xl md:text-2xl font-medium font-archivo text-[#111111] mb-4">
                                    Class Resources
                                </h5>
                                <p className="text-sm md:text-base text-[#7E8492] font-workSans leading-relaxed">
                                    Empower your teaching with AI-enhanced class resources that simplify lesson preparation and engage students more effectively
                                </p>
                            </div>
                        </div>


                        {/* Placeholder for Additional Content */}
                        <div className="col-span-2 md:col-span-1 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden order-1">
                            {/* Image Section */}
                            <div className="bg-gray-100/40 p-4 md:p-6">
                                <img
                                    src={slide}
                                    alt="Workflow"
                                    className="w-full object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:py-4">
                                <ul className="pl-5 space-y-4">
                                    <li className="flex items-center gap-2 text-sm md:text-base font-workSans text-[#7E8492]">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#24252A" />
                                        </svg>
                                        Slide Maker
                                    </li>
                                    <li className="flex items-center gap-2 text-sm md:text-base font-workSans text-[#7E8492]">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#24252A" />
                                        </svg>
                                        Worksheet Generator

                                    </li>
                                    <li className="flex items-center gap-2 text-sm md:text-base font-workSans text-[#7E8492]">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#24252A" />
                                        </svg>
                                        Assessment Generator
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Testimonials />
            <section className="w-full bg-white relative overflow-hidden">
                <div className="w-full py-16 px-6 md:px-24 3xl:max-w-[1800px] 3xl:mx-auto 3xl:px-8">
                    {/* Content Section */}
                    <div className="flex flex-col-reverse md:flex-row-reverse items-center gap-8">
                        {/* Left - Image */}
                        <div className="flex-1">
                            <img
                                src={today}
                                alt="Mobile"
                                className="w-full h-auto object-contain"
                            />
                        </div>

                        {/* Right - Text Content */}
                        <div className="flex-1">
                            <h4 className="text-2xl sm:text-3xl md:text-5xl font-medium font-archivo text-[#111111] md:leading-[70px] tracking-wider">
                                Want To Get Started?
                            </h4>
                            <p className='font-workSans text-sm text-[#7E8492] mb-8'>Simple, Effective, and Intuitive. GMind AI is an easy to use AI platform built for everyone. No prompt engineering required. </p>

                            {/* Features List */}
                            <div className="space-y-3">
                                {/* Feature 1 */}
                                <div className="flex items-start gap-4">
                                    {/* Icon and Line */}
                                    <div className="flex flex-col items-center">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="mb-2"
                                        >
                                            <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                        </svg>
                                        <svg
                                            width="3"
                                            height="45"
                                            viewBox="0 0 3 45"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line
                                                x1="1.5"
                                                y1="45"
                                                x2="1.5"
                                                y2="0"
                                                stroke="#24252A"
                                                strokeWidth="2"
                                                strokeDasharray="6 6"
                                            />
                                        </svg>
                                    </div>

                                    {/* Text Content */}
                                    <div>
                                        <h5 className="text-lg font-semibold text-[#24252A] mb-1 font-archivo md:text-xl">
                                            Sign Up
                                        </h5>
                                        <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                            Create your free account.
                                        </p>
                                    </div>
                                </div>

                                {/* Feature 2 */}
                                <div className="flex items-start gap-4">
                                    {/* Icon and Line */}
                                    <div className="flex flex-col items-center">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="mb-2"
                                        >
                                            <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                        </svg>
                                        <svg
                                            width="3"
                                            height="45"
                                            viewBox="0 0 3 45"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line
                                                x1="1.5"
                                                y1="45"
                                                x2="1.5"
                                                y2="0"
                                                stroke="#24252A"
                                                strokeWidth="2"
                                                strokeDasharray="6 6"
                                            />
                                        </svg>
                                    </div>

                                    {/* Text Content */}
                                    <div>
                                        <h5 className="text-lg font-semibold text-[#24252A] mb-1 font-archivo md:text-xl">
                                            Explore Features
                                        </h5>
                                        <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                            Discover the powerful AI tools tailored to your needs.
                                        </p>
                                    </div>
                                </div>

                                {/* Feature 3 */}
                                <div className="flex items-start gap-4">
                                    {/* Icon */}
                                    <div className="flex flex-col items-center">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                        </svg>
                                        <svg
                                            width="3"
                                            height="45"
                                            viewBox="0 0 3 45"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line
                                                x1="1.5"
                                                y1="45"
                                                x2="1.5"
                                                y2="0"
                                                stroke="#24252A"
                                                strokeWidth="2"
                                                strokeDasharray="6 6"
                                            />
                                        </svg>
                                    </div>

                                    {/* Text Content */}
                                    <div>
                                        <h5 className="text-lg font-semibold text-[#24252A] mb-1 font-archivo md:text-xl">
                                            Customize & Automate
                                        </h5>
                                        <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                            Set up automated workflows and task management.
                                        </p>
                                    </div>
                                </div>

                                {/* Feature 4 */}
                                <div className="flex items-start gap-4">
                                    {/* Icon */}
                                    <div className="flex flex-col items-center">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                        </svg>
                                        <svg
                                            width="3"
                                            height="45"
                                            viewBox="0 0 3 45"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line
                                                x1="1.5"
                                                y1="45"
                                                x2="1.5"
                                                y2="0"
                                                stroke="#24252A"
                                                strokeWidth="2"
                                                strokeDasharray="6 6"
                                            />
                                        </svg>
                                    </div>

                                    {/* Text Content */}
                                    <div>
                                        <h5 className="text-lg font-semibold text-[#24252A] mb-1 font-archivo md:text-xl">
                                            Boost Productivity
                                        </h5>
                                        <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                            Experience a new level of efficiency in your daily work.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-wrap gap-4 mt-8">
                                {/* Try GMind AI Free Button */}
                                <GetStartedForFree
                                    isLoggedIn={isLoggedIn}
                                    className="px-4 py-2  text-white rounded-2xl hover:bg-white hover:text-black transition duration-300"
                                    onclick={() => {
                                        handleGuestLogin()
                                    }}
                                />
                                {/* Explore Pricing Button */}
                                <a
                                    onClick={() => navigatePage(isLoggedIn ? '/dashboard/pricing' : '/pricing')}
                                    className="cursor-pointer flex items-center gap-2 px-6 py-2 border border-[#E9EAE9] text-black rounded-2xl hover:bg-white hover:text-black transition duration-300"
                                >
                                    <span>Explore Pricing</span>
                                    <span>
                                        <svg
                                            width="12"
                                            height="10"
                                            viewBox="0 0 12 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M11.4879 5.33166L7.26914 9.55041C7.18118 9.63836 7.06189 9.68778 6.9375 9.68778C6.81311 9.68778 6.69381 9.63836 6.60586 9.55041C6.5179 9.46245 6.46849 9.34316 6.46849 9.21877C6.46849 9.09438 6.5179 8.97508 6.60586 8.88713L10.0248 5.46877H0.84375C0.71943 5.46877 0.600201 5.41938 0.512294 5.33147C0.424386 5.24357 0.375 5.12434 0.375 5.00002C0.375 4.8757 0.424386 4.75647 0.512294 4.66856C0.600201 4.58065 0.71943 4.53127 0.84375 4.53127H10.0248L6.60586 1.11291C6.5179 1.02495 6.46849 0.905656 6.46849 0.781267C6.46849 0.656877 6.5179 0.537582 6.60586 0.449626C6.69381 0.361669 6.81311 0.312256 6.9375 0.312256C7.06189 0.312256 7.18118 0.361669 7.26914 0.449626L11.4879 4.66838C11.5315 4.71191 11.566 4.76361 11.5896 4.82051C11.6132 4.87742 11.6254 4.93842 11.6254 5.00002C11.6254 5.06162 11.6132 5.12261 11.5896 5.17952C11.566 5.23643 11.5315 5.28812 11.4879 5.33166Z"
                                                fill="#24252A"
                                            />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="w-full bg-white">
                <div className="w-full py-16 px-6 md:px-24 3xl:max-w-[1800px] 3xl:mx-auto 3xl:px-8">
                    <Faq />
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Features4;

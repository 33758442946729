import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import BackButton from '@src/components/BackButton';
import { useNavigate, useOutletContext } from 'react-router';
import { BASE_URL } from '@src/utils/constants';
import { toast } from 'react-toastify';
import { handleAutoGenerateMetaTag } from '../helper';
import { Table, Tooltip } from 'antd';

import { CopyOutlined } from '@ant-design/icons';

import './style.scss';
import { isHtmlString } from '@src/utils/app_functions';
import { marked } from 'marked';
import { convertHTMLToPDF, convertHTMLToWord } from '../../essay/functions';
import { Download } from '@src/pages/dashboard/components/Share/Download';
interface MetaTagInterface {
    title: string;
    description: string;
    openGraph: string;
    keywords: string;
}

const MetaTagGenerator: React.FC = () => {
    const [metaUrl, setMetaUrl] = useState<string>('');
    const [tag, setTag] = useState<MetaTagInterface[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const {
        setShowTop,
        setShowSidebar,
    } = useOutletContext<any>();
    const [pdfDownloading, setPdfDownloading] = useState(false);
    const [wordDownloading, setWordDownloading] = useState(false);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const UrlRef = useRef<string>('');

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text: string) => (
                <div className="table-cell-with-copy">
                    <span className='min-w-[15rem]'>{text}</span>
                    <Tooltip title="Copy">
                        <CopyOutlined
                            className="copy-icon"
                            onClick={() => {
                                navigator.clipboard.writeText(text);
                                toast.success('Title copied to clipboard!');
                            }}
                        />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text: string) => (
                <div className="table-cell-with-copy">
                    <span className='min-w-[15rem]'>{text}</span>
                    <Tooltip title="Copy">
                        <CopyOutlined
                            className="copy-icon h-10"
                            onClick={() => {
                                navigator.clipboard.writeText(text);
                                toast.success('Description copied to clipboard!');
                            }}
                        />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Keywords',
            dataIndex: 'keywords',
            key: 'keywords',
            render: (text: string) => (
                <div className="table-cell-with-copy">
                    <span className='min-w-[15rem]'>{text}</span>
                    <Tooltip title="Copy">
                        <CopyOutlined
                            className="copy-icon"
                            onClick={() => {
                                navigator.clipboard.writeText(text);
                                toast.success('Keywords copied to clipboard!');
                            }}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMetaUrl(event.target.value);
    };

    const ensureHttp = (url: string) => {
        // Check if the string starts with http://, https://, or www.
        const hasProtocolOrWWW = /^(http:\/\/|https:\/\/|www\.)/i;
        if (!hasProtocolOrWWW.test(url)) {
            return `http://${url}`;
        }
        return url;
    }

    const fetchTranscript = async () => {
        const apiUrl = `${BASE_URL}/gmind/site_extractor`;

        setTag([]);
        const token = localStorage.getItem('token');

        if (!metaUrl) {
            toast.error('Enter your website url');
        }

        

        const payload = { site_url: ensureHttp( metaUrl )}; // Correct payload structure
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(apiUrl, JSON.stringify(payload), {
                headers: {
                    'Authorization': `Bearer ${token}`, // Replace with your token
                    'Content-Type': 'application/json', // Ensures the correct content type is sent
                },
            });

            const textExtracted = response.data?.data.text_extracted;

            const meta = await handleAutoGenerateMetaTag(textExtracted);

            setTag((prevTag) => [...prevTag, meta as MetaTagInterface]);
            toast.success('Transcript fetched successfully');
        } catch (err:any) {
            console.error(err);
            toast.error(err.response.data.message);
        } finally {
            setLoading(false); // Always reset loading state
        }
    };

    const navigate = useNavigate();
    useEffect(() => {
        setShowSidebar(true);
        setShowTop(true);
    }, []);

    async function handlePDF() {
        let data = '';
        const metaTable = document.getElementById('meta-table');
        const editorContent = metaTable?.innerHTML;
        console.log(editorContent);

        if (!editorContent) {
            toast.error('No content to download');
            return;
        }

        // Temporarily hide copy icons
        const copyIcons = metaTable?.querySelectorAll('.copy-icon');
        copyIcons?.forEach((icon) => {
            if (icon instanceof HTMLElement) {
                icon.style.display = 'none'; // Hide copy icons
            }
        });

        if (isHtmlString(editorContent)) {
            data = editorContent;
        } else {
            data = await marked(editorContent);
        }

        try {
            setPdfDownloading(true);
            await convertHTMLToPDF(data);
        } catch (error) {
            toast.error('Error downloading documents');
        } finally {
            // Restore copy icons after download
            copyIcons?.forEach((icon) => {
                if (icon instanceof HTMLElement) {
                    icon.style.display = '';
                }
            });
            setPdfDownloading(false);
        }
    }

    async function handleWord() {
        let to_html = '';
        const metaTable = document.getElementById('meta-table');
        const editorContent = metaTable?.innerHTML;

        if (!editorContent) {
            toast.error('No content to download');
            return;
        }

        // Temporarily remove buttons or specific elements
        const buttons = metaTable?.querySelectorAll('button');
        buttons?.forEach((button) => (button.style.display = 'none')); // Hide buttons

        if (isHtmlString(editorContent)) {
            to_html = editorContent;
        } else {
            to_html = await marked(editorContent);
        }

        try {
            setWordDownloading(true);
            await convertHTMLToWord(to_html);
        } catch (error) {
            toast.error('Error downloading documents');
        } finally {
            // Restore buttons after download
            buttons?.forEach((button) => (button.style.display = ''));
            setWordDownloading(false);
        }
    }

    return (
        <div className="h-screen flex w-[85%] px-10 flex-col items-start gap-3 pt-6">
            <BackButton
            
                onclick={() => {
                    navigate(-1);
                }}
            />
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mt-2 text-center">Meta Tag Generator</h2>
            <p className="text-gray-600 dark:text-gray-100 mb-4">Enter url to generate a meta tag</p>
            <textarea
                value={metaUrl}
                onChange={handleInputChange}
                placeholder="Paste your website URL here"
                rows={4}
                cols={50}
                className="border border-gray-500 dark:border-gray-200 rounded-md p-2  w-full md:w-[50%] dark:text-gray-100"
            />
            <br />
            <button
                onClick={fetchTranscript}
                disabled={!metaUrl || loading}
                className={` px-4 py-2 rounded-md ${!metaUrl || loading ? ' bg-gray-300 text-black' : 'bg-customOrange text-white'}`}
            >
                {loading ? 'Fetching...' : 'Get Transcript'}
            </button>

            {tag.length > 0 && (
                <div className="meta-tag-table-container w-full" id='meta-table'>
                    <h2 className="table-header">Meta Tag Details</h2>
                    <Table
                        dataSource={tag}
                        columns={columns}
                        rowKey={(record) => record.title}
                        bordered
                        pagination={{ pageSize: 5 }}
                        className="custom-meta-table"
                    />
                </div>
            )}

            {tag.length > 0 && (
                <button
                    className=" text-white bg-customOrange rounded py-2 px-3 mb-20 ml-3"
                    onClick={() => setShowDownload(true)}
                >
                    Download
                </button>
            )}

            {showDownload && (
                <Download
                    handlePDF={handlePDF}
                    handleWord={handleWord}
                    url={UrlRef.current}
                    onClose={() => setShowDownload(false)}
                    isPdfDownloading={pdfDownloading}
                    isWordDownloading={wordDownloading}
                />
            )}
        </div>
    );
};

export default MetaTagGenerator;

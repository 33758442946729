import React from 'react';
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { acquisitionRoutes } from '@src/utils/app_routes';
import { googleSignIn } from '@src/pages/acquisition/google_auth/google';

interface LoginPromptModalProps {
    visible: boolean;
    isNotSignedIn?: boolean;
}

const LoginPromptModal: React.FC<LoginPromptModalProps> = ({ visible, isNotSignedIn }) => {
    const navigate = useNavigate();

    const handleLoginRedirect = () => {
        const pathUrl = window.location;
        console.log(pathUrl);
       isNotSignedIn ? navigate(`${acquisitionRoutes.getStarted}?callback=${pathUrl}`) : navigate(`${acquisitionRoutes.login}?callback=${pathUrl}`); // Adjust the path if your login route is different
    };

    const handleGoogleRedirect = async () => {
        await googleSignIn();
    };

    return (
        <Modal
            // title={<span className="text-xl font-semibold text-center">Sign up Or Login To Continue Searching</span>}
            open={visible}
            centered
            closable
            maskClosable={false} // Prevent closing by clicking outside
            keyboard={false}
            footer={null} // Disable default footer
            closeIcon={<div></div>}
            width={500}
        >
            <h2 className="self-center text-xl font-semibold text-center mx-[10%]">Sign up Or Login To Continue Searching</h2>
            {/* <p className="text-lg font-medium">{isNotSignedIn ? 'Please sign up to access this feature.':'Please log in to access the dashboard.'}</p> */}
            <div className="flex flex-col gap-y-3 justify-end space-x-2 mt-4 mx-3">
                <Button
                    className="font-optimistic py-3 !h-auto text-lg font-medium bg-[#ed5405] text-white"
                    onClick={handleGoogleRedirect}
                >
                   Continue with Google
                </Button>
                <Button
                    className="font-optimistic py-3 !h-auto text-lg font-medium bg-transparent border border-[#ed5405]  text-black"
                    onClick={handleLoginRedirect}
                >
                   Continue with Email
                </Button>
            </div>
        </Modal>
    );
};
export default LoginPromptModal;

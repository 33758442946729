import { Button, Form, Modal, Tooltip } from 'antd';
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import Input from '@src/pages/dashboard/components/Input';
import { FileContent } from '@src/core/interfaces/chat';

import { BASE_URL } from '@src/utils/constants';
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

import {
    CodeOutlined,
    CalculatorOutlined,
    ExperimentOutlined,
    EditOutlined,
    AppstoreAddOutlined,
} from '@ant-design/icons';
import { useNavigate, useOutletContext } from 'react-router';
import { convertStringToDelta, getUrlPath } from '@src/utils/app_functions';
import axios from 'axios';
import saveAs from 'file-saver';
import moment from 'moment';
import { pdfExporter } from 'quill-to-pdf';
import { toast } from 'react-toastify';
import Markdown from '@src/components/Markdown';
import ResponseTextbox from '@src/pages/dashboard/components/responseTextBox';
import { Share } from '@src/pages/dashboard/components/Share';
import { Download } from '@src/pages/dashboard/components/Share/Download';
import { ChatContent, Chats, isChatContentArray, ResponseSources } from '../../../../components/interface/interface';
import { ThemeContext } from '@src/theme_provider/theme';
import { ChatScreen } from '@src/pages/dashboard/components/chatScreen';
import { determineCategory } from '.';
import BackButton from '@src/components/BackButton';

const AssignmentSolver = () => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [extractedFile, setExtractedFile] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [value, setValue] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [isTyping, setIsTyping] = useState(false);
    const bottomRef = useRef<HTMLDivElement>(null);
    const UrlRef = useRef<string>('');
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [category, setCategory] = useState<string | null>(null);
    const StreamId = useRef<string>('');
    const token = localStorage.getItem('token');
    const currentEntity = useRef<Chats>({ role: 'user', content: '', type: 'gpt-4o' });
    const _chatsRef = useRef<Chats[]>([]);

    const {
        setShowTop,
        setShowSidebar,
        studentChatList,
        setStudentChatList,
        response,
        setResponse,
        details,
        historyId,
    } = useOutletContext<any>();
    const [prompt, setPrompt] = useState<string>('');
    const [streaming, setStreaming] = useState(false);
    const useInternet = false;
    const [img, setImg] = useState<File | null>(null);

    const { isMobile } = useContext(ThemeContext);
    const [editedContent, setEditedContent] = useState('');
    const [useSuggestiveInput, setUseSuggestiveInput] = useState(false);
    const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>([]);
    useEffect(() => {
        if (isTyping && studentChatList.length > 0) {
            // console.log("scrolling")
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [isTyping, studentChatList]);
    useEffect(() => {
        const _socket = io(BASE_URL.slice(0, -2));
        setSocket(_socket);

        setStudentChatList([]);
    }, []);

    useEffect(() => {
        if (!socket) return;
        socket.on('data', (data: string) => {
            !streaming && setStreaming(true);
            if (currentEntity.current.role !== 'assistant') {
                currentEntity.current = { role: 'assistant', content: data };
                setStudentChatList((c: any) => [...c, { role: 'assistant', content: data }]);
                return;
            }

            const updateCallback = (current: Chats[]) => {
                const updated = current.map((item, i) => {
                    if (i === current.length - 1) {
                        if (typeof item.content === 'string') {
                            return { ...item, content: item.content + data };
                        } else if (isChatContentArray(item.content)) {
                            // If content is ChatContent[], find and update the 'text' type
                            const updatedContent = item.content.map((content) => {
                                if (content.type === 'text' && content.text) {
                                    return { ...content, text: content.text + data };
                                }
                                return content;
                            });
                            return { ...item, content: updatedContent };
                        }
                    }
                    return item;
                });
                // console.log(updated);
                return updated;
            };

            setStudentChatList(updateCallback);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: Chats[] }) => {
            setIsTyping(false);
            const { streamId, assistant } = data;
            if (streamId === StreamId.current) {
                setStreaming(false);
                const uuid = uuidv4();

                if (!historyId.current) {
                    historyId.current = uuid;
                }

                // const response = await SaveHistory(token, {
                //     conversation: assistant,
                //     chat_id: historyId.current ?? uuid,
                // });

                // if (response?.statusCode === 1) {
                //     getHistory();
                //     localStorage.setItem('activeChat', JSON.stringify(_chatsRef.current));
                //     localStorage.setItem('lastChat', JSON.stringify(_chatsRef.current));
                // }
            }
        });
        // socket.on('suggested_questions', (suggestedQuestionData: string[]) => {
        //     console.log(suggestedQuestionData);
        //     setSuggestedQuestions(suggestedQuestionData);
        // });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.type.startsWith('image/')) {
                const file = item.getAsFile();
                console.log('File', file);
                if (file) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImg(file);
                    };
                    reader.readAsDataURL(file);
                }
                break; // Only handle the first image
            }
        }
    };

    function handleChat(chat: any) {
        let promptMessage = '';
        const baseMessage = extractedFile
            ? `Please review the attached file for context: ${extractedFile}.`
            : `Here is the user's input: "${chat}"`;

        switch (category) {
            case 'Coding':
                promptMessage = `
                You are a knowledgeable coding mentor guiding a curious student.
                Respond conversationally to their question or problem, providing a clear explanation and code examples where necessary.
                After answering, ask a relevant follow-up question to deepen their understanding.
                Note: if the ${category} does not match user question, please respond with "I'm sorry, I can't help with that."
                Note: Always give explanation and code examples.

                Example:
                User: What is the difference between a star and a planet? and category === 'Coding' then respond with "I'm sorry, This is not a ${category} question."
    
                
                ${baseMessage}`;
                break;

            case 'Mathematics':
                promptMessage = `
                You are a mathematics tutor helping a student learn.
                Provide a detailed solution to their problem with step-by-step reasoning.
                After explaining the solution, ask a follow-up question to test their comprehension or extend the discussion.
                Note: if the ${category} does not match user question, please respond with "I'm sorry, This is not a ${category} question."

                Example:
                User: What is the difference between a star and a planet? and category === 'Coding' then respond with "I'm sorry, This is not a ${category} question."
    
    
                ${baseMessage}`;
                break;

            case 'Science':
                promptMessage = `
                You are a science teacher engaging in a discussion with a student.
                Explain the scientific concept or solve the problem clearly and in simple terms.
                Follow up with a thought-provoking question to encourage further curiosity.

                Note: if the ${category} does not match user question, please respond with "I'm sorry, This is not a ${category} question."

                Example:
                User: What is the difference between a star and a planet? and category === 'Coding' then respond with "I'm sorry, This is not a ${category} question."
    
                ${baseMessage}`;
                break;

            default:
                promptMessage = `
                You are a helpful and conversational assistant.
                Respond to the user's query with a clear and thoughtful answer.
                Afterward, ask a follow-up question to keep the conversation going.
    
                ${baseMessage}`;
                break;
        }

        setPrompt(promptMessage.trim());
        setUseSuggestiveInput(true);

        setIsTyping(true);
        const uuid = uuidv4();
        StreamId.current = uuid;

        socket?.emit(
            'data',
            {
                data: {
                    messages: [{ role: 'system', content: promptMessage.trim(), type: 'gpt-4o' }, ...chat],
                    userId: details?.userId,
                },
                streamId: uuid,
            },
            true,
            useInternet,
            true,
        );
    }

    // useEffect(() => {
    //     setStudentChatList([]);
    //     currentEntity.current = {
    //         role: 'user',
    //         content: `Hello Gmind AI my name is ${details?.firstName} and i need your help`,
    //     };
    //     setStudentChatList((prevChats) => [...prevChats, { role: 'user', content: '' }]);
    //     _chatsRef.current = [
    //         ...studentChatList,
    //         { role: 'user', content: `Hello Gmind AI my name is ${details?.firstName} and i need your help` },
    //     ];
    //     handleChat([
    //         ...studentChatList,
    //         {
    //             role: 'user',
    //             content: `Hello Gmind AI my name is ${details?.firstName} and i need your help`,
    //             type: 'gpt-4',
    //         },
    //     ]);
    // }, [socket]);

    const handleCardClick = (selectedCategory: string) => {
        setCategory(selectedCategory);

        let promptMessage = '';
        switch (selectedCategory) {
            case 'Coding':
                promptMessage = 'I have a question about coding.';
                break;
            case 'Mathematics':
                promptMessage = 'I need help with mathematics.';
                break;
            case 'Science':
                promptMessage = 'I have a question about science.';
                break;
            default:
                promptMessage = 'I have a question.';
        }

        currentEntity.current = { role: 'user', content: promptMessage };

        setStudentChatList((prevChats) => [...prevChats, { role: 'user', content: promptMessage }]);

        handleChat([...studentChatList, { role: 'user', content: promptMessage, type: 'gpt-4' }]);
        _chatsRef.current = [...studentChatList, { role: 'user', content: promptMessage }];

        // Simulate sending the prompt to the server
        setPrompt(promptMessage);
    };

    const cardStyle = (selected: boolean) =>
        `py-1 px-3 border rounded-full w-[10rem] mt-5 flex flex-row gap-x-2 items-center justify-center cursor-pointer ${selected ? 'border-blue-100' : 'border-blue-400 hover:bg-custom-opacity-2'}`;

    useEffect(() => {
        setShowSidebar(true);
        setShowTop(true);
    }, []);

    const navigate = useNavigate();

    return (
        <div className="w-full flex flex-col h-[85%] justify-between items-center relative">
            {/* <div className="text-[16px] font-normal mt-[6rem] text-white flex flex-row items-start w-[90%]  ">
                <p className='bg-gray-600 p-4 rounded-full w-auto'>
                Hello, what can I do for you today?
                </p></div> */}
            {/* Options */}
            <div className='flex ml-10 mt-10 flex-row items-start justify-start w-full '>
            <BackButton
                onclick={() => {
                    navigate(-1);
                }}
            />
            </div>
           
            {!category && (
                <div className=" absolute top-1/4 flex  flex-col  items-center px-2 md:px-0 w-full space-y-2 space-x-4 mb-6">
                    <p className="text-2xl font-bold text-gray-800 dark:text-white">
                        Hi <span className="text-customOrange">{details?.firstName}</span>, Welcome
                    </p>
                    <p className="text-lg text-center text-gray-600 dark:text-gray-100">
                        what assignment can i help you with today?
                    </p>
                    <div className="flex  flex-row flex-wrap gap-x-4 gap-y-2 ">
                        {[
                            { label: 'Coding', icon: <CodeOutlined /> },
                            { label: 'Mathematics', icon: <CalculatorOutlined /> },
                            { label: 'Science', icon: <ExperimentOutlined /> },
                            { label: 'Others', icon: <AppstoreAddOutlined /> },
                        ].map((option) => (
                            <div
                                key={option.label}
                                className={cardStyle(selectedOption === option.label)}
                                onClick={() => {
                                    setCategory(option.label);
                                    handleCardClick(option.label);
                                }}
                            >
                                <div className=" text-xl md:text-2xl  dark:text-white">{option.icon}</div>
                                <div className="dark:text-white">{option.label}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}{' '}
            <div className="overflow-auto left-0 right-0 top-0 h-[90vh] w-full" ref={bottomRef}>
                <div className="w-[30%]">
                    {' '}
                    {getUrlPath().pagename === 'assignment_helper' && studentChatList.length !== 0 && (
                        <Tooltip
                            className="custom-tooltip hidden md:flex z-50"
                            title="Create New Chat"
                            placement="right"
                            overlayInnerStyle={{
                                backgroundColor: 'white',
                                color: 'black',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                            }}
                        >
                            <button
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    left: '0',
                                    right: '0',
                                    top: '0',
                                }}
                                onClick={() => {
                                    setCategory(null);
                                    setStudentChatList([]);
                                    localStorage.removeItem('activeChat');
                                    historyId.current = undefined;
                                }}
                                className="flex items-center  justify-center ml-6 w-6 h-6 lg:w-10 lg:h-10  md:bg-transparent border-[1.5px] border-gray-700 dark:border-white text-gray-700 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none shadow-md z-[9999999]"
                            >
                                <EditOutlined />
                            </button>
                        </Tooltip>
                    )}
                </div>

                <ChatScreen
                    editedContent={editedContent}
                    setEditedContent={setEditedContent}
                    isMobile={isMobile}
                    isWorkFlow={true}
                    actions={(v, i) => {
                        // handleAction(v, i);
                        // const selectedChat = studentChatList[i];
                        setCategory(null);
                        setStudentChatList([]);
                        localStorage.removeItem('activeChat');
                        historyId.current = undefined;
                    }}
                    streaming={streaming}
                    backClick={() => {
                        setStudentChatList([]);
                        localStorage.removeItem('activeChat');
                        historyId.current = undefined;
                    }}
                    handleUpdatedChat={() => {
                        currentEntity.current = { role: 'user', content: editedContent };
                        setStudentChatList([
                            ...studentChatList,
                            { role: 'user', content: editedContent, type: 'gpt-4' },
                        ]);
                        _chatsRef.current = [...studentChatList, { role: 'user', content: editedContent }];
                        handleChat([...studentChatList, { role: 'user', content: editedContent, type: 'gpt-4' }]);
                    }}
                    chatList={studentChatList}
                    showNavBar={true}
                    typing={isTyping}
                    suggestions={[]}
                    handleSuggestionClick={(question) => {
                        currentEntity.current = { role: 'user', content: question };
                        setStudentChatList([...studentChatList, { role: 'user', content: question, type: 'gpt-4' }]);
                        _chatsRef.current = [...studentChatList, { role: 'user', content: question }];
                        handleChat([...studentChatList, { role: 'user', content: question, type: 'gpt-4' }]);
                    }}
                    usingSuggestPrompt={false}
                />
            </div>
            <div className=" sticky absolute bottom-0  flex-1 flex flex-col w-[90%] gap-y-5 ">
                {/* {!category && (
                    <div className=" flex justify-center items-center h-auto">
                        <div className="max-w-5xl w-full p-6 bg-white rounded-lg shadow-md">
                            <div className="mb-6">
                                <h1 className="text-2xl font-semibold text-gray-800">Assignment helper</h1>
                                <p className="text-gray-600">Choose your assignment category</p>
                            </div>

                            <div className="grid grid-cols-3 gap-4">
                                <div className={optionStyle('Coding')} onClick={() => handleClick('Coding')}>
                                    <CodeOutlined className="text-2xl text-gray-800 mb-2" />
                                    <p className="text-sm text-gray-800 font-medium">Code Assist</p>
                                </div>

                                <div className={optionStyle('Mathematics')} onClick={() => handleClick('Mathematics')}>
                                    <CalculatorOutlined className="text-2xl text-gray-800 mb-2" />
                                    <p className="text-sm text-gray-800 font-medium">Maths solver</p>
                                </div>

                                <div className={optionStyle('Science')} onClick={() => handleClick('Science')}>
                                    <ExperimentOutlined className="text-2xl text-gray-800 mb-2" />
                                    <p className="text-sm text-gray-800 font-medium">Science sage</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}
                {category && (
                   <div className="sticky bottom-0 flex w-full justify-center items-center mx-auto dark:bg-[#212121]  p-4">
                   <div className="mx-auto flex w-full max-w-screen-lg justify-center items-center">
                       <Input
                           Value={value}
                           setValue={setValue}
                           onPaste={handlePaste}
                           img={img}
                           setImg={setImg}
                           showLeftField={false}
                           value={(v: string, image: string[], files: FileContent[]) => {
                               const contentValue: ChatContent[] = [
                                   {
                                       type: 'text',
                                       text: v,
                                   },
                               ];
                               if (image.length !== 0) {
                                   for (let i = 0; i < image.length; i++) {
                                       contentValue.push({
                                           type: 'image_url',
                                           image_url: {
                                               url: image[i],
                                           },
                                       });
                                   }
                               }
                               if (files.length > 0) {
                                   contentValue.push({
                                       type: 'files',
                                       files: files,
                                   });
                               }
               
                               if (!category) {
                                   toast.error('Please select a category first');
                                   return;
                               }
               
                               currentEntity.current = { role: 'user', content: contentValue };
                               setStudentChatList([
                                   ...studentChatList,
                                   { role: 'user', content: contentValue, type: 'gpt-4' },
                               ]);
                               _chatsRef.current = [...studentChatList, { role: 'user', content: contentValue }];
                               handleChat([
                                   ...studentChatList,
                                   { role: 'user', content: contentValue, type: 'gpt-4' },
                               ]);
                           }}
                       />
                   </div>
               </div>
               
                )}
            </div>
        </div>
    );
};

export default AssignmentSolver;

import useFormStore from '@src/providers/formStore';
import usePowerPointStore from '@src/providers/store';
import React, { useEffect, useState } from 'react';
import {
    generateOpenAimage,
    generateSonObjects,
    gradeOptions,
    handleAutoGenerate,
    handleAutoGenerateForKids,
} from '../../helper';
import { Button, Input, Select } from 'antd';
import { toast } from 'react-toastify';
import './global.scss';
import { DownOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
// import { Button } from "@/components/ui/button";
// import { Input } from "@/components/ui/input";
// import { Label } from "@/components/ui/label";
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from "@/components/ui/select";
// import { Textarea } from "@/components/ui/textarea";
// import usePowerPointStore, { Slide } from "@/app/providers/store";
// import useFormStore from "@/app/providers/formStore";
// import {
//   generateSonObjects,
//   handleAutoGenerate,
// } from "@/app/dashboard/new-slide/helper";
// import { ThreeDots } from "react-loader-spinner";
const { TextArea } = Input;
import { BASE_URL } from '@src/utils/constants';
import { io, Socket } from 'socket.io-client';
import PreviewPage2 from '../preview_page2';
import { onGenerateWithAI } from '../../../curriculum/functions';
import form from 'antd/es/form';
import { uploadToCloudinary } from '../../upload';
import { useForm } from 'antd/es/form/Form';
import ThirdForm from './third-form';
import { ChevronLeft } from 'lucide-react';
import BackButton from '@src/components/BackButton';
import { useOutletContext } from 'react-router';
const { Option } = Select;

type Props = {};
interface LoadingState {
    [key: number]: boolean;
}
interface SlideData {
    id: number;
    title: string;
    details: string;
    imageType: 'AI Generated' | 'Manual';
    aiCommand: string;
    uploadedImage?: string;
    previewImage?: string;
    loadingState: boolean;
    isUploading: boolean;
}

const FirstForm = ({ setCurrentPage }: { setCurrentPage: any }) => {
    const setSlidesDescription = usePowerPointStore((state) => state.setSlidesDescription);
    const setPages = usePowerPointStore((state) => state.setPages);
    const setContentSource = usePowerPointStore((state) => state.setContentSource);
    const nextStep = useFormStore((state) => state.nextStep);
    // const slideDescription = usePowerPointStore((state) => state.slidesDescription);

    // const setSlides = usePowerPointStore((state) => state.setSlides);

    const [slidesDescription, setSlideDescription] = useState('');
    const [loading, setIsLoading] = useState(false);
    const [slidePages, setSlidePages] = useState<number | undefined>(undefined);
    const [contentSource, setContentSourceState] = useState<'' | 'ai' | 'manual'>('');
    const [pageCount, setPageCount] = useState<number | undefined>(0);
    const [imageType, setImageType] = useState<'AI Generated' | 'Manual'>('AI Generated');
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const baseurl = BASE_URL;
    const [loadingStates, setLoadingStates] = useState<{ [key: number]: boolean }>({});
    const [slides, setSlides] = useState<SlideData[]>([]);
    const [selectedSlideId, setSelectedSlideId] = useState<number | null>(null);
    const [showPreview, setShowPreview] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [Id, setId] = useState<number>(0);
    const [grade, setGrade] = useState('');

    const [level, setLevel] = useState('');
    const [step, setStep] = useState(1);

    const handleNext = () => setStep((prev) => prev + 1);
    const handleBack = () => setStep((prev) => prev - 1);

    const {
        details: userDetails,
    } = useOutletContext<any>();

    useEffect(() => {
        const newSockets: { [key: number]: Socket } = {};
        slides.forEach((item) => {
            newSockets[item.id] = io(baseurl.slice(0, -2)); // Socket initialization
        });
        setSockets(newSockets);

        // Cleanup on component unmount
        return () => {
            Object.values(newSockets).forEach((socket) => {
                if (socket) socket.close();
            });
        };
    }, [slides.length]);
    const generateSlideContent = (description: string, index: number): { title: string; details: string } => {
        // Example logic to generate default title and content
        const defaultTitle = `Slide ${index + 1}: ${description.split(' ')[0]} Overview`;
        const defaultDetails = `This slide covers key points about ${description}. Add more specific content as needed.`;

        return { title: defaultTitle, details: defaultDetails };
    };

    const handleNumSlidesChange = (value: number) => {
        const updatedSlides: SlideData[] = Array.from({ length: value }, (_, index) => {
            const { title, details } = generateSlideContent(slidesDescription, index);

            return {
                id: index + 1,
                title,
                details,
                imageType: 'AI Generated',
                aiCommand: '',
                loadingState: false,
                isUploading: false,
            };
        });

        setSlides(updatedSlides);
    };

    const handleUpload = (id: number, file: File) => {
        const reader = new FileReader();
        reader.onload = () => {
            setSlides((prev) =>
                prev.map((slide) =>
                    slide.id === id
                        ? { ...slide, uploadedImage: reader.result as string, previewImage: reader.result as string }
                        : slide,
                ),
            );
        };
        reader.readAsDataURL(file);
    };

    function formatTextForPptx(text: string): Array<{ text: string; options: any }> {
        const formattedText: Array<{ text: string; options: any }> = [];

        // Split text into lines
        const lines = text.split(/\n/);
        for (const line of lines) {
            if (line.startsWith('- ')) {
                // Bullet points
                formattedText.push({
                    text: line.replace('- ', '').trim(),
                    options: { bullet: true, fontSize: 16 },
                });
            } else if (line.startsWith('**') && line.endsWith('**')) {
                // Bold text
                formattedText.push({
                    text: line.replace(/\*\*/g, '').trim(),
                    options: { bold: true, fontSize: 18 },
                });
            } else {
                // Plain text
                formattedText.push({
                    text: line.trim(),
                    options: { fontSize: 16 },
                });
            }
        }

        return formattedText;
    }

    const handleGenerateAI = async (id: number, command: string) => {
        try {
            if (!command) {
                toast.error('Kindly enter subject of the slide.');
            }
            setSlides((prev) => prev.map((slide) => (slide.id === id ? { ...slide, isUploading: true } : slide)));

            const aiCommand = `Generate an image using this ${command}`;

            // console.log(aiCommand);
            // const timer = setInterval(() => {
            //     setProgress((prev) => prev.map((value, i) => (i === index && value < 100 ? value + 10 : value)));
            // }, 1500);

            const aiImageUrl = await generateOpenAimage(aiCommand, '1024x1024');
            const url = await uploadToCloudinary(aiImageUrl?.data.data[0].b64_json);
            setSlides((prev) => prev.map((slide) => (slide.id === id ? { ...slide, previewImage: url } : slide)));

            if (aiImageUrl) {
                toast.success('AI-generated image added successfully.');
            }
        } catch (error) {
            console.error('Error generating AI image:', error);
            setSlides((prev) => prev.map((slide) => (slide.id === id ? { ...slide, isUploading: false } : slide)));
            toast.error('Failed to generate AI image. Please try again.');
        } finally {
            setSlides((prev) => prev.map((slide) => (slide.id === id ? { ...slide, isUploading: false } : slide)));
        }
    };

    useEffect(() => {
        slides.forEach((item) => {
            const socket = sockets[item.id];
            if (!socket) return;

            const handleData = (data: string) => {
                setSlides((prevSlides) =>
                    prevSlides.map((s) => (s.id === item.id ? { ...s, details: (s.details || '') + data } : s)),
                );
            };

            console.log(slides);

            const handleStreamEnd = () => {
                setSlides((prevSlides) =>
                    prevSlides.map((s) =>
                        s.id === item.id
                            ? { ...s, loadingState: false } // Update loadingState for the specific slide
                            : s,
                    ),
                );
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);
            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleData);
            };
        });
    }, [sockets, slides.length]);

    const handleInputChange = (id: number, field: keyof SlideData, value: string) => {
        let cleanedValue = value;

        cleanedValue = cleanedValue.replace(/\*\*/g, '');

        cleanedValue = cleanedValue.replace(/^#+\s*/, '');

        setSlides((prev) => prev.map((slide) => (slide.id === id ? { ...slide, [field]: cleanedValue } : slide)));
    };

    const handleGetStarted = async () => {
        if (!slidePages || slidePages <= 0) {
            toast.error('Please enter a valid number of slides.');
            return;
        }

        setSlides([]);
        setIsLoading(true);
        console.log(slidePages);
        const isGradeLevel = /(\d{1,2}(st|nd|rd|th))/i.test(level); // Checks for "1st" to "12th"

        const prompt = `
                ${
                    level !== 'General Public' && level !== 'Post Graduate'
                        ? `
                Use ${level} to generate the complexity of the slides as ${level} for the slides described as "${slidesDescription}".
                `
                        : isGradeLevel
                          ? `
                Use ${level} level to generate the complexity of the slides for kids, described as "${slidesDescription}".
                `
                          : level === 'Post Graduate'
                            ? ` Make the content complexity a post graduate level for the slides described as "${slidesDescription}".`
                            : `Make the content complexity of a general publc for the slides described as "${slidesDescription}".`
                } `;

        try {
            const generatedSlides =
                level.includes('1st') || level.includes('2nd') || level.includes('3rd')
                    ? await handleAutoGenerateForKids(slidePages ?? 0, prompt, level, grade)
                    : await handleAutoGenerate(slidePages ?? 0, prompt);

            const updatedSlides = generatedSlides.map((slide, index) => ({
                id: index + 1,
                title: slide.title,
                details: slide.content,
                imageType: 'AI Generated' as const,
                aiCommand: slide.title,
                previewImage: '',
                loadingState: false,
                isUploading: false,
            }));

            setSlides((prev) => [...prev, ...updatedSlides]);
            toast.success('Slides generated successfully.');
        } catch (error) {
            console.error('Error generating slides:', error);
            toast.error('Failed to generate slides. Please try again.');
            setIsLoading(false);
        } finally {
            setIsLoading(false);
            setPageCount(1);
        }
    };
    const handlePreviewPage = () => {
        // Check for missing fields in slides
        const incompleteSlides = slides.filter(
            (slide) => !slide.title.trim() || !slide.details.trim() || !slide.previewImage,
        );

        if (incompleteSlides.length > 0) {
            console.error('Some slides have missing fields:', incompleteSlides);
            toast.error('Please ensure all slides have a title, description, and image before previewing.');
            return;
        }

        // Proceed to show the preview if all slides are complete
        setShowPreview(true);
        console.log(slides);
    };

    const handleRemoveById = (id: number) => {
        setSlides((prev) => prev.filter((slide) => slide.id !== id));
    };

    const addEmptySlide = (id: number) => {
        setSlides((prev) => {
            const newId = Math.max(0, ...prev.map((slide) => slide.id)) + 1;
            setId(newId);
            const index = prev.findIndex((slide) => slide.id === id);
            if (index === -1) return prev;

            const newSlide: SlideData = {
                id: newId,
                title: '',
                details: '',
                imageType: 'Manual',
                aiCommand: '',
                loadingState: false,
                isUploading: false,
            };

            const updatedSlides = [...prev];
            updatedSlides.splice(index + 1, 0, newSlide);
            return updatedSlides;
        });

        setTimeout(() => {
            const slideElement = document.getElementById(`slide-${id}`);
            if (slideElement) {
                slideElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 100);
    };

    useEffect(() => {
        console.log(grade);
    }, [grade]);

    return (
        <div>
            <BackButton
                onclick={() => {
                    if (showPreview) {
                        setShowPreview(false);
                    } else if (pageCount === 1) {
                        setPageCount(0);
                    } else {
                        setCurrentPage(0);
                    }
                }}
            />
            {showPreview ? (
                <ThirdForm slides={slides} setShowPreview={setShowPreview} />
            ) : (
                <>
                    {pageCount === 0 && (
                        <div className="text-[#232323]">
                            <h1 className="font-[500] text-[36px] dark:text-white md:mt-[5%]">Create slides</h1>
                            <p className="text-gray-500 font-[400] dark:text-white text-[14px] mb-8">
                                Provide us the information requested below to create slides.
                            </p>

                            {step === 1 && (
                                <div className="space-y-4 w-full md:w-[60%]">
                                    <label className="text-[#434246] dark:text-white font-[400] text-[13px]">
                                        Slide topic
                                    </label>
                                    <Input.TextArea
                                        className="focus:border-[#E55109] focus:outline-none hover:border-[#E55109]"
                                        placeholder="E.g. Business presentation, Educational slides, etc."
                                        value={slidesDescription}
                                        onChange={(e) => setSlideDescription(e.target.value)}
                                    />
                                </div>
                            )}

                            {step === 2 && (
                                <div className="space-y-4 w-full md:w-[60%]">
                                    <div className="space-y-1 flex flex-col mb-4">
                                        <label
                                            htmlFor="slide-type"
                                            className="text-[#434246] dark:text-white font-[400] text-[13px]"
                                        >
                                            Audience type
                                        </label>

                                        <Select
                                            suffixIcon={<DownOutlined className="dark:text-white " />}
                                            className="w-full"
                                            value={level || undefined}
                                            placeholder={<p className="dark:text-gray-500 ">Select an grade level</p>}
                                            onSelect={(e) => {
                                                setLevel(e);
                                            }}
                                            options={[...gradeOptions()]}
                                        />
                                    </div>
                                </div>
                            )}

                            {step === 3 && (
                                <div className="space-y-4 w-full md:w-[60%]">
                                    <label className="text-[#434246] dark:text-white font-[400] text-[13px]">
                                        How many slide pages do you want?
                                    </label>
                                    <Input
                                        className="focus:border-[#E55109] focus:outline-none hover:border-[#E55109]"
                                        placeholder="e.g 5 pages"
                                        type="number"
                                        value={slidePages}
                                        onChange={(e) => setSlidePages(parseInt(e.target.value, 10))}
                                    />
                                </div>
                            )}

                            {step === 4 && (
                                <div className="space-y-4 flex flex-col w-full md:w-[60%]">
                                    <label className="text-[#434246] dark:text-white font-[400] text-[13px]">
                                        Content source
                                    </label>
                                    <Select
                                        className="focus:border-[#E55109] focus:outline-none hover:border-[#E55109]"
                                        value={contentSource}
                                        onChange={(value) => setContentSourceState(value)}
                                        placeholder="Select content source"
                                        suffixIcon={<DownOutlined className="dark:text-gray-300" />}
                                    >
                                        <Option value="manual">Manual</Option>
                                        <Option value="ai">AI Generated</Option>
                                    </Select>
                                </div>
                            )}

                            <div className="flex flex-row  items-start  justify-between mt-6 w-full md:w-[60%]">
                                {step > 1 && (
                                    <Button className="bg-gray-300" onClick={handleBack}>
                                        Back
                                    </Button>
                                )}
                                {step < 4 ? (
                                    <button
                                        className={`${
                                            (step === 1 && !slidesDescription) ||
                                            (step === 2 && !level) ||
                                            (step === 3 && !slidePages)
                                                ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                                                : 'bg-[#E55109] text-white hover:bg-[#b6511e]'
                                        } py-2 px-4 rounded-md`}
                                        onClick={handleNext}
                                        disabled={
                                            (step === 1 && !slidesDescription) ||
                                            (step === 2 && !level) ||
                                            (step === 3 && !slidePages)
                                        }
                                    >
                                        Next
                                    </button>
                                ) : (
                                    <button
                                        className="bg-[#E55109] text-[white]  border-none hover:bg-[#b6511e] text-sm py-2 px-3 disabled:bg-[#e4af95] rounded-md"
                                        disabled={loading}
                                        onClick={handleGetStarted}
                                    >
                                        {!loading ? (
                                            'Get started'
                                        ) : (
                                            <div className="flex items-center text-sm text-center">
                                                <Spinner />
                                                <small>Generating your slides' content...</small>
                                            </div>
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="flex flex-col gap-y-2  mt-12  ">
                        {pageCount === 1 && (
                            <div
                                className={`space-y-2  flex flex-col ${showPreview ? 'w-full' : 'max-w-[50%] max-sm:max-w-full'}`}
                            >
                                {slides.map((slide, index) => (
                                    <PreviewPage2
                                        key={index}
                                        id={slide.id}
                                        title={slide.title}
                                        details={slide.details}
                                        aiCommand={slide.aiCommand}
                                        uploadedImage={slide.uploadedImage}
                                        previewImage={slide.previewImage}
                                        imageType={slide.imageType}
                                        loadingState={slide.loadingState}
                                        isUploading={slide.isUploading}
                                        handleRemoveSlide={handleRemoveById}
                                        handleAddById={addEmptySlide}
                                        handleRemoveImage={() => {
                                            setSlides((prevSlides) =>
                                                prevSlides.map((s) =>
                                                    s.id === slide.id
                                                        ? { ...s, previewImage: '' } // Update loadingState for the specific slide
                                                        : s,
                                                ),
                                            );
                                        }}
                                        handleButtonClick={() => {
                                            setSelectedSlideId(slide.id);

                                            if (!slidesDescription) return;
                                            setSlides((prevSlides) =>
                                                prevSlides.map((s) =>
                                                    s.id === slide.id
                                                        ? { ...s, loadingState: true } // Update loadingState for the specific slide
                                                        : s,
                                                ),
                                            );
                                            if (!slide.title) {
                                                toast.error('Please provide a title for the slide.');
                                            }

                                            const socket = sockets[slide.id];
                                            const prompt = `Using the provided description: "${slidesDescription}", generate concise and well-structured content (maximum 100 words) for the slide titled "${slide.title}". Focus on key points that are relevant and suitable for a single slide.`;

                                            onGenerateWithAI(socket, prompt, userDetails?.userId ?? "",);
                                        }}
                                        handleGenerateAI={() => {
                                            handleGenerateAI(slide.id, slide.aiCommand);
                                        }}
                                        handleInputChange={handleInputChange}
                                        handleUpload={handleUpload}
                                    />
                                ))}
                            </div>
                        )}

                        {pageCount === 1 && (
                            <button
                                className="bg-[#E55109] w-[30%] text-[white] mt-6 border-none hover:bg-[#b6511e] text-sm py-2 px-3 disabled:bg-[#e4af95] rounded-md"
                                disabled={loading}
                                onClick={handlePreviewPage}
                            >
                                Design slides
                            </button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default FirstForm;

export const Spinner = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{ margin: 'auto', background: 'none', display: 'block' }}
        width="20px"
        height="20px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
    >
        <circle
            cx="50"
            cy="50"
            r="32"
            strokeWidth="8"
            stroke="#fff"
            strokeDasharray="50.26548245743669 50.26548245743669"
            fill="none"
            strokeLinecap="round"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;1"
                values="0 50 50;360 50 50"
            ></animateTransform>
        </circle>
    </svg>
);

import React from "react";
import { Slider } from "antd";
import "./index.scss"

export const EssayLength = ({length, setLength}: {length: number, setLength: React.Dispatch<React.SetStateAction<number>>}) => {
 

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="text-2xl font-semibold mb-4 dark:text-gray-300">How long should it be?</h2>
      <p className="text-gray-600 dark:text-gray-300 mb-6">Choose the length for your text (in words).</p>
      <Slider
        min={100}
        max={5000}
        step={100}
        className="w-[70%] custom-slider "
        value={length}
        onChange={(value) => setLength(value)}
      />
      <p className="text-gray-600 dark:text-gray-300 mt-4">Selected Length: {length} words</p>
    </div>
  );
};

import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import './style.scss';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import AIGenerate from '@icons/generate.svg';
import { io, Socket } from 'socket.io-client';
import { onGenerateWithAI } from '../../curriculum/functions';
import { BASE_URL } from '@src/utils/constants';
import { useOutletContext } from 'react-router';




const { TextArea } = Input;
const { Option } = Select;


interface CampaignAdGeneratorFormInterface {
  onGenerate: (v: string) => void;
  setTopic: (e: string) => void;
}

interface JobDescriptionLoadingStates {
  testing: boolean;
  compliance: boolean;
  parameters: boolean;
}

const CustomTextAreaWithButton = ({
  placeholder,
  uniqueKey,
  loadingStates,
  setLoadingStates,
  value, // Add value prop
  onChange,
  onClickButton,
  checkIfCanGenerate,
}: {
  placeholder: string;
  uniqueKey: keyof JobDescriptionLoadingStates;
  loadingStates: JobDescriptionLoadingStates;
  setLoadingStates: React.Dispatch<React.SetStateAction<JobDescriptionLoadingStates>>;
  value?: string; // Optional value prop
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onClickButton: () => void;
  checkIfCanGenerate: () => boolean;
}) => {
  const handleButtonClick = () => {
      let canGenerate = checkIfCanGenerate();
      if ( !canGenerate ) return;
      setLoadingStates((prevState) => ({
          ...prevState,
          [uniqueKey]: true,
      }));
      onClickButton();
  };

  return (
      <div className="relative">
          <TextArea
              autoSize={{ minRows: 4}}
              placeholder={placeholder}
              value={value} // Bind value
              onChange={onChange} // Bind onChange
              className="p-2 border border-gray-500 focus:outline-none"
              style={{ outline: 'none', boxShadow: 'none' }}
          />
          {!loadingStates[uniqueKey] ? (
              <div
                  onClick={handleButtonClick}
                  className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
              >
                  <img src={AIGenerate} alt="" />
                  <span>Use Gmind AI</span>
              </div>
          ) : (
              <div className="absolute top-[65px] right-[20px] ">
                  <CircularProgress size={15} className="w-1 h-1" />
              </div>
          )}
      </div>
  );
};

const CampaignAdGeneratorForm = ({ onGenerate, setTopic }: CampaignAdGeneratorFormInterface) => {
  const [isPageOne, setIsPageOne] = useState(true); // Boolean to control page visibility
  const [form] = Form.useForm();
  const baseurl = BASE_URL;
  const [loadingStates, setLoadingStates] = useState<JobDescriptionLoadingStates>({
    testing: false,
    compliance: false,
    parameters: false,
  });
  const [sockets, setSockets] = useState<{ [key: string]: Socket | null}>({});
  const socketKey = ['testing', 'compliance'];
  const {
    details,
} = useOutletContext<any>();
  
  useEffect(() => {
      const newSockets: { [key: string]: Socket } = {};
      socketKey.forEach(item => {
          newSockets[item] = io(baseurl.slice(0, -2));
      });
      setSockets(newSockets);

      return () => {
          Object.values(newSockets).forEach(socket => {
              socket.close();
          });
      };
  }, []);

  useEffect(() => {
      socketKey.forEach(item => {
          const socket = sockets[item];
          if (!socket) return;

          const handleData = (data: string) => {
              let previousValue = form.getFieldValue(item);
              form.setFieldValue( item, previousValue + data);
          };

          const handleStreamEnd = () => {
              setLoadingStates((prevState) => ({
                  ...prevState,
                  [item]: false,
              }));
          };

          socket.on('data', handleData);
          socket.on('stream_end', handleStreamEnd);
          return () => {
              socket.off('data', handleData);
              socket.off('stream_end', handleData);
          };
      });
  }, [sockets]);

  const [pageOneValues, setPageOneValues] = useState<{[key: string]: string}>({
    'objective': '',
    'copy': '',
    'audience': '',
    'assets': '',
    'platforms': '',
    'adFormat': '',
    'cta': '',
    'url': '',
  });

  const onFinish = (values: any) => {
    let promptMessage = '';

    const {
      objective,
      copy,
      audience,
      assets,
      platforms,
      adFormat,
      cta,
      url,
    } = pageOneValues;
    

    const {
      duration, 
      target, 
      scheduling, 
      deviceTargeting, 
      tracking, 
      parameters, 
      testing, 
      compliance 
    } = values;

    // Validate if all data is passed
    if (
        !objective 
        || !copy 
        || !audience
        || !assets
        || !platforms
        || !adFormat
        || !url
        || !duration
        || !cta
        || !scheduling
        || !target
        || !deviceTargeting
        || !parameters 
        || !tracking
        || !testing

    ) {
      toast.error('Please enter all prompts field');
      return;
    }

    let informationData = `
      Objective: ${objective}
      \nAd Copy: ${copy}
      \nTarget Audience: ${audience}
      \nVisual Assets: ${assets}
      \nAd Platform: ${platforms}
      \nAd Format: ${adFormat}
      \nCall to Action: ${cta}
      \nLanding Page URL: ${cta}
      \nBudget and Duration: ${duration}
      \nAd Scheduling: ${scheduling}
      \nTargeting Options: ${target}
      \nDevice Targeting: ${deviceTargeting}
      \nTracking and Measurement: ${tracking}
      \nA/B Testing Options: ${testing}
      ${compliance ? `Compliance and Policies: ${compliance}` : '' }
    `;

    promptMessage = `Generate a comprehensive Ads Campaign that aids marketers in planning, crafting, and optimizing their advertising campaigns with the following information: ${informationData}`;
    onGenerate(promptMessage);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        objective: '',
        demographics: '',
        platforms: [],
        formats: [],
        messaging: '',
        cta: '',
        totalBudget: 0,
        kpis: '',
        trackingTools: '',
      }}
    >
      <h1 className="text-xl dark:text-white font-bold font-Poppins mb-[16px] mt-[16px]">Campaign Ad Generator</h1>
      <p className="text-md  dark:text-gray-300 font-Poppins mb-[16px]">
        Create and customize ad campaigns to reach your target audience effectively.
      </p>

      {/* Page 1 Inputs */}
      {isPageOne && (
        <>
          <div  className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Objective" name="objective"   rules={[{ required: true, message: 'Please define your objective' }]}>
                <Input placeholder="Brand awareness, lead generation, increase sales." />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Ad Copy" name="copy"   rules={[{ required: true, message: 'Please add Ad copy' }]}>
                <TextArea autoSize={{ minRows: 2}} placeholder="Short headline (≤30 characters), Long headline (≤90 characters), Ad descriptions (Max 5, 90 characters each)" />
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Target Audience" name="audience"  rules={[{ required: true, message: 'Please define demographics' }]}>
                <Input placeholder="Describe demographics, interests, behaviors." />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Visual Assets" name="assets" rules={[{ required: true, message: 'Please define visual assets' }]}>
                <Input placeholder="Describe images/video content." />
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Ad Platforms" name="platforms" rules={[{ required: true, message: 'Please select platforms' }]}>
                <Select placeholder="E.g. Facebook, Google Ads, TikTok.">
                  <Option value="google">Google Ads</Option>
                  <Option value="facebook">Facebook</Option>
                  <Option value="instagram">Instagram</Option>
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Ad Format" name="adFormat" rules={[{ required: true, message: 'Please select ad format' }]}>
                <Select placeholder="E.g. Image, video, carousel.">
                  <Option value="Image">Image</Option>
                  <Option value="Video">Video</Option>
                  <Option value="Carousel">Carousel</Option>
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Call-to-Action (CTA)" name="cta"  rules={[{ required: true, message: 'Please define call to action' }]}>
                <Input placeholder=" E.g., Learn More, Shop Now, Sign Up." />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Landing Page URL" name="url" rules={[{ required: true, message: 'Please define landing page url' }]}>
                <Input placeholder="Enter the target page URL." />
              </Form.Item>
            </div>
          </div>

          {/* Next Button */}
          <Row gutter={16}>
            <Col span={24}>
              <button
               
                onClick={() => form.validateFields().then(() => {
                  setPageOneValues({
                    'objective': form.getFieldValue('objective'),
                    'copy': form.getFieldValue('copy'),
                    'audience': form.getFieldValue('audience'),
                    'assets': form.getFieldValue('assets'),
                    'platforms': form.getFieldValue('platforms'),
                    'adFormat': form.getFieldValue('adFormat'),
                    'cta': form.getFieldValue('cta'),
                    'url': form.getFieldValue('url'),
                  });
                  setIsPageOne(false);
                })}
                className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
              >
                Next
              </button>
            </Col>
          </Row>
        </>
      )}

      {/* Page 2 Inputs */}
      {!isPageOne && (
        <>
          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Budget and Duration" name="duration" rules={[{ required: true, message: 'Please select budget and duration' }]}>
                <Input placeholder="Specify financial plan and timetable." />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Targeting Options" name="target" rules={[{ required: true, message: 'Please specify targeting options' }]}>
                <Input placeholder="Demographics, interests, behaviors." />
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Ad Scheduling" name="scheduling" rules={[{ required: true, message: 'Please define schedule' }]}>
                <Input placeholder="Specify days and times." />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Device Targeting" name="deviceTargeting" rules={[{ required: true, message: 'Please select device targeting' }]}>
                <Select placeholder="E.g., Desktop, mobile, tablet.">
                  <Option value="Desktop">Desktop</Option>
                  <Option value="Mobile">Mobile</Option>
                  <Option value="Tablet">Tablet</Option>
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item label="Tracking and Measurement" name="tracking" rules={[{ required: true, message: 'Tracking and Measurement is required' }]}>
                <Select placeholder="E.g., Clicks, conversions, ROI.">
                  <Option value="Clicks">Clicks</Option>
                  <Option value="Conversion">conversions</Option>
                  <Option value="ROI">ROI</Option>
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item label="UTM Parameters" name="parameters" rules={[{ required: true, message: 'Please define UTM Parameters' }]}>
                <Input placeholder="Source, medium, campaign name." />
              </Form.Item>
            </div>
          </div>

          <div  className='grid grid-cols-1  md:grid-cols-2 gap-4'>
            <div>
              <Form.Item
                  label="A/B Testing Options"
                  name="testing"
                  rules={[{ required: true, message: 'Testing Option is required' }]}
              >
                  <CustomTextAreaWithButton
                      placeholder="E.g., Headlines, images, CTAs."
                      uniqueKey="testing"
                      loadingStates={loadingStates}
                      setLoadingStates={setLoadingStates}
                      value={form.getFieldValue('testing')} // Bind value to form field
                      onChange={(e) => form.setFieldValue('testing', e.target.value)} // Update form value on change
                      checkIfCanGenerate={() => {
                          return true;
                      }}
                      onClickButton={() => {
                          form.setFieldValue('testing', '')
                          let promptDetails = `
                              Objective: ${ form.getFieldValue('objective')}
                              Ad Copy: ${ form.getFieldValue('copy')}
                              Target Audience: ${ form.getFieldValue('audience')}
                              Call to Action: ${ form.getFieldValue('cta')}
                          `
                          let prompt = `Generate a ad campaign A/B Testing options with not more than 100 characters for an ad campaign with this details ${promptDetails}`;
                          const socket = sockets["testing"];
                          onGenerateWithAI(socket, prompt, details?.userId ?? "",);
                      }}
                  />
              </Form.Item>
            </div>
            <div>
            <Form.Item
                  label="Compliance and Policies ( Optional )"
                  name="compliance"
              >
                  <CustomTextAreaWithButton
                      placeholder="Confirm adherence to platform policies."
                      uniqueKey="compliance"
                      loadingStates={loadingStates}
                      setLoadingStates={setLoadingStates}
                      value={form.getFieldValue('compliance')} // Bind value to form field
                      onChange={(e) => form.setFieldValue('compliance', e.target.value)} // Update form value on change
                      checkIfCanGenerate={() => {
                          return true;
                      }}
                      onClickButton={() => {
                          form.setFieldValue('compliance', '')
                          let promptDetails = `
                              Objective: ${ form.getFieldValue('objective')}
                              Ad Copy: ${ form.getFieldValue('copy')}
                              Target Audience: ${ form.getFieldValue('audience')}
                              Call to Action: ${ form.getFieldValue('cta')}
                          `
                          let prompt = `Generate a ad campaign A/B compliance and policies with not more than 250 characters for an ad campaign with this details ${promptDetails}`;
                          const socket = sockets["compliance"];
                          onGenerateWithAI(socket, prompt, details?.userId ?? "",);
                      }}
                  />
              </Form.Item>
            </div>
          </div>

          {/* Submit Button */}
          <div className='flex flex-row justify-between' >
          <div>
              <button
               
                onClick={() => setIsPageOne(true)}
                className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
              >
                Prev
              </button>
            </div>
            <div>
            <button 
                    type="submit"
                    className="no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-customOrange text-white rounded-lg hover:none"
                >
                    Generate
                </button>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

export default CampaignAdGeneratorForm;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import letter from '@assets/gmind_letter.svg';
import avatar from '@assets/essay/GMind_Icon_Orange_compressed.png';
import avatarDark from '@assets/essay/GMind_Icon_White_compressed.png';
import check from '@assets/check_green.svg';
import copy from '@assets/chat/copy.svg';
import markdownToTxt from 'markdown-to-txt';
import regenerate from '@assets/chat/repeat.svg';
import markdown from '@wcj/markdown-to-html';
import back from '@assets/chat/back_circle.svg';
import { Share } from '@src/pages/dashboard/components/Share';
import Skeleton from './skeleton';
import share from '@public/share.svg';
import Copy from '@public/copy.svg';
import download from '@public/download.svg';
import './style.css';
import { Download } from '@src/pages/dashboard/components/Share/Download';
import { notification, Tooltip } from 'antd';
import Markdown from '@components/Markdown/index';
import { toast } from 'react-toastify';
import axios from 'axios';
import { pdfExporter } from 'quill-to-pdf';
import { getUrlPath } from '@src/utils/app_functions';
import ContentTypingComponent from './content_typing';
import {
    ChatContent,
    Chats,
    getAllChatContentImages,
    getIndicesOfImagesInChatContent,
    getIndicesOfSourcesInChatContent,
    isChatContentArray,
} from './interface/interface';
import { BASE_URL } from '@src/utils/constants';
import { marked } from 'marked';
import { convertHTMLToPDF, convertHTMLToWord } from '../pages/essay/functions';
import { useUserContext } from '@src/context/UseProvider';
import SuggestionBubble from '../pages/essay/editor/components/suggestion_box';
import ChatMessage from '../../../components/Markdown/chatMessage';
import { ThemeContext } from '@src/theme_provider/theme';
import { Arrow } from '@radix-ui/react-dropdown-menu';
import { ArrowLeft } from 'lucide-react';
import { EditOutlined, LeftOutlined } from '@ant-design/icons';
import ImageItem from '../pages/chat/components/sources_img';
import './chatScreen.scss';
import pdfIcon from '@icons/pdf.png';
import docxIcon from '@icons/docx.png';
// import { MathJaxContext } from 'better-react-mathjax';
// import { MathJaxContext } from 'better-react-mathjax';
import WorkflowLoading from './work_flow_loading';
import FlowChatMessage from '@src/components/FlowMarkDown/flowchatMessage';

export const ChatScreen = ({
    chatList,
    showNavBar,
    isMobile,
    streaming,
    backClick,
    actions,
    moreActions,
    typing,
    suggestions,
    handleSuggestionClick,
    handleUpdatedChat,
    editedContent,
    setEditedContent,
    usingSuggestPrompt,
    isWorkFlow,
    isFlow,
}: {
    chatList: Chats[];
    showNavBar: boolean;
    streaming: boolean;
    isMobile?: boolean;
    backClick: () => void;
    actions?: (v: string, i: number) => void;
    moreActions?: [{ icon: string; action: string }];
    typing?: boolean;
    suggestions?: string[];
    handleSuggestionClick?: (suggestion: string) => void;
    handleUpdatedChat?: () => void;
    editedContent?: string;
    setEditedContent: Dispatch<SetStateAction<string>>;
    usingSuggestPrompt: boolean;
    isWorkFlow?: boolean;
    isFlow?: boolean;
}) => {
    const containerRef = useRef<any>();
    const Document = useRef<any>();
    const [page, setPage] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const pageDetails = getUrlPath();
        setPage(pageDetails.pagename);
    }, [window.location.pathname]);

    useEffect(() => {
        const container: HTMLDivElement = containerRef.current;
        if (container) {
            container.scrollTop = container.scrollHeight - container.clientHeight;
        }
    }, [chatList, streaming]);

    function handleAction(action: string, index: number) {
        actions && actions(action, index);
    }

    const noSuggestion = () => {};
    const { savedTheme } = useContext(ThemeContext);
    const sendUpdated = (id: number) => {
        const updatedChatList = chatList.splice(id, 1);
        const updatedAssistantChatList = chatList.splice(id, 1);

        // Now you can update the state or send the updated list to the server
        handleUpdatedChat && handleUpdatedChat();
    };

    // console.log( JSON.stringify( chatList ));

    return (
        <section className="w-full overflow-auto">
            {showNavBar && (
                <div className="w-full  z-[100] sticky top-0 h-12 flex items-center justify-between   px-10">
                    <div className="flex items-center gap-3">
                        {/* {savedTheme === 'dark' ? (
                            <div className='h-5  rounded-full bg-gray-500 w-5 flex items-center justify-center cursor-pointer' onClick={backClick}>
                                <LeftOutlined className="text-white w-3 fontt-bold" />
                            </div>
                        ) : (
                            <img onClick={backClick} className="w-6" role="button" src={back} alt="" />
                        )} */}
                        {/* {page != 'chat' && (
                            <div>
                                <p className="text-sm">Chat</p>
                                <img className="w-4" role="button" src={edit} alt="" />
                            </div>
                        )} */}
                        {/* { typing && <ContentTypingComponent isTyping={ typing ?? false } /> } */}
                    </div>
                    <div className="flex items-center gap-3">
                        {/* <div role="button" className="bg-[#f9fafc] p-1 px-3 rounded-xl flex items-center gap-2">
                            <img src={Profile} alt="" className="w-3" />
                            <p className="text-[0.9rem] font-medium">Humanize</p>
                        </div> */}
                    </div>
                </div>
            )}
            <div
                ref={containerRef}
                className="px-10 pb-5 flex flex-col mb-5 gap-0"
                style={{
                    paddingTop: !showNavBar ? '1.5rem' : '',
                    padding: isMobile ? '0' : '',
                }}
            >
                {typing && <ContentTypingComponent isTyping={typing ?? false} />}{' '}
                {chatList.map(({ role, content, _id }, i) => (
                    <>
                        <span>{_id}</span>
                        <Bubble
                            isFlow={isFlow}
                            isWorkFlow={isWorkFlow}
                            sendUpdated={() => sendUpdated(i)}
                            editedContent={editedContent}
                            setEditedContent={setEditedContent}
                            moreActions={moreActions}
                            action={handleAction}
                            index={i}
                            key={i}
                            isMobile={isMobile}
                            role={role}
                            style={isMobile ? { width: 'calc(100% - 2rem)', margin: '0 auto' } : {}}
                            content={content}
                            typing={i != 0 ? typing : undefined}
                            usingSuggestPrompt={usingSuggestPrompt}
                        />
                    </>
                ))}
                {suggestions && (
                    <SuggestionBubble
                        suggestions={suggestions}
                        handleSuggestionClick={handleSuggestionClick ?? noSuggestion}
                    />
                )}
                {chatList?.length && chatList[chatList.length - 1].role === 'user' && !streaming ? (
                    isWorkFlow ? (
                        <WorkflowLoading />
                    ) : (
                        <WorkflowLoading />
                    )
                ) : (
                    ''
                )}
            </div>
        </section>
    );
};

const Bubble = ({
    role,
    icon,
    className,
    style,
    isMobile,
    content,
    index,
    action,
    moreActions,
    typing,
    sendUpdated,
    editedContent,
    setEditedContent,
    usingSuggestPrompt,
    isWorkFlow,
    isFlow,
}: {
    role: string;
    icon?: string;
    className?: string;
    isMobile?: boolean;
    style?: React.CSSProperties;
    content: ChatContent[] | string;
    index: number;
    action: (v: string, i: number) => void;
    moreActions?: [{ icon: string; action: string }];
    typing?: boolean;
    sendUpdated?: () => void;
    editedContent?: string;
    setEditedContent: Dispatch<SetStateAction<string>>;
    usingSuggestPrompt: boolean;
    isWorkFlow?: boolean;
    isFlow?: boolean;
}) => {
    const containerRef = useRef<any>();
    const UrlRef = useRef<string>('');
    const [copied, setCopied] = useState(false);
    const [liked, setLike] = useState(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [disliked, setDisLike] = useState(false);
    const [openDownload, setOpenDownload] = useState<boolean>(false);
    const [pdfDownloading, setPdfDownloading] = useState(false);
    const [profilePic, setProfilePic] = useState<string>('');
    const { userDetails } = useUserContext();
    const { savedTheme } = useContext(ThemeContext);

    function handleNewFeatures() {
        notification.info({ message: 'Please be patient as this feature is still in development.' });
    }

    useEffect(() => {
        const container: HTMLParagraphElement = containerRef.current;
        if (container) {
            container.scrollTop = container.scrollHeight - container.clientHeight;
        }
    }, [content]);

    useEffect(() => {
        if (!copied) return;

        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }, [copied]);

    async function handleShare() {
        const contentValue =
            typeof content === 'string'
                ? content
                : isChatContentArray(content)
                  ? content?.length > 0
                      ? (content[0]?.text ?? '')
                      : ''
                  : '';

        const baseurl = BASE_URL;
        console.log('share');
        const to_markdown = markdownToTxt(contentValue);

        if (UrlRef.current) {
            setShowShareModal(true);
            return;
        }
        try {
            const response = await axios.post(`${baseurl}/document/markdown-to-doc`, { content: to_markdown }, {});
            const { data: d } = response;
            if (d?.statusCode !== 1) {
                toast.error(d?.message ?? 'Something went wrong while generating share link');
                return;
            }

            const newUrl = baseurl.replace('v1', '');

            const url = `${newUrl}${d?.url}`;
            UrlRef.current = url;
            setShowShareModal(true);
        } catch (error: any) {
            console.log(error, 'e');
            const server_message = error?.response?.data?.message;
            toast.error(server_message ?? 'Something went wrong.');
        }
    }

    async function handlePDF() {
        // eslint-disable-next-line prefer-const
        let contentValue =
            typeof content === 'string'
                ? content
                : isChatContentArray(content)
                  ? content?.length > 0
                      ? (content[0]?.text ?? '')
                      : ''
                  : '';

        const images: string[] = getAllChatContentImages(content);
        const imagesHTML = images
            .map((src) => `<img src="${src}" alt="Image" style="max-width: 100%; margin-top: 10px;" /><br/>`)
            .join('');
        const htmlContent = await marked(contentValue);
        const data = htmlContent;
        try {
            setPdfDownloading(true);
            await convertHTMLToPDF(data);
        } catch (error) {
            toast.error('Error downloading documents');
        } finally {
            setPdfDownloading(false);
        }
    }

    async function handleWord() {
        const contentValue =
            typeof content === 'string'
                ? content
                : isChatContentArray(content)
                  ? content?.length > 0
                      ? (content[0]?.text ?? '')
                      : ''
                  : '';
        const images: string[] = getAllChatContentImages(content);
        const imagesHTML = images
            .map((src) => `<img src="${src}" alt="Image" style="max-width: 100%; margin-top: 10px;" /><br/>`)
            .join('');
        const to_html = markdown(contentValue);
        const dataToConvert = to_html;
        convertHTMLToWord(dataToConvert);
        // const response = await axios.post(
        //     `${BASE_URL.slice(0, -3)}/html-to-doc`,
        //     { content: to_html?.trim() },
        //     {},
        // );
        // const { data: d } = response;
        // if (d?.statusCode === 1) {
        //     console.log(d, "url")
        //     const link = document.createElement('a');

        //     link.download = `gmind_document-${moment().format("YYYY-MM-DD-HH-mm:ss A")}.docx`;
        //     link.href = `${BASE_URL.slice(0, -3)}/${d?.url}`;
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        //     toast.success('Download successful');
        // }
    }

    function handleRegenerate() {
        action('regenerate', index);
    }

    // const onRefreshPageDetails = async () => {
    //     const token = localStorage.getItem('token');
    //     if (!token) return;
    //     const details = await GetUser(token);
    //     const detailsFetched: UserDetailsData = details.data;
    //     onFetchDetails(detailsFetched);
    // };
    // useEffect(() => {
    //     if (!profilePic) {
    //         // Call the function if the profile picture is empty
    //         onRefreshPageDetails();
    //     }
    // }, [profilePic]);

    // const onFetchDetails = (details: UserDetailsData) => {

    //     if (details.profilePic === null) {
    //         setProfilePic("");
    //     } else {
    //         setProfilePic(details?.profilePic);
    //     }

    // };

    function handleCopyText() {
        let contentValue =
            typeof content === 'string'
                ? content
                : isChatContentArray(content)
                  ? content?.length > 0
                      ? (content[0]?.text ?? '')
                      : ''
                  : '';
        navigator.clipboard
            .writeText(contentValue)
            .then(() => {
                console.log('Written');
                toast.success('Content copied to clipboard!');

                setCopied(true);
            })
            .catch((err) => {
                console.log('Failed, ', err);
            });
    }

    const noSuggestion = () => {};

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    console.log(content);

    return (
        <div
            className={`flex items-center gap-3  rounded-lg w-full pl-5 md:pr-[17%] md:pl-[18%] pr-5  ` + className}
            style={
                role === 'assistant'
                    ? { gap: 0, flexDirection: 'column', alignItems: 'start', ...style }
                    : { flexDirection: 'column', gap: 0, alignItems: 'end', ...style }
            }
        >
            {showShareModal && (
                <Share
                    onClose={() => {
                        setShowShareModal(false);
                    }}
                    url={UrlRef.current}
                />
            )}
            {openDownload && (
                <Download
                    url=""
                    handlePDF={handlePDF}
                    handleWord={handleWord}
                    isPdfDownloading={pdfDownloading}
                    onClose={() => {
                        setOpenDownload(false);
                    }}
                />
            )}
            <div
                style={
                    !isMobile
                        ? { display: 'block', width: 'fit-content' }
                        : { display: 'flex', alignItems: 'center', width: role === 'user' ? 'fit-content' : '100%' }
                }
                className=""
            >
                <div className="flex flex-col gap-x-3  gap-y-2 items-start mb-2 mt-1">
                    {role !== 'user' && (
                        <img
                            src={
                                // role === 'user'
                                //     ? userDetails?.profilePic !== null
                                //         ? userDetails?.profilePic
                                //         :icon
                                //     :
                                savedTheme === 'dark' ? avatarDark : avatar
                            }
                            alt={role}
                            className={`${role === 'assistant' ? 'w-6 ' : userDetails?.profilePic !== null ? 'w-7 h-7 bg-cover bg-center border border-black bg-[#001f73] rounded-full' : 'w-6 border border-black bg-[#001f73] rounded-full p-[0.2rem]'}`}
                        />
                    )}
                </div>
            </div>

            <div className="w-full content_holder">
                {role === 'user' && isChatContentArray(content) && content?.length >= 2 && (
                    <div className="flex flex-col w-full h-auto ">
                        <div
                            className={`flex ${role === 'user' ? 'w-[40%] self-end justify-end flex-wrap' : 'max-w-full self-start justify-start overflow-x-scroll'} items-center gap-2`}
                        >
                            {content.map((item, index) => {
                                if (item.type !== 'image_url') return <></>;
                                if (item?.image_url?.url === undefined) return <></>;
                                return (
                                    <img
                                        key={index}
                                        className={`mt-5 ${content?.length == 2 ? 'w-40' : 'w-20'}}`}
                                        src={item?.image_url?.url ?? ''}
                                        alt="chat image"
                                        loading="lazy"
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}

                {role === 'user' && isChatContentArray(content) && content?.length >= 2 && (
                    <div className="flex flex-col w-full h-auto mb-">
                        <div
                            className={`flex ${role === 'user' ? 'w-[40%] self-end justify-end flex-wrap' : 'max-w-full self-start justify-start overflow-x-scroll'} items-center gap-2`}
                        >
                            {content.map((item, index) => {
                                if (item.type !== 'files') return <></>;
                                if ((item.files ?? []).length == 0) return <></>;
                                const fileValue = (item.files ?? [])[0];
                                return (
                                    <div className="flex gap-x-3 gap-y-2 h-full">
                                        <img
                                            src={fileValue.type === 'DOCX' ? docxIcon : pdfIcon}
                                            alt={fileValue.type === 'DOCX' ? 'doc image' : 'pdf image'}
                                            className="w-10 h-full rounded-md"
                                        />
                                        <div className="flex flex-col gap-y-3">
                                            <p>{fileValue.title.slice(0, 30)}</p>
                                            <p>{fileValue.type}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}

                {role === 'assistant' &&
                    isChatContentArray(content) &&
                    content?.length >= 2 &&
                    (getIndicesOfImagesInChatContent(content).length > 0 ? (
                        <div className="flex flex-col w-full h-auto ">
                            <p className="text-black dark:text-white font-bold text-[1.2rem] mb-1">Relevant Images</p>
                            <div
                                className={`flex w-[full] max-w-full self-start justify-start overflow-x-scroll no-scrollbar items-center gap-x-4`}
                            >
                                {content.map((item, index) => {
                                    if (item.type !== 'image_url') return <></>;
                                    if (item?.image_url?.url === undefined) return <></>;
                                    return (
                                        <ImageItem
                                            key={index}
                                            className={`mt-5 ${content?.length == 2 ? 'w-56' : 'w-64'} h-56`}
                                            src={item?.image_url?.url ?? ''}
                                            alt="chat image"
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <></>
                    ))}

                {role === 'assistant' &&
                    isChatContentArray(content) &&
                    content?.length >= 2 &&
                    (getIndicesOfSourcesInChatContent(content).length > 0 ? (
                        <div className="flex flex-col w-full h-auto overflow-x-scroll  no-scrollbar mt-4">
                            <p className="text-black dark:text-white font-bold text-[1.2rem] mb-1">Sources</p>
                            <div className="flex flex-wrap w-40">
                                {content.map((item, index) => {
                                    if (item.type !== 'sources') return <></>;
                                    if ((item.sources ?? []).length == 0) return <></>;
                                    return (
                                        <div className={`flex self-start justify-start items-start gap-x-5`}>
                                            {item.sources?.map((values, index2) => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            if (values.url === '') return;
                                                            openInNewTab(values.url);
                                                        }}
                                                        className={`
                                                            rounded-md flex flex-col gap-y-2 w-48 cursor-pointer 
                                                            ${getIndicesOfImagesInChatContent(content).length > 0 ? 'h-32' : 'h-64'} 
                                                            no-scrollbar overflow-x-hidden overflow-y-scroll bg-white dark:bg-[var(--gmind-white60)] pb-3`}
                                                    >
                                                        {values.image &&
                                                            getIndicesOfImagesInChatContent(content).length == 0 && (
                                                                <ImageItem
                                                                    key={index2}
                                                                    className={`rounded-md w-48 h-32 min-h-32 object-cover`}
                                                                    src={values.image ?? ''}
                                                                    alt="chat image"
                                                                />
                                                            )}

                                                        <div
                                                            className={`${!values.image ? 'mt-2' : ''} px-2 flex flex-col gap-y-2 w-full h-32`}
                                                        >
                                                            <p className={`dark:text-white h-16 overflow-hidden`}>
                                                                {values.title.substring(0, 50)}
                                                                {values.title.length > 50 ? '....' : ''}
                                                            </p>

                                                            {
                                                                <p
                                                                    className={`font-optimistic text-sm text-[var(--gmind-grey-color50)] dark:text-defaultWhiteLighter font-normal ${values.reporter ? 'h-10' : ''}`}
                                                                >
                                                                    {values.reporter ?? ''}
                                                                </p>
                                                            }
                                                        </div>

                                                        <div className="px-2 flex w-full items-center gap-x-5 h-6">
                                                            {values.providerImage && (
                                                                <ImageItem
                                                                    key={index2}
                                                                    className="w-6 h-6 rounded-full"
                                                                    src={values.providerImage ?? ''}
                                                                    alt="media image"
                                                                />
                                                            )}
                                                            {values.provider && (
                                                                <p className="font-optimistic text-[0.65rem] text-black dark:text-white font-normal">
                                                                    {values.provider ?? ''}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <></>
                    ))}

                {!usingSuggestPrompt || (usingSuggestPrompt && index > 0) ? (
                    <>
                        
                            <ChatMessage
                                editedContent={editedContent ?? ''}
                                setEditedContent={setEditedContent}
                                sendUpdated={sendUpdated}
                                id={index}
                                content={content}
                                role={role}
                                className=" dark:text-white leading-8"
                            />
                      
                    </>
                ) : (
                    <></>
                )}

                {/* {isChatContentArray(content) && content?.length >= 2 && (
                    <div className="flex flex-col w-full h-auto ">
                        <div className={`flex flex-wrap ${role === 'user' ? 'w-[40%] self-end justify-end'  : 'w-full self-start justify-start' } items-center gap-2`}>
                            {content.map((item, index) => {
                                if (item.type !== 'image_url' ) return <></>;
                                if (item?.image_url?.url === undefined ) return <></>;
                                return (
                                    <img
                                        key={index}
                                        className={`mt-5 ${ role === 'assistant' ? 'w-48' : ( content?.length == 2 ? 'w-40' : 'w-20' )} ${role === 'assistant' ? 'h-40' : ''}`}
                                        src={item?.image_url?.url ?? ''}
                                        alt="chat image"
                                        loading="lazy"
                                    />
                                );
                            })}
                        </div>
                    </div>
                )} */}

                {role === 'assistant' && (!usingSuggestPrompt || (usingSuggestPrompt && index > 1)) ? (
                    <div className="flex flex-col gap-y-3">
                        <section className="flex justify-between items-center w-full mb-[15px]">
                            {role === 'assistant' && isMobile && (
                                <div className="flex items-center gap-8">
                                    <img
                                        role="button"
                                        onClick={() => {
                                            handleShare();
                                        }}
                                        className="w-5"
                                        src={share}
                                        alt=""
                                    />
                                    <img
                                        role="button"
                                        onClick={() => {
                                            handleCopyText();
                                        }}
                                        className="w-5"
                                        src={copied ? check : copy}
                                        alt=""
                                    />
                                    <img
                                        onClick={() => {
                                            setOpenDownload(true);
                                        }}
                                        className="w-5"
                                        src={download}
                                        alt=""
                                    />
                                </div>
                            )}
                            {!isMobile && (
                                <div className="flex items-center gap-3 mt-3">
                                    <div
                                        onClick={handleShare}
                                        role="button"
                                        className="bg-[#f9fafc] p-1 px-3 rounded-xl flex items-center gap-2"
                                    >
                                        <img src={share} alt="" className="w-[1rem]" />
                                        <p className="text-[0.9rem] font-medium">Share</p>
                                    </div>
                                    <div
                                        onClick={handleCopyText}
                                        role="button"
                                        className="bg-[#f9fafc] p-1 px-3 rounded-xl flex items-center gap-2"
                                    >
                                        <img src={copied ? check : copy} alt="" className="w-[1rem]" />
                                        <p className="text-[0.9rem] font-medium">{copied ? 'Copied!' : 'Copy'}</p>
                                    </div>
                                    <div
                                        onClick={() => setOpenDownload(true)}
                                        role="button"
                                        className="bg-[#f9fafc] p-1 px-3 rounded-xl flex items-center gap-2"
                                    >
                                        <img src={download} alt="" className="w-[1rem]" />
                                        <p className="text-[0.9rem] font-medium">Download</p>
                                    </div>
                                    {moreActions?.map(({ action: _action, icon }) => (
                                        <div
                                            key={_action}
                                            onClick={() => action(_action, index)}
                                            role="button"
                                            className="bg-[#f9fafc] p-1 px-3 rounded-xl flex items-center gap-2"
                                        >
                                            <img src={icon} alt="" className="w-4" />
                                            <p className="text-[0.9rem] font-medium">{_action}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div
                                style={isMobile ? { flexDirection: 'row-reverse', width: '50%' } : {}}
                                className="flex items-center justify-between gap-10"
                            >
                                {isWorkFlow ? (
                                    <button onClick={() => handleRegenerate()} className="flex items-center gap-2">
                                        <img src={regenerate} alt="" className="w-4" />
                                        <p className="text-[0.9rem] dark:text-white">Change Subject Category</p>
                                    </button>
                                ) : (
                                    <button onClick={() => handleRegenerate()} className="flex items-center gap-2">
                                        <img src={regenerate} alt="" className="w-4" />
                                        <p className="text-[0.9rem] dark:text-white">Regenerate</p>
                                    </button>
                                )}
                            </div>
                        </section>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

import { Button, Form, Modal, Tooltip } from 'antd';
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import Input from '@src/pages/dashboard/components/Input';
import { FileContent } from '@src/core/interfaces/chat';

import { BASE_URL } from '@src/utils/constants';
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

import {
    CodeOutlined,
    CalculatorOutlined,
    ExperimentOutlined,
    EditOutlined,
    AppstoreAddOutlined,
    DotChartOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import { useNavigate, useOutletContext } from 'react-router';
import { convertStringToDelta, getUrlPath } from '@src/utils/app_functions';
import axios from 'axios';
import saveAs from 'file-saver';
import moment from 'moment';
import { pdfExporter } from 'quill-to-pdf';
import { toast } from 'react-toastify';
import Markdown from '@src/components/Markdown';
import ResponseTextbox from '@src/pages/dashboard/components/responseTextBox';
import { Share } from '@src/pages/dashboard/components/Share';
import { Download } from '@src/pages/dashboard/components/Share/Download';
import { ChatContent, Chats, isChatContentArray, ResponseSources } from '../../../components/interface/interface';
import { ThemeContext } from '@src/theme_provider/theme';
import { ChatScreen } from '@src/pages/dashboard/components/chatScreen';
import flowChart from '@assets/essay/project-management.png';

const FlowChartAnalyzer = () => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [extractedFile, setExtractedFile] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [value, setValue] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [isTyping, setIsTyping] = useState(false);
    const bottomRef = useRef<HTMLDivElement>(null);
    const UrlRef = useRef<string>('');
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [category, setCategory] = useState<string | null>(null);
    const StreamId = useRef<string>('');
    const token = localStorage.getItem('token');
    const currentEntity = useRef<Chats>({ role: 'user', content: '', type: 'gpt-4o' });
    const _chatsRef = useRef<Chats[]>([]);

    const { setShowTop, setShowSidebar, flowChartList, setFlowChartList, response, setResponse, details, historyId } =
        useOutletContext<any>();
    const [prompt, setPrompt] = useState<string>('');
    const [streaming, setStreaming] = useState(false);
    const useInternet = false;
    const [img, setImg] = useState<File | null>(null);

    const { isMobile } = useContext(ThemeContext);
    const [editedContent, setEditedContent] = useState('');
    const [useSuggestiveInput, setUseSuggestiveInput] = useState(false);
    const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>([]);
    useEffect(() => {
        if (isTyping && flowChartList.length > 0) {
            // console.log("scrolling")
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [isTyping, flowChartList]);
    useEffect(() => {
        const _socket = io(BASE_URL.slice(0, -2));
        setSocket(_socket);

        setFlowChartList([]);
    }, []);

    useEffect(() => {
        if (!socket) return;
        socket.on('data', (data: string) => {
            !streaming && setStreaming(true);
            if (currentEntity.current.role !== 'assistant') {
                currentEntity.current = { role: 'assistant', content: data };
                setFlowChartList((c: any) => [...c, { role: 'assistant', content: data }]);
                return;
            }

            const updateCallback = (current: Chats[]) => {
                const updated = current.map((item, i) => {
                    if (i === current.length - 1) {
                        if (typeof item.content === 'string') {
                            return { ...item, content: item.content + data };
                        } else if (isChatContentArray(item.content)) {
                            // If content is ChatContent[], find and update the 'text' type
                            const updatedContent = item.content.map((content) => {
                                if (content.type === 'text' && content.text) {
                                    return { ...content, text: content.text + data };
                                }
                                return content;
                            });
                            return { ...item, content: updatedContent };
                        }
                    }
                    return item;
                });
                // console.log(updated);
                return updated;
            };

            setFlowChartList(updateCallback);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: Chats[] }) => {
            setIsTyping(false);
            const { streamId, assistant } = data;
            if (streamId === StreamId.current) {
                setStreaming(false);
                const uuid = uuidv4();

                if (!historyId.current) {
                    historyId.current = uuid;
                }

                // const response = await SaveHistory(token, {
                //     conversation: assistant,
                //     chat_id: historyId.current ?? uuid,
                // });

                // if (response?.statusCode === 1) {
                //     getHistory();
                //     localStorage.setItem('activeChat', JSON.stringify(_chatsRef.current));
                //     localStorage.setItem('lastChat', JSON.stringify(_chatsRef.current));
                // }
            }
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.type.startsWith('image/')) {
                const file = item.getAsFile();
                console.log('File', file);
                if (file) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImg(file);
                    };
                    reader.readAsDataURL(file);
                }
                break; // Only handle the first image
            }
        }
    };

    function handleChat(chat: any) {
        let promptMessage = `
            You are an AI assistant specialized in analyzing flowcharts.
            You are given an image of a flowchart, and your task is to analyze it in depth, providing a clear, detailed description of its structure, elements, and logical flow.
    
            **Important Notes:**
            - The image provided must be a flowchart. If the image is not a flowchart, respond with: "This image is not a flowchart, please upload a flowchart image."
            - Your analysis should focus only on the provided flowchart image.
    
            **Analysis Instructions:**
            - **Nodes:** Identify all visible and hidden nodes in the flowchart, including common shapes such as rectangles, diamonds, ovals, or other shapes representing start points, processes, decisions, and endpoints.
            - **Connections:** Analyze the arrows or lines connecting the nodes, describing the direction of flow and how the steps are related.
            - **Flow Structure:** Break down the flowchart into start and end points, decision paths, loops, and sequential steps. Provide a clear description of how each part interacts with others.
            - **Hidden or Implicit Details:** Look for potential missing connections, gaps, logical inconsistencies, or errors in the flow. If any details are unclear, include reasonable inferences about the potential structure or missing pieces.
            
            **Format of Analysis:**
            - Present the analysis in a structured format for easy understanding, clearly identifying all important aspects of the flowchart.
    
            **Example Format:**
    
            <div class="flowchart-analysis">
              <h2 class="header">Flowchart Analysis</h2>
              
              <!-- Nodes Section -->
              <div class="section">
                <h3 class="subheader">Nodes</h3>
                <ul class="list">
                  <li><strong>Start Node:</strong> Description of the start point, including the shape and any text inside.</li>
                  <li><strong>Decision Node:</strong> Description of decision nodes, conditions, and branching logic.</li>
                  <li><strong>Process Nodes:</strong> Detailed description of processes, actions, or operations within the flowchart.</li>
                  <li><strong>End Node:</strong> Description of the endpoint, marking the conclusion of the process.</li>
                </ul>
              </div>
    
              <!-- Connections Section -->
              <div class="section">
                <h3 class="subheader">Connections</h3>
                <ul class="list">
                  <li>List the arrows or lines between nodes, describing their direction and purpose.</li>
                  <li>Clarify the flow of data, decisions, and operations across the flowchart.</li>
                </ul>
              </div>
    
              <!-- Hidden/Implicit Details Section -->
              <div class="section">
                <h3 class="subheader">Hidden or Implicit Details</h3>
                <ul class="list">
                  <li>Analyze logical inconsistencies or missing steps.</li>
                  <li>Note any potential errors or gaps in the flowchart’s logic.</li>
                  <li>Identify any possible flow interruptions or unconnected steps.</li>
                </ul>
              </div>
    
              <!-- Overall Flow Description Section -->
              <div class="section">
                <h3 class="subheader">Overall Flow Description</h3>
                <p class="description">
                  Provide a detailed, narrative overview of how the flowchart works. This should include an explanation of each node, the connections, and the general sequence of events or actions from start to finish.
                </p>
              </div>
            </div>
    
            **Guidelines:**
            - Ensure accuracy in your descriptions; no important details should be overlooked.
            - Name the shapes and connections clearly, with a focus on providing context and structure.
            - If applicable, include icons or symbols commonly used in flowchart diagrams (e.g., diamonds for decisions, rectangles for processes).
            - After first response use can ask for further explanation on the provided response
            - The user is only allowed to ask questions related to flowcharts. If the user asks about any other topic, respond with: "I am a Flowchart Analyzer and can only assist with flowchart-related queries."
            - If the user’s prompt does not include an image, instruct them to upload a flowchart image for analysis by responding with: "Please upload a flowchart image for analysis, as I can only process visual inputs related to flowcharts."
        `;
    
        setPrompt(promptMessage.trim());
        setUseSuggestiveInput(true);
    
        setIsTyping(true);
        const uuid = uuidv4();
        StreamId.current = uuid;
    
        socket?.emit(
            'data',
            {
                data: {
                    messages: [{ role: 'system', content: promptMessage.trim(), type: 'gpt-4o' }, ...chat],
                    userId: details?.userId,
                },
                streamId: uuid,
            },
            true,
            useInternet,
            true,
        );
    }
    

    // useEffect(() => {
    //     setFlowChartList([]);
    //     currentEntity.current = {
    //         role: 'user',
    //         content: `Hello Gmind AI my name is ${details?.firstName} and i need your help`,
    //     };
    //     setFlowChartList((prevChats) => [...prevChats, { role: 'user', content: '' }]);
    //     _chatsRef.current = [
    //         ...flowChartList,
    //         { role: 'user', content: `Hello Gmind AI my name is ${details?.firstName} and i need your help` },
    //     ];
    //     handleChat([
    //         ...flowChartList,
    //         {
    //             role: 'user',
    //             content: `Hello Gmind AI my name is ${details?.firstName} and i need your help`,
    //             type: 'gpt-4',
    //         },
    //     ]);
    // }, [socket]);

    useEffect(() => {
        setShowSidebar(true);
        setShowTop(true);
    }, []);

    const navigate = useNavigate();

    return (
        <div className="w-full flex flex-col h-[85%] justify-between items-start relative">
            {/* <div className="text-[16px] font-normal mt-[6rem] text-white flex flex-row items-start w-[90%]  ">
                <p className='bg-gray-600 p-4 rounded-full w-auto'>
                Hello, what can I do for you today?
                </p></div> */}
            {/* Options */}
            {/* <div className="flex ml-10 mt-10 flex-row items-start">
                <BackButton
                    onclick={() => {
                        navigate(-1);
                    }}
                />
            </div> */}
            {flowChartList.length === 0 && (
                <div className="absolute top-1/4 flex flex-col items-center w-full space-y-4 mb-6">
                    <div className=" border-2 border-gray-300 dark:border-gray-100 rounded-lg p-4 dark:bg-gray-900">
                        {' '}
                        <SettingOutlined className="text-blue-600 text-7xl" />
                    </div>
                    <p className="text-2xl font-bold text-gray-800 dark:text-white">
                        Hi <span className="text-customOrange">{details?.firstName}</span>, Welcome
                    </p>
                    <p className="text-lg text-gray-600 dark:text-gray-100">
                        What flowchart analysis can I assist you with today?
                    </p>
                </div>
            )}

            <div className="overflow-auto left-0 right-0 top-0 h-[90vh] w-full" ref={bottomRef}>
                <div className="w-[30%]">
                    {' '}
                    {getUrlPath().pagename === 'flow_chart' && flowChartList.length !== 0 && (
                        <Tooltip
                            className="custom-tooltip hidden md:flex z-50"
                            title="Create New Chat"
                            placement="right"
                            overlayInnerStyle={{
                                backgroundColor: 'white',
                                color: 'black',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                            }}
                        >
                            <button
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    left: '0',
                                    right: '0',
                                    top: '0',
                                }}
                                onClick={() => {
                                    setCategory(null);
                                    setFlowChartList([]);
                                    localStorage.removeItem('activeChat');
                                    historyId.current = undefined;
                                }}
                                className="flex items-center  justify-center ml-6 w-6 h-6 lg:w-10 lg:h-10  md:bg-transparent border-[1.5px] border-gray-700 dark:border-white text-gray-700 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none shadow-md z-[9999999]"
                            >
                                <EditOutlined />
                            </button>
                        </Tooltip>
                    )}
                </div>

                <ChatScreen
                    isFlow={true}
                    editedContent={editedContent}
                    setEditedContent={setEditedContent}
                    isMobile={isMobile}
                    isWorkFlow={true}
                    actions={(v, i) => {
                        // handleAction(v, i);
                        // const selectedChat = flowChartList[i];
                        setCategory(null);
                        setFlowChartList([]);
                        localStorage.removeItem('activeChat');
                        historyId.current = undefined;
                    }}
                    streaming={streaming}
                    backClick={() => {
                        setFlowChartList([]);
                        localStorage.removeItem('activeChat');
                        historyId.current = undefined;
                    }}
                    handleUpdatedChat={() => {
                        currentEntity.current = { role: 'user', content: editedContent };
                        setFlowChartList([...flowChartList, { role: 'user', content: editedContent, type: 'gpt-4' }]);
                        _chatsRef.current = [...flowChartList, { role: 'user', content: editedContent }];
                        handleChat([...flowChartList, { role: 'user', content: editedContent, type: 'gpt-4' }]);
                    }}
                    chatList={flowChartList}
                    showNavBar={true}
                    typing={isTyping}
                    suggestions={[]}
                    handleSuggestionClick={(question) => {
                        currentEntity.current = { role: 'user', content: question };
                        setFlowChartList([...flowChartList, { role: 'user', content: question, type: 'gpt-4' }]);
                        _chatsRef.current = [...flowChartList, { role: 'user', content: question }];
                        handleChat([...flowChartList, { role: 'user', content: question, type: 'gpt-4' }]);
                    }}
                    usingSuggestPrompt={false}
                />
            </div>
            <div className=" sticky absolute bottom-0 mx-auto flex-1 flex flex-col w-[90%] gap-y-5 ">
                <div className="mx-auto flex  w-full justify-center items-center">
                    <Input
                        placeholder="  Please upload an image of a flowchart for a detailed analysis."
                        Value={value}
                        setValue={setValue}
                        onPaste={handlePaste}
                        img={img}
                        setImg={setImg}
                        showLeftField={false}
                        value={(v: string, image: string[], files: FileContent[]) => {
                            const contentValue: ChatContent[] = [
                                {
                                    type: 'text',
                                    text: v,
                                },
                            ];
                            if (image.length !== 0) {
                                for (let i = 0; i < image.length; i++) {
                                    contentValue.push({
                                        type: 'image_url',
                                        image_url: {
                                            url: image[i],
                                        },
                                    });
                                }
                            }
                            if (files.length > 0) {
                                contentValue.push({
                                    type: 'files',
                                    files: files,
                                });
                            }

                            

                            // const categories = determineCategory(v);
                            // console.log(categories);
                            // if (category !== categories && category === 'Coding') {
                            //   toast.error('Please ask a question related to ' + categories);
                            //   return;
                            // }

                            currentEntity.current = { role: 'user', content: contentValue };
                            setFlowChartList([
                                ...flowChartList,
                                { role: 'user', content: contentValue, type: 'gpt-4' },
                            ]);
                            _chatsRef.current = [...flowChartList, { role: 'user', content: contentValue }];
                            handleChat([...flowChartList, { role: 'user', content: contentValue, type: 'gpt-4' }]);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default FlowChartAnalyzer;

// PaymentComponent.js
"use client";
import React, { useEffect, useState } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "./ui/button";
import { BASE_URL } from "@src/utils/constants";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Modal, Form, Input, Radio } from 'antd';
import { acquisitionRoutes } from "@src/utils/app_routes";

// let promoCode = 'RSFCB8TA'
let promoCode = 'TS2YS7GM'


const FlutterwareComponent = ({ amount, currency, title, cssStyle, pricingPeriod }: any) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  
  
  const handlePayment = async (discountCode = promoCode , affiliateCode = '') => {
    const token = localStorage.getItem("token");
    if ( !token ) {
      toast.error("Please login to continue");
      setTimeout(() => {
        navigate(`${acquisitionRoutes.login}?fromPricing=1&amount=${amount}&currency=${currency}&pricingPeriod=${pricingPeriod}`);
      }, 2000)
      return;
    }

    const user = localStorage.getItem("user");

    if ( !user ) return;

    let userDetails = JSON.parse(user);

    setLoading(true);

    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    
    const checkoutUrl = `${protocol}//${hostname}/payment-success`;
    const cancelUrl = `${protocol}//${hostname}/pricing`;
    
    console.log('Checkout URL:', checkoutUrl);
    console.log('Cancel URL:', cancelUrl);

    const baseurl = BASE_URL;

    const res = await fetch(`${baseurl}/user/create-checkout-flutterwave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ amount, currency, checkoutUrl, cancelUrl, repayment: pricingPeriod, discountCode, affiliateCode }),
    });
    

    const session = await res.json();

    if ( session.statusCode != 1 ){
      toast.error(session.message);
      setLoading(false);
      return;
    }

    const sessionData = session.data;

    window.location = sessionData.url;
  }

  return (
    <>
      <CodeEntryModal 
        isAffiliateModal={true} 
        loading={loading} 
        isModalVisible={isModalVisible} 
        onCancel={() => setIsModalVisible(false)} 
        onOkay={(e) => {
          handlePayment(promoCode, e);
        }} 
      />
      <Button
        className={cssStyle}
        variant="outline"
        disabled={loading}
        onClick={ () => {
          const token = localStorage.getItem("token");
          if ( !token ) {
            toast.error("Please login to continue");
            setTimeout(() => {
              navigate(`${acquisitionRoutes.login}?fromPricing=1&amount=${amount}&currency=${currency}&pricingPeriod=${pricingPeriod}`);
            }, 2000)
            return;
          }
          // handlePayment(promoCode);
          setIsModalVisible(true)
        }}
      >
        {title}
      </Button>
    </>
    
  );
};

const StripeComponent = ({ amount, currency, title, cssStyle, pricingPeriod }: any) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
 


  const handleClick = async (discountCode= promoCode, affiliateCode = '' ) => {
    // check if user is logged in
    const token = localStorage.getItem("token");
    if ( !token ) {
      toast.error("Please login to continue");
      setTimeout(() => {
        navigate(`${acquisitionRoutes.login}?fromPricing=1&amount=${amount}&currency=${currency}&pricingPeriod=${pricingPeriod}`);
      }, 2000)
      return;
    }

    const user = localStorage.getItem("user");

    setLoading(true);

    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    
    const checkoutUrl = `${protocol}//${hostname}/payment-success`;
    const cancelUrl = `${protocol}//${hostname}/pricing`;
    
    console.log('Checkout URL:', checkoutUrl);
    console.log('Cancel URL:', cancelUrl);

    const baseurl = BASE_URL;

    const res = await fetch(`${baseurl}/user/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ amount, currency, checkoutUrl, cancelUrl, repayment: pricingPeriod, discountCode, affiliateCode }),
    });
    

    const session = await res.json();

    if ( session.statusCode != 1 ){
      toast.error(session.message);
      setLoading(false);
      return;
    }

    const sessionData = session.data;

    console.log("session", sessionData);
   

    const stripePromise = loadStripe(
      "pk_live_51PVl0ZIU4MeowKFNhRw1waVtl9ZRNJIdclbrem4ogfnO6Fr7ylon26fPYKwMgu64AZwbsjG9ZrzTW4vuDDJD5JY500F22m1JLe"
    ); // Replace with your Stripe publishable key
    const stripe = await stripePromise;

    if (stripe != null) {
      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionData.id,
      });

      if (error) {
        console.error("Stripe Checkout error", error);
        setLoading(false);
      }
    } else {
      console.error("Stripe Checkout error");
      setLoading(false);
    }
  };



  return (
    <>
      <CodeEntryModal 
        isAffiliateModal={true} 
        loading={loading} 
        isModalVisible={isModalVisible} 
        onCancel={() => setIsModalVisible(false)} 
        onOkay={(e) => {
          handleClick(promoCode, e);
        }} 
      />
      <Button
        role="link"
        onClick={ () => {
          const token = localStorage.getItem("token");
          if ( !token ) {
            toast.error("Please login to continue");
            setTimeout(() => {
              navigate(`${acquisitionRoutes.login}?fromPricing=1&amount=${amount}&currency=${currency}&pricingPeriod=${pricingPeriod}`);
            }, 2000)
            return;
          } 
          
          // handleClick(promoCode);
          setIsModalVisible(true)
        }}
        className={cssStyle}
        disabled={loading}
      >
        {loading ? "Processing..." : title}
      </Button>
    </>
    
  );
};

const CodeEntryModal = ({
  isModalVisible, 
  isAffiliateModal,
  onCancel, 
  onOkay,
  loading,
}: {
  isModalVisible: boolean, 
  isAffiliateModal: boolean, 
  loading: boolean, 
  onCancel: () => void,
  onOkay: (discountCode: string) => void,
}) => {
  const [code, setCode] = useState<string>('');
  const [hasReferralCode, setHasReferralCode] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("hasReferralCode", false);
  }, [])


  const handleOk = () => {
    onOkay(code);
  };

  return (
    <>
     <Modal
  title={ "Complete your payment"
    // isAffiliateModal 
    //   ? "Enter Your Referral Code" 
    //   : "Enter Your Discount Code"
  }
  open={isModalVisible}
  onOk={handleOk}
  okText="Proceed to Payment"
  footer={[
    <Button
      key="proceed"
      role="link"
      onClick={handleOk}
      className="text-white text-sm bg-[var(--gmind-orange)] hover:bg-orange-600 transition-colors ease-in-out duration-300 px-4 py-2 rounded-md shadow-md"
      disabled={loading}
    >
      {loading ? "Processing..." : "Proceed to Payment"}
    </Button>
  ]}
  onCancel={() => onCancel()}
>
  {/* <p className="text-gray-600 text-sm mb-4">
    {isAffiliateModal
      ? "Enter your referral code below to claim your rewards!"
      : "Got a discount code? Enter it here to unlock your savings."}
  </p> */}
  {/* <p className="text-gray-600 text-sm mb-4">
    Do you have a referral code?
  </p> */}
  <Form form={form} layout="vertical" name="codeEntryForm">
    <Form.Item name="hasReferralCode" className="mb-4">
      <Radio.Group
        onChange={(e) => setHasReferralCode(e.target.value)} 
        value={hasReferralCode}
      >
        <span className="text-gray-600 text-sm mb-4 mr-3">
          Do you have a referral code?
        </span>
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    </Form.Item>
    { hasReferralCode && <Form.Item
      name="code"
      // label={<p className="text-black">{isAffiliateModal ?"Refferal Code":"Discount Code"}</p>}
      rules={[{ required: false, message: 'Please enter your code!' }]}
    >
      <input
        type="text"
        placeholder="Enter your code"
        className="w-full border border-gray-300 rounded-lg p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
        onChange={(e) => setCode(e.target.value)}
        value={code}
      />
    </Form.Item>}
  </Form>
</Modal>

    </>
  );
};

export { FlutterwareComponent, StripeComponent };

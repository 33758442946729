
import SignUp from "./components/SinUp";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";
import ExampleCard1 from "@src/components/example_card/index copy";
import { signupStructuredData } from "../strutured_data/structured_data";
import { acquisitionRoutes } from "@src/utils/app_routes";
// import { signupStructuredData } from "../strutured_data/structured_data";



export default function SignUpPage() {
    return (
        <div className="w-full flex flex-col h-screen bg-white gap-y-3 py-0 px-5 md:px-20">
            {/* <Helmet>
                <script type="application/ld+json">{JSON.stringify(signupStructuredData)}</script>
                <link rel="canonical" href="https://gmind.ai/signup" />
                <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
                <meta name="description" content="Best AI for educators, save time planning, creating teaching materials and get support for teaching practice" />
                <meta
                    name="title"
                    content="GMind AI SignUp | Access Your AI Productivity Tools"     
                />
              <link rel="canonical" href="https://www.gmind.ai/signup" />
                <title>Get Started</title>
                <meta name="keywords" content="gmind, ai, chat" />
            </Helmet> */}
            <Helmet>
                <title>Get Started for Free | GMind AI</title>
                <meta
                    name="description"
                    content="Sign up for GMind AI and explore its powerful productivity features for free. Begin your journey to enhanced efficiency and smarter workflows today!"
                />
                <meta
                    name="keywords"
                    content="Get started for free, GMind AI sign-up, free AI trial, productivity tools, AI features free access, smarter workflows, enhanced efficiency"
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://gmind.ai${acquisitionRoutes.getStarted}`} />
                <meta
                    property="og:title"
                    content="Get Started for Free | GMind AI"
                />
                <meta
                    property="og:description"
                    content="Sign up for GMind AI and explore its powerful productivity features for free. Begin your journey to enhanced efficiency and smarter workflows today!"
                />
                <meta
                    property="og:image"
                    content="https://gmind.ai/images/get-started-og-image.jpg"
                />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={`https://gmind.ai${acquisitionRoutes.getStarted}`} />
                <meta
                    property="twitter:title"
                    content="Get Started for Free | GMind AI"
                />
                <meta
                    property="twitter:description"
                    content="Sign up for GMind AI and explore its powerful productivity features for free. Begin your journey to enhanced efficiency and smarter workflows today!"
                />
                <meta
                    property="twitter:image"
                    content="https://gmind.ai/images/get-started-twitter-image.jpg"
                />
                <link rel="canonical" href={`https://gmind.ai${acquisitionRoutes.getStarted}`} />
                <meta name="robots" content="index, follow" />
                <script type="application/ld+json">
                    {JSON.stringify(signupStructuredData)}
                </script>
            </Helmet>

            {/* <div className=" w-full md:w-full">
                <Header isLoggedIn={false} onClickFaQ={() => {}} onClickFeature={() => {} } onClickPricing={() => {}} />
            </div> */}

            <div className="flex flex-col md:flex-row items-center justify-between mt-3">
                <SignUp />
                <div className="hidden md:block">
                    <ExampleCard1 />
                </div>
            </div>
            <div className="hidden md:block">
                <ToastContainer />
            </div>
        </div>
    );
}


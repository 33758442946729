import { BsFileEarmarkPdf } from 'react-icons/bs';
import cancel from '@assets/cancel.svg';
import { FaRegFileWord } from 'react-icons/fa';
import { GiShipWheel } from 'react-icons/gi';

export const Download = ({
    onClose,
    handlePDF,
    handleWord,
    isPdfDownloading,
    isWordDownloading,
    isAnswer,
    setIsAnswer,
    showCheck,
}: {
    url: string;
    onClose: () => void;
    handlePDF: () => void;
    handleWord: () => void;
    isPdfDownloading?: boolean;
    isWordDownloading?: boolean;
    isAnswer?: boolean;
    setIsAnswer?: React.Dispatch<React.SetStateAction<boolean>>;
    showCheck?: boolean;
}) => {
    return (
        <section className="fixed inset-0 z-[1000] flex items-center justify-center">
            <div className="bg-black bg-opacity-60 z-50 absolute inset-0"></div>
            <div className="h-fit pb-5 rounded-xl max-sm:w-[90vw] max-sm:h-fit w-[40vw] bg-white z-[1000]">
                <div className="flex items-center p-4 justify-between">
                    <p>Download</p>
                    <img role="button" onClick={() => onClose()} className="w-5" src={cancel} alt="cancel" />
                </div>

                <div className="flex flex-col gap-2 px-4">
                    <div className="flex gap-x-3 items-center">
                        <button
                            onClick={() => {
                                handlePDF();
                            }}
                            className="w-44 flex items-center justify-between gap-2 text-sm rounded-sm font-medium transition-all text-red-500 hover:text-white hover:bg-red-500 border border-red-500 p-1 px-2"
                            type="button"
                        >
                            <span>PDF</span>
                            <BsFileEarmarkPdf className="text-xl" />
                        </button>
                        {isPdfDownloading && <GiShipWheel className="animate-spin text-2xl" />}
                    </div>

                    <div className="flex items-center gap-x-3">
                        <button
                            onClick={() => {
                                handleWord();
                            }}
                            className="w-44 flex items-center justify-between gap-2 text-sm rounded-sm font-medium transition-all border text-blue-500 hover:text-white border-blue-500 hover:bg-blue-500 p-1 px-2"
                            type="button"
                        >
                            <span>Word Document</span>
                            <FaRegFileWord className="text-xl" />
                        </button>
                        {isWordDownloading && <GiShipWheel className="animate-spin text-2xl" />}
                    </div>

                    {showCheck && (
                        <div className="mt-4 px-4 flex flex-row justify-end w-full">
                            <label className="flex items-center gap-2 text-sm">
                                <input
                                    type="checkbox"
                                    checked={isAnswer}
                                    onChange={() => setIsAnswer && setIsAnswer((prev) => !prev)}
                                    className="cursor-pointer"
                                />
                                <span>Download with Answers</span>
                            </label>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

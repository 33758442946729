import axios from "axios";
import { BASE_URL } from "../../../utils/constants";
import { ExtractDocumentResponse, PictureUploadResponse } from "../components/interface/interface";
import { VisualizeDataPayloadProps } from "../pages/data_visualizer/interface";

const baseURL = BASE_URL; //process.env.NEXT_PUBLIC_GMIND_BASEURL;


const  handleAddHistory = async (conversation: any, type: string, docData: any, specificType?: string ) => {
    const token = localStorage.getItem('token') || '';

    let payload: any = {
      conversation,
      docData
    }

    if ( specificType ){
      payload['type'] = specificType
    }
    
    try {
      const response = await axios.post(
        `${BASE_URL}/gmind/${type}`,
        payload,
        {headers: {
            Authorization: `Bearer ${token}`,
        },}
      );

      return response.data;
    } catch (error) {
      console.error("Error adding chat history:", error);
    }
}


const handleDeleteHistory = async (id: string, type: string ) => {
  const token = localStorage.getItem('token') || '';


  try {
    const response = await axios.delete(
      `${BASE_URL}/gmind/${type}/${id}`,
      {headers: {
          Authorization: `Bearer ${token}`,
      },}
    );
  } catch (error) {
    console.error("Error adding chat history:", error);
  }
}

const handleDeleteAllHistory = async (type: string ) => {
  const token = localStorage.getItem('token') || '';


  try {
    const response = await axios.delete(
      `${BASE_URL}/gmind/${type}`,
      {headers: {
          Authorization: `Bearer ${token}`,
      },}
    );
  } catch (error) {
    console.error("Error adding chat history:", error);
  }
}

const handleDeleteAllWithTypeHistory = async (type: string, specificType: string) => {
  const token = localStorage.getItem('token') || '';


  try {
    const response = await axios.delete(
      `${BASE_URL}/gmind/${type}/all/${specificType}`,
      {headers: {
          Authorization: `Bearer ${token}`,
      },}
    );
  } catch (error) {
    console.error("Error adding chat history:", error);
  }
}

const  getHistory = async (type: string) => {
  const token = localStorage.getItem('token') || '';

  try {
    const response = await axios.get(`${BASE_URL}/gmind/${type}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const { data } = response;

    return data;
  } catch (error) {
    return {
        statusCode: 0,
        message: error,
    };
  }
}

const visualizeData = async (payload: VisualizeDataPayloadProps) => {
  const token = localStorage.getItem('token') || '';

  try {
    const response = await axios.post(
      `${BASE_URL}/gmind/generate_chart`,
      payload,
      {headers: {
          Authorization: `Bearer ${token}`,
      },}
    );

    const {data} = response;

    return data;
  } catch (error: any) {
    console.error("Error adding chat history:", error);
    return {
        status: 0,
        message: error.message || 'Error visualizing data',
    };
  }
}

export async function uploadChatPicture(file: File): Promise<PictureUploadResponse> {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append('image', file);

  try {
    const response = await axios.post(`${BASE_URL}/gmind/chat-image`, formData, {
      headers: {

        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return {
        success: true,
        message: 'Picture uploaded successfully',
        url: response.data?.url ?? '',
        base64: response.data?.base64 ?? '',
      };
      
    } else {
      return {
        success: false,
        message: `Failed to upload profile picture. Status code: ${response.status}`,
      };
    }
  } catch (error: any) {
    return {
      success: false,
      message: error.response?.data?.message || 'An error occurred while uploading the profile picture',
    };
  }
}

export async function uploadChatFile(file: File): Promise<PictureUploadResponse> {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append('document', file);

try {
  const response = await axios.post(`${BASE_URL}/gmind/chat-doc-extract`, formData, {
    headers: {

      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status === 200) {
    return {
      success: true,
      message: 'File uploaded successfully',
      url: response.data?.content ?? '',
    };
    
  } else {
    return {
      success: false,
      message: `Failed to upload file. Status code: ${response.status}`,
    };
  }
} catch (error: any) {
  return {
    success: false,
    message: error.response?.data?.message || 'An error occurred while uploading the file',
  };
}
}


export const extractFileData = async (file: File): Promise<ExtractDocumentResponse> => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append('document', file);

  try {
    const response = await axios.post(`${BASE_URL}/gmind/chat-doc-extract`, formData, {
      headers: {

        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return {
        success: true,
        message: 'File uploaded successfully',
        content: response.data?.content ?? '',
      };
      
    } else {
      return {
        success: false,
        message: `Failed to upload file. Status code: ${response.status}`,
      };
    }
  } catch (error: any) {
    return {
      success: false,
      message: error.response?.data?.message || 'An error occurred while uploading the file',
    };
  }
}


export type  Chats = { role: string; content: string; type?: 'gpt-4'; };
export type ChatType = {
  role: 'assistant' | 'user';
  content: string;
};

export {
  handleAddHistory,
  getHistory,
  handleDeleteHistory,
  handleDeleteAllHistory,
  handleDeleteAllWithTypeHistory,
  visualizeData,
}
import React from "react";


export const EssayTitle = ({topic, setTopic}: {topic: string, setTopic: React.Dispatch<React.SetStateAction<string>>}) => {
 

  return (
    <div className="flex flex-col items-center justify-center">
         <h2 className="text-2xl font-semibold mb-4 dark:text-gray-300">What do you want us to write about?</h2>
      <p className="text-gray-600 dark:text-gray-300 mb-6">
        Your prompt can be short, long, or even ambiguous.
      </p>
      <textarea
      className="w-full p-2 border border-gray-500 rounded-md focus:outline-none focus:ring focus:border-blue-300 placeholder-gray-500 dark:bg-white dark:text-gray-900 dark:placeholder-gray-800"
        rows={6}
        placeholder="Enter your essay topic..."
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
      />
    </div>
  );
};

import { Button, Form, Modal } from 'antd';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import Input from '@src/pages/dashboard/components/Input';
import { FileContent, HistoryTypeFormat } from '@src/core/interfaces/chat';
import { ChatContent } from '@src/pages/dashboard/components/interface/interface';

import { FileTextOutlined, HighlightOutlined, EditOutlined, FilePdfFilled, CloseCircleOutlined, PaperClipOutlined } from '@ant-design/icons';
import { MdFormatListBulleted } from 'react-icons/md';
import { Chats, ChatType, getHistory } from '@src/pages/dashboard/endpoints';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import { BASE_URL } from '@src/utils/constants';

import { io, Socket } from 'socket.io-client';
import { useOutletContext, useNavigate } from 'react-router';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import { openCurriculumEditor } from '@src/utils/app_functions';
import { prompts } from '@src/utils/prompts';

import { v4 as uuidv4 } from 'uuid';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import Profile from '@assets/Avatar.svg';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import BackButton from '@src/components/BackButton';
import { extractTextFromPDF, extractTextFromDOCX } from '@src/pages/dashboard/function/pdf_doc_helper';
import { chatRoutePages } from '@src/utils/app_routes';
import { CircularProgress } from '@mui/material';
import { Value } from '@radix-ui/react-select';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const CustomerBuilder = () => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [uploadedFile, setUploadedFile] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [description, setDescription] = useState('');
    const [template, setTemplate] = useState('');
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState<any>([]);
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [generate, setGenerate] = useState<boolean>(false);
    const [generating, setGenerating] = useState(false);
    const [promptSent, setPromptSent] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [canNavigate, setCanNavigate] = useState(false);
    const [option, setOption] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const { loading, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const editorInstanceRef = useRef<any>();

    const {
        setShowTop,
        setShowSidebar,
        setHistory,
        setMobileNavStyle,
        setShowPagePath,
        setShowHistoryOption,
        setShowRightComponent,
        setShowHistory,

        response,
        details,
        setResponse,
    } = useOutletContext<any>();

    const pageHistory = 'essay';
    const navigate = useNavigate();
    const baseurl = BASE_URL;
    const bottomRef = useRef<HTMLDivElement>(null);
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const [userInput, setUserInput] = useState('');
    const [isloading, setisLoading] = useState(false);
    const [extractedFile, setExtractedFile] = useState<string>('');

    useEffect(() => {
        const newSocket = io(baseurl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
            console.log('Received data:', data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            // setShowEditorButton(true);
            const { assistant } = data;
            setStreamEndMessage(assistant);
            setIsTyping(false);
            setCanNavigate(true);
            // handle stream end
            const uuid = uuidv4();
            setDocId(uuid);

            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                });
            }
            socket?.emit('get-documents', user?.id);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };

    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

    useEffect(() => {
        console.log(generate);

        if (generate || showingHistory) {
            openCurriculumEditor({
                response,
                pageId: docId,
                promptSent,
                promptType: prompts.curriculum,
                navigate,
                pageSocket: socket,
            });
            getDocIdFromUrl();
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);

    const getPageHistory = useCallback(() => {
        getHistory(pageHistory).then((response) => {
            if (response?.statusCode === 1) {
                const data = response?.data;
                const filtered: HistoryTypeFormat[] = data?.map((d: any) => {
                    return {
                        date: d.date,
                        histories: d.histories?.map((history: any) => {
                            return [
                                ...history.conversation,
                                { chat_id: history?.chat_id },
                                { id: history._id },
                                { date: history.updatedAt },
                            ];
                        }),
                    };
                });

                console.log(filtered);
                setHistories(filtered);
            } else {
                setHistories([]);
            }
        });
    }, [pageHistory, setHistories]);

    useEffect(() => {
        if (generate || showingHistory) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (response) {
            console.log(response);
            setEditorContent(response);
            setTranslatedContent(response);
        }
    }, [response]);

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message, type: 'gpt-4' };
        setChats(msgs);
        setResponse('');
        setIsTyping(true);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: systemPrompt,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId,
                },
            });
            // await handleAddHistory([{ role: 'user', content: message }, msgs], pageHistory, {});
            // getPageHistory();
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    const onFinish = (values: string) => {
        setGenerate(true);
        setGenerating(true);
        const promptMessage = `
        You are a professional Customer Personas Builder.
        Follow the instructions below:
        Using this ${values}, create detailed customer personas:
        ${
            extractedFile
                ? `Template: ${values}. The uploaded file content is attached below. ${extractedFile}`
                : `Use the provided information to create customer personas based on the following details: ${values}.`
        }

        - Context: Use ${values} as a guide to structure and enhance the personas.
`;

        onGenerateHandler(promptMessage);
    };

    useEffect(() => {
        console.log('working');
    }, [generate]);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0];

        console.log('Selected file:', file);
        setisLoading(true);

        if (!file) return;
        console.log('Selected file:', file);

        const fileType = file.type;

        if (
            fileType === 'application/pdf' ||
            fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            // Handle PDF and DOCX files
            const fileTypeValue: 'PDF' | 'DOCX' = fileType === 'application/pdf' ? 'PDF' : 'DOCX';
            try {
                // }

                if (fileType === 'application/pdf') {
                    const doc = await extractTextFromPDF(file);
                    setExtractedFile(doc);
                    setUserInput(doc);
                    console.log(doc);
                    console.log('exract', extractedFile);
                } else {
                    const doc = await extractTextFromDOCX(file);
                    setExtractedFile(doc);
                    console.log(doc);
                    setUserInput(doc);
                }
            } catch (error) {
                console.error('Error during file upload:', error);
                toast.error('File upload failed');
            } finally {
                setisLoading(false);
            }
        } else {
            console.warn('Unsupported file type:', fileType);
            return;
        }
    };

    return (
        <div
            className={`${!generate && !showingHistory ? 'px-10' : ''} w-full flex flex-col h-full overflow-y-auto md:h-screen items-start`}
        >
            <div className="flex flex-row items-start justify-start mt-5 w-full">
                {!generate && !showingHistory && <BackButton onclick={() => navigate(`/${chatRoutePages.studentResources}`)} />}
            </div>
            {!generate && !showingHistory ? (
                <>
                    {' '}
                    <div className="flex flex-col gap-y-4 p-6 md:p-10  rounded-lg ">
                        <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-100">
                            Customer Personas Builder
                        </h2>
                        <p className="text-lg text-gray-600 dark:text-gray-300">
                            Create detailed profiles for your target customers with ease.
                        </p>

                        <div className="max-w-4xl flex flex-col gap-y-4 w-full">
                        <div className="flex flex-row items-center gap-3  w-full  border border-gray-700 dark:border-gray-200 p-2 rounded-lg focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-0">
                                    <div className="relative flex-grow ">
                                        {!isloading ? (
                                            <label className="flex items-center text-gray-600 dark:text-gray-300 cursor-pointer">
                                                {extractedFile ? (
                                                    <div className="relative flex items-center justify-start w-20 h-12 bg-white rounded-md shadow-md">
                                                        {/* PDF Icon */}
                                                        <FilePdfFilled className="text-2xl text-red-600 z-10 ml-4" />

                                                        {/* Close Icon */}

                                                        <CloseCircleOutlined
                                                            className="absolute top-0 right-0 z-[100]  text-red-500 cursor-pointer hover:text-red-700"
                                                            onClick={() => setExtractedFile('')}
                                                        />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <input
                                                            type="file"
                                                            accept=".pdf,.docx"
                                                            onChange={handleFileChange}
                                                            className="hidden"
                                                        />
                                                        <PaperClipOutlined className="absolute top-[1rem] text-gray-600 dark:text-gray-200 text-2xl " />
                                                    </>
                                                )}
                                            </label>
                                        ) : (
                                            <div className='h-6 w-6 '>
                                            <CircularProgress className="text-md" /> </div>
                                        )}

                                        <div className="">
                                            <textarea
                                                value={inputValue}
                                                onChange={(e) => setInputValue(e.target.value)}
                                                className="w-full mt-2 pr-12 pl-10 rounded-lg border-none text-black dark:text-white focus:outline-none placeholder-gray-600 dark:placeholder-gray-300 "
                                                placeholder="Attach a file or enter your prompt to generate a customer persona"
                                            />
                                        </div>
                                    </div>
                                </div>
                        </div>

                        <div className="flex justify-start items-center">
                            <button
                                onClick={() =>
                                    
                                   {
                                    if(!inputValue) {
                                        toast.info('Please enter a prompt');
                                        return;
                                    }
                                    
                                    
                                    onFinish(inputValue)}}
                                className="px-6 py-3 bg-customOrange text-white font-medium rounded-lg shadow hover:bg-orange-600 transition-colors w-full md:w-auto"
                            >
                                Generate
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                <div className="w-full flex flex-col gap-0 ">
                    <NewEditorPage
                        editorInstanceRef={editorInstanceRef}
                        content={
                            <NewSunEditorComponent
                                latestRespone={latestRespone}
                                setLatestRespone={setLatestRespone}
                                highlightedTexts={highlightedTexts}
                                setHighlightedTexts={setHighlightedTexts}
                                editorInstanceRef={editorInstanceRef}
                                // humanizeded_content={Document.current?.data.ops}
                                // hasAccess={hasAccess}
                                hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                                initial_content={response}
                                onChangeEditorContent={(e: string) => {
                                    setEditorContent(e);
                                    const newtext = e;
                                    console.log('newtext', newtext);

                                    console.log('docId', docId);
                                    console.log('userDetails?.userId', userDetails?.userId);

                                    sendChanges(docId, userDetails?.userId, e);
                                }}
                            />
                        }
                        showTopNavBar={false}
                        showInvite={true}
                        isTyping={isTyping}
                        Profile={Profile}
                        promptSent={promptSent}
                        showingHistory={showingHistory}
                        setGenerate={setGenerate}
                        setShowingHistory={setShowingHistory}
                        generate={generate}
                        setEditorContent={setEditorContent}
                        editorContent={editorContent}
                        translatedContent={translatedContent}
                        setTranslatedContent={setTranslatedContent}
                        humanizedContent={humanizedContent}
                        setHumanizedContent={setHumanizedContent}
                        setUserOnDoc={setUserOnDoc}
                        userOnDoc={userOnDoc}
                        latestRespone={latestRespone}
                        setLatestRespone={setLatestRespone}
                        highlightedTexts={highlightedTexts}
                        setHighlightedTexts={setHighlightedTexts}
                        regenerate={onFinish}
                    />
                </div>
            )}
        </div>
    );
};

export default CustomerBuilder;

const systemPrompt = `
You are an AI-powered Customer Personas Builder. Your goal is to provide detailed and actionable customer personas that help businesses deeply understand their target audience by focusing on:

1. **Demographics**: Define the customer's basic information such as age, gender, job title, education, income level, and location.
2. **Psychographics**: Describe the customer's values, attitudes, interests, and lifestyle choices.
3. **Goals**: Identify the customer's professional or personal aspirations.
4. **Challenges**: Highlight the primary pain points or obstacles they face.
5. **Preferred Communication Channels**: Identify the customer’s preferred ways of receiving information or engaging with businesses.
6. **Product/Service Fit**: Explain how the business's products or services meet the needs of this customer persona.

**Response Guidelines**:
- **Structured Format**: Provide the personas in a clear, easy-to-read structure with concise and actionable details.
- **Custom Tailoring**: Customize the personas based on industry, business goals, and target audience profiles provided.
- **Solution-Oriented**: Focus on how the product or service solves specific challenges or meets the goals of the persona.
- **Positive and Constructive**: Ensure all feedback and suggestions encourage business alignment and clarity.

**Response Format**:

Persona 1: **[Persona Name]**

| **Attribute**                 | **Description**                                                                 |
|-------------------------------|---------------------------------------------------------------------------------|
| **Demographics**              | - Age: [Age]                                                                   |
|                               | - Gender: [Gender]                                                             |
|                               | - Job Title: [Role/Position]                                                   |
|                               | - Location: [City/Country]                                                     |
|                               | - Education: [Education Background]                                            |
|                               | - Income Level: [Income Group]                                                 |
| **Psychographics**            | - [Key Values, Interests, and Preferences]                                     |
|                               | - [Behavioral Traits or Buying Habits]                                         |
| **Goals**                     | - [Primary Goals the Persona Wants to Achieve]                                 |
| **Challenges**                | - [Specific Challenges or Pain Points the Persona Faces]                       |
| **Preferred Communication Channels** | - [Email, Social Media, Events, Phone, etc.]                                |
| **How Our Product/Service Helps** | - [Describe how your offering meets their needs or solves their challenges] |

**Example**:

Persona 1: **Adebola Oluwole**

| **Attribute**                 | **Description**                                                                 |
|-------------------------------|---------------------------------------------------------------------------------|
| **Demographics**              | - Age: 45                                                                      |
|                               | - Gender: Male                                                                 |
|                               | - Position: Procurement Manager at a large manufacturing firm                  |
|                               | - Location: Lagos, Nigeria                                                     |
|                               | - Education: Bachelor's in Mechanical Engineering                              |
|                               | - Income Level: High                                                           |
| **Psychographics**            | - Values efficiency and reliability                                            |
|                               | - Analytical thinker                                                           |
|                               | - Focuses on cost-effectiveness                                                |
|                               | - Prefers long-term partnerships                                               |
|                               | - Stays updated with industry trends                                           |
| **Goals**                     | - Secure a consistent and affordable supply of petroleum products              |
|                               | - Reduce operational costs                                                     |
|                               | - Ensure product quality meets industry standards                              |
|                               | - Streamline procurement processes                                             |
| **Challenges**                | - Fluctuating oil prices                                                       |
|                               | - Supply chain disruptions                                                     |
|                               | - Regulatory compliance                                                        |
|                               | - Vendor reliability issues                                                    |
| **Preferred Communication Channels** | - Email                                                                    |
|                               | - Professional networking events                                               |
|                               | - Industry conferences                                                         |
|                               | - Business newsletters                                                         |
| **How Our Product/Service Helps** | - Offers competitive pricing with flexible contracts                          |
|                               | - Provides reliable supply chain management                                    |
|                               | - Ensures high-quality petroleum products                                      |
|                               | - Offers personalized customer support                                         |
`;

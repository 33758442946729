import React from "react";
import { Card } from "antd";

const tones = ['Narrative', 'Argumentative', 'Persuasive', 'Expository', 'Descriptive', 'Informative'];

export const EssayTone = ({selectedTone, setSelectedTone}: {selectedTone: string, setSelectedTone: React.Dispatch<React.SetStateAction<string>>}) => {
 

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="text-2xl font-semibold mb-4 dark:text-gray-300">How should it sound?</h2>
      <p className="text-gray-600 dark:text-gray-300 mb-6">Select a tone for your text:</p>
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }} className="w-full items-center justify-center">
        {tones.map((tone) => (
          <Card
            key={tone}
            hoverable
            className='w-full md:w-auto flex flex-row items-center justify-center'
            style={{
              border: tone === selectedTone ? "2px solid #1890ff" : "1px solid #ddd",
            }}
            onClick={() => setSelectedTone(tone)}
          >
            {tone}
          </Card>
        ))}
      </div>
    </div>
  );
};

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Upload, Button, Spin, message, Table, Tooltip } from 'antd';
import { CloseOutlined, CopyOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { io, Socket } from 'socket.io-client';
import { uploadToCloudinary } from '../../slide/upload';

import './project.scss';
import { BASE_URL } from '@src/utils/constants';

import axios from 'axios';
import { useNavigate } from 'react-router';
import BackButton from '@src/components/BackButton';
import { toast } from 'react-toastify';
import { Download } from '@src/pages/dashboard/components/Share/Download';
import { isHtmlString } from '@src/utils/app_functions';
import { marked } from 'marked';
import { convertHTMLToPDF, convertHTMLToWord } from '../../essay/functions';
import { auto } from '@popperjs/core';

interface ProductProps {
    title: string;
    description: string;
    useCases: string;
    specifications: string[];
}

const ProductDescriptionGenerator: React.FC = () => {
    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [product, setProduct] = useState<ProductProps[] | null>(null);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [pdfDownloading, setPdfDownloading] = useState(false);
    const [wordDownloading, setWordDownloading] = useState(false);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const UrlRef = useRef<string>('');

    const handleUpload = async () => {
        if (!file) {
            message.warning('Please upload an image first.');
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            message.error('Authorization token is missing. Please log in.');
            return;
        }

        setLoading(true);
        setProduct(null);

        try {
            const image = await uploadToCloudinary(file);
            const productDescriptionPrompt = `
    You are an AI assistant specialized in creating engaging and detailed product descriptions for online listings. Based on the image provided (image), analyze and generate the following:
    {
      "title": "Generated product title",
      "description": "Detailed product description",
      "useCases": "usecases1, usecases2, usecases3",
      "specifications": ["Specification 1", "Specification 2", "Specification 3"]
    }
    `;

            const payload = {
                model: 'gpt-4o',
                messages: [
                    {
                        role: 'system',
                        content: productDescriptionPrompt,
                    },
                    {
                        role: 'user',
                        content: [
                            {
                                type: 'text',
                                text: 'Generate product description for this product',
                            },
                            {
                                type: 'image_url',
                                image_url: {
                                    url: image,
                                },
                            },
                        ],
                    },
                ],
            };

            const response = await axios.post(`${BASE_URL}/knowledge-base`, payload, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const AIRESULT = response?.data?.data?.choices?.[0]?.message;
            console.log('AIRESULT:', AIRESULT);

            const generatedContent = AIRESULT?.content;
            console.log('Generated Content:', generatedContent);

            if (!generatedContent) {
                throw new Error('No content returned from AI.');
            }

            let formattedData;
            try {
                formattedData = JSON.parse(generatedContent);
            } catch (parseError) {
                console.error('JSON parse error:', parseError);
                throw new Error('Invalid JSON format in AI response.');
            }

            // Validate object structure
            const isValidProduct =
                formattedData &&
                typeof formattedData === 'object' &&
                'title' in formattedData &&
                'description' in formattedData &&
                'useCases' in formattedData &&
                'specifications' in formattedData;

            if (!isValidProduct) {
                console.error('Invalid response format:', formattedData);
                throw new Error('Invalid response format.');
            }

            // Set the product data
            setProduct(formattedData);
        } catch (error: any) {
            console.error('Error generating product description:', error);
            message.error(error.message || 'Failed to generate product description.');
        } finally {
            setLoading(false);
        }
    };

    const uploadProps = {
        beforeUpload: (file: File) => {
            setFile(file);
            return false; // Prevent default upload
        },
        onRemove: () => {
            setFile(null);
            setProduct(null);
        },
        fileList: file ? [{ uid: '-1', name: file.name, status: 'done', url: URL.createObjectURL(file) }] : [],
    };

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text: string) => (
                <div className="table-cell-with-copy">
                    <span className="min-w-[10rem] md:min-w-auto">{text}</span>
                    <Tooltip title="Copy">
                        <CopyOutlined
                            className="copy-icon"
                            onClick={() => {
                                navigator.clipboard.writeText(text);
                                toast.success('Keywords copied to clipboard!');
                            }}
                        />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text: string) => (
                <div className="table-cell-with-copy">
                    <span className="min-w-[30rem] md:min-w-auto">{text}</span>
                    <Tooltip title="Copy">
                        <CopyOutlined
                            className="copy-icon"
                            onClick={() => {
                                navigator.clipboard.writeText(text);
                                toast.success('Keywords copied to clipboard!');
                            }}
                        />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Use Cases',
            dataIndex: 'useCases',
            key: 'useCases',
            render: (text: string) => (
                <div className="table-cell-with-copy">
                    <span className="min-w-[10rem] md:min-w-auto">{text}</span>
                    <Tooltip title="Copy">
                        <CopyOutlined
                            className="copy-icon"
                            onClick={() => {
                                navigator.clipboard.writeText(text);
                                toast.success('Keywords copied to clipboard!');
                            }}
                        />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Specifications',
            dataIndex: 'specifications',
            key: 'specifications',
            render: (specs: string[]) => (
                <div className="table-cell-with-copy">
                    <span className="min-w-[10rem] md:min-w-auto">{specs.join(', ')}</span>
                    <Tooltip title="Copy">
                        <CopyOutlined
                            className="copy-icon"
                            onClick={() => {
                                navigator.clipboard.writeText(specs.join(', '));
                                toast.success('Keywords copied to clipboard!');
                            }}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const navigate = useNavigate();

    async function handlePDF() {
        let data = '';
        const metaTable = document.getElementById('meta-table');
        const editorContent = metaTable?.innerHTML;
        console.log(editorContent);

        if (!editorContent) {
            toast.error('No content to download');
            return;
        }

        // Temporarily hide copy icons
        const copyIcons = metaTable?.querySelectorAll('.copy-icon');
        copyIcons?.forEach((icon) => {
            if (icon instanceof HTMLElement) {
                icon.style.display = 'none'; // Hide copy icons
            }
        });

        if (isHtmlString(editorContent)) {
            data = editorContent;
        } else {
            data = await marked(editorContent);
        }

        try {
            setPdfDownloading(true);
            await convertHTMLToPDF(data);
        } catch (error) {
            toast.error('Error downloading documents');
        } finally {
            // Restore copy icons after download
            copyIcons?.forEach((icon) => {
                if (icon instanceof HTMLElement) {
                    icon.style.display = '';
                }
            });
            setPdfDownloading(false);
        }
    }

    async function handleWord() {
        let to_html = '';
        const metaTable = document.getElementById('meta-table');
        const editorContent = metaTable?.innerHTML;

        if (!editorContent) {
            toast.error('No content to download');
            return;
        }

        // Temporarily remove buttons or specific elements
        const buttons = metaTable?.querySelectorAll('button');
        buttons?.forEach((button) => (button.style.display = 'none')); // Hide buttons

        if (isHtmlString(editorContent)) {
            to_html = editorContent;
        } else {
            to_html = await marked(editorContent);
        }

        try {
            setWordDownloading(true);
            await convertHTMLToWord(to_html);
        } catch (error) {
            toast.error('Error downloading documents');
        } finally {
            // Restore buttons after download
            buttons?.forEach((button) => (button.style.display = ''));
            setWordDownloading(false);
        }
    }

    return (
        <div className="flex flex-col  pb-5  gap-3 px-10 overflow-y-auto">
            <div className="  flex flex-row items-start mt-10 flex-row items-start">
                <BackButton
                    onclick={() => {
                        navigate(-1);
                    }}
                />
            </div>
            <div className="flex flex-col items-center justify-center mt-4 gap-3 ">
                <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mt-2 text-center">
                    Product Description Generator
                </h2>
                <p className="text-gray-600 dark:text-gray-100 ">Upload an image to get a product description.</p>
            </div>

            {file ? (
                <div className="flex flex-row justify-center relative mt-3 h-[200px] w-[200px] mx-auto ">
                    <img
                        src={URL.createObjectURL(file)}
                        alt="img"
                        className="h-[200px] w-[200px] rounded"
                        style={{ objectFit: 'cover' }}
                    />
                    <button
                        className="absolute top-2 right-2 h-8 w-8 bg-red-500 text-white p-2 rounded-full  "
                        onClick={() => setFile(null)}
                    >
                        <CloseOutlined />
                    </button>
                </div>
            ) : (
                <div className="upload-container mt-2">
                    <Upload {...uploadProps} accept="image/*">
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </div>
            )}
            <div className="flex flex-col items-center justify-center gap-3 ">
                <button
                    onClick={handleUpload}
                    disabled={!file || loading}
                    className="bg-customOrange 0 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    {loading ? (
                        <div className="h-4 w-4 border-2 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
                    ) : (
                        'Generate Description'
                    )}
                </button>
            </div>

            {product && (
                <div className="meta-tag-table-container  w-full mb-4" id="meta-table">
                    {/* <Table
                        dataSource={[product]}
                        columns={columns}
                        bordered
                        pagination={{ pageSize: 10 }}
                        rowKey="title"
                        className="project-table"
                    /> */}
                    <ProductTable product={product} />{' '}
                </div>
            )}

            {product && (
                <button
                    className=" text-white bg-customOrange w-24 rounded py-2 px-3 mb-20 ml-3"
                    onClick={() => setShowDownload(true)}
                >
                    Download
                </button>
            )}

            {showDownload && (
                <Download
                    handlePDF={handlePDF}
                    handleWord={handleWord}
                    url={UrlRef.current}
                    onClose={() => setShowDownload(false)}
                    isPdfDownloading={pdfDownloading}
                    isWordDownloading={wordDownloading}
                />
            )}
        </div>
    );
};
export default ProductDescriptionGenerator;

const ProductTable = ({ product }) => {
    return (
        <table className="custom-table">
            <thead>
                <tr>
                    <th className="header">Title</th>
                    <td>{product?.title || 'N/A'}</td>
                </tr>
                <tr>
                    <th className="header">Description</th>
                    <td>{product?.description || 'N/A'}</td>
                </tr>
                <tr>
                    <th className="header">Use Cases</th>
                    <td>
                        <ul>
                            {(product?.useCases || '').split(',').map((useCase, index) => (
                                <li key={index}>{useCase.trim()}</li>
                            ))}
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th className="header">Specifications</th>
                    <td>
                        <ul>{product?.specifications?.map((spec, index) => <li key={index}>{spec}</li>)}</ul>
                    </td>
                </tr>
            </thead>
        </table>
    );
};

import { ArrowRightIcon } from "lucide-react";

interface  getStartedProps  {
    onclick: () => void,
    className?: string | undefined
    style?: React.CSSProperties | undefined
    isLoggedIn?: boolean;
    showforFree?: boolean;
    btnText?: string;
}




const GetStartedButton = ({ onclick, className, style, isLoggedIn, showforFree }: getStartedProps) => {
    const path = window.location.pathname;
    // console.log(path);
    return (
        <button style={style} onClick={() => onclick()} className={`${className} flex flex-row items-center gap-2 justify-center rounded-[1000px]   text-white font-normal text-center text-[0.8rem] px-3 py-2 ${path === '/features'? 'border border-white' : 'border border-white'}`}>
          <span> {( isLoggedIn ? 'Go to Dashboard' : ( showforFree ? 'Get Started for Free': 'Get Started for Free' ) ) }</span> <span className="rounded-full flex flex-row items-center w-[33px] h-[33px] bg-white justify-center ">
            <ArrowRightIcon size={18} color="black" />
          </span>
        </button>
    );
}

const GetStartedForFree = ({ onclick, className, style, isLoggedIn, btnText }: getStartedProps) => {
  return (
      <button
          style={style}
          onClick={() => onclick()}
          className={`
              ${className} 
              flex flex-row items-center gap-4 justify-center 
              rounded-full shadow-md transition-all duration-300
              text-white font-semibold text-center text-sm px-5 md:px-8 py-3
              bg-[#00103A]
              active:scale-95 active:shadow-sm
          `}
      >
          <span>{ btnText ?? ( isLoggedIn ? 'Go to Dashboard' : 'Get Started for Free' ) }</span>
          <span
              className="
                  rounded-full flex flex-row items-center 
                  w-[35px] h-[35px] bg-white justify-center 
                  text-black font-semibold shadow-inner
              "
          >
              <ArrowRightIcon size={18} color="black" />
          </span>
      </button>
  );
};



export { 
    GetStartedButton,
    GetStartedForFree 
}
import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import Input from '@src/pages/dashboard/components/Input';
import { FileContent } from '@src/core/interfaces/chat';
import { ChatContent } from '@src/pages/dashboard/components/interface/interface';

import { HighlightOutlined, EditOutlined } from '@ant-design/icons';
import { GiMultipleTargets } from 'react-icons/gi';
import { CheckCheck } from 'lucide-react';
import { Slider } from 'antd';
import { handleAutoGenerateQuiz } from '../helper';
import Quiz from './answer_quiz';
import BackButton from '@src/components/BackButton';
import { useNavigate, useOutletContext } from 'react-router';


interface QuizQuestion {
    question: string;
    options: string[];
    correctAnswer: string;
    type: string;
    userAnswer?: string;
    questionScore?: number;
}
const QuizMaker = () => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [quizQuestions, setQuizQuestions] = useState<string>('');
    const [isModalVisible, setIsModalVisible] = useState(false);
   
    const [gradeLevel, setGradeLevel] = useState('');
    const [quiz, setQuiz] = useState<QuizQuestion[]>([]);
    const [generating, setGenerating] = useState(false);
    const [count, setCount] = useState(1);
    const [page, setPage] = useState(1);
    const [template, setTemplate] = useState('');
    const [Value, setIsValue] = useState('');
    const [quizState, setQuizState] = useState<QuizQuestion[]>([]);
    const [score, setScore] = useState<number|null>(null);
    const {
        setShowTop,
        setShowSidebar,
    } = useOutletContext<any>();

    const handleGradeChange = (e: any) => {
        setGradeLevel(e.target.value);
    };

    const handleCountChange = (value: any) => {
        setCount(value);
    };

    const handleQuizSubmit = () => {
        // console.log(`Score: ${score}/${quizQuestions.length}`);
        // console.log("Results:", results);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };

 

    const handleClick = (template: string) => {
        setIsModalVisible(true);

        setSelectedOption(template);
        setTemplate(template);
    };

    const optionStyle = (template: string) =>
        ` w-40 px-4 py-6 rounded-md shadow-sm cursor-pointer flex flex-col items-center text-center
        ${selectedOption === template ? 'bg-gray-200' : 'bg-gray-100 hover:bg-gray-200'}`;

    const onFinish = async () => {
        setGenerating(true);

        if (Value || quizQuestions) {
            const response = await handleAutoGenerateQuiz(count, template, gradeLevel, quizQuestions, Value);
            console.log(response);
            const quizQuestion = Array.isArray(response) ? response : [response];
            console.log(quizQuestion);
            quizQuestion.map((question) => {
                setQuiz((prevQuiz) => [...prevQuiz, { ...question, userAnswer: '',}]);
            });
            console.log(response);
            console.log(quiz);

            if (response.length > 0) {
                setGenerating(false);
                setPage(2);
            }
        }
    };

    useEffect(() => {
        if (generating) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generating]);
    const navigate = useNavigate();

    useEffect(()=>{
        setQuizState(quiz);
    },[quiz])

    return (
        <>
            {generating && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
                        </div>
                        <p className="text-center mt-4">Generating quiz...</p>
                    </div>
                </div>
            )}
            {page === 1 && !generating && (
                <div className="w-full flex flex-col h-[90%] pt-7 px-[2rem] overflow-y-auto mb-20">
                    <BackButton
                  
                        onclick={() => {
                            navigate(-1);

                            setQuiz([]);
                        }}
                    />
                    <div className="flex-1 flex flex-col justify-center gap-y-6  items-center">
                        <div className="mx-10 flex mt-10 w-full max-w-5xl">
                            <Input
                                Value={Value}
                                setValue={setIsValue}
                                extractedFile={quizQuestions}
                                setExtractedFile={setQuizQuestions}
                                isNotChat={true}
                                showLeftField={false}
                                placeholder="Attach Document or Type"
                                value={(v: string, image: string[], files: FileContent[]) => {
                                    const contentValue: ChatContent = {
                                        type: 'text',
                                        text: v || template,
                                    };

                                    if (image.length > 0) {
                                        contentValue.type = 'image_url';
                                        contentValue.image_url = {
                                            url: image[0],
                                        };
                                    }

                                    if (files.length > 0) {
                                        contentValue.type = 'files';
                                        contentValue.files = files;
                                    }
                                }}
                            />
                        </div>
                        <div className="max-w-5xl w-[85%] p-6 bg-white rounded-lg shadow-md">
                            <div className="mb-6">
                                <h1 className="text-2xl font-semibold text-gray-800">Quiz Me</h1>
                                <p className="text-gray-600">Create engaging quizzes with ease</p>
                            </div>

                            <div className="flex flex-wrap md:w-auto w-full gap-4 justify-center ">
                                <div
                                    className={optionStyle('Multiple Choice Quiz')}
                                    onClick={() => handleClick('Multiple Choice Quiz')}
                                >
                                    <GiMultipleTargets className="text-2xl text-gray-800 mb-2" />
                                    <p className="text-sm text-gray-800 font-medium">Multiple Choice Quiz</p>
                                </div>

                                <div
                                    className={optionStyle('True/False Quiz')}
                                    onClick={() => handleClick('True/False Quiz')}
                                >
                                    <CheckCheck className="text-2xl text-gray-800 mb-2" />
                                    <p className="text-sm text-gray-800 font-medium">True/False Quiz</p>
                                </div>

                                <div
                                    className={optionStyle('Short Answer Quiz')}
                                    onClick={() => handleClick('Short Answer Quiz')}
                                >
                                    <HighlightOutlined className="text-2xl text-gray-800 mb-2" />
                                    <p className="text-sm text-gray-800 font-medium">Short Answer Quiz</p>
                                </div>

                                <div
                                    className={optionStyle('Fill in the Blank Quiz')}
                                    onClick={() => handleClick('Fill in the Blank Quiz')}
                                >
                                    <EditOutlined className="text-2xl text-gray-800 mb-2" />
                                    <p className="text-sm text-gray-800 font-medium">Fill in the Blank Quiz</p>
                                </div>

                                <div
                                    className={optionStyle('Flashcard Quiz')}
                                    onClick={() => handleClick('Flashcard Quiz')}
                                >
                                    <EditOutlined className="text-2xl text-gray-800 mb-2" />
                                    <p className="text-sm text-gray-800 font-medium">Flashcard Quiz  </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex-1 flex  justify-center items-center mt-4 mb-4 ">
                        {quizQuestions  && template && (
                            <button
                                onClick={() => {
                                    onFinish();
                                }}
                                disabled={quizQuestions === ''}
                                className="p-4 rounded bg-customOrange text-white w-[30%]"
                            >
                                {' '}
                                Generate
                            </button>
                        )}
                    </div>

                    {isModalVisible && (
                        <Modal
                            title="Attach a Document"
                            open={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleOk}
                            footer={[
                                <Button key="ok" type="primary" onClick={handleOk}>
                                    OK
                                </Button>,
                            ]}
                        >
                            <div style={{ marginBottom: '16px' }}>
                                <label htmlFor="grade-level" style={{ display: 'block', marginBottom: '8px' }}>
                                    Grade Level
                                </label>
                                <select
                                    id="grade-level"
                                    style={{ width: '100%' }}
                                    value={gradeLevel}
                                    onChange={handleGradeChange}
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                >
                                    <option value="" disabled className="text-gray-500">
                                        Select Grade Level
                                    </option>
                                    {Array.from({ length: 12 }, (_, i) => (
                                        <option key={`Grade-${i + 1}`} value={`Grade ${i + 1}`}>
                                            Grade {i + 1}
                                        </option>
                                    ))}
                                    <option value="Post Graduate">Post Graduate</option>
                                </select>
                            </div>

                            <div style={{ marginBottom: '16px' }}>
                                <label htmlFor="count-slider" style={{ display: 'block', marginBottom: '8px' }}>
                                    Select Number Count
                                </label>
                                <Slider
                                    id="count-slider"
                                    min={1}
                                    max={100}
                                    value={count}
                                    onChange={handleCountChange}
                                />
                                <div>Selected Count: {count}</div>
                            </div>
                            <p>Please attach a document to create quiz questions or type them manually.</p>
                        </Modal>
                    )}
                </div>
            )}

            {page === 2 && (
                <div className="flex flex-col gap-y-6 pt-7 px-[2rem]  h-screen " style={{ overflow: 'scroll' }}>
                    <BackButton
                        onclick={() => {
                            setPage(1);
                            setQuiz([]);
                        }}
                    />

                    <Quiz questions={quiz} onSubmit={handleQuizSubmit} handleGenerate={() => handleClick(template)} quizState={quizState} setQuizState={setQuizState}  score={score}  setScore={setScore}/>

                    {isModalVisible && (
                        <Modal
                            
                            open={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleOk}
                            footer={[
                                <Button key="Cancel" className=" ml-3 p-2 rounded bg-gray-400  text-white" onClick={handleOk}>
                                    Cancel
                                </Button>,
                                <button
                                    className=" ml-3 p-2 rounded bg-customOrange  text-white"
                                    onClick={() => {
                                        setQuizState([]);
                                        setScore(null)
                                        setIsModalVisible(false)
                                        setQuiz([])
                                        onFinish();
                                        
                                    }}
                                > Submit</button>,
                            ]}
                        >
                            <div style={{ marginBottom: '16px' }}>
                                <label htmlFor="grade-level" style={{ display: 'block', marginBottom: '8px' }}>
                                    Grade Level
                                </label>
                                <select
                                    id="grade-level"
                                    style={{ width: '100%' }}
                                    value={gradeLevel}
                                    onChange={handleGradeChange}
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                >
                                    <option value="" disabled className="text-gray-500">
                                        Select Grade Level
                                    </option>
                                    {Array.from({ length: 12 }, (_, i) => (
                                        <option key={`Grade-${i + 1}`} value={`Grade ${i + 1}`}>
                                            Grade {i + 1}
                                        </option>
                                    ))}
                                    <option value="Post Graduate">Post Graduate</option>
                                </select>
                            </div>

                            <div style={{ marginBottom: '16px' }}>
                                <label htmlFor="count-slider" style={{ display: 'block', marginBottom: '8px' }}>
                                    Select Number Count
                                </label>
                                <Slider
                                    id="count-slider"
                                    min={1}
                                    max={100}
                                    value={count}
                                    onChange={handleCountChange}
                                />
                                <div>Selected Count: {count}</div>
                            </div>
                            <p>Please attach a document to create quiz questions or type them manually.</p>
                        </Modal>
                    )}
                </div>
            )}
        </>
    );
};
export default QuizMaker;

import { Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import './style.scss';
import AIGenerate from '@icons/generate.svg';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { BASE_URL } from '@src/utils/constants';
import { io, Socket } from 'socket.io-client';
import { onGenerateWithAI } from '../../curriculum/functions';
import { useOutletContext } from 'react-router';

const { TextArea } = Input;

interface JobDescriptionFormValues {
    jobTitle: string;
    department: string;
    reportsTo: string;
    jobSummary: string;
    keyResponsibilities: string;
    requiredQualifications: string;
    preferredQualifications: string;
    skillsCompetencies: string;
    compensationBenefits: string;
    applicationProcess: string;
}

interface JobDescriptionLoadingStates {
    jobSummary: boolean;
    responsibilities: boolean;
    requiredQualifications: boolean;
    structure:  boolean;
    functions: boolean;
    preferredQualifications: boolean;
    skillsCompetencies: boolean;
    instruction: boolean;
    environment: boolean;
    opportunity: boolean;
    information: boolean;
    company_values: boolean;
    growth: boolean;
}

const CustomTextAreaWithButton = ({
    placeholder,
    uniqueKey,
    loadingStates,
    setLoadingStates,
    value, // Add value prop
    onChange,
    onClickButton,
    checkIfCanGenerate,
    minRows= 4,
}: {
    placeholder: string;
    uniqueKey: keyof JobDescriptionLoadingStates;
    loadingStates: JobDescriptionLoadingStates;
    setLoadingStates: React.Dispatch<React.SetStateAction<JobDescriptionLoadingStates>>;
    value?: string; // Optional value prop
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onClickButton: () => void;
    checkIfCanGenerate: () => boolean;
    minRows?: number;
}) => {
    const handleButtonClick = () => {
        let canGenerate = checkIfCanGenerate();
        if ( !canGenerate ) return;
        setLoadingStates((prevState) => ({
            ...prevState,
            [uniqueKey]: true,
        }));
        onClickButton();
    };

    return (
        <div className="relative">
            <TextArea
                autoSize={{ minRows: minRows}}
                placeholder={placeholder}
                value={value} // Bind value
                onChange={onChange} // Bind onChange
                className="p-2 border border-gray-500 focus:outline-none"
                style={{ outline: 'none', boxShadow: 'none' }}
            />
            {!loadingStates[uniqueKey] ? (
                <div
                    onClick={handleButtonClick}
                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[45px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                >
                    <img src={AIGenerate} alt="" />
                    <span>Use Gmind AI</span>
                </div>
            ) : (
                <div className="absolute top-[65px] right-[20px] ">
                    <CircularProgress size={15} className="w-1 h-1" />
                </div>
            )}
        </div>
    );
};

const JobDescriptionWriter = ({ onGenerate }: { onGenerate: (e: string) => void;}) => {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [loadingStates, setLoadingStates] = useState<JobDescriptionLoadingStates>({
        jobSummary: false,
        responsibilities: false,
        requiredQualifications: false,
        structure: false,
        functions: false,
        preferredQualifications: false,
        skillsCompetencies: false,
        instruction: false,
        environment: false,
        opportunity: false,
        information: false,
        company_values: false,
        growth: false,
    });
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null}>({});
    const baseurl = BASE_URL;
    const socketKey = [
        'jobSummary', 
        'responsibilities', 
        'requiredQualifications', 
        'structure', 
        'functions',
        'preferredQualifications',
        'skillsCompetencies',
        'instruction',
        'environment',
        'opportunity',
        'information',
        'company_values',
        'growth',
    ];
    const [pageOneValues, setPageOneValues] = useState<{[key: string]: string}>({
        'jobTitle': '',
        'type': '',
        'compensation': '',
        'jobSummary': '',
        'responsibilities': '',
        'requiredQualifications': '',
        'structure': '',
    });

    const {
        details: userDetails,
    } = useOutletContext<any>();
    
    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        socketKey.forEach(item => {
            newSockets[item] = io(baseurl.slice(0, -2));
        });
        setSockets(newSockets);

        return () => {
            Object.values(newSockets).forEach(socket => {
                socket.close();
            });
        };
    }, []);

    useEffect(() => {
        socketKey.forEach(item => {
            const socket = sockets[item];
            if (!socket) return;

            const handleData = (data: string) => {
                let previousValue = form.getFieldValue(item);
                form.setFieldValue( item, previousValue + data);
            };

            const handleStreamEnd = () => {
                setLoadingStates((prevState) => ({
                    ...prevState,
                    [item]: false,
                }));
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);
            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleData);
            };
        });
    }, [sockets]);

    const onFinish = (values: any) => {
        const {
            jobTitle,
            type,
            compensation,
            jobSummary,
            responsibilities,
            requiredQualifications,
            structure,
        } = pageOneValues;

        const {
            functions,
            preferredQualifications,
            environment,
            instruction,
            opportunity,
            information,
            company_values,
            growth,
        } = values;

        console.log({
            jobTitle,
            type,
            compensation,
            jobSummary,
            responsibilities, 
            requiredQualifications, 
            structure,
            preferredQualifications, 
            functions, 
            environment, 
            instruction, 
            opportunity, 
            information, 
            company_values, 
            growth, 
        })

        if (
            !jobTitle ||
            !type ||
            !compensation ||
            !jobSummary ||
            !responsibilities ||
            !requiredQualifications ||
            !preferredQualifications ||
            !structure ||
            !preferredQualifications ||
            !environment ||
            !instruction ||
            !opportunity ||
            !information ||
            !company_values ||
            !functions
        ) {
            toast.error('Please fill all the required fields before generating.');
            return;
        }

        const promptMessageInfo = `
            Title: ${jobTitle}
            \nEmployment Type: ${type}
            \nReporting Structure: ${structure}
            \nCompensation and Benefits: ${compensation}
            \nJob Summary: ${jobSummary}
            \nKey Responsibilities: ${responsibilities}
            \nRequired Qualifications: ${requiredQualifications}
            \nEssential Functions: ${functions}
            \nPreferred Qualifications: ${preferredQualifications}
            \nWork Environment: ${environment}
            \nApplication Instructions: ${instruction}
            \nEqual Opportunity Statement: ${opportunity}
            \nCompany Information: ${information}
            \nCompany Values: ${company_values}
            \nGrowth Opportunities: ${growth}
        `;
        

        let promptMessage = `Generate a detailed and professional job description for the specified position with the following details: ${promptMessageInfo}. Kindly ensure to follow the details provided`;
        onGenerate(promptMessage);
    };

    const handleNext = () => {
        if (page < 2) {
            form.validateFields().then(() => {
                setPage(page + 1);
            });
        }
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
                jobTitle: '',
                department: '',
                reportsTo: '',
                jobSummary: '',
                keyResponsibilities: '',
                requiredQualifications: '',
                preferredQualifications: '',
                skillsCompetencies: '',
                compensationBenefits: '',
                applicationProcess: '',
            }}
        >
            <h1 className="text-xl dark:text-white font-bold font-Poppins mb-2">Job Description Writer</h1>
            <p className="text-md  dark:text-gray-300 font-Poppins mb-4">
                Create a comprehensive and informative job description with the help of this tool.
            </p>

            {page === 1 && (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Job Title"
                                name="jobTitle"
                                rules={[{ required: true, message: 'Job Title is required' }]}
                            >
                                <Input placeholder={`E.g., "Marketing Manager," "Software Engineer," "Customer Service Representative."`} />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Employment Type"
                                name="type"
                                rules={[{ required: true, message: 'Employment Type is required' }]}
                            >
                                <Input placeholder={`E.g., "Full-time with occasional travel," "Parttime with evening shifts."`} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Compensation and Benefits"
                                name="compensation"
                                rules={[{ required: true, message: 'Reports To is required' }]}
                            >
                                <TextArea autoSize={{minRows: 2}} placeholder={` E.g., "$60,000 - $80,000 per year," "Health benefits, 401(k)."`} />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Job Summary"
                                name="jobSummary"
                                rules={[{ required: true, message: 'Job Summary is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    minRows={3}
                                    placeholder="Let Gmind AI generate job summary"
                                    uniqueKey="jobSummary"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('jobSummary')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('jobSummary', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        let jobTitle = form.getFieldValue('jobTitle');
                                        if ( !jobTitle ){
                                            toast.error('Enter job title to use this feature');
                                            return false;
                                        }

                                        let compensation = form.getFieldValue('compensation');
                                        if ( !compensation ){
                                            toast.error('Enter compensation to use this feature');
                                            return false;
                                        }

                                        let type = form.getFieldValue('type');
                                        if ( !type ){
                                            toast.error('Fill in Employment type to value to use this feature');
                                            return false;
                                        }
                                        
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('jobSummary', '')
                                        let jobDetails = `
                                            Title: ${ form.getFieldValue('jobTitle')}
                                            Employment Type: ${ form.getFieldValue('type')}
                                            Compensation and Benefits: ${ form.getFieldValue('compensationBenefits')}
                                        `
                                        let prompt = `Generate a job summary with not more than 300 characters for a job with this details ${jobDetails}`;
                                        const socket = sockets["jobSummary"];
                                        onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Key Responsibilities"
                                name="responsibilities"
                                rules={[{ required: true, message: 'Key Responsibilities is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    minRows={3}
                                    placeholder={` E.g., "Develop strategies for marketing campaigns," "Analyze data to inform product development."`}
                                    uniqueKey="responsibilities"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('responsibilities')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('responsibilities', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        let jobTitle = form.getFieldValue('jobTitle');
                                        if ( !jobTitle ){
                                            toast.error('Enter job title to use this feature');
                                            return false;
                                        }

                                        let compensation = form.getFieldValue('compensation');
                                        if ( !compensation ){
                                            toast.error('Enter compensation to use this feature');
                                            return false;
                                        }

                                        let type = form.getFieldValue('type');
                                        if ( !type ){
                                            toast.error('Fill in Employment type to value to use this feature');
                                            return false;
                                        }
                                        
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('responsibilities', '')
                                        let jobDetails = `
                                            Title: ${ form.getFieldValue('jobTitle')}
                                            Employment Type: ${ form.getFieldValue('type')}
                                            Compensation and Benefits: ${ form.getFieldValue('compensationBenefits')}
                                        `
                                        let prompt = `Generate a job Key Responsibilities with not more than 300 characters for a job with this details ${jobDetails}`;
                                        const socket = sockets["responsibilities"];
                                        onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Required Qualifications"
                                name="requiredQualifications"
                                rules={[{ required: true, message: 'Key Responsibilities are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    minRows={3}
                                    placeholder="Let Gmind AI generate key responsibilities"
                                    uniqueKey="requiredQualifications"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('requiredQualifications')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('requiredQualifications', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        let jobTitle = form.getFieldValue('jobTitle');
                                        if ( !jobTitle ){
                                            toast.error('Enter job title to use this feature');
                                            return false;
                                        }

                                        let compensation = form.getFieldValue('compensation');
                                        if ( !compensation ){
                                            toast.error('Enter compensation to use this feature');
                                            return false;
                                        }

                                        let type = form.getFieldValue('type');
                                        if ( !type ){
                                            toast.error('Fill in Employment type to value to use this feature');
                                            return false;
                                        }
                                        
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('requiredQualifications', '')
                                        let jobDetails = `
                                            Title: ${ form.getFieldValue('jobTitle')}
                                            Employment Type: ${ form.getFieldValue('type')}
                                            Compensation and Benefits: ${ form.getFieldValue('compensationBenefits')}
                                        `
                                        let prompt = `Generate a job key responsibilities with not more than 300 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["requiredQualifications"];
                                        onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Reporting Structure"
                                name="structure"
                                rules={[{ required: true, message: 'Reporting Structure is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    minRows={3}
                                    placeholder={` E.g., "Develop strategies for marketing campaigns," "Analyze data to inform product development."`}
                                    uniqueKey="structure"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('structure')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('structure', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        let jobTitle = form.getFieldValue('jobTitle');
                                        if ( !jobTitle ){
                                            toast.error('Enter job title to use this feature');
                                            return false;
                                        }

                                        let compensation = form.getFieldValue('compensation');
                                        if ( !compensation ){
                                            toast.error('Enter compensation to use this feature');
                                            return false;
                                        }

                                        let type = form.getFieldValue('type');
                                        if ( !type ){
                                            toast.error('Fill in Employment type to value to use this feature');
                                            return false;
                                        }
                                        
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('structure', '')
                                        let jobDetails = `
                                            Title: ${ form.getFieldValue('jobTitle')}
                                            Employment Type: ${ form.getFieldValue('type')}
                                            Compensation and Benefits: ${ form.getFieldValue('compensationBenefits')}
                                        `
                                        let prompt = `Generate a job Reporting Structure with not more than 300 characters for a job with this details ${jobDetails}`;
                                        const socket = sockets["structure"];
                                        onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <Row gutter={16}>
                        <Col span={24}>
                            <button
                                onClick={() =>
                                    form.validateFields().then(() => {
                                        setPageOneValues({
                                            'jobTitle': form.getFieldValue('jobTitle'),
                                            'type': form.getFieldValue('type'),
                                            'requiredQualifications': form.getFieldValue('requiredQualifications'),
                                            'compensation': form.getFieldValue('compensation'),
                                            'jobSummary': form.getFieldValue('jobSummary'),
                                            'responsibilities': form.getFieldValue('responsibilities'),
                                            'structure': form.getFieldValue('structure'),
                                        })
                                        setPage(page + 1);
                                    })
                                }
                                className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
                            >
                                Next
                            </button>
                        </Col>
                    </Row>
                </>
            )}
            {page === 2 && (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Essential Functions"
                                name="functions"
                                rules={[{ required: true, message: 'Essential Functions are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`E.g., "Must be able to lift 20 lbs," "Proficiency in coding required."`}
                                    uniqueKey="functions"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('functions')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('functions', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('functions', '')
                                        let jobDetails = `
                                            Title: ${ pageOneValues['jobTitle']}
                                            Employment Type: ${ pageOneValues['type']}
                                            Reporting Structure: ${ pageOneValues['structure']}
                                            Compensation and Benefits: ${ pageOneValues['compensation']}
                                            Job Summary: ${ pageOneValues['jobSummary']}
                                            Key Responsibilities: ${ pageOneValues['responsibilities']}
                                            Required Qualifications: ${ pageOneValues['requiredQualifications']}
                                        `
                                        let prompt = `Generate a job Essential Functions with not more than 500 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["functions"];
                                        onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Preferred Qualifications"
                                name="preferredQualifications"
                                rules={[{ required: true, message: 'Preferred Qualifications are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`: E.g., "Master’s degree," "Experience with cloud infrastructure."`}
                                    uniqueKey="preferredQualifications"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('preferredQualifications')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('preferredQualifications', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('preferredQualifications', '')
                                        let jobDetails = `
                                            Title: ${ pageOneValues['jobTitle']}
                                            Employment Type: ${ pageOneValues['type']}
                                            Reporting Structure: ${ pageOneValues['structure']}
                                            Compensation and Benefits: ${ pageOneValues['compensation']}
                                            Job Summary: ${ pageOneValues['jobSummary']}
                                            Key Responsibilities: ${ pageOneValues['responsibilities']}
                                            Required Qualifications: ${ pageOneValues['requiredQualifications']}
                                        `
                                        let prompt = `Generate a job preferred Qualifications with not more than 500 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["preferredQualifications"];
                                        onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Work Environment"
                                name="environment"
                                rules={[{ required: true, message: 'Work Environment are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`E.g., "Office environment with moderate noise", "Outdoor work required weekly."`}
                                    uniqueKey="environment"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('environment')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('environment', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('environment', '')
                                        let jobDetails = `
                                            Title: ${ pageOneValues['jobTitle']}
                                            Employment Type: ${ pageOneValues['type']}
                                            Reporting Structure: ${ pageOneValues['structure']}
                                            Compensation and Benefits: ${ pageOneValues['compensation']}
                                            Job Summary: ${ pageOneValues['jobSummary']}
                                            Key Responsibilities: ${ pageOneValues['responsibilities']}
                                            Required Qualifications: ${ pageOneValues['requiredQualifications']}
                                        `
                                        let prompt = `Generate a job Work Environment requirement with not more than 500 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["environment"];
                                        onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                                    }}
                                />
                            </Form.Item>
                        </div>

                        <div>
                            <Form.Item
                                label="Growth Opportunities"
                                name="growth"
                                rules={[{ required: true, message: 'Growth Opportunities are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`E.g., "Opportunity for promotion to Senior Manager," "Pathway to leadership roles."`}
                                    uniqueKey="growth"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('growth')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('growth', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('growth', '')
                                        let jobDetails = `
                                            Title: ${ pageOneValues['jobTitle']}
                                            Employment Type: ${ pageOneValues['type']}
                                            Reporting Structure: ${ pageOneValues['structure']}
                                            Compensation and Benefits: ${ pageOneValues['compensation']}
                                            Job Summary: ${ pageOneValues['jobSummary']}
                                            Key Responsibilities: ${ pageOneValues['responsibilities']}
                                            Required Qualifications: ${ pageOneValues['requiredQualifications']}
                                        `
                                        let prompt = `Generate a job Growth Opportunities requirement with not more than 500 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["growth"];
                                        onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Application Instructions"
                                name="instruction"
                                rules={[{ required: true, message: 'Skills and Competencies are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`E.g., "Submit resume and cover letter via email," "Include a portfolio link."`}
                                    uniqueKey="instruction"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('instruction')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('instruction', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('instruction', '')
                                        let jobDetails = `
                                            Title: ${ pageOneValues['jobTitle']}
                                            Employment Type: ${ pageOneValues['type']}
                                            Reporting Structure: ${ pageOneValues['structure']}
                                            Compensation and Benefits: ${ pageOneValues['compensation']}
                                            Job Summary: ${ pageOneValues['jobSummary']}
                                            Key Responsibilities: ${ pageOneValues['responsibilities']}
                                            Required Qualifications: ${ pageOneValues['requiredQualifications']}
                                        `
                                        let prompt = `Generate a job skills and Competencies requirement with not more than 500 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["instruction"];
                                        onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                                    }}
                                />
                            </Form.Item>
                        </div>

                        <div>
                            <Form.Item
                                label="Equal Opportunity Statement"
                                name="opportunity"
                                rules={[{ required: true, message: 'Equal Opportunity Statement are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`E.g., "We are an equal opportunity employer, committed to diversity and inclusion."`}
                                    uniqueKey="opportunity"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('opportunity')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('opportunity', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('opportunity', '')
                                        let jobDetails = `
                                            Title: ${ pageOneValues['jobTitle']}
                                            Employment Type: ${ pageOneValues['type']}
                                            Reporting Structure: ${ pageOneValues['structure']}
                                            Compensation and Benefits: ${ pageOneValues['compensation']}
                                            Job Summary: ${ pageOneValues['jobSummary']}
                                            Key Responsibilities: ${ pageOneValues['responsibilities']}
                                            Required Qualifications: ${ pageOneValues['requiredQualifications']}
                                        `
                                        let prompt = `Generate a job Equal Opportunity Statement requirement with not more than 500 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["opportunity"];
                                        onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Company Information"
                                name="information"
                                rules={[{ required: true, message: 'Company Information are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`E.g., "Innovative tech firm focused on sustainability," "Family-owned business with a collaborative work environment."`}
                                    uniqueKey="information"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('information')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('information', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('information', '')
                                        let jobDetails = `
                                            Title: ${ pageOneValues['jobTitle']}
                                            Employment Type: ${ pageOneValues['type']}
                                            Reporting Structure: ${ pageOneValues['structure']}
                                            Compensation and Benefits: ${ pageOneValues['compensation']}
                                            Job Summary: ${ pageOneValues['jobSummary']}
                                            Key Responsibilities: ${ pageOneValues['responsibilities']}
                                            Required Qualifications: ${ pageOneValues['requiredQualifications']}
                                        `
                                        let prompt = `Generate a job Company Information requirement with not more than 500 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["information"];
                                        onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                                    }}
                                />
                            </Form.Item>
                        </div>

                        <div>
                            <Form.Item
                                label="Company Values"
                                name="company_values"
                                rules={[{ required: true, message: 'Company Values are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder={`E.g., "Integrity, Innovation, Customer Focus."`}
                                    uniqueKey="company_values"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('company_values')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('company_values', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('company_values', '')
                                        let jobDetails = `
                                            Title: ${ pageOneValues['jobTitle']}
                                            Employment Type: ${ pageOneValues['type']}
                                            Reporting Structure: ${ pageOneValues['structure']}
                                            Compensation and Benefits: ${ pageOneValues['compensation']}
                                            Job Summary: ${ pageOneValues['jobSummary']}
                                            Key Responsibilities: ${ pageOneValues['responsibilities']}
                                            Required Qualifications: ${ pageOneValues['requiredQualifications']}
                                        `
                                        let prompt = `Generate a job Company Values requirement with not more than 500 characters for a job with this details ${jobDetails}. Ensure to itemize key responsibilities`;
                                        const socket = sockets["company_values"];
                                        onGenerateWithAI(socket, prompt, userDetails?.userId ?? "");
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <button
                            className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
                            onClick={handlePrevious}
                        >
                            Previous
                        </button>

                        <button type="submit" className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2">
                            Generate
                        </button>
                    </div>
                </>
            )}
        </Form>
    );
};

export default JobDescriptionWriter;

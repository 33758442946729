import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router';
import { planAmountDetails } from '@src/core/interfaces/general';
import { toast } from 'react-toastify';
import { BASE_URL } from '@src/utils/constants';
import { getRegion } from '@src/utils/app_functions';
import { Button as CustomButton } from "@src/pages/acquisition/pricing/components/ui/button";
import { chatRoutes } from '@src/utils/app_routes';

interface expiredModalProps {
    isVisible: boolean;
    packageName: string;
    amountDetails?: planAmountDetails;
    isOnAffiliate: boolean;
}

const FreeExpiredPackageModal = ({isVisible}: {isVisible: boolean}) => {
  const [isModalVisible, setIsModalVisible] = useState(isVisible);
  const [planInterval, setPlanInterval] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

 

  useEffect(() => {
    setIsModalVisible(isVisible);
  }, [isVisible])



  const handleUpgrade = () => {
    navigate(chatRoutes.pricing);
    setIsModalVisible(false);
  };
  return (
    <div>
      <Modal
        title={<h2 className='text-center text-[0.9rem]' >{`Subscribe to Gmind AI`}</h2>}
        open={isModalVisible}
        onCancel={() => {}}
        closable={false}
        maskClosable={false}
        width={500}
        footer={[
            <Button key="ok" type="primary" className='bg-[#75e900] text-white' onClick={handleUpgrade}>
                Select Plan
            </Button>,
          
        ]}
      >
        <p>
          {
            "Pay now to continue enjoying uninterrupted access to all your favorite features. Don't miss out—renew today and stay connected!" 
          }
          </p>
      </Modal>
    </div>
  );
};

export default FreeExpiredPackageModal;